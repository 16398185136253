import { ReactNode, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectProps,
} from "@material-ui/core";
import SearchBar from "components/SearchBar";
import { ItemFilter } from "FiltersModule/utils";

type ItemType = number | string;

type DropdownSelectProps<T extends ItemType> = {
  inputLabel: string;
  width?: string;
  items: T[];
  /**
   * If itemHash is null, then use the string | number from items as label
   */
  itemHash: Nullable<Record<T, unknown>>;
  selectedItems: T[];
  onSelect: (item: T | T[]) => void;
  labelKey?: string;
  menuMaxHeight?: number;
  isMultiSelect: boolean;
  // Do later
  showSearchBar?: boolean;
  itemFilter?: ItemFilter;
  isRequired?: boolean;
};

const DropdownSelect = <T extends ItemType>({
  inputLabel,
  items,
  itemHash,
  selectedItems,
  menuMaxHeight = 300,
  onSelect,
  isMultiSelect,
  labelKey = "label",
  isRequired = false,
  showSearchBar,
  itemFilter,
}: DropdownSelectProps<T>) => {
  // const [inputValue, setInputValue] = useState("");

  // const itemsToUse =
  //   showSearchBar && itemFilter && inputValue
  //     ? items.filter((item) =>
  //         itemFilter({ item, searchWords: [inputValue], filterKeysArray: [] })
  //       )
  //     : items;

  const itemsToUse = items; // Later will use itemFilter

  const selectedItemsSet = new Set(selectedItems);

  const renderSelectedItems = () => {
    return selectedItems.join(", ");
  };

  const onChange: SelectProps["onChange"] = (e) => {
    const { value } = e.target;

    if (isMultiSelect) {
      onSelect(value as T[]);
    } else {
      onSelect(value as T);
    }
  };

  return (
    <FormControl className="filter-fields" fullWidth>
      <InputLabel id={`multi-select-${inputLabel}`}>{inputLabel}</InputLabel>
      <Select
        required={isRequired}
        labelId={`multi-select-${inputLabel}`}
        multiple={isMultiSelect}
        value={selectedItems}
        onChange={onChange}
        input={<Input />}
        renderValue={renderSelectedItems}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          style: {
            maxHeight: menuMaxHeight,
          },
        }}
      >
        {/* <div>
          {showSearchBar ? (
            <SearchBar inputValue={inputValue} setInputValue={setInputValue} />
          ) : (
            <></>
          )}
        </div> */}
        {itemsToUse.map((itemStr) => {
          const item = itemHash ? itemHash[itemStr] : itemStr;
          const label =
            typeof item === "string" || typeof item === "number"
              ? item
              : (item[labelKey as keyof typeof item] as ReactNode);

          return (
            <MenuItem key={itemStr} value={itemStr}>
              {isMultiSelect ? (
                <Checkbox checked={selectedItemsSet.has(itemStr)} />
              ) : (
                <></>
              )}
              <ListItemText primary={label} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default DropdownSelect;
