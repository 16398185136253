import { omit } from "lodash";

const GALAXY_PROPS: Set<string> = new Set([
  "sales_force_link",
  "sales_force_id",
]);

const isGalaxyProp = (prop: string, galaxyProps: Set<string>) =>
  galaxyProps.has(prop);

/**
 * Return an original object with galaxy props key and value nested inside `galaxy_props`
 * Currently does not handle nested props
 * @param obj - A flat object that may/may not contain galaxy props
 * @param galaxyProps - Set<String>
 * @returns
 */
export const makeObjectWithGalaxyProps = (
  obj: Record<any, any>,
  galaxyPropsToFind: Set<string> = GALAXY_PROPS
) => {
  const newObj = Object.keys(obj).reduce(
    (acc: Record<any, any>, key: string) => {
      if (isGalaxyProp(key, galaxyPropsToFind)) {
        acc.galaxy_props[key] = obj[key];
      } else {
        acc[key] = obj[key];
      }

      return acc;
    },
    {
      galaxy_props: {},
    }
  );
  return newObj;
};
