import { TenantIsoStateIds } from "TenantModule/constants";
import {
  selectTenantHash,
  selectTenantOrder,
  selectTenantIsoStates,
  selectDefaultTenant,
} from "TenantModule/selectors";
import { Tenant } from "TenantModule/types";
import ApiClientManager from "core/apiClient";
import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAuthUser } from "selectors";
import { archiveTenant, fetchTenants } from "thunk";
import { getIsTenantEnabled } from "utils/feature";

export const useTenants = ({ isOff = true }: { isOff?: boolean } = {}) => {
  const dispatch = useDispatch();
  const isoStates = useSelector(selectTenantIsoStates);
  const defaultTenant = useSelector(selectDefaultTenant);
  const user = useSelector(selectAuthUser);
  const tenantsHash = useSelector(selectTenantHash);
  const tenantOrder = useSelector(selectTenantOrder);

  useEffect(() => {
    if (!isOff && getIsTenantEnabled()) dispatch(fetchTenants({}));
  }, [dispatch, isOff]);

  const getTenant = useCallback(
    ({ tenantId }: { tenantId: Tenant["id"] }): Tenant | undefined => {
      if (tenantId === defaultTenant?.id) {
        return defaultTenant;
      }

      return tenantsHash[tenantId];
    },
    [tenantsHash, defaultTenant]
  );

  const getTenantByIsoState = useCallback(
    ({ isoStateId }: { isoStateId: TenantIsoStateIds }) => {
      return isoStates[isoStateId] || {};
    },
    [isoStates]
  );

  const bulkArchiveTenant = useCallback(
    ({
      tenantIds,
      onArchiveTenantSuccess,
    }: {
      tenantIds: number[];
      onArchiveTenantSuccess?: VoidFunction;
    }) => {
      tenantIds.forEach((tenantId) => {
        const tenant = getTenant({ tenantId });
        if (tenant) {
          dispatch(
            archiveTenant({
              tenant: tenant?.tenantName,
              archiver: user?.account.name || "",
              meta: {
                onSuccess: ({ response }) => {
                  onArchiveTenantSuccess?.();
                },
              },
            })
          );
        }
      });
    },
    [dispatch, getTenant, user?.account.name]
  );

  const handleSelectGlobalTenant = useCallback(
    ({ tenant, skipReload }: { tenant: Tenant; skipReload?: boolean }) => {
      ApiClientManager.setTenant(tenant);
      if (!skipReload) window.location.reload();
    },
    []
  );

  const selectedGlobalTenant = getTenant({
    tenantId: ApiClientManager.getTenant().id,
  });

  return {
    tenantsHash,
    tenantOrder,
    bulkArchiveTenant,
    selectedGlobalTenant,
    getTenant,
    getTenantByIsoState,
    defaultTenant,
    handleSelectGlobalTenant,
    isUsingNonDefaultTenant: getIsTenantEnabled() ? !selectedGlobalTenant?.isDefault : false,
  };
};
