import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  checkUrlHasSalesforceSubscriptionLink,
  getTeamProfileUrl,
} from "utils";
import { useRequestStatus } from "RequestStatusModule/hooks/useRequestStatus";
import { TagContainer } from "SandboxModule/styles";
import { standardTagsList } from "@constants";
import { createTeam } from "TeamModule/thunks";
import { LinearBuffer } from "components/Progress";
import { useArrayTagsInput } from "hooks/useArrayItems/useArrayTagsInput";
import {
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
} from "@material-ui/core";
import SimpleModal from "components/SimpleModal";
import { CreateTeamSuccessResponse } from "TeamModule/types";
import { BaseDivider } from "components/BaseDivider";
import { TenantRealmInfo } from "TenantModule/components/TenantRealmInfo";
import { useTenantRealmDropdownsFlow } from "TenantModule/hooks/useTenantRealmDropdownsFlow";
import { TagsListSectionWithInput } from "components/TagsListSectionWithInput";

const requestStatusId = "create-team-modal";

type InitialValuesType = {
  email: string;
  company: string;
  sales_force_id: string;
  sales_force_link: string;
};

const initialValues = {
  email: "",
  company: "",
  sales_force_link: "",
  sales_force_id: "",
};

export const CreateTeamModal = ({ toggle }: { toggle: () => void }) => {
  const [createTeamFormFields, setFields] = useState<InitialValuesType>(
    initialValues
  );
  const dispatch = useDispatch();
  const { requestStatus } = useRequestStatus({
    requestStatusId,
  });
  const { isRequesting: isCreatingTeam } = requestStatus || {};
  const {
    handleCheckboxChange,
    standardTags,
    addTag,
    removeTag,
    inputRef,
    tagsList,
    tagError,
  } = useArrayTagsInput();
  const { selectedTenant, selectedRealm } = useTenantRealmDropdownsFlow({});

  // True when salesforce link is a subscription link
  const salesforceLinkHasSubscriptionLink = checkUrlHasSalesforceSubscriptionLink(
    createTeamFormFields.sales_force_link
  );
  // Don't show error when salesforce link is empty string
  const salesforceLinkError = createTeamFormFields.sales_force_link.trim()
    ? !salesforceLinkHasSubscriptionLink
    : false;

  const onCreateTeamFormChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value, type, checked } = event.target;
    setFields((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const submitCreateTeamForm = () => {
    const {
      sales_force_id,
      sales_force_link,
      ...newTeamFields
    } = createTeamFormFields;
    const params = {
      ...newTeamFields,
      meta: {
        onSuccess: ({
          response,
        }: {
          response: { data: CreateTeamSuccessResponse };
        }) => {
          const { team_id, team_created } = response.data;
          if (!team_id || !team_created) {
            alert("Create team failed, please try again");
          } else {
            window.location.href = getTeamProfileUrl({
              teamId: +team_id,
            });
          }
        },
        onFailure: () => {
          alert("Create team failed, please try again");
        },
        requestStatusId,
      },
      galaxy_props: {
        sales_force_link,
        tags: tagsList,
        sales_force_id,
      },
    };
    dispatch(createTeam(params));
  };

  const onDeleteTag = (tag: string) => {
    removeTag(tag);
  };

  const CreateTeamModalBody = (
    <div>
      <TenantRealmInfo
        tenantName={selectedTenant?.tenantName}
        realmId={selectedRealm?.realm_id}
        realmName={selectedRealm?.team_name}
      />
      <p>Enter information to sign up for a new team</p>
      <form>
        <Box display={"flex"} gridGap={10} flexDirection={"column"}>
          <TextField
            required
            onChange={onCreateTeamFormChange}
            value={createTeamFormFields.email || ""}
            label="Email Address"
            type="email"
            name="email"
            placeholder="Email"
            fullWidth
            variant="outlined"
            disabled={isCreatingTeam}
          />
          <TextField
            required
            onChange={onCreateTeamFormChange}
            value={createTeamFormFields.company || ""}
            label="Company name"
            name="company"
            placeholder="My Company"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isCreatingTeam}
          />
          <Box display={"flex"} flexDirection={"row"} gridGap={5}>
            <TextField
              onChange={onCreateTeamFormChange}
              value={createTeamFormFields.sales_force_link || ""}
              label="Salesforce link"
              name="sales_force_link"
              placeholder="Salesforce Link"
              type="url"
              fullWidth
              variant="outlined"
              disabled={isCreatingTeam}
              error={salesforceLinkError}
            />
            <TextField
              onChange={onCreateTeamFormChange}
              value={createTeamFormFields.sales_force_id}
              label="Salesforce Subscription Name"
              name="sales_force_id"
              placeholder="SUB-000100"
              type="text"
              fullWidth
              variant="outlined"
              disabled={isCreatingTeam}
            />
          </Box>
        </Box>

        <BaseDivider />
        {/* ----------------------------------- add tags ---------------------------------- */}

        <FormControl component="fieldset" fullWidth>
          <Box component="h3">Add tags to this team</Box>
          <TagContainer>
            <div className="initial-tag-div">
              <FormGroup>
                {standardTagsList.map((item) => (
                  <FormControlLabel
                    key={`${item.value}-${
                      standardTags[item.value as keyof typeof standardTags]
                    }`}
                    control={
                      <Checkbox
                        checked={
                          standardTags[item.value as keyof typeof standardTags]
                        }
                        onChange={handleCheckboxChange}
                        name={item.name}
                        value={item.value}
                        disabled={isCreatingTeam}
                      />
                    }
                    label={item.label}
                  />
                ))}
              </FormGroup>
            </div>
            <div className="add-tag-div">
              <TagsListSectionWithInput
                tagList={tagsList}
                onDelete={removeTag}
                inputRef={inputRef}
                onKeyDown={addTag}
                helperText={tagError}
                error={!!tagError}
                disabled={isCreatingTeam}
                placeholder="Enter custom tag"
              />
            </div>
          </TagContainer>
        </FormControl>
        <Box> {isCreatingTeam ? <LinearBuffer /> : ""}</Box>
      </form>
    </div>
  );

  return (
    <SimpleModal
      onClose={toggle}
      open
      onConfirm={submitCreateTeamForm}
      confirmButtonText={"Submit"}
      closeOnConfirm
      headerText={"Create New Team"}
      disableConfirmButton={isCreatingTeam || salesforceLinkError}
      disableCancelButton={isCreatingTeam}
      Body={CreateTeamModalBody}
    />
  );
};
