import {
  Box,
  Button,
  DialogActions,
  TextField,
  FormControl,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { TagContainer } from "SandboxModule/styles";
import { standardTagsList } from "@constants";
import { LinearBuffer } from "components/Progress";
import { conditionallyRenderElement } from "utils";
import ListSelect from "components/ListSelect";
import { BaseDivider } from "components/BaseDivider";
import { TenantRealmInfo } from "TenantModule/components/TenantRealmInfo";
import { TagsListSectionWithInput } from "components/TagsListSectionWithInput";
import { useTenantRealmDropdownsFlow } from "TenantModule/hooks/useTenantRealmDropdownsFlow";
import { useSandboxModalFields } from "SandboxModule/hooks/useSandboxModalFields";
import { CreateRealmParams } from "SandboxModule/types";
import { useRealmIdGeneration } from "SandboxModule/hooks/useRealmIdGeneration";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToggle } from "react-use";
import { fetchAvailableDumpFiles } from "SandboxModule/thunks";
import { selectAuthUser, selectAvailableDumpFilesData } from "selectors";
import useCreateSandboxModal from "hooks/useCreateSandboxModal";
import { RealmTypes } from "RealmModule/constants/realm";
import { useCreateRealm } from "SandboxModule/hooks/useCreateRealm";

export type NewSandboxInitialValues = Omit<
  CreateRealmParams,
  "realmType" | "metaPayload"
>;

const defaultRegion = "us-east-1";
const defaultDomain = "mosaicapp.com";

const newSandboxInitialValues = {
  realmId: "", //
  teamName: "",
  serviceName: "", //
  fileName: "", //
  region: "", //
  domain: "", //
  /* ---------------------------------Optional----------------------------------------- */
  port: 0,
  protocol: "",
  environment: "",
};

export const CreateSandboxForm: React.FC<{
  onRealmCreateSuccess: () => void;
}> = ({
  onRealmCreateSuccess,
}) => {
  const { selectedTenant } = useTenantRealmDropdownsFlow({});
  const dispatch = useDispatch();
  const {
    handleTagCheckboxChange,
    standardTags,
    addTag,
    removeTag,
    inputRef,
    tagsList,
    tagError,
    teamNameInputError,
  } = useSandboxModalFields();
  const [
    newSandboxValues,
    setNewSandboxValues,
  ] = useState<NewSandboxInitialValues>(newSandboxInitialValues);
  const [isAdvancedMode, toggleAdvanceMode] = useToggle(false);
  const { createRealm, isCreatingRealm } = useCreateRealm({
    onSuccess: onRealmCreateSuccess,
  });
  const { openCreateSandboxDumpFileModal } = useCreateSandboxModal();
  const refreshFileList = () => {
    dispatch(fetchAvailableDumpFiles({}));
  };
  const user = useSelector(selectAuthUser);
  const { isRequesting: isFetchingDumpFiles, data: dumpFiles } = useSelector(
    selectAvailableDumpFilesData
  );

  const generateRealmIdOnSuccess: Parameters<
    typeof useRealmIdGeneration
  >[0]["onSuccess"] = ({ response }) => {
    const realmId = response.data;
    if (realmId && user && selectedTenant) {
      const params = {
        realmId,
        teamName: newSandboxValues.teamName,
        fileName: newSandboxValues.fileName,
        domain: defaultDomain,
        region: defaultRegion, // Hard-coded - May need it to be dynamic in the future
        tags: tagsList,
        realmType: RealmTypes.sandbox,
        tenantName: selectedTenant.tenantName,
        metaPayload: { name: user.account.name },
      };

      alert(
        `Initiating creating new sandbox: ${newSandboxValues.teamName}. It will take a while.`
      );
      createRealm(params);
    }
  };

  // Dup file
  const onSelectSandboxFile = (values: string[]) => {
    setNewSandboxValues((prev) => ({
      ...prev,
      fileName: values[0],
    }));
  };

  const mostRecentFile = dumpFiles[dumpFiles.length - 1]?.value;
  useEffect(() => {
    setNewSandboxValues({
      ...newSandboxInitialValues,
      fileName: mostRecentFile,
    });
  }, [mostRecentFile]);

  // on form change
  const onCreateSandboxFormChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setNewSandboxValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const { generateRealmId } = useRealmIdGeneration({
    onSuccess: generateRealmIdOnSuccess,
  });

  const handleSubmitCreateSandboxForm = () => {
    generateRealmId({
      teamName: newSandboxValues.teamName,
    });
  };

  return (
    <form>
      <TenantRealmInfo tenantName={selectedTenant?.tenantName} />
      <TextField
        required
        onChange={onCreateSandboxFormChange}
        value={newSandboxValues.teamName}
        label="Team Name"
        name="teamName"
        type="text"
        fullWidth
        variant="outlined"
        helperText={teamNameInputError}
        error={!!teamNameInputError}
      />
      <BaseDivider />

      <FormControl component="fieldset" fullWidth>
        <Box component="h3">Add tags to this team</Box>
        <TagContainer>
          <div className="initial-tag-div">
            <FormGroup>
              {standardTagsList.map((item) => (
                <FormControlLabel
                  key={`${item.value}-${
                    standardTags[item.value as keyof typeof standardTags]
                  }`}
                  control={
                    <Checkbox
                      checked={
                        standardTags[item.value as keyof typeof standardTags]
                      }
                      onChange={handleTagCheckboxChange}
                      name={item.name}
                      value={item.value}
                      disabled={isCreatingRealm}
                    />
                  }
                  label={item.label}
                />
              ))}
            </FormGroup>
          </div>
          <div className="add-tag-div">
            <TagsListSectionWithInput
              tagList={tagsList}
              onDelete={removeTag}
              inputRef={inputRef}
              onKeyDown={addTag}
              helperText={tagError}
              disabled={isCreatingRealm}
              error={!!tagError}
              placeholder="Enter custom tag"
            />
          </div>
          {/* ----------------------------------- for start date ---------------------------------- */}
          {/* <div className="start-date">
              <TextField
                value={tagInput}
                onKeyDown={addTag(tagInput)}
                helperText="Start Date"
                onChange={handleInputChange}
                disabled={isCreatingTeam}
                type="date"
              />
            </div> */}
        </TagContainer>
      </FormControl>

      {isAdvancedMode && (
        <>
          <Box justifyContent={"flex-end"} display="flex" mb={2}>
            <Button
              disabled={isCreatingRealm}
              color="primary"
              onClick={openCreateSandboxDumpFileModal}
            >
              New
            </Button>
            <IconButton
              onClick={isFetchingDumpFiles ? undefined : refreshFileList}
              color="primary"
              size="small"
              edge="end"
              disabled={isCreatingRealm}
            >
              <RefreshIcon />
            </IconButton>
          </Box>

          {newSandboxValues.fileName
            ? conditionallyRenderElement({
                isLoading: isFetchingDumpFiles,
                error: null,
                content: (
                  <ListSelect
                    name="fileName"
                    onChange={onSelectSandboxFile}
                    optionList={dumpFiles}
                    values={[newSandboxValues.fileName]}
                    required
                  />
                ),
              })
            : null}
        </>
      )}

      <Box>{isCreatingRealm ? <LinearBuffer /> : null}</Box>
      <DialogActions>
        <Button
          disabled={isCreatingRealm}
          onClick={toggleAdvanceMode}
          color="primary"
        >
          {isAdvancedMode ? "Basic" : "Advanced"}
        </Button>
      </DialogActions>
      <Box display={"flex"} justifyContent={"end"}>
        <Button
          size="large"
          disabled={isCreatingRealm || !!tagError}
          onClick={handleSubmitCreateSandboxForm}
          color="primary"
        >
          Submit
        </Button>
      </Box>
    </form>
  );
};
