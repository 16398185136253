import {
  PENDING_MAPPING_STATUS,
  pendingOnMosaicHash,
  pendingOnTargetHash,
  MAPPING_STATUS,
} from "@constants";

export const isDataTypePending = (dataTypeMappingStatus: string) => {
  return PENDING_MAPPING_STATUS[dataTypeMappingStatus];
};

export const isPendingOnMosaic = (mappingStatus: string) =>
  !!pendingOnMosaicHash[mappingStatus];

export const isPendingOnTarget = (mappingStatus: string) =>
  !!pendingOnTargetHash[mappingStatus];

export const isUpdateOnMosaic = (mappingStatus: string | null) =>
  MAPPING_STATUS.UPDATE_ON_MOSAIC === mappingStatus;

export const isUpdateOnTarget = (mappingStatus: string | null) =>
  MAPPING_STATUS.UPDATE_ON_TARGET === mappingStatus;

export const isCreateOnMosaic = (mappingStatus: string | null) =>
  MAPPING_STATUS.CREATE_NEW_ON_MOSAIC === mappingStatus;

export const isCreateOnTarget = (mappingStatus: string | null) =>
  MAPPING_STATUS.CREATE_NEW_ON_TARGET === mappingStatus;
