import { useCallback, useMemo } from "react";
import { getTableConfig } from "./tableConfigs";
import useMuiTableBuilderV2 from "hooks/useMuiTableBuilderV2";
import { Box, TablePagination, TablePaginationProps } from "@material-ui/core";
import { useSuperAdminListItemsBuilder } from "./helpers/useSuperAdminListItemsBuilder";
import { SuperAdminInterface } from "types";
import { useSelector } from "react-redux";
import { selectSuperAdminTableFilter } from "selectors";
import useRequestSort from "hooks/useRequestSort";
import { useSuperAdminInfoModal } from "TeamModule/components/MemberInfoModal/useSuperAdminInfoModal";
import { CustomRowProps } from "ReportsModule/Administration/SuperAdminsTable/types";

const tableConfig = getTableConfig();

export const SuperAdminsTable = ({
  superAdmins,
  paginationProps,
}: {
  superAdmins: SuperAdminInterface[];
  paginationProps: Pick<
    TablePaginationProps,
    "count" | "page" | "onPageChange" | "rowsPerPage" | "rowsPerPageOptions"
  >;
}) => {
  const listItemsBuilder = useSuperAdminListItemsBuilder();
  const { openSuperAdminInfoModal } = useSuperAdminInfoModal();
  const filter = useSelector(selectSuperAdminTableFilter);
  const { toggleSort } = useRequestSort({ filter });

  const onRowEditClick = useCallback(
    (accountId: number) => (event: React.MouseEvent) => {
      event.stopPropagation();
      openSuperAdminInfoModal({ accountId });
    },
    [openSuperAdminInfoModal]
  );

  const mainList = useMemo(() => {
    return {
      listItems: listItemsBuilder({ superAdmins }),
    };
  }, [listItemsBuilder, superAdmins]);

  const onSort = useCallback<CustomRowProps["onSort"]>(
    (columnId) => {
      toggleSort({ orderBy: columnId });
    },
    [toggleSort]
  );

  const customRowProps: CustomRowProps = useMemo(
    () => ({
      onRowEditClick,
      orderDirection: filter.orderDirection,
      orderBy: filter.orderBy,
      onSort,
    }),
    [onSort, filter.orderBy, filter.orderDirection, onRowEditClick]
  );

  const renderFooterOptions = useCallback(() => {
    return <TablePagination color="primary" {...paginationProps} />;
  }, [paginationProps]);

  const { TableComponent } = useMuiTableBuilderV2({
    ...tableConfig,
    mainList,
    customRowProps,
    tableMaxHeight: "1000px",
    renderFooterOptions,
  });

  return <Box>{TableComponent}</Box>;
};
