import styled from "styled-components";

export const TagContainer = styled.div`
  display: flex;
  .add-tag-div {
    flex: 2;
    min-width: 80px;
  }
  .initial-tag-div {
    flex: 2;
  }
  .start-date {
    flex: 2;
  }
`;

export const ChipsContainer = styled.div`
  margin-bottom: 15px;
  .chip {
    margin-right: 5px;
    margin-bottom: 5px;
    max-width: 370px;
    height: 27px;
  }
  .red-chip {
    background-color: #f44336;
    color: white;
  }
`;
