import { INPUT_ERROR_MESSAGE } from "SandboxModule/constants";
import { checkUppercaseAndSpecialCharacters } from "./regex";

export const checkRealmTeamNameInputError = (realmTeamName: string) => {
  if (checkUppercaseAndSpecialCharacters(realmTeamName)) {
    // show error if they do
    return INPUT_ERROR_MESSAGE;
  }

  return "";
};
