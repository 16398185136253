import { getTodayMoment, standardDateFormat } from "@constants/date";
import keyBy from "lodash/keyBy";

export const COUNT_STATISTICS_DATA_TYPE = {
  TEAMS: "teams",
  TEAM_MEMBERSHIPS: "team_memberships",
  BOARDS: "boards",
  PROJECTS: "projects",
  PHASES: "phases",
  WORK_PLANS: "work_plans",
  TIME_ENTRIES: "time_entries",
  PROJECT_TASKS: "project_tasks",
  PROJECT_SCOPES: "project_scopes",
  UNASSIGNED_ROLES: "unassigned_roles",
  ROLES: "roles",
  DEPARTMENTS: "departments",
  OFFICES: "offices",
  LOCATIONS: "locations",
  REGIONS: "regions",
  DISCIPLINES: "disciplines",
  ACTIVE_MEMBERS: "active",
};

export const countStatisticsBySectionArray = [
  {
    label: "Customers",
    value: [
      {
        label: "Teams",
        value: COUNT_STATISTICS_DATA_TYPE.TEAMS,
      },
      {
        label: "Team memberships",
        value: COUNT_STATISTICS_DATA_TYPE.TEAM_MEMBERSHIPS,
      },
      {
        label: "Active Users",
        value: COUNT_STATISTICS_DATA_TYPE.ACTIVE_MEMBERS,
      },
    ],
  },
  {
    label: "Organizational Data",
    value: [
      {
        label: "Departments",
        value: COUNT_STATISTICS_DATA_TYPE.DEPARTMENTS,
      },
      {
        label: "Offices",
        value: COUNT_STATISTICS_DATA_TYPE.OFFICES,
      },
      {
        label: "Roles",
        value: COUNT_STATISTICS_DATA_TYPE.ROLES,
      },
      {
        label: "Disciplines",
        value: COUNT_STATISTICS_DATA_TYPE.DISCIPLINES,
      },
      {
        label: "Regions",
        value: COUNT_STATISTICS_DATA_TYPE.REGIONS,
      },
      {
        label: "Locations",
        value: COUNT_STATISTICS_DATA_TYPE.LOCATIONS,
      },
    ],
  },
  {
    label: "Projects Data",
    value: [
      {
        label: "Portfolios",
        value: COUNT_STATISTICS_DATA_TYPE.BOARDS,
      },
      {
        label: "Projects",
        value: COUNT_STATISTICS_DATA_TYPE.PROJECTS,
      },
      {
        label: "Phases",
        value: COUNT_STATISTICS_DATA_TYPE.PHASES,
      },
      {
        label: "Time Entries",
        value: COUNT_STATISTICS_DATA_TYPE.TIME_ENTRIES,
      },
      {
        label: "Work Plans",
        value: COUNT_STATISTICS_DATA_TYPE.WORK_PLANS,
      },
      {
        label: "Tasks",
        value: COUNT_STATISTICS_DATA_TYPE.PROJECT_TASKS,
      },
      {
        label: "Scopes",
        value: COUNT_STATISTICS_DATA_TYPE.PROJECT_SCOPES,
      },
      {
        label: "Unassigned Roles",
        value: COUNT_STATISTICS_DATA_TYPE.UNASSIGNED_ROLES,
      },
    ],
  },
];

export const countStatisticsHash = keyBy(
  countStatisticsBySectionArray,
  "value"
);

/** 
 * @deprecated Add to constant.date.ts
 */
export const COUNT_STATISTICS_SEARCH_RANGE = {
  LAST_WEEK: "last_week",
  LAST_MONTH: "last_month",
  LAST_QUARTER: "last_quarter",
  CUSTOM: "custom",
};


/**
 * @deprecated
 *  TODO use the constant ffrom date.ts and try to use `useDateRangeOptionSelector`!!
 */
export const countStatisticsSearchRangeArray = [
  {
    label: "Data from Last Week",
    shortLabel: "Last Week",
    value: COUNT_STATISTICS_SEARCH_RANGE.LAST_WEEK,
    date: getTodayMoment().subtract(7, "days").format(standardDateFormat),
  },
  {
    label: "Data from Last Month",
    shortLabel: "Last Month",
    value: COUNT_STATISTICS_SEARCH_RANGE.LAST_MONTH,
    date: getTodayMoment().subtract(30, "days").format(standardDateFormat),
  },
  {
    label: "Data from Last Quarter",
    shortLabel: "Last Quarter",
    value: COUNT_STATISTICS_SEARCH_RANGE.LAST_QUARTER,
    date: getTodayMoment().subtract(180, "days").format(standardDateFormat),
  },
  {
    label: "Custom date range",
    shortLabel: "Custom",
    value: COUNT_STATISTICS_SEARCH_RANGE.CUSTOM,
  },
];

export const countStatisticsSearchRangeHash = keyBy(
  countStatisticsSearchRangeArray,
  "value"
);

export const TEAM_CREATION_RANGE_OPTIONS = {
  ALL_TEAMS: "all-teams",
  LAST_WEEK: "last_week",
  LAST_MONTH: "last_month",
  LAST_QUARTER: "last_quarter",
};

export const TEAM_CREATION_RANGE_OPTIONS_ARRAY = [
  {
    label: "All Teams",
    value: TEAM_CREATION_RANGE_OPTIONS.ALL_TEAMS,
  },
  {
    label: "Teams created last week",
    value: TEAM_CREATION_RANGE_OPTIONS.LAST_WEEK,
  },
  {
    label: "Teams created last month",
    value: TEAM_CREATION_RANGE_OPTIONS.LAST_MONTH,
  },
  {
    label: "Teams created last quarter",
    value: TEAM_CREATION_RANGE_OPTIONS.LAST_QUARTER,
  },
];

export const TEAM_CREATION_RANGE_OPTIONS_HASH = keyBy(
  TEAM_CREATION_RANGE_OPTIONS_ARRAY,
  "value"
);
