import { useRequestStatus } from "RequestStatusModule/hooks/useRequestStatus";
import {
  DEFAULT_TENANT_TABLE_VIEW,
  TENANT_TABLE_VIEW,
  TenantIsoStateIds,
} from "TenantModule/constants";
import { useTenants } from "TenantModule/hooks/useTenants";
import { useEffect, useState } from "react";
import { fetchTenants } from "TenantModule/thunks";
import { useDispatch } from "react-redux";

const containerConfigs = {
  [TENANT_TABLE_VIEW.ACTIVE]: {
    isoStateId: TenantIsoStateIds.ActiveTenantTable,
    headerLabel: "Tenant",
    fetchTenantAdditionalParams: {
      isArchived: false,
    },
  },
  [TENANT_TABLE_VIEW.ARCHIVED]: {
    isoStateId: TenantIsoStateIds.ArchivedTenantTable,
    headerLabel: "Archived Tenant",
    fetchTenantAdditionalParams: {
      isArchived: true,
    },
  },
};

export const useTenantsTableData = () => {
  const [tenantTableView, setTenantTableView] = useState(
    DEFAULT_TENANT_TABLE_VIEW
  );
  const { getTenantByIsoState } = useTenants();
  const dispatch = useDispatch();

  const configs = containerConfigs[tenantTableView];
  const { isoStateId, headerLabel, fetchTenantAdditionalParams } = configs;
  const { topLevelOrder } = getTenantByIsoState({ isoStateId });
  const { requestStatus } = useRequestStatus({ requestStatusId: isoStateId });
  const isLoading = !requestStatus || requestStatus.isRequesting;

  useEffect(() => {
    dispatch(
      fetchTenants({
        ...fetchTenantAdditionalParams,
        meta: {
          isoStateId,
          requestStatusId: isoStateId,
        },
      })
    );
  }, [tenantTableView, isoStateId, fetchTenantAdditionalParams, dispatch]);

  return {
    isLoading,
    setTenantTableView,
    tenantTableView,
    headerLabel,
    isoStateId,
    fetchTenantAdditionalParams,
    tenantIds: topLevelOrder || [],
  };
};
