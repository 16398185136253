import { cloneDeep } from "lodash";
import moment from "moment";

const defaultbarChartOptions = {
  cornerRadius: 2,
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  scales: {
    yAxis: {
      gridLines: {
        display: false,
      },
      ticks: {
        fontColor: "black",
        min: 0,
        max: 0,
        maxTicksLimit: 5,
        stepSize: 0,
      },
    },
    xAxis: {
      gridLines: {
        color: "transparent",
      },
      ticks: {
        fontColor: "black",
      },
    },
  },
};

/**
 *
 * @param options - See https://www.chartjs.org/docs/3.5.0/charts/bar.html for a list of option
 * Add more options as needed
 * @returns
 */
export const generateBarChartOptions = (options: {
  maxTicksLimit?: number;
  max: number;
  min: number;
  stepSize?: number;
}) => {
  const newBarChartOptions = cloneDeep(defaultbarChartOptions);
  const { max, min, stepSize, maxTicksLimit } = options;
  newBarChartOptions.scales.yAxis.ticks.maxTicksLimit = maxTicksLimit || 5;
  newBarChartOptions.scales.yAxis.ticks.stepSize =
    stepSize || Math.floor((max - min) / 5);
  newBarChartOptions.scales.yAxis.ticks.max = max || 100;
  newBarChartOptions.scales.yAxis.ticks.min = min || 0;

  return newBarChartOptions;
};

/**
 *
 * @param startDate
 * @param endDate
 * @returns date in format of "MMM D - MMM D"
 */
export const formatChartDateRange = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  const formattedStartDate = moment(startDate).format("MMM D");
  const formattedEndDate = moment(endDate).format("MMM D");

  return `${formattedStartDate} - ${formattedEndDate}`;
};
