import { useDynamicModal } from "components/DynamicModalProvider/useDynamicModal";
import SimpleMenu from "components/SimpleMenu";
import { useCallback } from "react";
import { useAppContext } from "containers/AppProvider";
import { SearchTeamStrategies, searchTeamStrategyItems } from "@constants";

export const useSearchTeamStrategiesDropdown = () => {
  const { openModal } = useDynamicModal();
  const { setSearchTeamStrategies } = useAppContext();

  const openSearchTeamStrategiesDropdown = useCallback(
    ({ target }: { target: HTMLElement }) => {
      openModal({
        modal: ({ isOpen, toggle }) => {
          return (
            <SimpleMenu
              anchorEl={target}
              items={searchTeamStrategyItems}
              handleClickV2={({ item }) =>
                setSearchTeamStrategies(item.value as SearchTeamStrategies)
              }
              handleClose={toggle}
            />
          );
        },
      });
    },
    [openModal, setSearchTeamStrategies]
  );

  return { openSearchTeamStrategiesDropdown };
};
