import {
  DEFAULT_RAILS_REALM_ID,
  DEFAULT_REALM_NAME,
  RealmTypes,
} from "RealmModule/constants/realm";
import { Realm } from "types";
import { Tenant } from "TenantModule/types";
import pick from "lodash/pick";
import { SandboxStatusTypes } from "SandboxModule/constants";
import { TenantStatusTypes } from "TenantModule/constants";
import qs from "qs";
import { RedirectKeys } from "@constants";

export const DEFAULT_TENANT_NAME = process.env.REACT_APP_UI_ENV || "";
export const DEFAULT_TENANT_ID = 1;

export const isParty = process.env.REACT_APP_UI_ENV === "party";
export const isProd = process.env.REACT_APP_UI_ENV === "prod";
export const isDemo = process.env.REACT_APP_UI_ENV === "demo";
export const isGovCloud = process.env.REACT_APP_UI_ENV === "gov";

export enum LocalStorageKeys {
  Realm = "sb-rails",
  Tenant = "mosaic-tenant",

  // Storing only the realm id when authenticating temporarily
  LastSelectedRealmId = "lsrid",
  LastSelectedTenantName = "lstn",
}
/**
 * Builds sandbox baseURL from baseURL and realmId
 * by inserting realmId into the beginning of any API that is supported by sandboxes
 * Format: [protocol]//[realmid].api.[region].[env].mosaicapp.com
 * i.e: https://{realmId}.api.us-east-1.demo.mosaicapp.com - https://mosaic_proddump.api.us-east-1.demo.mosaicapp.com
 */
const _buildUrl = ({
  baseURL,
  realmId,
}: {
  baseURL: string;
  realmId: string;
}) => {
  const { protocol, host, pathname } = new URL(baseURL);

  // https://mosaicapp.slack.com/archives/C03G4RL6E3A/p1659470362967569?thread_ts=1659468577.776349&cid=C03G4RL6E3A
  // should replace [realmid] in the host with passed realmId
  return `${protocol}//${realmId}.${host}${pathname}`;
};

/** A class for managing api related things (i.e: when a sandbox is selected) */
class ApiClientManager {
  private realm: Realm;
  private tenant: Tenant;
  private readonly defaultRealm: Realm = {
    realm_id: DEFAULT_RAILS_REALM_ID, // Technically its not a sandbox, but an option to revert back to regular demo endpoint
    hosts: {},
    created_at: "",
    archived_at: "",
    team_name: DEFAULT_REALM_NAME,
    is_ready: true,
    tags: [],
    status: SandboxStatusTypes.Initialized,
    created_by: "",
    archived_by: "",
    realm_type: RealmTypes.sandbox,
    tenant: DEFAULT_TENANT_NAME,
    realm_services: [],
  };
  private readonly defaultTenant: Tenant = {
    id: DEFAULT_TENANT_ID,
    isDefault: true,
    archivedAt: "",
    archiver: "",
    createdAt: "",
    creator: "",
    csm: "",
    isArchived: false,
    realms: [],
    status: TenantStatusTypes.Ready,
    tags: [],
    tenantName: DEFAULT_TENANT_NAME,
    tenantMetadata: null,
  };

  constructor() {
    const realm = window.localStorage.getItem(LocalStorageKeys.Realm);
    const storedTenant = window.localStorage.getItem(LocalStorageKeys.Tenant);

    this.realm = realm ? JSON.parse(realm) : this.getDefaultRealm();

    this.tenant = storedTenant ? JSON.parse(storedTenant) : this.defaultTenant;
  }

  reset() {
    this.removeRealm();
    this.removeTenant();
  }
  /**
   * baseUrl is used for building the url when a non-default sandbox is selected
   * defaultUrl is the default url that will be used if sandbox is not selected
   */

  buildUrlHeaders({
    tenantName,
    realmId,
  }: { tenantName?: Tenant["tenantName"]; realmId?: Realm["realm_id"] } = {}) {
    const tenantNameToUse = tenantName || this.getTenant().tenantName;
    const realmIdToUse = realmId || this.getCurrentRealmId();

    return {
      "x-tenant": tenantNameToUse,
      "x-realm-id": realmIdToUse,
    };
  }

  /* ------------------------------ Rails Sandbox ----------------------------- */
  /**
   * Using sandbox means 1) Sandbox is enabled AND 2) Using a non default sandbox
   */
  getIsUsingNonDefaultRealm() {
    const currentRealm = this.getCurrentRealm();
    const defaultRealm = this.getDefaultRealm();
    const nonDefaultRealm = currentRealm.realm_id !== defaultRealm.realm_id;

    return this.getIsRealmEnabled() && nonDefaultRealm;
  }

  getDefaultRealm() {
    return this.defaultRealm;
  }

  getCurrentRealm() {
    return this.realm;
  }

  getCurrentRealmId() {
    return this.getCurrentRealm().realm_id;
  }

  // If we want to persist realm even closed browser, use localStorage
  setRailsSandbox(realm: Realm) {
    window.localStorage.setItem(
      LocalStorageKeys.Realm,
      JSON.stringify(pick(realm, ["realm_type", "realm_id"]))
    );
    this.realm = realm;
  }

  removeRealm() {
    window.localStorage.removeItem(LocalStorageKeys.Realm);
  }

  buildUrl(
    realmId: string,
    isUsingNonDefaultSandbox: boolean,
    baseURL: string,
    defaultUrl: string
  ) {
    const url = isUsingNonDefaultSandbox
      ? _buildUrl({
          realmId,
          baseURL,
        })
      : defaultUrl;

    return url;
  }

  /**
   * TO REMOVE FROM ENV prod and demo
   *
   //REACT_APP_SANDBOX_RAILS_API_URL: https://api.us-east-1.party.mosaicapp.com/api
   // REACT_APP_INTEGRATION_SERVER_API_URL=https://integration-server.us-east-1.party.mosaicapp.com
   */

  buildUrlV2({
    service,
    tenantName,
    shouldAppendApi = false,
  }: {
    service: "integration-server" | "api";
    tenantName?: Tenant["tenantName"];
    shouldAppendApi?: boolean; // Used when service = api to append "api" at the end of the URL
  }) {
    const environment = process.env.REACT_APP_UI_ENV;
    const region = process.env.REACT_APP_REGION;
    const { tenantName: globalTenantName } = this.getTenant(); // Globally selected tenant
    const { tenantName: defaultTenantName } = this.getDefaultTenant();

    // Use globalTenantName if tenantName is not provided
    const tenantNameToUse = tenantName || globalTenantName;

    const isDefaultTenant = tenantNameToUse === defaultTenantName;

    let formattedUrl = "";

    if (environment === "party") {
      // In 'party' environment, always include tenantName
      formattedUrl = `https://${service}.${tenantNameToUse}.${environment}.${region}.mosaicapp.com`;
    } else if (!isDefaultTenant) {
      // Non-default tenant in other environments
      formattedUrl = `https://${service}.${region}.${tenantNameToUse}.${environment}.mosaicapp.com`;
    } else {
      // Default tenant in other environments
      formattedUrl = `https://${service}.${region}.${environment}.mosaicapp.com`;
    }

    return shouldAppendApi ? `${formattedUrl}/api` : formattedUrl;
  }

  getDefaultRailsServerUrl() {
    return process.env.REACT_APP_RAILS_API_URL as string;
  }

  getRailsServerUrl({
    tenantName,
    realmId,
  }: {
    //Should use tenantName for Url once the new api is working
    // TenantName used to check if user is super admin on selected realm before redircting
    tenantName?: string;
    realmId?: string;
  } = {}) {
    const { realm_id } = this.getCurrentRealm();
    const realmIdToUse = realmId || realm_id;
    const defaultRealm = this.getDefaultRealm();
    const IsNonDefaultRealm = realmIdToUse !== defaultRealm.realm_id;
    const defaultTenant = this.getDefaultTenant();

    if (isParty) {
      return this.buildUrlV2({
        service: "api",
        tenantName,
        shouldAppendApi: true,
      });
    }

    if (isProd) {
      const tenantNameToUse = tenantName ?? this.getTenant()?.tenantName;

      const shouldBuildV2 =
        tenantNameToUse !== undefined &&
        tenantNameToUse !== defaultTenant.tenantName;

      if (shouldBuildV2)
        return this.buildUrlV2({
          service: "api",
          tenantName,
          shouldAppendApi: true,
        });
    }

    // Demo
    return this.buildUrl(
      realmIdToUse,
      IsNonDefaultRealm,
      process.env.REACT_APP_SANDBOX_RAILS_API_URL as string,
      this.getDefaultRailsServerUrl()
    );
  }

  buildRailsServerApiConfigs({
    tenantName,
    realmId,
  }: {
    tenantName?: string;
    realmId?: string;
  } = {}) {
    return {
      baseURL: this.getRailsServerUrl({
        tenantName,
        realmId,
      }),
      headers: this.buildUrlHeaders({
        tenantName,
        realmId,
      }),
    };
  }

  /* -------------------------------------------------------------------------- */

  //
  getGalaxyServerUrl() {
    return process.env.REACT_APP_GALAXY_SERVER_API_URL;
  }

  //
  getIntegrationServerUrl({
    tenantName,
  }: {
    tenantName?: string;
  } = {}) {
    // TO REMOVE once working on prod and demo
    const defaultTenant = this.getDefaultTenant();
    const shouldBuildV2 =
      isParty || (isProd && tenantName !== defaultTenant.tenantName);

    if (shouldBuildV2) {
      return this.buildUrlV2({
        service: "integration-server",
        tenantName,
      });
    }
    return process.env.REACT_APP_INTEGRATION_SERVER_API_URL;
  }

  buildIntegrationServerApiConfigs({
    tenantName,
    realmId,
  }: {
    tenantName?: string;
    realmId?: string;
  } = {}) {
    const currentRealmId = this.getCurrentRealmId();
    // these two realms are using the main IS db, not their own realm is db.
    // they wrere created before IS starting supporting realm
    const shouldUseDefaultHeader = currentRealmId === "eki-1853";

    return {
      baseURL: this.getIntegrationServerUrl({
        tenantName,
      }),
      headers: this.buildUrlHeaders({
        tenantName,
        realmId: shouldUseDefaultHeader
          ? process.env.REACT_APP_UI_ENV
          : realmId,
      }),
    };
  }

  getWebappUrl() {
    const { tenantName: globalTenantName } = this.getTenant();
    const defaultTenant = this.getDefaultTenant();
    const isNonDefaultTenant = globalTenantName !== defaultTenant.tenantName;
    const environment = process.env.REACT_APP_UI_ENV;

    if (isNonDefaultTenant) {
      if (isParty) {
        return `https://${globalTenantName}.${environment}.mosaicapp.com`;
      }

      // TO DO when tenant is on demo, use the same logic as party
      // tenant.demo.mosaicapp.com
      if (isDemo) {
        return process.env.REACT_APP_WEB_APP_URL;
      }

      // prod
      return `https://${globalTenantName}.mosaicapp.com`;
    }

    // Default Tenant
    return process.env.REACT_APP_WEB_APP_URL;
  }

  getLoginRepoUrl({ tenantName }: { tenantName: Tenant["tenantName"] }) {
    const defaultTenant = this.getDefaultTenant();
    const isNonDefaultTenant = tenantName !== defaultTenant.tenantName;
    const environment = process.env.REACT_APP_UI_ENV;
    const urlSuffix = `mosaicapp.com/login?sourceUrl=${process.env.REACT_APP_GALAXY_SOURCE_URL_REDIRECT_AFTER_SUCCESS_AUTH}`;

    if (isNonDefaultTenant) {
      if (isProd) {
        // https://login-stanleygroup.mosaicapp.com
        return `https://login-${tenantName}.${urlSuffix}`;
      }

      // https://login-release.party.mosaicapp.com
      if (isParty) {
        return `https://login-${tenantName}.${environment}.${urlSuffix}`;
      }

      // TO DO when tenant is on demo, use the same logic as party
      // demo-login.tenant.mosaicapp.com
      if (isDemo) {
        return `${process.env.REACT_APP_FE_AUTH_DOMAIN}.${urlSuffix}`;
      }
    }

    if (isProd && !isNonDefaultTenant) {
      // https://login.mosaicapp.com
      return `https://login.${urlSuffix}`;
    }

    // demo and party on default tenant
    // https://demo-login.mosaicapp.com or https://party-login.mosaicapp.com
    return `https://${environment}-login.${urlSuffix}`;
  }

  /**
   *
   * Refer https://mosaic-ai.atlassian.net/l/cp/8GM6vk3q
   */
  getIsSandboxRealmEnabled() {
    return isDemo || isParty || isProd;
  }

  /**
   *
   * temporarily flag to disable destroying sandbox
   */
  getIsDestroyingSandboxRealmDisabled() {
    return isProd;
  }

  /**
   *
   * Refer https://mosaic-ai.atlassian.net/l/cp/8GM6vk3q
   */
  getIsSubscriptionRealmEnabled() {
    return isProd || isParty;
  }

  /**
   *
   * The only flag for checking whether sandbox is enabled on environmen
   * Useful for checking whether to fetch sandboxes or not
   */
  getIsRealmEnabled() {
    return (
      this.getIsSandboxRealmEnabled() || this.getIsSubscriptionRealmEnabled()
      // NOTE: Somehow on prod, fetching sandbox from glx server give 401 (for now)
      // It is reported, once it is fixed, remove this comment
    );
  }

  getIsSubscriptionRealmSelected() {
    const currentRealm = this.getCurrentRealm();

    return currentRealm.realm_type === RealmTypes.subscription;
  }

  getIsSandboxRealmSelected() {
    const currentRealm = this.getCurrentRealm();
    const isSelectedRealmSandbox =
      currentRealm.realm_type === RealmTypes.sandbox;

    return isSelectedRealmSandbox;
  }

  getTenant() {
    return this.tenant;
  }

  getTenantId() {
    return this.tenant.id;
  }

  removeTenant() {
    window.localStorage.removeItem(LocalStorageKeys.Tenant);
  }

  getDefaultTenant() {
    return this.defaultTenant;
  }

  setTenant(tenant: Tenant) {
    window.localStorage.setItem(
      LocalStorageKeys.Tenant,
      JSON.stringify(pick(tenant, ["id", "tenantName"]))
    );
    this.tenant = tenant; // id and tenantName
  }
}

export default new ApiClientManager();
