import { GenericTeamInterface } from "../types";
import { sortBy, keyBy } from "lodash";
import { REPORTS_INTEGRATION_DETAILS_PATH } from "@constants";

/**
 * A utility function that takes an array of teams
 * And added targetServices list to the team,
 * The team also exists on BE, but not all teams have integrations
 * Thus, this function should only be used for teams that are fetched from IS
 * (i.e: fetched from service metrics endpoints)
 * The goal is to avoid calling IS API with all the teams that doesn't have integration
 * @returns {Record<number,object>}
 */
export const makeIntegrationTeamInfoMappings = (
  teams: GenericTeamInterface[],
  targetService: string
): Record<number, object> => {
  const integrationTeamsInfoMapping = teams.map((team) => {
    // The team can have more than 1 type of target service
    if (!team.targetServices) {
      return {
        ...team,
        targetServices: [targetService],
      };
    }
    const targetServiceSet = new Set(team.targetServices);

    return {
      ...team,
      targetServices: targetServiceSet.has(targetService)
        ? team.targetServices
        : [...team.targetServices, targetService],
    };
  });
  return keyBy(integrationTeamsInfoMapping, "id");
};

export const getTeamsWithIntegration = (teams: GenericTeamInterface[]) => {
  return teams.filter((team) => team.targetServices);
};

export const checkUrlHasSalesforceSubscriptionLink = (url: string) => {
  const trimmedUrl = url.trim();

  return trimmedUrl.includes(
    "mosaicapp.lightning.force.com/lightning/r/Subscription__c/"
  );
};

export const generateStrongPassword = () => {
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";
  const specialChars = "!@#$%^&*()_+-=[]{}|;:,.<>?";

  const getRandomChar = (charSet: string | any[]) => {
    const randomIndex = Math.floor(Math.random() * charSet.length);
    return charSet[randomIndex];
  };

  let password = "";

  // Add at least one lowercase letter
  password += getRandomChar(lowercaseChars);

  // Add at least one uppercase letter
  password += getRandomChar(uppercaseChars);

  // Add at least one number
  password += getRandomChar(numbers);

  // Add at least one special character
  password += getRandomChar(specialChars);

  const remainingLength = 14 - password.length;

  // Add remaining random characters
  for (let i = 0; i < remainingLength; i++) {
    const charSet = lowercaseChars + uppercaseChars + numbers + specialChars;
    password += getRandomChar(charSet);
  }

  // Shuffle the password characters
  const shuffledPassword = password
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");
  return shuffledPassword;
};
