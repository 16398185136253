import { Box } from "@material-ui/core";
import { ComponentProps } from "react";
import BaseSelectorWithArrow from "components/BaseSelectorWithArrow";
import { useTenants } from "TenantModule/hooks/useTenants";
import { Tenant } from "TenantModule/types";
import { makeTenantLabel } from "TenantModule/utils";

export const TenantSelector = ({
  onClick,
  tenantId,
}: {
  onClick: ComponentProps<typeof BaseSelectorWithArrow>["onClick"];
  tenantId: Tenant["id"];
}) => {
  const { getTenant } = useTenants();
  const tenant = getTenant({ tenantId });

  const tenantLabel = tenant
    ? tenant.isDefault
      ? makeTenantLabel({
          name: `${tenant?.tenantName} (Default)`,
        })
      : makeTenantLabel({
          name: tenant?.tenantName,
        })
    : "";

  return (
    <Box display={"flex"} alignItems={"center"} ml={4} gridGap={10}>
      Tenant: <BaseSelectorWithArrow onClick={onClick} label={tenantLabel} />
    </Box>
  );
};
