import SimpleMenu from "components/SimpleMenu";
import useCreateSandboxModal from "hooks/useCreateSandboxModal";
import { useDynamicModal } from "components/DynamicModalProvider/useDynamicModal";
import { useCreateTenantRequestModal } from "TenantModule/hooks/useCreateTenantRequestModal";
import { useCallback } from "react";
import { useCreateTeamModal } from "hooks/useCreateTeamModal";
import { useCreateSubscriptionRealmModal } from "hooks/useCreateSubscriptionRealmModal";
import { useAppContext } from "containers/AppProvider";

export const usePlusButtonMenu = () => {
  const { openModal } = useDynamicModal();
  const { openCreateSandboxModal } = useCreateSandboxModal();
  const { openCreateTenantRequestModal } = useCreateTenantRequestModal();
  const { openCreateTeamModal } = useCreateTeamModal();
  const { isSandboxCreationDisabled } = useAppContext();
  const disableSandboxCreation = isSandboxCreationDisabled;
  const {
    openCreateSubscriptionRealmModal,
  } = useCreateSubscriptionRealmModal();
  const openPlusButtonMenu = useCallback(
    ({ target }: { target: HTMLElement }) => {
      const menuItems = [
        {
          label: "Create a new team",
          onClick: openCreateTeamModal,
        },
        {
          label: "Create a new sandbox",
          onClick: openCreateSandboxModal,
          disableButton: disableSandboxCreation,
          tooltip: disableSandboxCreation
            ? "Sandbox creation is disabled from 9am to 5pm EST. Please contact Solution Team or wait until 5pm EST."
            : "",
        },
        {
          label: "Create an isolated database",
          onClick: openCreateSubscriptionRealmModal,
        },
        {
          label: "Create a new tenant",
          onClick: openCreateTenantRequestModal,
        },
      ];

      openModal({
        modal: ({ isOpen, toggle }) => {
          return (
            <SimpleMenu
              anchorEl={target}
              items={menuItems}
              handleClose={toggle}
              transformMenu={""}
            />
          );
        },
      });
    },

    [
      disableSandboxCreation,
      openCreateSandboxModal,
      openCreateSubscriptionRealmModal,
      openCreateTeamModal,
      openCreateTenantRequestModal,
      openModal,
    ]
  );

  return {
    openPlusButtonMenu,
  };
};
