import SimpleModal from "components/SimpleModal";
import { Box } from "@material-ui/core";

export const CreateTenantRequestModal = ({
  toggle,
}: {
  toggle: VoidFunction;
}) => {
  return (
    <SimpleModal
      onClose={toggle}
      open
      onConfirm={toggle}
      closeOnConfirm
      headerText={"Create Tenant"}
      Body={
        <Box>
          Please fill out this request form{" "}
          <a
            href="https://form.jotform.com/233047536806054"
            target="_blank"
            rel="noreferrer"
          >
            Tenant Request Form{" "}
          </a>
        </Box>
      }
    />
  );
};
