import { Tooltip, Box } from "@material-ui/core";
import { ComponentProps, ReactNode } from "react";

const BoxWithTooltip = ({
  children,
  tooltip,
  boxProps,
}: {
  children: ReactNode;
  tooltip?: ReactNode;
  boxProps?: ComponentProps<typeof Box>;
}) => {
  return (
    <Tooltip title={tooltip || ""}>
      <Box {...boxProps}>{children}</Box>
    </Tooltip>
  );
};

export default BoxWithTooltip;
