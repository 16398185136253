import keyBy from "lodash/keyBy";
import { SimpleMenuItem } from "types";

export enum SearchTeamStrategies {
  ByTeamId = "ByTeamId", // Default
  ByTargetServiceId = "ByTargetServiceId",
}

export type SearchTeamStrategyItem = Pick<SimpleMenuItem, "value" | "label">;

export const searchTeamStrategyItems: SearchTeamStrategyItem[] = [
  {
    label: "Team ID, Team Name, Member Email",
    value: SearchTeamStrategies.ByTeamId,
  },
  {
    label: "Integration ID",
    value: SearchTeamStrategies.ByTargetServiceId,
  },
];

export const searchTeamStategiesHash = keyBy(searchTeamStrategyItems, "value");
