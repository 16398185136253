import { createAction } from "@reduxjs/toolkit";
import { RequestStatus } from "./types";
import { SetRequired } from "type-fest";

export const setRequestStatus = createAction<SetRequired<RequestStatus, "id">>(
  "SET_REQUEST_STATUS"
);

export const clearRequestStatus = createAction<{ id: RequestStatus["id"] }>(
  "CLEAR_REQUEST_STATUS"
);

export const clearAllRequestStatuses = createAction("CLEAR_ALL_REQUEST_STATUSES");
