import { RequestStatus } from "RequestStatusModule/types";
import { SetRequired } from "type-fest";
import { reduxHelper } from "src";
import {
  setRequestStatus,
  clearRequestStatus,
  clearAllRequestStatuses,
} from "RequestStatusModule/actionCreators";

const defaultRequestStatus: Omit<RequestStatus, "id"> = {
  /**
   * Set initial = true in the payload. Used for determining if initially loading/fetching something
   */
  isLoading: false,
  isRequesting: false,
  isSuccess: false,
  isAborted: false,
  error: undefined,
  abortController: undefined,
};

class RequestStatusManager {
  public getRequestStatuses() {
    return reduxHelper.getState().requestStatusReducer;
  }

  public getRequestStatus(id: RequestStatus["id"]): RequestStatus | undefined {
    const requestStatuses = this.getRequestStatuses();

    return requestStatuses[id];
  }

  public setRequestStatus({ id, ...rest }: SetRequired<RequestStatus, "id">) {
    const dispatch = reduxHelper.getDispatch();
    dispatch(setRequestStatus({ id, ...rest }));
  }

  /* -------------------------------------------------------------------------- */

  public getRequestStatusAbortController({
    requestStatusId,
  }: {
    requestStatusId: RequestStatus["id"];
  }) {
    return this.getRequestStatus(requestStatusId)?.abortController;
  }

  public abortRequest({ id }: { id: RequestStatus["id"] }) {
    const requestStatus = this.getRequestStatus(id);
    const abortController = requestStatus?.abortController;

    if (abortController) {
      abortController.abort();
      this.setRequestStatus({ id, ...defaultRequestStatus, isAborted: true });
    } else {
      console.error(`No abort controller found for ${id}`);
    }
  }

  public removeRequestStatus({ id }: { id: RequestStatus["id"] }) {
    this.abortRequest({ id });
    const dispatch = reduxHelper.getDispatch();
    dispatch(clearRequestStatus({ id }));
  }
}

export const AppRequestStatusManager = new RequestStatusManager();
