import { TENANT_TABLE_VIEW, TenantViewType } from "TenantModule/constants";
import { Tenant } from "TenantModule/types";
import { TableConfigV2 } from "hooks/useMuiTableBuilderV2";
import merge from "lodash/merge";
// Data cells
import { TenantArchiverCell } from "./DataCells/TenantArchiverCell";
import { TenantArchivedAtCell } from "./DataCells/TenantArchivedAtCell";
import { TenantIdCell } from "./DataCells/TenantIdCell";
import { TenantNameCell } from "./DataCells/TenantNameCell";
import { TenantStatusCell } from "./DataCells/TenantStatusCell";
import { TenantCreatorCell } from "./DataCells/TenantCreatorCell";
import { TenantCreatedAtCell } from "./DataCells/TenantCreatedAtCell";
import { TenantTagsCell } from "./DataCells/TenantTagsCell";
import { TenantCsmCell } from "./DataCells/TenantCsmCell";
// Header cells
import { TenantArchiverHeaderCell } from "./HeaderCells/TenantArchiverHeaderCell";
import { TenantArchivedAtHeaderCell } from "./HeaderCells/TenantArchivedAtHeaderCell";
import { TenantIdHeaderCell } from "./HeaderCells/TenantIdHeaderCell";
import { TenantNameHeaderCell } from "./HeaderCells/TenantNameHeaderCell";
import { TenantStatusHeaderCell } from "./HeaderCells/TenantStatusHeaderCell";
import { TenantCreatedAtHeaderCell } from "./HeaderCells/TenantCreatedAtHeaderCell";
import { TenantCreatorHeaderCell } from "./HeaderCells/TenantCreatorHeaderCell";
import { TenantTagsHeaderCell } from "./HeaderCells/TenantTagsHeaderCell";
import { TenantCsmHeaderCell } from "./HeaderCells/TenantCsmHeaderCell";
import { TenantCheckboxCell } from "./DataCells/TenantCheckboxCell";

export type TenantListItem = Tenant & {
  id: string;
  selected: boolean;
};

const EmptyDiv = () => <div />;

export const COLUMNS = {
  TENANT_ID: "ID",
  TENANT_NAME: "TenantName",
  STATUS: "status",
  CREATOR: "creator",
  CREATED_AT: "createdAt",
  TAGS: "tags",
  CSM: "csm",
  ARCHIVER: "archived_by",
  ARCHIVED_AT: "archivedAt",
  CHECKBOX: "checkbox",
  // REALMS: "realms",
};

export const ROW_TYPES = {
  HEADER: "HEADER",
  DATA: "DATA",
};

const customTableConfigs = {
  [TENANT_TABLE_VIEW.ACTIVE]: {
    columns: [
      COLUMNS.CHECKBOX,
      COLUMNS.TENANT_ID,
      COLUMNS.TENANT_NAME,
      COLUMNS.STATUS,
      COLUMNS.CREATOR,
      COLUMNS.CREATED_AT,
      COLUMNS.TAGS,
      COLUMNS.CSM,
    ],
  },
  [TENANT_TABLE_VIEW.ARCHIVED]: {
    columns: [
      COLUMNS.TENANT_ID,
      COLUMNS.TENANT_NAME,
      COLUMNS.STATUS,
      COLUMNS.CREATOR,
      COLUMNS.CREATED_AT,
      COLUMNS.TAGS,
      COLUMNS.CSM,
      COLUMNS.ARCHIVER,
      COLUMNS.ARCHIVED_AT,
    ],
  },
};

export const getTableConfig = ({
  variant = TENANT_TABLE_VIEW.ACTIVE,
}: {
  variant: TenantViewType;
}): TableConfigV2 => {
  const variantConfig = customTableConfigs[variant];
  const sharedConfig = {
    /* --------------------------------- Shared --------------------------------- */
    hasStickyHeader: true,
    tableHeaderTitle: "Tenant",
    rowTypes: ROW_TYPES,
    hideToolbar: true,
    columnsMinWidth: {
      [COLUMNS.CHECKBOX]: 40,
      [COLUMNS.TENANT_ID]: 40,
    },
    cellsByRowTypesAndColumns: {
      [ROW_TYPES.DATA]: {
        [COLUMNS.TENANT_ID]: TenantIdCell,
        [COLUMNS.TENANT_NAME]: TenantNameCell,
        [COLUMNS.STATUS]: TenantStatusCell,
        [COLUMNS.CREATOR]: TenantCreatorCell,
        [COLUMNS.CREATED_AT]: TenantCreatedAtCell,
        [COLUMNS.TAGS]: TenantTagsCell,
        [COLUMNS.CSM]: TenantCsmCell,
        [COLUMNS.CHECKBOX]: TenantCheckboxCell,
        [COLUMNS.ARCHIVER]: TenantArchiverCell,
        [COLUMNS.ARCHIVED_AT]: TenantArchivedAtCell,
      },
      [ROW_TYPES.HEADER]: {
        [COLUMNS.TENANT_ID]: TenantIdHeaderCell,
        [COLUMNS.TENANT_NAME]: TenantNameHeaderCell,
        [COLUMNS.STATUS]: TenantStatusHeaderCell,
        [COLUMNS.CREATOR]: TenantCreatorHeaderCell,
        [COLUMNS.CREATED_AT]: TenantCreatedAtHeaderCell,
        [COLUMNS.TAGS]: TenantTagsHeaderCell,
        [COLUMNS.CSM]: TenantCsmHeaderCell,
        [COLUMNS.CHECKBOX]: EmptyDiv,
        [COLUMNS.ARCHIVER]: TenantArchiverHeaderCell,
        [COLUMNS.ARCHIVED_AT]: TenantArchivedAtHeaderCell,
      },
    },
  };

  return merge({}, sharedConfig, variantConfig);
};
