import keyBy from "lodash/keyBy";

export const REMOVE_TAG_TEXT = "remove";
export const STAND_BY_TAG = "Stand-By";
export const LOWER_CASE_STAND_BY = "stand by";
export const KEYBOARD_KEYS = { ENTER: "Enter" };
export const chipWidth = "150px";

export enum StandardTag {
  TestTeam = "test-team",
  SelfServe = "self-serve",
  Over50 = "50+",
  Over100 = "100+",
  ApiEnabled = "api enabled",
  IsolatedAi = "isolated-ai",
}

export const standardTagsList = [
  {
    name: StandardTag.TestTeam,
    label: "Test team",
    value: StandardTag.TestTeam,
  },
  {
    name: StandardTag.SelfServe,
    label: "Self-serve",
    value: StandardTag.SelfServe,
  },
  {
    name: StandardTag.Over50,
    label: "50+",
    value: StandardTag.Over50,
  },
  {
    name: StandardTag.Over100,
    label: "100+",
    value: StandardTag.Over100,
  },
];

export const realmStandardTagsList = [
  ...standardTagsList,
  {
    name: StandardTag.IsolatedAi,
    label: "Isolated AI",
    value: StandardTag.IsolatedAi,
  },
];

const allStandardTags = [
  ...standardTagsList,
  {
    name: StandardTag.IsolatedAi,
    label: "Isolated AI",
    value: StandardTag.IsolatedAi,
  },
];

export const standardTagsHash = keyBy(allStandardTags, "value");
