import { useTenants } from "TenantModule/hooks/useTenants";
import { isGovCloud } from "core/apiClient";
import useSandboxes from "SandboxModule/hooks/useSandboxes";
import { useTeamRetoolIntegrations } from "../hooks/useTeamRetoolIntegrations";
import { LinearBuffer } from "components/Progress";
import { useSearchParam } from "react-use";
import Retool from "react-retool";

export const PricingCalculatorPage = () => {
  const { isUsingNonDefaultTenant } = useTenants();
  const { isUsingNonDefaultRailsSandbox } = useSandboxes();
  const teamId = useSearchParam("team_id");
  const teamIdToUse = teamId ? +teamId : null;

  const {
    getRetoolIntegrationList,
    isFetchingIntegrationForRetool: isLoading,
    userCount,
    companyName,
  } = useTeamRetoolIntegrations({ teamId: teamIdToUse });

  if (isLoading) return <LinearBuffer />;

  const activeIntegrations = getRetoolIntegrationList();

  const data = {
    companyName,
    userCount,
    activeIntegrations,
    isUsingRealm: isUsingNonDefaultRailsSandbox,
    isUsingTenant: isUsingNonDefaultTenant,
    isUsingGovCloud: isGovCloud,
  };

  return (
    <Retool
      url="https://mosaicai.retool.com/embedded/public/2211d5e3-90f0-4894-a5ee-3dfa3c9cb32d"
      data={data}
    />
  );
};
