import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppStateType } from "reducers";
// import { isSuperAdmin } from "utils";
import * as Types from "StatisticsModule/types";
import * as StatisticsService from "StatisticsModule/services";
import {
  countStatisticsSearchRangeArray,
  COUNT_STATISTICS_SEARCH_RANGE,
} from "StatisticsModule/constants";
import * as constants from "@constants";

export const fetchCountStatistics = createAsyncThunk(
  "fetchCountStatistics",
  async (
    params: Omit<Types.FetchCountStatisticsParams, "search_range_date"> & {
      customOnly?: boolean; // Only fetch for custom range
      customEnd?: string;
      customStart?: string;
      isCustomRangeValid: boolean;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const { onSuccess, onFailure, customOnly, customEnd, customStart } = params;
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;

      // Validate to make sure provide both start and end date
      const countStatisticsSearchRangeArrayToUse = params.isCustomRangeValid
        ? countStatisticsSearchRangeArray
        : countStatisticsSearchRangeArray.filter(
            (obj) => obj.value !== COUNT_STATISTICS_SEARCH_RANGE.CUSTOM
          );

      const promises = customOnly
        ? [
            StatisticsService.getCountStatistics(
              {
                ...params,
                search_start_date: customStart,
                search_end_date: customEnd,
              },
              {},
              auth_token
            ),
          ]
        : [
            ...countStatisticsSearchRangeArrayToUse.map((dateRange) => {
              const apiParams =
                dateRange.value === COUNT_STATISTICS_SEARCH_RANGE.CUSTOM
                  ? {
                      ...params,
                      search_start_date: customStart,
                      search_end_date: customEnd,
                    }
                  : { ...params, search_range_date: dateRange.value };

              return StatisticsService.getCountStatistics(
                apiParams,
                {},
                auth_token
              );
            }),
          ];

      const response = await Promise.allSettled(promises);

      onSuccess && onSuccess({ response });
      return response;
    } catch (e) {
      onFailure && onFailure({ error: e });
      return rejectWithValue(e);
    }
  }
);
