import { createReducer } from "@reduxjs/toolkit";
import { GenericTeamInterface } from "types";
import keyBy from "lodash/keyBy";
import * as thunkActions from "thunk";

export type TeamStateType = {
  teamHash: Record<number, GenericTeamInterface>;
  requestStatusesAndErrors: Record<
    string,
    {
      isRequesting: boolean;
      error: Record<string, any>;
    }
  >;
};

const INITIAL_STATE: TeamStateType = {
  teamHash: {},
  requestStatusesAndErrors: {},
};

export default createReducer(INITIAL_STATE, (builder) => {
  /* ------------------------------- Create Team ------------------------------ */
  builder.addCase(thunkActions.createTeam.pending, (state, { payload }) => {
    state.requestStatusesAndErrors[thunkActions.createTeam.typePrefix] = {
      isRequesting: true,
      error: {},
    };
  });
  /* -------------------------------------------------------------------------- */
  builder.addCase(thunkActions.fetchTeams.fulfilled, (state, { payload }) => {
    state.teamHash = {
      ...state.teamHash,
      ...keyBy(payload.teams, "id"),
    };
  });
  builder.addCase(thunkActions.fetchReportsTeams.fulfilled, (state, { payload }) => {
    state.teamHash = {
      ...state.teamHash,
      ...keyBy(payload.teams, "id"),
    };
  });
  /* -------------------------------------------------------------------------- */
  builder.addCase(
    thunkActions.updateTeam.fulfilled,
    (state, { payload, meta }) => {
      state.requestStatusesAndErrors[thunkActions.updateTeam.typePrefix] = {
        isRequesting: false,
        error: {},
      };
      state.teamHash = {
        ...state.teamHash,
        [payload.id]: {
          ...payload,
        },
      };
    }
  );
});
