import {
  REPORTS_INTEGRATION_DETAILS_PATH,
  REPORTS_TABLE_TEAMS_PATH,
  LOGS_PATH,
  TEAM_PROFILE_PATH,
  REPORTS_PENDING_ENTITY_PATH,
  INTEGRATION_HEALTH_PATH,
  SANDBOXES_PATH,
  MEMBER_TABLE_VIEWS,
  DEFAULT_MEMBER_TABLE_VIEW,
  TEAM_MANAGEMENT_PATH,
  REPORTS_VIEW_TYPES,
  DEFAULT_REPORTS_VIEW_TYPES,
  REPORTS_OVERALL_INTEGRATION_DETAILS_PATH,
  DATA_MANAGEMENT_PATH,
  CSM_TOOLS_PATH,
  ENTITY_ERROR_PATH,
  REPORT_SUPER_ADMIN_SECTION_PATH,
  TENANT_PATH,
  SUBSCRIPTION_REALMS_PATH,
  TENANT_AND_REALM_MANAGEMENT_PATH,
  PRICING_CALCULATOR_PATH,
} from "@constants";
import { ValueOf } from "type-fest";
import qs from "qs";
import { EntityErrorPageUrlQueryParams } from "types";

export const getIntegrationDetailsDeepLinkUrl = (
  teamId: string | number,
  targetService: string,
  targetServiceId: string | number
) => {
  return `${REPORTS_INTEGRATION_DETAILS_PATH}/team/${teamId}/targetService/${targetService}/targetServiceId/${targetServiceId}`;
};

export const getIntegrationHealthDeepLinkUrl = (
  teamId: string | number,
  targetService: string,
  targetServiceId: string | number
) => {
  return `${INTEGRATION_HEALTH_PATH}/team/${teamId}/targetService/${targetService}/targetServiceId/${targetServiceId}`;
};

export const getTeamProfileUrl = ({
  teamId,
  memberTableView = DEFAULT_MEMBER_TABLE_VIEW,
}: {
  teamId: number;
  memberTableView?: ValueOf<typeof MEMBER_TABLE_VIEWS>;
}) => `${TEAM_PROFILE_PATH}/${teamId}/${memberTableView}`;

export const getPendingEntitiesUrl = ({
  targetServiceId,
  teamId,
}: {
  targetServiceId: number;
  teamId: number;
}) =>
  `${REPORTS_PENDING_ENTITY_PATH}/targetServiceId/${targetServiceId}/teamId/${teamId}`;

export const getNetworkLogUrl = ({
  mosaic_team_id,
  requestId,
  sessionId,
}: {
  mosaic_team_id: number;
  requestId: string | null;
  sessionId: string;
}) =>
  `${LOGS_PATH}/${mosaic_team_id}?requestId=${requestId}&sessionId=${sessionId}`;

export const getSandboxesPageUrl = () => SANDBOXES_PATH;
export const getSubscriptionRealmsPageUrl = () => SUBSCRIPTION_REALMS_PATH;
export const getTenantAndRealmManagementPageUrl = () =>
  TENANT_AND_REALM_MANAGEMENT_PATH;

export const getReportTeamsPageDeepLinkUrl = ({
  reportTableView = DEFAULT_REPORTS_VIEW_TYPES,
}: {
  reportTableView?: ValueOf<typeof REPORTS_VIEW_TYPES>;
}) => {
  return `${REPORTS_TABLE_TEAMS_PATH}/${reportTableView}`;
};

export const getOverallIntegrationUrl = (queryParams?: string) => {
  return `${REPORTS_OVERALL_INTEGRATION_DETAILS_PATH}?${queryParams}`;
};
export const getTeamManagementPageUrl = () => {
  return TEAM_MANAGEMENT_PATH;
};

export const getDataManagementPageUrl = () => {
  return DATA_MANAGEMENT_PATH;
};

export const getCsmToolsPageUrl = () => {
  return CSM_TOOLS_PATH;
}

export const getSuperAdminPageUrl = () => {
  return REPORT_SUPER_ADMIN_SECTION_PATH;
};

export const getEntityErrorPageUrl = (
  params?: EntityErrorPageUrlQueryParams
) => {
  if (!params) return ENTITY_ERROR_PATH;

  const queryParams = qs.stringify(params);

  return `${ENTITY_ERROR_PATH}/?${queryParams}`;
};

export const getPricingCalculatorUrl = (params: { team_id: number }) => {
  const queryParams = qs.stringify(params);

  return `${PRICING_CALCULATOR_PATH}?${queryParams}`;
};

export const getTenantsPageUrl = () => TENANT_PATH;

export const standardizeUrl = ({ url }: { url: string }) => {
  // Ensure the URL starts with http:// or https://
  if (!/^https?:\/\//.test(url)) {
    // Prepend http:// for localhost URLs specifically
    if (url.startsWith("localhost")) {
      url = "http://" + url;
    } else {
      url = "https://" + url;
    }
  }

  // Special handling for localhost to ensure it uses http instead of https
  if (url.includes("https://localhost")) {
    url = url.replace("https://localhost", "http://localhost");
  }

  // Normalize to 'http:/' or 'https:/' by removing duplicate slashes after the scheme
  return url.replace(/^(https?:\/)(\/)+/, "$1/");
};
