import { AppStateType } from "reducers";
import { isSuperAdmin } from "utils";
import { createSelector } from "reselect";

export const selectAuthToken = (state: AppStateType) =>
  state.authReducer.user?.auth_token;

export const selectAuthUser = (state: AppStateType) => state.authReducer.user;

export const selectAuth = (state: AppStateType) => state.authReducer;

export const selectAuthAccount = (state: AppStateType) =>
  state.authReducer.user?.account;

export const selectIsSuperAdmin = createSelector(selectAuthUser, (user) => {
  if (!user?.account) return false;

  const { default_team, teams_roles } = user.account;
  const defaultTeamRoles: number[] = teams_roles?.[default_team?.id] || [];

  return isSuperAdmin(defaultTeamRoles);
});

/** Error and loading state */
export const selectAuthLoadingState = (state: AppStateType) =>
  state.authReducer.loadingState;

export const selectAuthError = (state: AppStateType) => state.authReducer.error;
