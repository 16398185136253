import {
  RequiredQueryKeyFilter,
  createDefaultQueryKeysGenerator,
} from "core/query/constants";

interface IntegrationQueryDetailKeyFilter extends RequiredQueryKeyFilter {}

interface IntegrationQueryListKeyFilter extends RequiredQueryKeyFilter {}

export const integrationQueryKeys = createDefaultQueryKeysGenerator<
  IntegrationQueryListKeyFilter,
  IntegrationQueryDetailKeyFilter
>({
  keyDataType: "integration",
});
