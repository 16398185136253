import { AppEvents } from "./constants";

const eventTarget = new EventTarget();

class EventManager {
  emit({ eventName, detail }: { eventName: AppEvents; detail: any }) {
    const newEvent = new CustomEvent(eventName, {
      detail,
    });
    eventTarget.dispatchEvent(newEvent);
  }

  listen({
    eventName,
    callback,
  }: {
    eventName: AppEvents;
    callback: NonNullable<Parameters<EventTarget["addEventListener"]>[1]>;
  }) {
    eventTarget.addEventListener(eventName, callback);
  }

  stopListen({
    eventName,
    callback,
  }: {
    eventName: AppEvents;
    callback: NonNullable<Parameters<EventTarget["removeEventListener"]>[1]>;
  }) {
    eventTarget.removeEventListener(eventName, callback);
  }
}

export const eventManager = new EventManager();
