import { useDynamicModal } from "components/DynamicModalProvider/useDynamicModal";
import { useCallback } from "react";
import { ItemFilter, filterItemWithKeywords } from "FiltersModule/utils";
import { csmHashById, csmList } from "CsmModule/constants";
import { SimpleMenuItem } from "types";
import SimpleMenu from "components/SimpleMenu";

const itemFilter: ItemFilter = ({ searchWords, item }) =>
  filterItemWithKeywords({
    searchWords,
    item,
    filterKeysArray: ["name"],
  });

export type CsmMenuItem = {
  accountId: number;
  name: string;
};

const menuItems = csmList.map((item) => ({
  ...item,
  label: item.name,
}));

export const useCsmDropdown = () => {
  const { openModal } = useDynamicModal();

  const openCsmDropdown = useCallback(
    ({
      target,
      onSelect,
    }: {
      target: HTMLElement;
      onSelect: (args: { csm: CsmMenuItem }) => void;
    }) => {
      const onClick = ({ item }: { item: CsmMenuItem }) => {
        const csm = csmHashById[item.accountId];

        if (csm) {
          onSelect?.({ csm });
        }
      };

      openModal({
        modal: ({ isOpen, toggle }) => {
          return (
            <SimpleMenu
              anchorEl={target}
              items={menuItems}
              handleClickV2={onClick}
              handleClose={toggle}
              itemFilter={itemFilter}
              showSearchBar
            />
          );
        },
      });
    },
    [openModal]
  );

  return { openCsmDropdown };
};
