import { SwitchTenantModal } from "TenantModule/components/SwitchTenantModal";
import { Tenant } from "TenantModule/types";
import { useDynamicModal } from "components/DynamicModalProvider/useDynamicModal";
import { useCallback } from "react";

export const useSwitchTenantModal = () => {
  const { openModal } = useDynamicModal();

  const openSwitchTenantModal = useCallback(
    (args: { realmId: string; tenantName: Tenant["tenantName"] }) => {
      openModal({
        modal: ({ isOpen, toggle }) => {
          return (
            <SwitchTenantModal
              toggle={toggle}
              tenantName={args.tenantName}
              realmId={args.realmId}
            />
          );
        },
      });
    },
    [openModal]
  );

  return {
    openSwitchTenantModal,
  };
};
