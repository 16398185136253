import { SearchContainer } from "TeamModule/components/TeamMembersTable/style";
import {
  SearchIconWrapper,
  Search,
  Input,
} from "ProfileModule/components/styles";
import { ChangeEvent } from "react";
import { Search as SearchIcon } from "react-feather";

export const SuperAdminsTableHeader: React.FC<{
  searchMember: (e: ChangeEvent<HTMLInputElement>) => void;
  searchedMemberName: string;
}> = ({ searchedMemberName, searchMember }) => {
  return (
    <SearchContainer>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <Input
          placeholder="Search member by name"
          value={searchedMemberName}
          onChange={searchMember}
        />
      </Search>
    </SearchContainer>
  );
};
