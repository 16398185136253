import ApiClientManager from "core/apiClient";
import { AppStateType } from "reducers";
import { createSelector } from "reselect";
import { initialState } from "TenantModule/reducers";

const selectTenantState = (state: AppStateType) =>
  state.tenantReducer || initialState;

export const selectTenantOrder = createSelector(
  selectTenantState,
  (tenantState) => tenantState.order
);

export const selectTenantHash = createSelector(
  selectTenantState,
  (tenantState) => tenantState.tenantHash
);

export const selectTenantIsoStates = createSelector(
  selectTenantState,
  (state) => state.isoStates
);

export const selectDefaultTenant = createSelector(
  selectTenantState,
  (state) => state.defaultTenant ?? ApiClientManager.getDefaultTenant()
);
