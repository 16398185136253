import styled, { css } from "styled-components";
import { rgba } from "polished";
import { darken } from "polished";
import cn from "classnames";

type SidebarButtonProps = {
  id: number | string;
  children: React.ReactNode;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
};

export const sidebarSharedStyle = css`
  padding-left: ${(props) => props.theme.spacing(12)}px;
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }
  &:hover {
    background-color: ${(props) =>
      darken(0.015, props.theme.sidebar.background)};
  }
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

export const Item = styled.div`
  ${sidebarSharedStyle}
  cursor: pointer;

  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;

  &.disabled {
    color: gray;
    pointer-events: none;
  }

  &.active {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const SidebarItem = ({
  id,
  children,
  onClick,
  disabled,
  active,
}: SidebarButtonProps) => {
  return (
    <Item
      onClick={onClick}
      className={cn({
        disabled,active
      })}
      key={id}
    >
      {children}
    </Item>
  );
};

export default SidebarItem;
