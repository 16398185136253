import React, { ReactNode } from "react";
import { startCase, isNil } from "lodash";
import styled from "styled-components";
import { LinearBuffer } from "../components/Progress";
import * as Types from "types";
import { Tooltip, Typography, Grid, Chip, Box } from "@material-ui/core";
import { AlertProps } from "@material-ui/lab";
import { StyledAlert } from "shared/styles";
import * as IntegrationTypes from "IntegrationModule/types";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import isEmpty from "lodash/isEmpty";

const BrokenLinkIcon = styled(LinkOffIcon)`
  color: #a0a0a0;
  margin-right: 0.7rem;
  &.sidebar {
    position: relative;
    top: 6px;
    left: 6px;
  }
`;

const StyledListItem = styled.li`
  max-width: 25rem;
  word-break: break-word;
`;

/**
 * Green (success) = if no create or update
 * Blue (info) = any update and no create
 * Yellow (warning) = More than 30 and less than 100 inclusive (create can be very little, but total create + total update can be very big)
 * Red (error) = Less than 30%
 * Note: Refer to Material UI component <Alert> in "@material-ui/core"
 * @param {IntegrationTypes.CountPerDataTypeValues} countsByDataType
 * @param {number} ratioPercentage
 * @returns AlertProps["color"] | string
 */
export const getSyncRatioAlertDisplay = (
  countsByDataType: IntegrationTypes.CountPerDataTypeValues,
  ratioPercentage: number
): AlertProps["color"] | string => {
  const { totalCreate, totalUpdate } = countsByDataType;

  if (!totalCreate && !totalUpdate) return "success";

  if (!totalCreate && totalUpdate) return "info";

  if (ratioPercentage > 30 && ratioPercentage <= 100) return "warning";

  if (ratioPercentage <= 30) return "error";
};

/**
 * Success / Request ratio
 */
export const getIntegrationHealthAlertDisplay = (percentageNumber: number) => {
  if (percentageNumber <= 50) return "error";

  if (percentageNumber <= 70) return "warning";

  if (percentageNumber <= 95) return "info";

  return "success";
};

export const calculateAlertSeverityByStatusCode = (statusCode: number) => {
  if (statusCode <= 299) return "success";

  if (statusCode <= 399) return "info";

  if (statusCode <= 499) return "warning";

  if (statusCode >= 500) return "error";

  return "success";
};

const renderSubRowArrayRecursive = (arr: Array<any>): JSX.Element => {
  return (
    <>
      {arr.map((v) => {
        if (typeof v === "object" && v !== null) {
          return renderSubRowRecursive(v);
        }

        if (Array.isArray(v)) return renderSubRowArrayRecursive(v);

        return v;
      })}
    </>
  );
};

export const renderSubRowRecursive = (records: Record<any, any> | any[]) => {
  if (!records) return;

  if (Array.isArray(records)) {
    return <ul>{renderSubRowArrayRecursive(records)}</ul>;
  }

  if (typeof records !== "object") {
    return JSON.stringify(records);
  }

  return (
    <ul>
      {Object.entries(records).map(([key, value]) => {
        if (Array.isArray(value)) {
          return (
            <StyledListItem>
              <b>{startCase(key)}</b>: {renderSubRowArrayRecursive(value)}
            </StyledListItem>
          );
        }

        if (typeof value === "object" && value !== null) {
          return (
            <>
              <StyledListItem>
                <b>{startCase(key)}</b>
              </StyledListItem>

              {renderSubRowRecursive(value)}
            </>
          );
        }

        return (
          <StyledListItem>
            <b>{startCase(key)}</b>: {"  "}
            {stringifyIfNotUndefinedNorNull(value)}
          </StyledListItem>
        );
      })}
    </ul>
  );
};

export const stringifyIfNotUndefinedNorNull = (
  value: any,
  falseReturnValue = "N/A"
) => {
  return value !== null && value !== undefined
    ? String(value)
    : falseReturnValue;
};

type ConditionallyRenderElement = {
  isLoading: boolean;
  /**
   *  Make sure not to pass Record (object) type
   */
  error?: ReactNode;
  renderError?: () => unknown;
  content: any;
  Loader?: any;
  loaderCardContentClassName?: string;
};

export const conditionallyRenderElement = ({
  isLoading,
  error,
  renderError,
  content,
  Loader,
  loaderCardContentClassName,
}: ConditionallyRenderElement) => {
  if (isLoading) {
    return (
      Loader || (
        <LinearBuffer loaderCardContentClassName={loaderCardContentClassName} />
      )
    );
  }

  if (error) {
    return renderError ? renderError() : error;
  }

  return content;
};

const noop = () => {};

/**
 * A util only used for showing 2 icons depending on the health status
 * - Green check mark = If request = success
 * - Red mark = If request > success
 */
export const renderIntegrationHealthStatus = ({
  isHealthy,
  integrationHealth,
  className = "",
  style = {},
  overrideTooltip,
  onClick,
}: {
  isHealthy: boolean | null | undefined;
  integrationHealth: Types.IntegrationHealth;
  className?: string;
  style?: React.CSSProperties;
  overrideTooltip?: any;
  onClick?: (e: any) => void;
}) => {
  return !isNil(isHealthy) ? (
    <Tooltip
      title={
        overrideTooltip || (
          <>
            {/* <Typography>
              Request Count: {integrationHealth.requestCount}
            </Typography> */}
            <Typography>
              Healthy Connection Today: {integrationHealth.successCount}
            </Typography>
          </>
        )
      }
    >
      <StyledAlert
        onClick={onClick}
        style={style}
        className={className}
        severity={isHealthy ? "success" : "error"}
      />
    </Tooltip>
  ) : (
    <BrokenLinkIcon />
  );
};

/**
 * A util only used for showing 4 icons depending on the health status
 * requestSuccessRatio = (request / success * 100)
 * - Show nothing if integrationHealth is falsy
 * - else check getIntegrationHealthAlertDisplay() for severity equivalence to percent
 */
export const renderIntegrationHealthByRequestAndSuccessCountPercent = ({
  integrationHealth,
  onClick,
  className,
  style,
  overrideTooltip,
  linkIconClassName = "",
}: {
  integrationHealth?: Types.IntegrationHealth;
  className?: string;
  style?: React.CSSProperties;
  overrideTooltip?: any;
  onClick?: (e: any) => void;
  linkIconClassName?: string;
}) => {
  /**
   * Couple of edge case that mean integration health is not appropriate for display
   * 1) integrationHealth not defined
   * 2) integrationHealth defined, but empty object
   * 3) requestCount == 0 -> This case shouldn't happen, because requestCount being 0 means there should be no successful count for today.
   *
   * => Show Broken Link
   */
  if (
    !integrationHealth ||
    isEmpty(integrationHealth) ||
    integrationHealth.requestCount === 0
  )
    return <BrokenLinkIcon className={linkIconClassName} />;

  const { requestCount, successCount, errors } = integrationHealth;

  const successToRequestRatioPercent = (
    (successCount / requestCount) *
    100
  ).toFixed(1);

  const formatError = ({
    errorName,
    errorCount,
  }: {
    errorName: string;
    errorCount: number;
  }) => {
    const errorPercentage = ((errorCount / requestCount) * 100).toFixed(1);

    return (
      <Box
        key={errorName}
      >{`${errorName}: ${errorCount} (${errorPercentage}%)`}</Box>
    );
  };

  const integrationHealthTooltipWithErrors = (
    <div>
      {`${successToRequestRatioPercent}% Successful Connections Today`}
      {errors
        ? Object.entries(errors).map(([errorName, errorCount]) =>
            errorCount ? formatError({ errorName, errorCount }) : null
          )
        : null}
    </div>
  );

  return (
    <Tooltip title={overrideTooltip || integrationHealthTooltipWithErrors}>
      <StyledAlert
        className={className}
        onClick={onClick}
        style={{ ...style, cursor: onClick ? "pointer" : "default" }}
        severity={getIntegrationHealthAlertDisplay(
          +successToRequestRatioPercent
        )}
      />
    </Tooltip>
  );
};

const renderRemainingChips = (tags: Array<string>, limit: number = 3) => {
  return tags && tags.length > limit ? (
    <ChipContainer className="tooltip-chip-container">
      <Grid>
        {tags.slice(limit).map((tag) => (
          <Chip className="tooltip-chip" label={tag} color="primary" />
        ))}
      </Grid>
    </ChipContainer>
  ) : (
    <></>
  );
};

const renderVisibleChips = (tags: Array<string>, limit: number) => {
  return (
    <ChipContainer>
      {tags.slice(0, limit).map((tag) => (
        <Chip className="chip" label={tag} color="primary" />
      ))}
      {tags.length >= limit + 1 && (
        <Chip className="chip" label={`+${tags.slice(limit).length}`} />
      )}
    </ChipContainer>
  );
};

export const renderChips = (chips: Array<string>, limit: number) => {
  return (
    <Tooltip
      placement="top-start"
      title={renderRemainingChips(chips || [], limit)}
    >
      {renderVisibleChips(chips || [], limit)}
    </Tooltip>
  );
};

/* -------------------------------------------------------------------------- */

const ChipContainer = styled.span`
  .MuiChip-root {
    margin-right: 15px;
    margin-top: 10px;
    margin-bottom: 15px;
    height: 26px;
  }
  display: flex;
  justify-content: flex-start;
  &.tooltip-chip-container {
    flex-direction: column;
  }
  .chip {
    margin: 0 3px;
    height: 23px;
  }
  .tooltip-chip {
    margin: 4px;
    max-width: 280px;
  }
`;
