import { Draft, createReducer } from "@reduxjs/toolkit";
import * as thunkActions from "thunk";
import * as actions from "actionCreators";
import { SelectedTargetService, TeamMembershipType, DefaultBoard } from "types";
import * as ProfileTypes from "ProfileModule/types";

type InitialStateType = {
  provisionErrors: ProfileTypes.ProvisionErrors;
  team_members: Array<TeamMembershipType>;
  selectedTargetService: SelectedTargetService;
  agentToken: ProfileTypes.AgentToken;
  // integrationConnect: ProfileTypes.IntegrationConnection;
  provisionIntegrationResponse: ProfileTypes.ProvisionIntegration; // provision
  requestStatusesAndErrors: {
    [x: string]: {
      isRequesting: any;
      error: any;
      isSuccess?: boolean;
    };
  };
  default_board: DefaultBoard | object;
};

export const initialState: InitialStateType = {
  team_members: [],
  default_board: {},
  selectedTargetService: {
    name: "",
    id: "",
    pendingEntitiesDataTypes: [],
    pendingEntitiesMappingStatuses: [],
  },
  agentToken: "",
  // integrationConnection: {}, TO COME
  provisionIntegrationResponse: {},
  provisionErrors: [],
  requestStatusesAndErrors: {
    // Example
    [thunkActions.fetchTeamMembers.typePrefix]: {
      isRequesting: false,
      error: {},
    },
    [thunkActions.updateTeamMember.typePrefix]: {
      isRequesting: false,
      error: {},
    },
    [thunkActions.updateTeam.typePrefix]: {
      isRequesting: false,
      error: {},
    },
  },
};

export default createReducer(initialState, (builder) => {
  /* ---------------------------- Non Async Request --------------------------- */

  builder.addCase(actions.setTargetService, (state, { payload }) => {
    state.selectedTargetService = payload;
  });

  builder.addCase(actions.updateProfileState, (state, { payload }) => {
    const nextState = { ...state };
    Object.keys(payload).forEach((reduxKey) => {
      nextState[reduxKey as keyof typeof nextState] = payload[reduxKey];
    });
    return nextState;
  });

  /* --------------------------- Fetch Team Members --------------------------- */
  builder.addCase(
    thunkActions.fetchTeamMembers.pending,
    (state, { payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamMembers.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );

  builder.addCase(
    thunkActions.fetchTeamMembers.fulfilled,
    (state, { payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamMembers.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.team_members = payload;
    }
  );
  builder.addCase(
    thunkActions.fetchTeamMembers.rejected,
    (state, { meta, error }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamMembers.typePrefix
      ] = {
        isRequesting: false,
        error,
      };
      state.team_members = [];
    }
  );

  /* --------------------------- Update Team Member --------------------------- */
  builder.addCase(
    thunkActions.updateTeamMember.pending,
    (state, { payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.updateTeamMember.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );

  builder.addCase(
    thunkActions.updateTeamMember.fulfilled,
    (state, { payload, meta }) => {
      const { arg } = meta;
      const { teamMembershipId } = arg;
      const updatedMember = payload.team_member?.account;
      if (!updatedMember) return state;

      state.requestStatusesAndErrors[
        thunkActions.updateTeamMember.typePrefix
      ] = {
        isRequesting: false,
        error: payload.data?.error,
      };

      state.team_members = state.team_members.map((team_member) =>
        team_member.team_membership_id === teamMembershipId
          ? { ...team_member, ...updatedMember, id: teamMembershipId }
          : team_member
      );
    }
  );
  builder.addCase(
    thunkActions.updateTeamMember.rejected,
    (state, { meta, error }) => {
      state.requestStatusesAndErrors[
        thunkActions.updateTeamMember.typePrefix
      ] = {
        isRequesting: false,
        error,
      };
    }
  );
  /* ------------------------------- Update Team ------------------------------- */
  builder.addCase(thunkActions.updateTeam.pending, (state, { payload }) => {
    state.requestStatusesAndErrors[thunkActions.updateTeam.typePrefix] = {
      isRequesting: true,
      error: {},
    };
  });
  builder.addCase(
    thunkActions.updateTeam.rejected,
    (state, { meta, error }) => {
      state.requestStatusesAndErrors[thunkActions.updateTeam.typePrefix] = {
        isRequesting: false,
        error,
      };
    }
  );
  /* ------------------------------- Reset Members Password ------------------------------- */
  builder.addCase(
    thunkActions.resetMemberPassword.fulfilled,
    (state, { payload, meta }) => {
      state.requestStatusesAndErrors[
        thunkActions.resetMemberPassword.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
    }
  );
  /* ------------------------------- Provision Customer response ------------------------------- */
  builder.addCase(thunkActions.provisionQbd.pending, (state, { payload }) => {
    state.requestStatusesAndErrors[
      thunkActions.provisionIntegration.typePrefix
    ] = {
      isRequesting: true,
      error: {},
    };
  });

  builder.addCase(
    thunkActions.provisionQbd.fulfilled,
    (state, { payload, meta }) => {
      state.requestStatusesAndErrors[
        thunkActions.provisionIntegration.typePrefix
      ] = {
        isRequesting: false,
        error: {},
        isSuccess: true,
      };
      state.provisionIntegrationResponse = payload;
    }
  );
  builder.addCase(
    thunkActions.provisionQbd.rejected,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.provisionIntegration.typePrefix
      ] = {
        isRequesting: false,
        error: payload,
        isSuccess: false,
      };
      state.provisionIntegrationResponse = {};
    }
  );
  /* ------------------------------- Provision Integration response ------------------------------- */
  builder.addCase(
    thunkActions.provisionIntegration.pending,
    (state, { payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.provisionIntegration.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.provisionIntegration.fulfilled,
    (state, { payload, meta }) => {
      state.requestStatusesAndErrors[
        thunkActions.provisionIntegration.typePrefix
      ] = {
        isRequesting: false,
        error: {},
        isSuccess: true,
      };
      state.provisionIntegrationResponse = payload;
    }
  );
  builder.addCase(
    thunkActions.provisionIntegration.rejected,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.provisionIntegration.typePrefix
      ] = {
        isRequesting: false,
        error: payload,
        isSuccess: false,
      };
      alert("Provision failed, please try again");
      state.provisionIntegrationResponse = {};
    }
  );

  /* ------------------------------- Bulk Activate Accounts ------------------------------- */
  builder.addCase(
    thunkActions.bulkActivateAccounts.pending,
    (state, { payload }) => {}
  );
  builder.addCase(
    thunkActions.bulkActivateAccounts.fulfilled,
    (state, { payload, meta }) => {
      const { activated_account_ids, failed_account_ids } = payload;
      const successfullyActivateIdsText = activated_account_ids?.length
        ? `Accounts with successful activation: ${activated_account_ids.join(
            ", "
          )}`
        : "";
      const failedActivateIdsText = failed_account_ids?.length
        ? `Accounts failed to activate: ${failed_account_ids.join(", ")}`
        : "";
      alert(`${successfullyActivateIdsText}\n${failedActivateIdsText}`);

      const activatedAccountIdsSet = new Set(activated_account_ids);

      if (activatedAccountIdsSet.size) {
        state.team_members = state.team_members.map((member) =>
          activatedAccountIdsSet.has(member.account_id)
            ? { ...member, is_active: true }
            : member
        );
      }
    }
  );
  builder.addCase(
    thunkActions.bulkActivateAccounts.rejected,
    (state, { meta, payload }) => {
      alert("All accounts activation failed. Please try again.");
    }
  );
});
