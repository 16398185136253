import { Button, ButtonGroup } from "@material-ui/core";

type ToggleOptionItem = {
  label: string;
  value: string;
};

type ToggleOptionsButtonProps = {
  options: ToggleOptionItem[];
  currentOption: string;
  onSelect?: (args: { option: ToggleOptionItem }) => void;
  isDisabled?: boolean;
};

export const ToggleOptionsButton = ({
  options,
  currentOption,
  onSelect,
  isDisabled,
}: ToggleOptionsButtonProps) => {
  return (
    <ButtonGroup>
      {options.map((option) => (
        <Button
          key={option.value}
          color={option.value === currentOption ? "primary" : "secondary"}
          variant={option.value === currentOption ? "contained" : "outlined"}
          onClick={() => onSelect?.({ option })}
          disabled={isDisabled}
        >
          {option.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};
