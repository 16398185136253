import {
  getApiIntegrationServer,
  putApiRailsServer,
  postApiRailsServer,
  postApiIntegrationServer,
  getApiRailsServer,
} from "services";
import { UpdateTeamParams } from "ProfileModule/types";
import {
  fetchTeamsHistoricalUtilizationParams,
  FetchDataToIntegrationServerApiParams,
  FetchTeamsUtilizationTrendsParams,
  RecalculateBudgetAggregatesPayload,
  FetchActiveTeamsPayload,
} from "ReportsModule/types";
import { FetchTeamApiTokenPayload } from "TeamModule/types";

export const getSuperAdmins = (config = {}, token = "") =>
  getApiRailsServer(`/teams/super_admins`, config, token);

export const fetchDataToIntegrationServer = (
  {
    dataType,
    targetServiceId,
  }: Omit<FetchDataToIntegrationServerApiParams, "fetchFrom">,
  config = {},
  token = ""
) => {
  return getApiIntegrationServer(
    `/fetch-data/${dataType}/${targetServiceId}`,
    config,
    token
  );
};

type IntegrationForceUpdateApiParams = {
  mosaicTeamId: number;
  targetServiceId: number;
};

type IntegrationForceUpdateApiBody = {
  dataType: string;
  targetSide: boolean;
  ignoreWaitingResponse: boolean;
};

export const integrationServerForceUpdate = (
  { mosaicTeamId, targetServiceId }: IntegrationForceUpdateApiParams,
  body: IntegrationForceUpdateApiBody,
  config = {},
  token = ""
) =>
  postApiIntegrationServer(
    `/mappings/utils/force-update/${mosaicTeamId}/${targetServiceId}`,
    body,
    config,
    token
  );

export const updateTeam = (
  data: UpdateTeamParams,
  token: string,
  config: Record<string, any> = {}
) => putApiRailsServer(`/team`, data, config, token);

export const fetchTeamApiToken = ({
  params,
  token,
  config,
}: BaseApiParams<FetchTeamApiTokenPayload>) =>
  postApiRailsServer(`/integrations/auth/key`, params, config, token);  

export const getTeamsHistoricalUtilization = (
  params: fetchTeamsHistoricalUtilizationParams,
  token: string
) =>
  getApiRailsServer(
    "/historical_utilization/bulk",
    {
      params,
    },
    token
  );

export const getTeamsUtilizationTrends = (
  params: FetchTeamsUtilizationTrendsParams,
  token: string
) =>
  getApiRailsServer(
    "/utilization_trend",
    {
      params,
    },
    token
  );

export const reactivateTeam = (
  { teamId }: { teamId: number },
  config = {},
  token: string
) => postApiRailsServer(`/teams/${teamId}/reactivate`, {}, config, token);

export const recalculateBudgetAggregates = (
  params = {},
  body: RecalculateBudgetAggregatesPayload,
  config = {},
  token: string
) => postApiRailsServer("/budget_aggregates/recalculate", body, config, token);

export const getActiveTeams = ({
  params,
  token,
  config,
}: BaseApiParams<FetchActiveTeamsPayload>) =>
  getApiRailsServer(`get_active_teams`, { ...config, params }, token);
