import { MouseEventHandler } from "react";
import { useAppContext } from "containers/AppProvider";
import { useSearchTeamStrategiesDropdown } from "hooks/useSearchTeamStrategiesDropdown";
import BaseSelectorWithArrow from "components/BaseSelectorWithArrow";

export const SearchTeamStrategiesDropdown = () => {
  const { searchTeamStrategy } = useAppContext();
  const {
    openSearchTeamStrategiesDropdown,
  } = useSearchTeamStrategiesDropdown();

  const handleOpenSearchTeamStrategiesDropdown: MouseEventHandler<HTMLElement> = (
    e
  ) => {
    openSearchTeamStrategiesDropdown({ target: e.currentTarget });
  };
  return (
    <BaseSelectorWithArrow
      onClick={handleOpenSearchTeamStrategiesDropdown}
      label={searchTeamStrategy.label}
    />
  );
};
