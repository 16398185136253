import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { ComponentProps, forwardRef, Ref } from "react";
import BaseSelectorUI from "./BaseSelectorUI"; 

const BaseSelectorWithArrow = (
  props: Omit<ComponentProps<typeof BaseSelectorUI>, "endAdornment">,
  ref: Ref<HTMLDivElement>
) => <BaseSelectorUI ref={ref} {...props} endAdorment={<ArrowDropDownIcon />} />;

export default forwardRef(BaseSelectorWithArrow);
