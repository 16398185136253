import { useCallback, MouseEventHandler, ComponentProps } from "react";
import { TenantTable } from "./components/TenantTable/TenantTable";
import { TENANT_TABLE_VIEW_ARRAY, TenantViewType } from "./constants";
import { Box, Button, ButtonGroup, Typography } from "@material-ui/core";
import { TenantFiltersContainer } from "./components/TenantFiltersContainer";
import { useTenantsTableBulkSelectMenu } from "./components/TenantTable/helpers/useTenantsTableBulkSelectMenu";
import { useTenantsTableData } from "./components/TenantTable/helpers/useTenantsTableData";
import { ToggleOptionsButton } from "components/ToggleOptionsButton";
import SandboxesTable from "SandboxModule/components/SandboxesTable";

export const TenantsContainer = () => {
  const {
    setTenantTableView,
    headerLabel,
    tenantTableView,
    isLoading,
    tenantIds,
    isoStateId,
    fetchTenantAdditionalParams,
  } = useTenantsTableData();
  const {
    openTenantTableBulkSelectMenu,
    setSelectedTenantIdsSet,
    selectedTenantIds,
    selectTenant,
  } = useTenantsTableBulkSelectMenu();

  const numSelected = selectedTenantIds.size;
  const disableBulkMenu = !numSelected;

  const onViewToggle: ComponentProps<
    typeof ToggleOptionsButton
  >["onSelect"] = useCallback(
    ({ option }) => {
      setTenantTableView(option.value);
    },
    [setTenantTableView]
  );

  const TenantTableViewToggle = (
    <ToggleOptionsButton
      options={TENANT_TABLE_VIEW_ARRAY}
      currentOption={tenantTableView}
      onSelect={onViewToggle}
      isDisabled={isLoading}
    />
  );

  const onModifyClick: MouseEventHandler<HTMLElement> = (e) => {
    openTenantTableBulkSelectMenu({ target: e.currentTarget });
  };

  return (
    <Box component={"div"} style={{ backgroundColor: "white" }}>
      <Box padding={6}>
        <Typography variant="h6">{headerLabel}</Typography>
      </Box>

      <TenantFiltersContainer
        isoStateId={isoStateId}
        fetchTenantAdditionalParams={fetchTenantAdditionalParams}
      />

      <Box display={"flex"} justifyContent={"space-between"} padding={4}>
        {TenantTableViewToggle}
        <Box>
          <Button
            variant="outlined"
            color="primary"
            disabled={disableBulkMenu}
            onClick={onModifyClick}
          >
            Modify {numSelected > 0 ? numSelected : ""}
          </Button>
        </Box>
      </Box>

      <TenantTable
        variant={tenantTableView}
        isLoading={isLoading}
        selectTenant={selectTenant}
        selectedTenantIds={selectedTenantIds}
        tenantIds={tenantIds}
      />
    </Box>
  );
};
