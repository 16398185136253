import { ORDER_DIRECTION } from "@constants";
import { TableSortLabel } from "@material-ui/core";
import { ComponentProps, ReactNode } from "react";
import BoxWithTooltip from "./BoxWithTooltip";
import { TableSortProps } from "hooks/useMuiTableBuilderV2";

const TableSort = ({
  tooltip,
  text,
  column,
  orderDirection,
  orderBy,
  onSort,
  disabled,
}: ComponentProps<typeof BoxWithTooltip> & {
  text: ReactNode;
  column: string;
  disabled?: boolean;
} & TableSortProps) => {
  const isDisabled = disabled || !onSort;

  const onClick = () => {
    onSort?.(column);
  };

  return (
    <BoxWithTooltip
      boxProps={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      tooltip={tooltip}
    >
      <TableSortLabel
        active={orderBy === column}
        direction={orderBy === column ? orderDirection : ORDER_DIRECTION.ASC}
        disabled={isDisabled}
        onClick={isDisabled ? undefined : onClick}
      >
        <span>{text}</span>
      </TableSortLabel>
    </BoxWithTooltip>
  );
};

export default TableSort;
