import { useDynamicModal } from "components/DynamicModalProvider/useDynamicModal";
import { ComponentProps, useCallback } from "react";
import { SuperAdminInfoModal } from "./SuperAdminInfoModal";

export const useSuperAdminInfoModal = () => {
  const { openModal } = useDynamicModal();

  const openSuperAdminInfoModal = useCallback(
    (args: Omit<ComponentProps<typeof SuperAdminInfoModal>, "onClose">) => {
      openModal({
        modal: ({ isOpen, toggle }) => {
          return <SuperAdminInfoModal onClose={toggle} {...args} />;
        },
      });
    },
    [openModal]
  );

  return {
    openSuperAdminInfoModal,
  };
};
