import { useCallback } from "react";
import { useTenantDropdown } from "./useTenantDropdown";
import { useSandboxDropdown } from "SandboxModule/hooks/useSandboxDropdown";
import { Tenant } from "TenantModule/types";
import useSandboxes, {
  SandboxIsoStateIds,
} from "SandboxModule/hooks/useSandboxes";
import { useTenants } from "./useTenants";
import { checkIsSandboxAccessible, getIsTenantEnabled } from "utils";
import { tenantDisabledTooltipHash } from "TenantModule/constants";
import { Realm } from "types";

export const useTenantRealmDropdownsFlow = ({
  realmId,
}: {
  realmId?: Realm["realm_id"];
} = {}) => {
  const {
    openTenantDropdown,
    selectedGlobalTenant,
    selectedTenant,
    setSelectedLocalTenant,
  } = useTenantDropdown();
  const {
    openRealmDropdown,
    selectedLocalSandbox,
    selectedGlobalRealm,
    handleSelectGlobalRailsSandbox,
  } = useSandboxDropdown({
    isoStateId: SandboxIsoStateIds.AppbarRealmSelector,
    realmId,
  });
  const { getTenant, handleSelectGlobalTenant } = useTenants();
  const { getSandbox } = useSandboxes();

  const onSetGlobalTenantRealm = useCallback(
    ({
      tenantId,
      realmId,
    }: {
      tenantId: Tenant["id"];
      realmId: Realm["realm_id"];
    }) => {
      const tenant = getTenant({ tenantId });
      const realm = getSandbox(realmId);

      if (tenant && realm) {
        handleSelectGlobalRailsSandbox({
          sandbox: realm,
          tenant: tenant,
          skipReload: true,
          onSelect: () => {
            handleSelectGlobalTenant({ tenant }); // This will reload
          },
        });
      } else {
        alert("Tenant or Realm doesn't exist");
      }
    },
    [
      getSandbox,
      getTenant,
      handleSelectGlobalRailsSandbox,
      handleSelectGlobalTenant,
    ]
  );

  const onSelectRealm = ({
    target,
    tenantId,
    isSelectingGlobalRealm = false,
    onSelect,
  }: {
    target: HTMLElement;
    /**
     * If undefined, then the dropdown will show All the realms
     * If provided, will show only the realms under this tenant
     */
    tenantId?: number;
    isSelectingGlobalRealm?: boolean;
    onSelect?: (args: { realm: Realm }) => void;
  }) => {
    const tenant =
      tenantId && getIsTenantEnabled() ? getTenant({ tenantId }) : undefined;

    openRealmDropdown({
      target,
      isSelectingGlobalRealm,
      onSelect,
      formatItems: tenant
        ? (realmItems) => {
            const realmToShowSet = new Set(tenant.realms);
            return realmItems.filter((item) => {
              return (
                item.isDefault ||
                item.isHeader ||
                realmToShowSet.has(item.value)
              );
            });
          }
        : undefined,
    });
  };

  const onSelectTenantRealm = ({
    tenantDropdownTarget,
    realmDropdownTarget,
    onDone,
  }: {
    tenantDropdownTarget: HTMLElement;
    realmDropdownTarget: HTMLElement;
    onDone?: (args: { tenant: Tenant; realm: Realm }) => void;
  }) => {
    openTenantDropdown({
      target: tenantDropdownTarget,
      shouldSkipSetTenant: true,
      formatItems: (tenantItems) => {
        return tenantItems.flatMap((tenantItem) => {
          const tenantId = tenantItem.value;

          const tenant = getTenant({ tenantId });

          if (tenant) {
            const validSandboxes = tenant.realms.filter((realmId) => {
              const realm = getSandbox(realmId);
              return realm && checkIsSandboxAccessible(realm.status);
            });
            const disabled = validSandboxes.length === 0;

            return {
              ...tenantItem,
              disableButton: disabled,
              tooltip: disabled
                ? tenantDisabledTooltipHash.noRealms
                : undefined,
            };
          }

          return [];
        });
      },
      onSelect: ({ tenant }) => {
        onSelectRealm({
          target: realmDropdownTarget,
          tenantId: tenant.id,
          onSelect: ({ realm }) => {
            onDone?.({ realm: realm, tenant });

            setSelectedLocalTenant(tenant);
          },
        });
      },
    });
  };

  return {
    selectedRealm: selectedLocalSandbox,
    selectedTenant,
    selectedGlobalRealm,
    selectedGlobalTenant,
    onSelectTenantRealm,
    onSelectRealm,
    onSetGlobalTenantRealm,
  };
};
