import { useState, useEffect, Dispatch, SetStateAction } from 'react';

/**
 * A useState, but ** Every ** time when `initialValue` is changed,
 * setState will be called to rerender the component
 *
 * Use wisely to avoid infinite rerender
 *
 * Use cases:
 * - initialValue is a derived value from response of an api call/some other variables and stored in local state, and it takes couple of seconds fetch the data.
 * After the fetch finishes, the derived value will be updated to the response
 */
export const useStateEffect = <S>(
  initialValue: S
): [S, Dispatch<SetStateAction<S>>] => {
  const [state, setState] = useState<S>(initialValue);

  useEffect(() => {
    setState(initialValue);
  }, [initialValue]);

  return [state, setState];
};
