import {
  getApiGalaxyServer,
  postApiGalaxyServer,
  putApiGalaxyServer,
} from "services/api";
import {
  GetCsmAssignmentsParams,
  CreateCsmAssignmentParams,
  UpdateCsmAssignmentParams,
} from "CsmModule/types/api";

export const fetchCsmAssignments = (
  { onSuccess, onFailure, ...params }: GetCsmAssignmentsParams,
  config = {},
  token: string
) => {
  return getApiGalaxyServer(
    "/customer-success-managers/assignment/all",
    {
      ...config,
      params,
    },
    token
  );
};

export const createCsmAssignment = (
  { onSuccess, onFailure, ...body }: CreateCsmAssignmentParams,
  config = {},
  token: string
) =>
  postApiGalaxyServer(
    "/customer-success-managers/assignment/create",
    body,
    config,
    token
  );
export const updateCsmAssignment = (
  { onSuccess, onFailure, ...body }: UpdateCsmAssignmentParams,
  config = {},
  token: string
) =>
  putApiGalaxyServer(
    "/customer-success-managers/assignment/update",
    body,
    config,
    token
  );
