import { TeamMembershipType } from "types";
import { TextField, Button, Typography } from "@material-ui/core";
import * as yup from "yup";
import Modal from "shared/Modal";
import { MemberInfoContainer, MemberFormStyler } from "shared/styles";
import SimpleMenu from "components/SimpleMenu";
import Alert, { Color as AlertColor } from "@material-ui/lab/Alert";
import { useState, FC } from "react";
import { useFormik } from "formik";
import styled from "styled-components";
import useSimpleMenu from "hooks/useSimpleMenu";

const validationSchema = yup.object({
  email: yup.string().email("Enter a valid email"),
});

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

interface MemberInfoFields {
  email: string;
  first_name: string;
  last_name: string;
}

export const SharedMemberInfoModalUI: FC<{
  isEditMemberEmailDisabled?: boolean;
  email: TeamMembershipType["email"];
  firstName: TeamMembershipType["first_name"];
  lastName: TeamMembershipType["last_name"];
  accountId: TeamMembershipType["account_id"];
  onFormSubmit: (values: MemberInfoFields) => void;
  onClose: VoidFunction;
  popoverItems: {
    label: string;
    onClick: VoidFunction;
  }[];
  isRequesting: boolean;
  error: string | undefined;
}> = ({
  isEditMemberEmailDisabled = false,
  accountId,
  email,
  firstName,
  lastName,
  onFormSubmit,
  onClose,
  popoverItems,
  isRequesting,
  error,
}) => {
  const { anchorEl, handleClick, handleClose } = useSimpleMenu({});
  const [isSubmitClick, setIsSubmitClick] = useState(false);

  const showAlert = () => {
    if (!isSubmitClick || isRequesting) return;

    const {
      severity,
      message,
    }: { severity: AlertColor; message: string } = error
      ? {
          severity: "error",
          message: `Update member failed. ${error}`,
        }
      : { severity: "success", message: "Member updated successfully." };

    return (
      <Alert onClose={() => setIsSubmitClick(false)} severity={severity}>
        {message}
      </Alert>
    );
  };

  const { handleSubmit, handleChange, touched, errors } = useFormik({
    initialValues: {
      email,
      first_name: firstName,
      last_name: lastName,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      setIsSubmitClick(true);
      onFormSubmit(values);
    },
  });

  const fields = [
    {
      label: (touched.email && errors.email) || "Email",
      type: "email",
      name: "email",
      required: true,
      defaultValue: email,
      error: touched.email && Boolean(errors.email),
      disabled: isEditMemberEmailDisabled,
    },
    {
      label: "First Name",
      type: "text",
      name: "first_name",
      required: true,
      defaultValue: firstName,
    },
    {
      label: "Last Name",
      type: "text",
      name: "last_name",
      required: true,
      defaultValue: lastName,
    },
  ];

  const fullName = `${firstName} ${lastName}`;

  return (
    <Modal open onClose={onClose}>
      <MemberFormStyler>
        <form onSubmit={handleSubmit}>
          {showAlert()}
          <Typography className="header-name" variant="h4">
            {fullName}
          </Typography>
          <Typography className="header-id">Account ID: {accountId}</Typography>
          <MemberInfoContainer>
            {fields.map((field, index) => (
              <TextField
                key={index}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
                {...field}
              />
            ))}
            <ButtonContainer>
              <Button
                className="submit-button"
                type="submit"
                color="primary"
                disabled={isRequesting}
              >
                Update
              </Button>
              <SimpleMenu
                disabled={isRequesting}
                items={popoverItems}
                anchorEl={anchorEl}
                handleClick={handleClick}
                handleClose={handleClose}
              />
            </ButtonContainer>
          </MemberInfoContainer>
        </form>
      </MemberFormStyler>
    </Modal>
  );
};
