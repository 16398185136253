import { CreateTeamModal } from "TeamModule/components/CreateTeamModal";
import { useDynamicModal } from "components/DynamicModalProvider/useDynamicModal";
import React, { useCallback } from "react";

export const useCreateTeamModal = () => {
  const { openModal } = useDynamicModal();

  const openCreateTeamModal = useCallback(() => {
    openModal({
      modal: ({ isOpen, toggle }) => {
        return <CreateTeamModal toggle={toggle} />;
      },
    });
  }, [openModal]);

  return {
    openCreateTeamModal,
  };
};
