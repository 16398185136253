import { keyBy } from "lodash";
import moment from "moment";
import { ValueOf } from "type-fest";

export const INTERVAL = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  YEAR: "year",
};

export const INTERVAL_PLURAL = {
  DAYS: "days",
  WEEKS: "weeks",
  MONTHS: "months",
  YEARS: "years",
};

export const INTERVAL_SET = new Set<string>(["day", "week", "month"]);

export const INTERVAL_DISPLAY = {
  [INTERVAL.DAY]: "Day",
  [INTERVAL.WEEK]: "Week",
  [INTERVAL.MONTH]: "Month",
  [INTERVAL.YEAR]: "Year",
};

export const intervals: Array<IntervalType> = [
  {
    id: INTERVAL.DAY,
    label: "Since 1 day ago",
    value: 1,
  },
  {
    id: INTERVAL.WEEK,
    label: "Since 7 days ago",
    value: 7,
  },
  {
    id: INTERVAL.MONTH,
    label: "Since 30 days ago",
    value: 30,
  },
  {
    id: INTERVAL.YEAR,
    label: "Since 1 year ago",
    value: 365,
  },
];

export type IntervalType = {
  id: string;
  label: string;
  value: number;
};

export const intervalsHash = keyBy(intervals, "id");

export const DATE_RANGE_OPTIONS = {
  LAST_12_WEEKS: "LAST_12_WEEKS",
  LAST_12_MONTHS: "LAST_12_MONTHS",
  YTD: "YTD",
  CUSTOM: "custom",
} as const;

// used for date range
export const dateRangeDefaultDateFormat = "MM/DD/YYYY";

export const standardDateFormat = "YYYY-MM-DD";

export const DATE_RANGE_OPTIONS_ARRAY = [
  {
    label: "Last 12 weeks",
    value: DATE_RANGE_OPTIONS.LAST_12_WEEKS,
    generateDateRange: (dateFormat: string = dateRangeDefaultDateFormat) => {
      return {
        start_date: moment()
          .subtract(12, "weeks")
          .startOf("week")
          .format(dateFormat),
        end_date: moment().endOf("week").format(dateFormat),
      };
    },
  },
  {
    label: "Last 12 months",
    value: DATE_RANGE_OPTIONS.LAST_12_MONTHS,
    generateDateRange: (dateFormat: string = dateRangeDefaultDateFormat) => {
      return {
        start_date: moment()
          .subtract(12, "month")
          .startOf("month")
          .format(dateFormat),
        end_date: moment().endOf("month").format(dateFormat),
      };
    },
  },
  {
    label: "Years to Date",
    value: DATE_RANGE_OPTIONS.YTD,
    generateDateRange: (dateFormat: string = dateRangeDefaultDateFormat) => {
      return {
        start_date: moment().startOf("year").format(dateFormat),
        end_date: moment().format(dateFormat),
      };
    },
  },
  {
    label: "Custom",
    value: DATE_RANGE_OPTIONS.CUSTOM,
  },
];

export const DATE_RANGE_OPTIONS_VALUE_ARRAY = DATE_RANGE_OPTIONS_ARRAY.map(
  (i) => i.value
);

export const DATE_RANGE_OPTIONS_HASH = keyBy(DATE_RANGE_OPTIONS_ARRAY, "value");

export type DateRangeOptionsValuesType = ValueOf<typeof DATE_RANGE_OPTIONS>;

///////

export const generateDateRange = ({
  customStartDate,
  customEndDate,
  option,
  format = dateRangeDefaultDateFormat,
}: {
  option: ValueOf<typeof DATE_RANGE_OPTIONS>;
  customStartDate?: string | null;
  customEndDate?: string | null;
  format?: string;
}) => {
  const dateRangeItem = DATE_RANGE_OPTIONS_HASH[option];

  if (!option || !dateRangeItem)
    return { start_date: undefined, end_date: undefined };

  const dateRange = dateRangeItem.generateDateRange?.(format);

  if (dateRange) return dateRange;

  return {
    start_date: customStartDate,
    end_date: customEndDate,
  };
};

// Use in DateRangeOptionSelectorUI

export enum DateRangeOptions {
  SevenDaysAgo = "SevenDaysAgo",
  ThirtyDaysAgo = "ThirtyDaysAgo",
  ThreeMonthsAgo = "ThreeMonthsAgo",
  SixMonthsAgo = "SixMonthsAgo",
  Custom = "Custom",
}

export enum StartEndDates {
  StartDate = "startDate",
  EndDate = "endDate",
}

export const getTodayMoment = () => moment();

const getFormattedToday = (dateFormat: string = standardDateFormat) =>
  getTodayMoment().format(dateFormat);

export const getFormattedNextThirtyDaysRange = (
  dateFormat: string = standardDateFormat
) => {
  return {
    startDate: getFormattedToday(dateFormat),
    endDate: getTodayMoment().add(1, "month").format(dateFormat),
  };
};

export const dateRangeOptionsArray = [
  {
    label: "Last 7 days",
    value: DateRangeOptions.SevenDaysAgo,
    formatDates: () => ({
      startDate: getTodayMoment()
        .subtract(7, "days")
        .format(standardDateFormat),
      endDate: getFormattedToday(),
    }),
  },
  {
    label: "Last 30 days",
    value: DateRangeOptions.ThirtyDaysAgo,
    formatDates: () => ({
      startDate: getTodayMoment()
        .subtract(30, "days")
        .format(standardDateFormat),
      endDate: getFormattedToday(),
    }),
  },
  {
    label: "Last 90 days",
    value: DateRangeOptions.ThreeMonthsAgo,
    formatDates: () => ({
      startDate: getTodayMoment()
        .subtract(90, "days")
        .format(standardDateFormat),
      endDate: getFormattedToday(),
    }),
  },
  {
    label: "Last 180 days",
    value: DateRangeOptions.SixMonthsAgo,
    formatDates: () => ({
      startDate: getTodayMoment()
        .subtract(180, "days")
        .format(standardDateFormat),
      endDate: getFormattedToday(),
    }),
  },
  {
    label: "Custom",
    value: DateRangeOptions.Custom,
  },
];

export const FIVE_MINUTES = 5 * 60 * 1000;

export const dateRangeOptionsHash = keyBy(dateRangeOptionsArray, "value");
