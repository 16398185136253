import { useCallback } from "react";
import { DataListItem, HeaderListItem } from "../types";
import { COLUMNS, ROW_TYPES } from "../tableConfigs";
import { useTenants } from "TenantModule/hooks/useTenants";
import { serializeId } from "utils";

export const useTenantListItemsBuilder = ({
  tenantIds,
}: {
  tenantIds: number[];
}) => {
  const { getTenant, defaultTenant } = useTenants();

  const listItemBuilder = useCallback(() =>
    //   {
    //   sortBy,
    //   sortDirection,
    // }: {
    //   sortBy: string;
    //   sortDirection: SortDirection;
    // }
    {
      const headerListItem: HeaderListItem = {
        id: ROW_TYPES.HEADER,
        rowType: ROW_TYPES.HEADER,
        disableRowHover: false,
        isStickyHeader: true,
        cellClassNameByColumns: {
          [COLUMNS.TENANT_ID]: ["sticky", "zindex3"],
          [COLUMNS.TENANT_NAME]: ["sticky", "zindex3"],
          [COLUMNS.STATUS]: ["sticky", "zindex3"],
          [COLUMNS.CREATOR]: ["sticky", "zindex3"],
          [COLUMNS.CREATED_AT]: ["sticky", "zindex3"],
          [COLUMNS.TAGS]: ["sticky", "zindex3"],
          [COLUMNS.ARCHIVER]: ["sticky", "zindex3"],
          [COLUMNS.CSM]: ["sticky", "zindex3"],
          [COLUMNS.ARCHIVED_AT]: ["sticky", "zindex3"],
        },
      };
      const formattedTenants = tenantIds.flatMap(
        (tenantId) => getTenant({ tenantId }) || []
      );

      const sortedTenantListItems: DataListItem[] = formattedTenants.map(
        (tenant) => {
          return {
            id: serializeId([tenant.id]),
            rowType: ROW_TYPES.DATA,
            tenant,
            hideSelectBox: tenant.tenantName === defaultTenant?.tenantName,
            cellClassNameByColumns: {
              [COLUMNS.TENANT_ID]: ["sticky", "zindex3"],
              [COLUMNS.TENANT_NAME]: ["sticky", "zindex3"],
              [COLUMNS.STATUS]: ["sticky", "zindex3"],
              [COLUMNS.CREATOR]: ["sticky", "zindex3"],
              [COLUMNS.CREATED_AT]: ["sticky", "zindex3"],
              [COLUMNS.TAGS]: ["sticky", "zindex3"],
              [COLUMNS.ARCHIVER]: ["sticky", "zindex3"],
              [COLUMNS.CSM]: ["sticky", "zindex3"],
              [COLUMNS.ARCHIVED_AT]: ["sticky", "zindex3"],
              [COLUMNS.CHECKBOX]: ["sticky", "zindex3"],
            },
          };
        }
      );

      return [headerListItem, ...sortedTenantListItems];
    }, [defaultTenant?.tenantName, getTenant, tenantIds]);

  return listItemBuilder;
};
