import { combineReducers } from "redux";

import themeReducer from "./theme";
import authReducer from "AuthModule/reducers";
import reportsReducer from "ReportsModule/reducers";
import profileReducer from "ProfileModule/reducers";
import integrationReducer from "IntegrationModule/reducers/team";
import overallIntegrationReducer from "IntegrationModule/reducers/general";
import globalReducer from "GlobalModule/reducers";
import filterReducer from "./filters";
import sandboxReducer from "SandboxModule/reducers";
import csmReducer from "CsmModule/reducers/csm";
import teamReducer from "TeamModule/reducers";
import { redirectToLoginScreen } from "utils";
import requestStatusReducer from "RequestStatusModule/reducers";
import tenantReducer from "TenantModule/reducers";
import ApiClientManager, { LocalStorageKeys } from "core/apiClient";
import { LOGIN_TO_TENANT, SIGN_OUT } from "@constants";

export const appReducer = combineReducers({
  themeReducer,
  authReducer,
  reportsReducer,
  profileReducer,
  overallIntegrationReducer,
  integrationReducer,
  filterReducer,
  globalReducer,
  sandboxReducer,
  teamReducer,
  csmReducer,
  requestStatusReducer,
  tenantReducer,
});

const rootReducer = (
  state: AppStateType | undefined,
  action: any
): AppStateType => {
  switch (action.type) {
    case SIGN_OUT: {
      ApiClientManager.reset();
      localStorage.clear();

      setTimeout(() => {
        // Need to clear out state before redirecting
        redirectToLoginScreen();
      }, 300);

      return appReducer(undefined, action);
    }

    case LOGIN_TO_TENANT: {
      const { realmId, tenantName } = action.payload;
      ApiClientManager.reset();

      localStorage.setItem(LocalStorageKeys.LastSelectedRealmId, realmId);
      localStorage.setItem(LocalStorageKeys.LastSelectedTenantName, tenantName);

      setTimeout(() => {
        window.location.href = ApiClientManager.getLoginRepoUrl(action.payload);
      }, 300);

      return appReducer(undefined, action);
    }
  }

  return appReducer(state, action);
};

export default rootReducer;

export type RootReducerType = typeof appReducer;
export type AppStateType = ReturnType<RootReducerType>;
