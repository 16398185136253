import { AppStateType } from "reducers";

export type DefaultIsoState<ID extends string | number = string | number> = {
  /** eg. { `project-${projectId}`: (subGroupIds | recordIds)[] } */
  ordersByGroup: Record<ID, ID[]>; // { Subscription: [""], RegularSandBpx: [""]}
  /** eg. [‘july’, ‘aug’] - could also just be recordIds[] when no groupings */
  topLevelOrder: ID[];
  totalCount: number;
  totalFetchedCount: number;
  /** Total number of records per group */
  groupTotalCounts: Record<ID, number>;
};

export const defaultInitialIsoState = {
  ordersByGroup: {},
  topLevelOrder: [],
  totalCount: 0,
  totalFetchedCount: 0,
  groupTotalCounts: {},
};

export const getOwnIsoStateId = (
  _: AppStateType,
  ownProps: {
    [key: string]: unknown;
  } & {
    isoStateId: string;
  }
) => ownProps.isoStateId;
