import { createReducer } from "@reduxjs/toolkit";
import * as actions from "GlobalModule/actionCreators";
import * as thunkActions from "thunk";
import * as Types from "GlobalModule/types";
import { GenericTeamInterface } from "types";
import { getTeamProfileUrl } from "utils/url";
import keyBy from "lodash/keyBy";
// import { sidebarRoutes } from "../../../routes";

export type GlobalStateType = {
  isDevMode: boolean;
  openRoutes: Record<string, boolean>;
  recurlyPlans: never[] | Types.RecurlyPlan[];
  requestStatusesAndErrors: {
    [x: string]: {
      isRequesting: boolean;
      error: Record<string, any>;
    };
  };
};

const INITIAL_STATE: GlobalStateType = {
  isDevMode: false,
  openRoutes: {},
  recurlyPlans: [],
  requestStatusesAndErrors: {
    [thunkActions.createTeam.typePrefix]: {
      isRequesting: false,
      error: {},
    },
  },
};

export default createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(actions.toggleDevMode, (state, _) => {
    state.isDevMode = !state.isDevMode;
  });
  builder.addCase(actions.updateOpenRoutes, (state, { payload }) => {
    state.openRoutes = {
      ...state.openRoutes,
      ...payload,
    };
  });
  /* --------------------------- Fetch Recurly Plan (Deprecated) --------------------------- */
  builder.addCase(
    thunkActions.fetchRecurlyPlan.pending,
    (state, { payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchRecurlyPlan.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchRecurlyPlan.fulfilled,
    (state, { payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchRecurlyPlan.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.recurlyPlans = payload;
    }
  );
  builder.addCase(
    thunkActions.fetchRecurlyPlan.rejected,
    (state, { payload, error }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchRecurlyPlan.typePrefix
      ] = {
        isRequesting: false,
        error,
      };
      state.recurlyPlans = [];
    }
  );
});
