export const addNumericStrings = (numericStringArray: Array<string>): number => {
  const total = numericStringArray.reduce((acc, curr: string) => {
    if (!isNaN(curr as any)) {
      return acc + Number.parseInt(curr);
    }
    return acc;
  }, 0);

  return total;
};


export const makeStringFromArrays = (
  arrays: (string | number)[],
  shouldBreakLine: boolean = false
) => {
  return arrays.reduce((acc: string, curr) => {
    acc += ` ${curr} ${shouldBreakLine ? "\n" : ""}`;
    return acc;
  }, "");
};