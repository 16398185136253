import { Box } from "@material-ui/core";
import { SuperAdminsTableHeader } from "./SuperAdminsTableHeader";
import { ChangeEvent, useCallback, useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSuperAdmins } from "selectors";
import { useTablePagination } from "hooks/useTablePagination";
import { filterItemWithKeywords } from "FiltersModule/utils";
import { conditionallyRenderElement } from "utils";
import { SuperAdminsTable } from "./SuperAdminsTable";
import { fetchSuperAdmins } from "ReportsModule/thunks";

export const SuperAdminsTableContainer: React.FC<{}> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSuperAdmins({}));
  }, [dispatch]);

  const {
    startIndex,
    endIndex,
    pageNumber,
    handlePageChange,
    limit,
    resetPagination,
  } = useTablePagination();
  // Sorted Members
  const { formattedData, isFetchingMember, fetchingMemberError } = useSelector(
    selectSuperAdmins
  );
  const [searchedMemberName, setMemberName] = useState("");

  const searchMember = (e: ChangeEvent<HTMLInputElement>) => {
    resetPagination();
    const { value } = e.target;
    setMemberName(value);
  };

  const filteredMembers = useMemo(() => {
    return formattedData.filter((member) => {
      if (!searchedMemberName && !member.name) {
        return true;
      }

      return filterItemWithKeywords({
        searchWords: [searchedMemberName],
        item: member,
        filterKeysArray: ["name"],
      });
    });
  }, [formattedData, searchedMemberName]);

  const paginatedMembers = useMemo(() => {
    return filteredMembers.slice(startIndex, endIndex); // paginated
  }, [endIndex, filteredMembers, startIndex]);

  const onPageChange = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      page: number
    ) => {
      handlePageChange({ newPageNumber: page });
    },
    [handlePageChange]
  );

  const paginationProps = useMemo(() => {
    return {
      onPageChange,
      count: filteredMembers.length,
      page: pageNumber,
      rowsPerPage: limit,
      rowsPerPageOptions: [],
    };
  }, [filteredMembers.length, limit, onPageChange, pageNumber]);

  return conditionallyRenderElement({
    isLoading: isFetchingMember,
    error: fetchingMemberError?.message,
    content: (
      <Box padding={4}>
        <SuperAdminsTableHeader
          searchedMemberName={searchedMemberName}
          searchMember={searchMember}
        />
        <SuperAdminsTable
          superAdmins={paginatedMembers}
          paginationProps={paginationProps}
        />
      </Box>
    ),
  });
};
