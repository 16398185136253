import keyBy from "lodash/keyBy";

// Hard coded for now until BE have an endpoint
const csmListByEnv = {
  party: [
    { accountId: 8608, name: "Marissa Walker" },
    { accountId: 8720, name: "Nick DiGia" },
    { accountId: 41258, name: "Danielle Taddie" },
    { accountId: 73615, name: "Jessica Khouvongsavanh" },
  ],
  demo: [
    { accountId: 12438, name: "Marissa Walker" },
    { accountId: 12434, name: "Nick DiGia" },
  ],
  prod: [
    { accountId: 32762, name: "Marissa Walker" },
    { accountId: 62506, name: "Nick DiGia" },
    { accountId: 131627, name: "Danielle Taddie" },
    { accountId: 142002, name: "Jessica Khouvongsavanh" },
  ],
  gov: [
    { accountId: 12438, name: "Marissa Walker" },
    { accountId: 12434, name: "Nick DiGia" },
  ],
};

/**
 * Hard coded to reduce cost dev, but ideally should have an endpoint to provide this list
 */
export const csmList =
  csmListByEnv[process.env.REACT_APP_UI_ENV as keyof typeof csmListByEnv] || [];

export const csmHashById = keyBy(csmList, "accountId");

export const csmHashByName = keyBy(csmList, "name");
