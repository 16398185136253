import { Box, Divider } from "@material-ui/core";
import styled from "styled-components";

const DividerBox = styled(Box)`
  margin-left: -2rem;
  margin-top: 1rem;
  width: calc(100% + 55px);

  .divider {
    background-color: black;
  }
`;

export const BaseDivider = () => {
  return (
    <DividerBox>
      <Divider className="divider" />
    </DividerBox>
  );
};
