import startCase from "lodash/startCase";
import orderBy from "lodash/orderBy";
import { HeadCell } from "types";

export const makeHeaderCellBasedOnKeys = (keys: string[]): HeadCell[] =>
  keys.map((key) => ({ id: key, label: startCase(key) }));

export const makeHeaderCells = (
  keys: string[],
  headerIdToOmit?: string[],
  order?: "asc" | "desc"
) => {
  let results = makeHeaderCellBasedOnKeys(keys);

  if (headerIdToOmit) {
    const headerIdToOmitSet = new Set(headerIdToOmit);

    results = makeHeaderCellBasedOnKeys(keys).filter(
      (header) => !headerIdToOmitSet.has(header.id)
    );
  }

  if (order) {
    results = orderBy(results, "id", order);
  }

  return results;
};
