import { ValueOf } from "type-fest";

export const DEFAULT_RAILS_REALM_ID = process.env.REACT_APP_UI_ENV || "";
export const DEFAULT_REALM_NAME = process.env.REACT_APP_UI_ENV || "";

export enum RealmTypes {
  sandbox = "Sandbox", // Matching glx server values
  subscription = "Subscription",
}

export type RealmTypesValues = ValueOf<typeof RealmTypes>;

export const realmTypeConfigs = {
  [RealmTypes.sandbox]: {
    value: RealmTypes.sandbox,
    label: "Sandbox",
  },
  [RealmTypes.subscription]: {
    value: RealmTypes.subscription,
    label: "Subscription",
  },
} as const;
