import SimpleModal from "components/SimpleModal";
import { Box } from "@material-ui/core";
import { Tenant } from "TenantModule/types";
import { useDispatch } from "react-redux";
import { loginToTenant } from "actionCreators";

export const SwitchTenantModal: React.FC<{
  toggle: () => void;
  tenantName: Tenant["tenantName"];
  realmId: string;
}> = ({ toggle, tenantName, realmId }) => {
  const dispatch = useDispatch();

  const onConfirm = () => { 
    dispatch(loginToTenant({ tenantName, realmId }));
  };

  return (
    <SimpleModal
      onClose={toggle}
      open
      confirmButtonText={"Log in again"}
      onConfirm={onConfirm}
      headerText={"Switching Tenant"}
      Body={
        <Box>You selected a different Tenant, you will need to login again</Box>
      }
    />
  );
};
