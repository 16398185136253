import { IntegrationAgentNames, integrationAgentsHash } from "@constants";
import DropdownSelect from "components/DropdownSelect";
import { useIntegrationAgentsMultiSelector } from "IntegrationModule/components/IntegrationAgentsMultiSelector/useIntegrationAgentsMultiSelector";
import { FC } from "react";

export const IntegrationAgentsMultiSelector: FC<
  ReturnType<typeof useIntegrationAgentsMultiSelector>
> = (props) => {
  return (
    <DropdownSelect<IntegrationAgentNames>
      showSearchBar
      inputLabel={"Service Names"}
      items={props.integrationAgentItems}
      itemHash={integrationAgentsHash}
      labelKey={props.labelKey}
      selectedItems={props.selectedIntegrationAgents}
      onSelect={props.onSelectIntegrationAgent}
      isMultiSelect
    />
  );
};
