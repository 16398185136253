import { KEYBOARD_KEYS } from "@constants";
import { useRef, useState } from "react";

export const useArrayItemsInput = ({
  checkItemValidity,
}: {
  checkItemValidity?: (args: {
    item: string;
    itemsList: string[];
  }) => {
    isValid: boolean;
    errorMessage: string | undefined;
    itemToAdd: string;
  };
}) => {
  const [error, setInputError] = useState("");
  const [itemsList, setItemsList] = useState<string[]>([]);
  const inputRef = useRef<Nullable<HTMLInputElement>>(null);

  const updateItemList = (item: string) => {
    const newItemsList = [...itemsList, item];
    setItemsList(newItemsList);
  };

  // on press enter
  const addItem = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const item = inputRef.current?.value || "";

    const isEnter = e.key === KEYBOARD_KEYS.ENTER;

    if (isEnter) {
      const { isValid, errorMessage, itemToAdd } = checkItemValidity?.({
        item,
        itemsList,
      }) ?? {
        errorMessage: "",
        itemToAdd: item,
        isValid: true,
      };

      if (!isValid && errorMessage) {
        setInputError(errorMessage);
      } else {
        updateItemList(itemToAdd);
        setInputError("");
        if (inputRef.current) inputRef.current.value = "";
      }
    }
  };

  const removeItem = (itemToRemove: string) => {
    const newList = itemsList.filter((item) => item !== itemToRemove);
    setItemsList(newList);
  };

  const clearErrorAndItems = () => {
    setInputError("");
    setItemsList([]);
  };

  return {
    addItem,
    removeItem,
    updateItemList,
    error,
    setInputError,
    setItemsList,
    inputRef,
    itemsList,
    clearErrorAndItems,
  };
};
