import { Box } from "@material-ui/core";
import { Tenant } from "TenantModule/types";
import { FC } from "react";
import { Realm } from "types";
import { getIsTenantEnabled } from "utils";

export const TenantRealmInfo: FC<{
  tenantName?: Tenant["tenantName"];
  realmName?: Realm["team_name"];
  realmId?: Realm["realm_id"];
}> = ({ tenantName, realmName, realmId }) => {
  return (
    <>
      <Box display="flex" m="1rem 0" alignItems={"center"} gridGap={8}>
        {getIsTenantEnabled() && tenantName ? (
          <Box>Current Tenant: {tenantName}</Box>
        ) : null}

        {realmName ? (
          <Box>
            Current Realm: {realmName} ({realmId})
          </Box>
        ) : null}
      </Box>
    </>
  );
};
