import * as ReportService from "ReportsModule/services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Types from "ReportsModule/types";
import { pickBy, identity } from "lodash";
import { AppStateType } from "reducers";

/* -------------------------------------------------------------------------- */
export const integrationForceUpdate = createAsyncThunk(
  "integrationForceUpdate",
  async (
    {
      mosaicTeamId,
      targetServiceId,
      dataType,
      targetSide,
      ignoreWaitingResponse, 
      onSuccess,
      onFailure,
    }: Types.IntegrationForceUpdateApiParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;

      const auth_token = user?.auth_token;
      const body = { dataType, targetSide, ignoreWaitingResponse };
      const response = await ReportService.integrationServerForceUpdate(
        { mosaicTeamId, targetServiceId },
        body,
        {},
        auth_token
      );
      onSuccess && onSuccess({ response });
      return {
        data: response.data,
        status: response.status,
      };
    } catch (e) {
      onFailure && onFailure({ error: e });
      return rejectWithValue(e);
    }
  }
);

/* -------------------------------------------------------------------------- */
export const fetchDataToIntegrationServer = createAsyncThunk(
  "fetchDataToIntegrationServer",
  async (
    {
      dataType,
      targetServiceId,
      dateAfter,
      startDate,
      projectId,
      onSuccess,
      onFailure,
    }: Types.FetchDataToIntegrationServerApiParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const queryParams = {
        dataType,
        targetServiceId,
      };
      const body = pickBy({ dateAfter, startDate, projectId }, identity);
      const config = {
        params: body,
      };
      const response = await ReportService.fetchDataToIntegrationServer(
        queryParams,
        config,
        auth_token
      );
      onSuccess && onSuccess({ response });
      return {
        status: response.status,
        data: response.data
      };
    } catch (e) {
      onFailure && onFailure({ error: e });
      return rejectWithValue(e);
    }
  }
);
/* -------------------------------------------------------------------------- */
export const recalculateBudgetAggregates = createAsyncThunk(
  "recalculateBudgetAggregates",
  async (
    { team_id, onSuccess, onFailure }: Types.RecalculateBudgetAggregatesParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;

      const response = await ReportService.recalculateBudgetAggregates(
        {},
        { team_id },
        {},
        auth_token as string
      );
      onSuccess && onSuccess({ response });
      return response;
    } catch (e) {
      onFailure && onFailure({ error: e });
      return rejectWithValue(e);
    }
  }
);
