import { Checkbox } from "@material-ui/core";
import { DataCellProps } from "../types";

export const TenantCheckboxCell = ({
  tenant: { id: tenantId },
  customRowProps: { selectTenant, selectedTenantIds },
  hideSelectBox,
}: DataCellProps) => {
  if (hideSelectBox) {
    return <></>;
  }
  return (
    <div>
      <Checkbox
        checked={selectedTenantIds.has(tenantId)}
        onClick={() => selectTenant({ tenantId })}
      />
    </div>
  );
};
