import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@material-ui/core";
import styled from "styled-components";
import EllipsisText from "components/EllipsisText";
import { CsmSelector } from "CsmModule/components/CsmSelector";
import { ComponentProps, useRef, useState } from "react";
import { KEYBOARD_KEYS, STAND_BY_TAG, chipWidth } from "@constants";
import { useArrayTagsInput } from "hooks/useArrayItems/useArrayTagsInput";
import { useTenants } from "TenantModule/hooks/useTenants";
import { updateTenant } from "thunk";
import { useDispatch } from "react-redux";
import { useRequestStatus } from "RequestStatusModule/hooks/useRequestStatus";
import { useCsmSelector } from "CsmModule/hooks/useCsmSelector";

const TagContainer = styled.div`
  display: flex;
  .add-tag-div {
    flex: 2;
    min-width: 80px;
  }
  .initial-tag-div {
    flex: 2;
  }
  .start-date {
    flex: 2;
  }
`;

const ChipsContainer = styled.div`
  margin-bottom: 15px;
  .chip {
    margin-right: 5px;
    margin-bottom: 5px;
    max-width: 370px;
    height: 27px;
  }
  .red-chip {
    background-color: #f44336;
    color: white;
  }
`;

const initialErrorMessages = {
  teamNameError: "",
  tagInputError: "",
};

export type EditTenantModalProps = {
  onClose: VoidFunction;
  tenantId: number;
  onEditTenantSuccess?: VoidFunction;
};

const requestStatusId = "edit-tenant-modal";

export const EditTenantModal = ({
  onClose,
  tenantId,
  onEditTenantSuccess,
}: EditTenantModalProps) => {
  const dispatch = useDispatch();
  const { getTenant } = useTenants();
  const tenant = getTenant({ tenantId: tenantId });
  const { onCsmSelectorClick, selectedCsm } = useCsmSelector({
    accountId: tenant?.id || null,
  });
  const { requestStatus } = useRequestStatus({
    requestStatusId,
  });
  const isRequesting = Boolean(requestStatus?.isRequesting);

  const {
    addTag,
    removeTag,
    inputRef,
    tagsList,
    tagError,
  } = useArrayTagsInput();

  const onDelete = (tag: string) => {
    removeTag(tag);
  };

  const handleSubmitEditTenantForm = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (tenant) {
      const params: Parameters<typeof updateTenant>[0] = {
        tenantName: tenant.tenantName,
        csm: selectedCsm?.name,
        tags: tagsList,
        meta: {
          onSuccess: () => {
            onClose();
            onEditTenantSuccess?.();
          },
        },
      };
      dispatch(updateTenant(params));
    }
  };

  const handleCsmSelectorClick: ComponentProps<
    typeof CsmSelector
  >["onClick"] = (e) => {
    onCsmSelectorClick({ target: e.currentTarget });
  };

  return (
    <Dialog maxWidth={"xs"} fullWidth open onClose={onClose}>
      <DialogTitle id="form-dialog-title">
        Edit Tenant: {tenant?.["tenantName"]}
      </DialogTitle>
      <DialogContent>
        <CsmSelector
          accountId={selectedCsm?.accountId || null}
          onClick={handleCsmSelectorClick}
          tooltip="Select Customer Success Manager for this tenant"
        />
        <FormControl component="fieldset" fullWidth>
          <Box component="h3">Edit tags</Box>
          <TagContainer>
            <div>
              <TextField
                placeholder="Enter custom tag"
                inputRef={inputRef}
                onKeyDown={addTag}
                helperText={tagError}
                error={!!tagError}
                disabled={isRequesting}
              />
              <ChipsContainer>
                {tagsList?.map((tag: string) => (
                  <EllipsisText text={tag} width={chipWidth}>
                    <Chip
                      className="chip"
                      color="primary"
                      label={tag}
                      onDelete={isRequesting ? undefined : () => onDelete(tag)}
                      style={{ maxWidth: `calc(${chipWidth} - 10%)` }}
                    />
                  </EllipsisText>
                ))}
              </ChipsContainer>
            </div>
          </TagContainer>
        </FormControl>

        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!!tagError}
            color="primary"
            onClick={handleSubmitEditTenantForm}
          >
            Save
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
