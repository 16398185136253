import React from "react";
import styled from "styled-components";
import { Tooltip } from "@material-ui/core";
import cn from "classnames";

type EllipsisTextProps = {
  text: string;
  width?: string;
  style?: {};
  className?: string;
};

export const TextContainer = styled.div<{
  width: EllipsisTextProps["width"];
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: ${({ width }) => width || "unset"};
`;

const EllipsisText: React.FC<EllipsisTextProps> = ({
  text,
  width,
  style = {},
  className = "",
  children,
}) => {
  return (
    <Tooltip title={text || ""} placement="top">
      <TextContainer
        width={width}
        style={style}
        className={cn(className, { "ellipsis-text": true })}
      >
        {children}
      </TextContainer>
    </Tooltip>
  );
};

export default EllipsisText;
