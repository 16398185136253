import React from "react";
import styled from "styled-components/macro";

import {
  Button,
  CardContent,
  Fade,
  Menu,
  MenuItem,
  Card as MuiCard,
  Paper as MuiPaper,
  ListProps,
  ButtonProps,
  Color,
} from "@material-ui/core";

import { ArrowDropDown } from "@material-ui/icons";

import { spacing } from "@material-ui/system";

const Card = styled(MuiCard)(spacing);

export type TransitionMenuPropsType = {
  anchorEl: null | HTMLElement;
  menuItems: Array<string>;
  selectedItem: string | any;
  open: boolean;
  isLoading: boolean;
  buttonVariant?: ButtonProps | any;
  buttonColor?: Color | any;
  handleMenuClick: (e: any) => void;
  handleMenuItemClick: (item: string | any) => void;
  handleMenuClose: () => void;
  accessorFn?: Function;
  defaultText?: string;
};

function TransitionMenu(props: TransitionMenuPropsType) {
  const {
    anchorEl,
    selectedItem,
    open,
    menuItems,
    handleMenuClick,
    handleMenuClose,
    handleMenuItemClick,
    isLoading,
    accessorFn,
    buttonVariant = "contained",
    buttonColor = "secondary",
    defaultText = "Select an option",
  } = props;

  return (
    <>
      {" "}
      <Button
        aria-owns={anchorEl ? "transition-menu" : undefined}
        aria-haspopup="true"
        onClick={(e) => handleMenuClick(e)}
        variant={buttonVariant}
        color={buttonColor}
        disabled={isLoading}
      >
        {(accessorFn ? accessorFn(selectedItem) : selectedItem) || defaultText}
        <ArrowDropDown />
      </Button>
      <Menu
        id="transition-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        TransitionComponent={Fade}
      >
        {menuItems.map((item) => (
          <MenuItem onClick={() => handleMenuItemClick(item)} key={item}>
            {accessorFn ? accessorFn(item) : item}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default React.memo(TransitionMenu);
