import { useCallback, FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { signOut } from "actionCreators";
import { LinearQuery } from "components/Progress";
import { useAuthenticationLayer } from "./useAuthenticationLayer";

const AuthenticationLayer: FC = ({ children }) => {
  const dispatch = useDispatch();

  const { isError, isLoading, canMoveToNextLayer } = useAuthenticationLayer();

  const clearCacheAndRedirect = useCallback(async () => {
    dispatch(signOut());
  }, [dispatch]);

  if (isLoading) {
    return (
      <>
        <LinearQuery />
        <DelayRenderer>
          <button onClick={clearCacheAndRedirect}>Log out</button>
        </DelayRenderer>
      </>
    );
  }

  if (isError) {
    return (
      <div>
        <span>There is an issue.</span>
        <button onClick={clearCacheAndRedirect}>Go back to login screen</button>
      </div>
    );
  }

  /** Auth token and `me` is fetched
   * Move onto security layer for super admin check
   */
  if (canMoveToNextLayer) {
    return <div>{children}</div>;
  }

  return null;
};

export default AuthenticationLayer;

const DelayRenderer: FC = ({ children }) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldRender(true);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (shouldRender) {
    return <>{children}</>;
  }

  return null;
};
