import { integrationAgentsArray } from "@constants";
import useArrayFilters from "hooks/useArrayFilters";

export const useIntegrationAgentsMultiSelector = () => {
  const { items, selectedItems, onSelect } = useArrayFilters({
    items: integrationAgentsArray,
  });
  return {
    selectedIntegrationAgents: selectedItems,
    onSelectIntegrationAgent: onSelect,
    integrationAgentItems: items,
    labelKey: "name",
  };
};
