import { useAppQuery } from "core/query/hooks/useAppQuery";
import { FIVE_MINUTES } from "@constants";
import { getIntegrations } from "services";
import {
  FetchIntegrationsListAxiosErrorResponse,
  FetchIntegrationsListAxiosSuccessResponse,
  FetchIntegrationsPayload,
} from "IntegrationModule/types";
import { integrationQueryKeys } from "./integrationQueryKeys";
import partition from "lodash/partition";
import groupBy from "lodash/groupBy";
import { findMostRecentlyArchivedIntegrationAndOnlyActiveIntegrationInTargetServiceIdGroup } from "IntegrationModule/utils/utils";
import { Integration } from "types";
import { useEffect } from "react";


interface UseIntegrationsListQueryResponse
  extends FetchIntegrationsListAxiosSuccessResponse {
  sortedIntegrationsList: Integration[];
}

export const useIntegrationsListQuery = (params: FetchIntegrationsPayload) => {
  const { data, isLoading, ...query } = useAppQuery<
    UseIntegrationsListQueryResponse,
    FetchIntegrationsListAxiosErrorResponse
  >({
    queryKey: integrationQueryKeys.list({
      ...params,
      identifier: `useIntegrationsListQuery`,
    }),
    queryFn: ({ token }) => getIntegrations({ params, token }),
    staleTime: FIVE_MINUTES,
    select: (response) => {
      const { data: axiosData } = response;

      const getSortedIntegrationsList = () => {
        let integrationsList: Integration[] = [];

        if (!axiosData) return integrationsList;

        const allIntegrationsByTargetServiceIdOfCurrentTeam = groupBy(
          Object.values(axiosData),
          "targetServiceId"
        );

        Object.values(allIntegrationsByTargetServiceIdOfCurrentTeam).forEach(
          (integrationsWithSharedTargetServiceId) => {
            const {
              activeIntegration,
              mostRecentlyArchivedIntegration,
            } = findMostRecentlyArchivedIntegrationAndOnlyActiveIntegrationInTargetServiceIdGroup(
              integrationsWithSharedTargetServiceId
            );

            if (activeIntegration) {
              integrationsList.push(activeIntegration);
            } else if (mostRecentlyArchivedIntegration) {
              integrationsList.push(mostRecentlyArchivedIntegration);
            }
          }
        );

        const [archived, active] = partition(integrationsList, "isArchived");

        return [...active, ...archived];
      };

      return {
        ...response,
        sortedIntegrationsList: getSortedIntegrationsList(),
      };
    },
  });

  // TO DO: Add onSuccess
  // useEffect(() => {
  //   if(!isFetching && !isError && data){
  //     onSuccess?.();
  //   }
  // }, [onSuccess])

  return {
    integrationsList: data?.sortedIntegrationsList,
    isLoadingIntegrationsList: isLoading,
  };
};
