import { IntegrationAgentEntry } from "@constants";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { ChangeEvent } from "react";

export const IntegrationAgentsNameSelector = ({
  value,
  onChange,
  items,
  disabled,
}: {
  value: string;
  onChange: (event: ChangeEvent<any>) => void;
  items: (Omit<IntegrationAgentEntry, "value"> & {
    disabled?: boolean;
    value: string;
  })[];
  disabled?: boolean;
}) => {
  return (
    <FormControl required variant="outlined" className="text-field">
      <InputLabel id="integrationAgentName">Service Name</InputLabel>
      <Select
        labelId="integrationAgentName"
        value={value}
        onChange={onChange}
        label="Service Name"
        name="integrationAgentName"
        disabled={disabled}
      >
        {items.map((item) => {
          return (
            <MenuItem value={item.value} disabled={item.disabled}>
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
