import { Box, Button, TextField, Grid, Chip } from "@material-ui/core";
import { useState, useRef, ComponentProps } from "react";
import { KEYBOARD_KEYS } from "@constants";
import EllipsisText from "components/EllipsisText";
import { pickBy, identity } from "lodash";
import { fetchTenants } from "TenantModule/thunks";
import { useDispatch } from "react-redux";
import { CsmSelector } from "CsmModule/components/CsmSelector";
import { csmHashById } from "CsmModule/constants";
import { useCsmSelector } from "CsmModule/hooks/useCsmSelector";

const chipWidth = "100px";

type useTenantFiltersProps = {
  fetchTenantAdditionalParams: {
    isArchived: boolean;
  };
  isoStateId: string;
};

export const useTenantFilters = ({
  isoStateId,
  fetchTenantAdditionalParams,
}: useTenantFiltersProps) => {
  const dispatch = useDispatch();
  const { onCsmSelectorClick, selectedCsm, clearCsm } = useCsmSelector({
    accountId: null,
  });

  const [tenantFilter, setTenantFilter] = useState({
    tenantName: "",
    csm: "",
  });
  const [tags, setChipList] = useState<Array<string>>([]);
  const chipInputRef = useRef(document.createElement("input"));

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTenantFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddTag = (value: string) => {
    setChipList((prev) => {
      if (prev.some((chip) => chip === value)) return prev;

      const nextTagsState = [...prev, value];
      return nextTagsState;
    });
    chipInputRef.current.value = "";
  };

  const onTagsInputChange = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = e;
    const search = chipInputRef.current.value.trim();
    if (!search) return;
    if (key === KEYBOARD_KEYS.ENTER) {
      handleAddTag(search);
    }
  };

  const handleTagDelete = (value: string) => () => {
    setChipList((prev) => {
      const nextTagsState = prev.filter((chip) => chip !== value);

      return nextTagsState;
    });
  };

  const renderChip = (chip: string) => {
    return (
      <EllipsisText text={chip} width={chipWidth} key={`${chip}`}>
        <Chip
          color="primary"
          label={chip}
          onDelete={handleTagDelete(chip)}
          style={{ maxWidth: `calc(${chipWidth} - 10%)` }}
        />
      </EllipsisText>
    );
  };

  const handleCsmSelectorClick: ComponentProps<
    typeof CsmSelector
  >["onClick"] = (e) => {
    onCsmSelectorClick({ target: e.currentTarget });
  };

  const onSearch = () => {
    const tenantFilterParams = pickBy(
      {
        tenantName: tenantFilter.tenantName,
        tags: tags,
        csm: selectedCsm ? csmHashById[selectedCsm.accountId]?.name : "",
      },
      identity
    );
    dispatch(
      fetchTenants({
        ...tenantFilterParams,
        ...fetchTenantAdditionalParams,
        meta: {
          isoStateId,
          requestStatusId: isoStateId,
        },
      })
    );
  };

  const clearFilters = () => {
    setTenantFilter({
      tenantName: "",
      csm: "",
    });
    clearCsm();
    setChipList([]);
    chipInputRef.current.value = "";
    dispatch(
      fetchTenants({
        ...fetchTenantAdditionalParams,
        meta: {
          isoStateId,
          requestStatusId: isoStateId,
        },
      })
    );
  };

  return {
    clearFilters,
    onFilterChange,
    TenantFilter: (
      <Grid
        container
        spacing={4}
        justifyContent="flex-start"
        alignItems="center"
        style={{ padding: "10px" }}
      >
        <Grid item xs={3}>
          <TextField
            className="filter-fields"
            label="Tenant Name"
            name="tenantName"
            onChange={onFilterChange}
            value={tenantFilter.tenantName}
            fullWidth
          />
        </Grid>
        <Grid item xs={9}>
          <Box paddingTop={3}>
            <CsmSelector
              accountId={selectedCsm?.accountId || null}
              onClick={handleCsmSelectorClick}
              tooltip="Filter tenants by Customer Success Manager"
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="tags"
            label="Tags"
            name="tags"
            type="text"
            onKeyDown={onTagsInputChange}
            inputRef={chipInputRef}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Box display={"flex"} flexWrap={"wrap"} gridGap={"3px"}>
            {tags.map((chip) => renderChip(chip))}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display={"flex"} gridGap={"5px"} justifyContent="flex-end">
            <Button variant="contained" color="primary" onClick={onSearch}>
              Search
            </Button>
            <Button variant="contained" color="primary" onClick={clearFilters}>
              Clear
            </Button>
          </Box>
        </Grid>
      </Grid>
    ),
    selectedCsm,
    handleCsmSelectorClick,
    onTagsInputChange,
    chipInputRef,
    tags,
    onSearch,
    renderChip,
  };
};
