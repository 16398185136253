import { AppStateType } from "reducers";
import { createSelector } from "reselect";

export const selectTeamState = (state: AppStateType) =>
  state.teamReducer;

export const selectTeamHash = createSelector(
  selectTeamState,
  (state) => state.teamHash
);
