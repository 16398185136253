import { useState, useMemo, useCallback } from "react";

const defaultLimit = 60;

const defaultPaginationPropsValue = {
  pageNumber: 0,
  offset: 0,
  // maxPageNumber that the user has visited
  maxPageNumber: 0,
  canLoadMoreData: false,
};

/**
 * Currently only handles single list
 *
 * ASSUMPTION: Page is zero-based index (start from 0)
 */
export const useTablePagination = () => {
  const [{ pageNumber, offset, maxPageNumber }, setPaginationProps] = useState(
    defaultPaginationPropsValue
  );

  const { startIndex, endIndex } = useMemo(() => {
    const startIndex = offset;
    const endIndex = startIndex + defaultLimit;

    return {
      startIndex,
      endIndex,
    };
  }, [offset]);

  const handlePageChange = useCallback(
    ({ newPageNumber }: { newPageNumber: number }) => {
      // Offset 0: Page 0
      // Offset 50: Page 1
      // Offset 100: Page 2
      // Offset 150: Page 3
      // Offset 200: Page 4
      const newOffset = newPageNumber * defaultLimit;
      const canLoadMoreData = newPageNumber > maxPageNumber;

      // Consider that the user will go back and forth
      // If the newPageNumber is more than the max page that the user has visited, update the maxPageNumber
      // set canLoadMoreData to true to fetch more
      setPaginationProps((prev) => ({
        ...prev,
        offset: newOffset,
        pageNumber: newPageNumber,
        maxPageNumber: canLoadMoreData ? newPageNumber : prev.maxPageNumber,
        canLoadMoreData,
      }));
    },
    [maxPageNumber]
  );

  const resetPagination = useCallback(() => {
    setPaginationProps(defaultPaginationPropsValue);
  }, []);

  return {
    pageNumber,
    handlePageChange,
    startIndex,
    endIndex,
    offset,
    limit: defaultLimit,
    resetPagination,
  };
};
