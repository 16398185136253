import * as ReportService from "ReportsModule/services";
import * as TeamServices from "TeamModule/services";
import * as EmailValidator from "email-validator";
import { AppStateType } from "reducers";

import { GenericTeamInterface } from "types";
import * as Types from "ReportsModule/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setFilters } from "actionCreators";
import * as constants from "@constants";
import omit from "lodash/omit";
import { retainNonNilValues } from "utils";
import { fetchCsmAssignments } from "CsmModule/thunks/csm";
import {
  FetchActiveTeamsParams,
  FetchActiveTeamsSuccessResponse,
} from "ReportsModule/types";

const initialFetchOption = {
  page: 0,
  offset: 0,
};

const tableSortOptionsHash = {
  last_user_activity_at: {
    backendParams: "last_user_activity_time",
    frontendParams: "last_user_activity_at",
  },
};

export const fetchReportsTeams = createAsyncThunk(
  "fetchReportsTeams",
  async (
    {
      orderBy,
      orderDirection,
      search_text,
      ids,
      tags,
      excluding_tags,
      offset,
      page,
      isInitialFetch,
      isSearchMode,
      show_only_deactivated = false,
      show_deactivation_info = false,
      onSuccess,
      statistics_start_date,
      statistics_end_date,
    }: Types.FetchReportsTeamsParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
        csmReducer: { csmAssignmentByTeamHash },
      } = getState() as AppStateType;

      const auth_token = user?.auth_token;

      /**
       * FE sort - keep same
       * BE sort - After fetching, must return to frontend params
       */
      const sortOption =
        tableSortOptionsHash[orderBy as keyof typeof tableSortOptionsHash];

      const searchByName = !ids?.length;

      const searchValue = {
        ...(searchByName
          ? {
              search_text,
            }
          : { search_text: "" }),
        ...(!searchByName ? { ids } : { ids: [] }),
      };

      const apiConfig = {
        params: {
          initial: true,
          limit: constants.REPORT_TEAMS_FETCH_LIMIT,
          is_active: false,
          offset: isInitialFetch ? initialFetchOption.offset : offset,
          page: isInitialFetch ? initialFetchOption.page : page,
          order_by: sortOption?.backendParams || orderBy, // This is the case for last_user_activity_time
          order_direction: orderDirection,
          show_only_deactivated,
          show_deactivation_info,
          ...searchValue,
          tags,
          excluding_tags,
          statistics_start_date,
          statistics_end_date,
        },
      };

      const response = await TeamServices.getTeams(apiConfig, auth_token);
      const fetchedTeams = response.data.teams as GenericTeamInterface[];
      const processedData = fetchedTeams.map((team: GenericTeamInterface) =>
        !team.last_user_activity_at
          ? { ...team, last_user_activity_at: "" }
          : team
      );

      dispatch(
        setFilters({
          filterId: constants.filterIds.teamsReports,
          ...omit(apiConfig.params, "order_by", "order_direction"),
          total: response.data.total,
          isSearchMode,
          orderDirection,
          orderBy:
            (sortOption && sortOption.frontendParams) ||
            apiConfig.params.order_by,
          ...searchValue,
        })
      );
      // Fetch assignments by fetched teams
      const teamIdsToFetchCsmAssignments = fetchedTeams.reduce<number[]>(
        (acc, team: GenericTeamInterface) => {
          const teamId = team.id;
          if (!csmAssignmentByTeamHash[teamId]) acc.push(team.id);
          return acc;
        },
        []
      );
      if (teamIdsToFetchCsmAssignments.length) {
        dispatch(
          fetchCsmAssignments({
            mosaicTeamIds: teamIdsToFetchCsmAssignments,
          })
        );
      }

      onSuccess?.({ response });

      return {
        teams: processedData,
        isInitialFetch,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

/* -------------------------------------------------------------------------- */

export const reactivateTeam = createAsyncThunk(
  "reactivateTeam",
  async (
    { teamIds, onFailure, onSuccess }: Types.ReactivateTeamParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const promises = teamIds.map((teamId) =>
        ReportService.reactivateTeam({ teamId }, {}, auth_token as string)
      );
      const response = await Promise.allSettled(promises);

      onSuccess && onSuccess({ response });
      return response;
    } catch (e) {
      onFailure && onFailure({ error: e });
      return rejectWithValue(e);
    }
  }
);

export const fetchTeamsUtilizationTrends = createAsyncThunk(
  "fetchTeamsUtilizationTrends",
  async (
    params: Types.FetchTeamsUtilizationTrendsParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await ReportService.getTeamsUtilizationTrends(
        omit(params, ["onSuccess", "onFailure"]),
        auth_token as string
      );

      params.onSuccess && params.onSuccess({ response });
      return response.data;
    } catch (e) {
      params.onFailure && params.onFailure({ error: e });
      return rejectWithValue(e);
    }
  }
);

export const fetchActiveTeams = createAsyncThunk<
  FetchActiveTeamsSuccessResponse,
  FetchActiveTeamsParams
>(
  "fetchActiveTeams",
  async (
    { meta = {}, ...params }: FetchActiveTeamsParams,
    { getState, rejectWithValue }
  ) => {
    const {
      authReducer: { user },
    } = getState() as AppStateType;
    const token = user?.auth_token!;
    const { onFailure, onSuccess } = meta;

    try {
      const response = await ReportService.getActiveTeams({
        params,
        token,
      });
      onSuccess && onSuccess({ response });
      return response.data;
    } catch (error) {
      onFailure && onFailure({ error });
      return rejectWithValue(error);
    }
  }
);
