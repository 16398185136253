import { ValueOf } from "type-fest";

export enum TenantStatusTypes {
  Creating = "Creating",
  Ready = "Ready",
  Archived = "Archived",
  PartiallyRemoved = "Partially Removed",
}

export const tenantDisabledTooltipHash = {
  noRealms: "Tenant has no realms",
};

export const activeTenantStatus = new Set([
  TenantStatusTypes.Creating,
  TenantStatusTypes.Ready,
]);

export const nonActiveTenantStatus = new Set([
  TenantStatusTypes.Archived,
  TenantStatusTypes.PartiallyRemoved,
]);

export const TENANT_TABLE_VIEW = {
  ACTIVE: "active",
  ARCHIVED: "archived",
};

export const DEFAULT_TENANT_TABLE_VIEW = TENANT_TABLE_VIEW.ACTIVE;

export const TENANT_TABLE_VIEW_ARRAY = [
  {
    label: "Active",
    value: TENANT_TABLE_VIEW.ACTIVE,
  },
  {
    label: "Archived",
    value: TENANT_TABLE_VIEW.ARCHIVED,
  },
];

export type TenantViewType = ValueOf<typeof TENANT_TABLE_VIEW>;

export enum TenantIsoStateIds {
  ActiveTenantTable = "ActiveTenantTable",
  ArchivedTenantTable = "ArchivedTenantTable",
}
