import { createReducer } from "@reduxjs/toolkit";
import {
  setRequestStatus,
  clearRequestStatus,
  clearAllRequestStatuses,
} from "./actionCreators";
import { RequestStatus } from "./types";

export type RequestStatusState = Record<string, RequestStatus | undefined>;

const INITIAL_STATE = {} as RequestStatusState;

export default createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(setRequestStatus, (state, { payload }) => {
    const { id } = payload;
    state[id] = payload;
  });
  builder.addCase(clearRequestStatus, (state, { payload }) => {
    const { id } = payload;
    delete state[id];
  });
  builder.addCase(clearAllRequestStatuses, (state, { payload }) => {
    state = INITIAL_STATE;
  });
});
