import { useDynamicModal } from "components/DynamicModalProvider/useDynamicModal";
import SimpleMenu from "components/SimpleMenu";
import { useCallback, useMemo } from "react";
import { SimpleMenuItem } from "types";
import { ItemFilter, filterItemWithKeywords } from "FiltersModule/utils";
import { useTenants } from "./useTenants";
import { Tenant } from "TenantModule/types";
import { makeTenantLabel } from "TenantModule/utils";
import { useStateEffect } from "hooks/useStateEffect";

type TenantMenuItem = SimpleMenuItem & {
  name: Tenant["tenantName"];
  value: Tenant["id"];
};

const itemFilter: ItemFilter = ({ searchWords, item }) =>
  filterItemWithKeywords({
    searchWords,
    item,
    filterKeysArray: ["name"],
  });

export const useTenantDropdown = () => {
  const {
    tenantOrder,
    selectedGlobalTenant,
    getTenant,
    defaultTenant,
    handleSelectGlobalTenant,
  } = useTenants();

  const [selectedTenant, setSelectedLocalTenant] = useStateEffect<
    Tenant | undefined
  >(selectedGlobalTenant ?? defaultTenant);

  const tenantMenuItems: TenantMenuItem[] = useMemo(() => {
    if (defaultTenant) {
      const items = [
        {
          label: makeTenantLabel({
            name: `${defaultTenant.tenantName} (Default)`,
          }),
          value: defaultTenant.id,
          name: defaultTenant.tenantName,
        },
      ];

      tenantOrder.forEach((tenantId: number) => {
        const tenant = getTenant({ tenantId });
        const isDefault = tenant?.isDefault;
        const isTenantArchived = tenant?.isArchived;
        if (tenant && !isDefault && !isTenantArchived) {
          const tenantName = tenant.tenantName;

          items.push({
            label: makeTenantLabel({
              name: tenantName,
            }),
            value: tenantId,
            name: tenantName,
          });
        }
      });

      return items;
    }

    return [];
  }, [defaultTenant, getTenant, tenantOrder]);

  const { openModal } = useDynamicModal();

  const openTenantDropdown = useCallback(
    ({
      target,
      isSelectingGlobalTenant = false,
      onSelect,
      shouldSkipSetTenant = false,
      formatItems,
    }: {
      target: HTMLElement;
      onSelect?: (args: { tenant: Tenant }) => void;
      isSelectingGlobalTenant?: boolean;
      shouldSkipSetTenant?: boolean;
      formatItems?: (items: TenantMenuItem[]) => TenantMenuItem[];
    }) => {
      const onClick = ({ item }: { item: TenantMenuItem }) => {
        const tenant = getTenant({ tenantId: item.value });

        if (isSelectingGlobalTenant && tenant) {
          handleSelectGlobalTenant({ tenant });
          return;
        }

        if (tenant) {
          onSelect?.({ tenant });
          if (!shouldSkipSetTenant) {
            setSelectedLocalTenant(tenant);
          }
        }
      };

      openModal({
        modal: ({ isOpen, toggle }) => {
          return (
            <SimpleMenu<TenantMenuItem>
              anchorEl={target}
              items={
                formatItems ? formatItems(tenantMenuItems) : tenantMenuItems
              }
              handleClickV2={onClick}
              handleClose={toggle}
              itemFilter={itemFilter}
              showSearchBar
              menuMaxHeight={316}
            />
          );
        },
      });
    },
    [
      getTenant,
      handleSelectGlobalTenant,
      openModal,
      tenantMenuItems,
      setSelectedLocalTenant,
    ]
  );

  return {
    openTenantDropdown,
    makeTenantLabel,
    selectedTenant,
    selectedGlobalTenant,
    setSelectedLocalTenant,
  };
};
