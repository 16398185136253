import styled from "styled-components/macro";

import {
  Avatar as MuiAvatar,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  ListItemTypeMap,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

export const Card = styled(MuiCard)(spacing);

export const CardContent = styled(MuiCardContent)(spacing);

export const Divider = styled(MuiDivider)(spacing);

export const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const ListItem = styled(MuiListItem)(spacing);

export const ListItemNotButton: OverridableComponent<
  ListItemTypeMap<{ button?: false }, "li">
> = styled(MuiListItem)(spacing);

export const Avatar = styled(MuiAvatar)(spacing);

export const ListItemText = styled(MuiListItemText)(spacing);

