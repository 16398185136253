import { fetchSandboxes as _fetchSandboxes, fetchSuperAdmins } from "thunk";
import { useSelector, useDispatch } from "react-redux";
import { ValueOf } from "type-fest";
import {
  selectRailsSandboxIsoState,
  selectRailsSandboxesHash,
  selectSandboxReducerRequestStatusesAndErrors,
} from "SandboxModule/selectors";
import ApiClientManager from "core/apiClient";
import { FetchSandboxParams } from "../types";
import { useCallback } from "react";
import useAppNavigation from "hooks/useAppNavigation";
import { RealmTypes } from "RealmModule/constants/realm";
import { Realm } from "types";
import { defaultSandboxIsoState } from "SandboxModule/reducers";
import {
  checkIsDefaultRealm,
  checkIsMosaicSandboxTeam,
} from "SandboxModule/utils";
import { Tenant } from "TenantModule/types";
import { SANDBOX_TEAM_CREDENTIALS_WARNING_MESSAGE } from "SandboxModule/constants";

export enum SandboxIsoStateIds {
  AppbarRealmSelector = "AppbarRealmSelector",
  SandboxTable = "SandboxTable",
  SandboxCreationTimer = "SandboxCreationTimer",
}

export type SandboxIsoStateIdsValues = ValueOf<typeof SandboxIsoStateIds>;

const useSandboxes = () => {
  const dispatch = useDispatch();
  const railsSandboxesByIsoState = useSelector(selectRailsSandboxIsoState);
  const railsSandboxesHash = useSelector(selectRailsSandboxesHash);
  const requestStatuses = useSelector(
    selectSandboxReducerRequestStatusesAndErrors
  );
  const { navigateToTeamsTable } = useAppNavigation();

  const isUsingNonDefaultRailsSandbox = ApiClientManager.getIsUsingNonDefaultRealm();

  const fetchSandboxes = useCallback(
    (
      isoStateId: ValueOf<typeof SandboxIsoStateIds>,
      params?: Partial<FetchSandboxParams>
    ) => {
      if (ApiClientManager.getIsRealmEnabled()) {
        let realmTypes = params?.realmTypes || [
          RealmTypes.sandbox,
          RealmTypes.subscription,
        ]; // Default to fetching all sandboxes types

        // Spec: If Subscription realm sandbox is not enabled, should not fetch for it
        if (!ApiClientManager.getIsSubscriptionRealmEnabled()) {
          realmTypes = realmTypes.filter(
            (realmType) => realmType !== RealmTypes.subscription
          );
        }

        // Spec: If Regular realm sandbox is not enabled, should not fetch for it
        if (!ApiClientManager.getIsSandboxRealmEnabled()) {
          realmTypes = realmTypes.filter(
            (realmType) => realmType !== RealmTypes.sandbox
          );
        }

        dispatch(
          _fetchSandboxes({
            includeArchived: true,
            isoStateId,
            ...params,
            realmTypes,
          })
        );
      }
    },
    [dispatch]
  );

  const getSandboxesByIsoState = useCallback(
    (isoStateId: ValueOf<typeof SandboxIsoStateIds>) => {
      const sandboxIsoStateData = {
        ...(railsSandboxesByIsoState[isoStateId] || defaultSandboxIsoState),
        ...requestStatuses[isoStateId],
      };
      return sandboxIsoStateData;
    },
    [railsSandboxesByIsoState, requestStatuses]
  );

  const getSandbox = useCallback(
    (realmId: string): Realm | undefined => {
      if (checkIsDefaultRealm({ realmId }))
        return ApiClientManager.getDefaultRealm();

      return railsSandboxesHash[realmId];
    },
    [railsSandboxesHash]
  );

  const getSelectedRailsSandbox = useCallback(() => {
    const selectedRailsSandboxRealmId = ApiClientManager.getCurrentRealmId();
    return getSandbox(selectedRailsSandboxRealmId);
  }, [getSandbox]);

  const getDefaultRealm = useCallback(() => {
    return getSandbox(ApiClientManager.getDefaultRealm().realm_id);
  }, [getSandbox]);

  const handleSelectGlobalRailsSandbox = useCallback(
    ({
      sandbox,
      tenant,
      skipReload,
      onSelect,
    }: {
      sandbox: Realm;
      tenant?: Tenant;
      skipReload?: boolean;
      onSelect?: VoidFunction;
    }) => {
      const params: Parameters<typeof fetchSuperAdmins>[0] = {
        realmId: sandbox.realm_id,
        tenantName: tenant?.tenantName,
        meta: {
          onSuccess: () => {
            ApiClientManager.setRailsSandbox(sandbox);

            onSelect?.();

            if (!skipReload) {
              navigateToTeamsTable();
              window.location.reload();
            }
          },
          onFailure: (e: any) => {
            // fetch using unauthorized account will fail
            alert(
              "You are not a super admin, you don't have access to this realm"
            );
          },
        },
      };
      dispatch(fetchSuperAdmins(params));
    },
    [dispatch, navigateToTeamsTable]
  );

  // a warning message to show when the user is on the default demo Mosaic Sandbox team
  // show on the profile page and set user temporary password modal
  const getSandboxCredentialsWarningMessage = useCallback(
    ({ teamId }: { teamId: number }) => {
      const isMosaicSandboxTeam = checkIsMosaicSandboxTeam(teamId);
      const shouldShowDoNotShareCredientialsWarning =
        isMosaicSandboxTeam && !isUsingNonDefaultRailsSandbox;

      return shouldShowDoNotShareCredientialsWarning
        ? SANDBOX_TEAM_CREDENTIALS_WARNING_MESSAGE
        : "";
    },
    [isUsingNonDefaultRailsSandbox]
  );

  return {
    getSandboxesByIsoState,
    getSandbox,
    getSelectedRailsSandbox,
    getDefaultRealm,
    handleSelectGlobalRailsSandbox,
    fetchSandboxes,
    isUsingNonDefaultRailsSandbox,
    getSandboxCredentialsWarningMessage,
  };
};

export default useSandboxes;
