import { queryClient } from "core/query/queryClient";
import { integrationQueryKeys } from "./integrationQueryKeys";

export const integrationListInvalidations = {
  onUpdate: (filter: { mosaicTeamId?: number; targetServiceId?: number }) => {
    queryClient.invalidateQueries({
      queryKey: integrationQueryKeys.listInvalidation({
        ...filter,
        identifier: `useIntegrationsListQuery`,
      }),
    });
  },
};
