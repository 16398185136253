import { createAction } from "@reduxjs/toolkit";

export const updateOpenRoutes = createAction(
  "updateOpenRoutes",
  (params) => ({
    payload: params,
  })
);

export const toggleDevMode = createAction("toggleDevMode");
