import React from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GlobalStyleProps } from "../types/styles";
import { CssBaseline } from "@material-ui/core";
import { redirectToLoginScreen } from "utils";
import { AppStateType } from "reducers";
import useAppNavigation from "hooks/useAppNavigation";

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

/**
 * Container for old authentication routes that are deprecated
 */
const Auth: React.FC = ({ children }) => {
  const user = useSelector((state: AppStateType) => state.authReducer.user);
  const history = useHistory();
  const { navigateToTeamsTable } = useAppNavigation();

  if (user) {
    navigateToTeamsTable();
  } else {
    redirectToLoginScreen();
  }

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  );
};

export default Auth;
