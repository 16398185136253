import { RequestStatus } from "../types";
import { useSelector } from "react-redux";
import { selectRequestStatusState } from "RequestStatusModule/selectors/requestStatus";
import { useUnmount } from "react-use";
import { useCallback } from "react";
import { AppRequestStatusManager } from "RequestStatusModule/RequestStatusManager/RequestStatusManager";

export const useRequestStatus = ({
  requestStatusId,
  clearRequestStatusOnUnmount = true,
}: {
  requestStatusId: RequestStatus["id"];
  clearRequestStatusOnUnmount?: boolean;
}) => {
  const requestStatusState = useSelector(selectRequestStatusState);
  const requestStatus = requestStatusState[requestStatusId];

  const abortRequest = useCallback(() => {
    AppRequestStatusManager.abortRequest({ id: requestStatusId });
  }, [requestStatusId]);

  useUnmount(() => {
    if (clearRequestStatusOnUnmount) {
      abortRequest();
    }
  });

  return {
    requestStatus,
    abortRequest,
  };
};
