import styled from "styled-components/macro";

import "../../../vendor/roundedBarCharts";

import {
  Avatar as MuiAvatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Chip as MuiChip,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
  InputBase as MuiInputBase,
} from "@material-ui/core";

import { darken } from "polished";

import { spacing, SpacingProps } from "@material-ui/system";

export const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const Button = styled(MuiButton)(spacing);

export const Card = styled(MuiCard)(spacing);

export const Chip = styled(MuiChip)(spacing);

export const Divider = styled(MuiDivider)(spacing);

export const Grid = styled(MuiGrid)(spacing);

export const LinearProgress = styled(MuiLinearProgress)(spacing);

export const Spacer = styled.div(spacing);

export interface TypographyPropsType extends SpacingProps {
  component?: string;
}

export type ProfileParams = {
  teamId?: string;
};

export const Typography = styled(MuiTypography)<TypographyPropsType>(spacing);

export const Centered = styled.div`
  text-align: center;
`;

export const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

export const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

export const ChartWrapper = styled.div`
  height: 280px;
  position: relative;
`;

export const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

export const ProductsChip = styled(Chip)<{ rgbcolor?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) => props.rgbcolor};
  color: ${(props) => props.theme.palette.common.white};
`;

export const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
`;

export const Input = styled(MuiInputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 230px;
  }
`;

export const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  position: relative;
  width: 300px;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
  display: block;ƒ
`;

export const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

export const IconContainer = styled.span`
  position: relative;
  top: 3px;
  left: 5px;
  visibility: hidden;

  &:hover {
    path {
      fill: blue;
    }
  }
`;