import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { signIn } from "../../redux/thunk";

import {
  Avatar,
  Checkbox,
  FormControlLabel,
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";

import {
  selectAuthUser,
  selectAuthError,
  selectAuthLoadingState,
} from "selectors";
import useAppNavigation from "hooks/useAppNavigation";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)}px;
`;

function SignIn() {
  const dispatch = useDispatch();
  const history = useHistory();
  const authUser = useSelector(selectAuthUser);

  /** Error and loading state */
  const authLoadingState = useSelector(selectAuthLoadingState);
  const authError = useSelector(selectAuthError);
  const { navigateToTeamsTable } = useAppNavigation();

  useEffect(() => {
    if (authUser && !authError.AUTHENTICATION_ERROR) {
      navigateToTeamsTable();
    }
  }, [authError.AUTHENTICATION_ERROR, authUser, history, navigateToTeamsTable]);

  return (
    <Wrapper>
      <Helmet title="Sign In" />
      {/* <BigAvatar alt="Lucy" src="/static/img/avatars/avatar-1.jpg" /> */}

      <Typography component="h1" variant="h4" align="center" gutterBottom>
        {/* Welcome back, Lucy! */}
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Sign in to your account to continue
      </Typography>

      <Formik
        initialValues={{
          email: "",
          password: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={async (values) =>
          dispatch(
            signIn({
              email: values.email,
              password: values.password,
              /** Triggering BE check for super admin status */
              is_super_admin: true,
            })
          )
        }
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {authError.AUTHENTICATION_ERROR && (
              <Alert mt={2} mb={1} severity="warning">
                {authError.AUTHENTICATION_ERROR.message}.{" "}
                {`Error code: ${authError.AUTHENTICATION_ERROR.status}`}
              </Alert>
            )}
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <TextField
              type="password"
              name="password"
              label="Password"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={authLoadingState.AUTHENTICATION_LOADING}
            >
              Sign in
            </Button>
            <Button
              onClick={() => history.push("/auth/reset-password")}
              fullWidth
              color="primary"
            >
              Forgot password
            </Button>
          </form>
        )}
      </Formik>
    </Wrapper>
  );
}

export default SignIn;
