import { useEditTenantModal } from "TenantModule/hooks/useEditTenantModal";
import { Tenant } from "TenantModule/types";
import { useDynamicModal } from "components/DynamicModalProvider/useDynamicModal";
import SimpleMenu from "components/SimpleMenu";
import { useCallback, useState } from "react";
import { useArchiveTenantConfirmationModal } from "TenantModule/hooks/useArchiveTenantConfirmationModal";
import { useCreateTenantRequestModal } from "TenantModule/hooks/useCreateTenantRequestModal";

export const useTenantsTableBulkSelectMenu = () => {
  const { openModal } = useDynamicModal();
  const [selectedTenantIds, setSelectedTenantIdsSet] = useState<Set<number>>(
    new Set()
  );
  const numSelected = selectedTenantIds.size;
  const selectedTenantIdsArray = Array.from(selectedTenantIds);
  const tenantIdToEdit = selectedTenantIdsArray[0];
  const {
    openArchiveTenantConfirmationModal,
  } = useArchiveTenantConfirmationModal();
  const { openEditTenantModal } = useEditTenantModal();
  const { openCreateTenantRequestModal } = useCreateTenantRequestModal();

  const selectTenant = ({ tenantId }: { tenantId: Tenant["id"] }) => {
    setSelectedTenantIdsSet((prev) => {
      const isChecked = prev.has(tenantId);
      isChecked ? prev.delete(tenantId) : prev.add(tenantId);

      return new Set(prev);
    });
  };

  const clearAll = () => {
    setSelectedTenantIdsSet(new Set());
  };

  const openTenantTableBulkSelectMenu = useCallback(
    ({ target }: { target: HTMLElement }) => {
      const menuItems = [
        {
          label: "Archive Tenant",
          onClick: () => {
            openArchiveTenantConfirmationModal({
              tenantIds: selectedTenantIdsArray,
              onArchiveTenantSuccess: () => {
                clearAll();
              },
            });
          },
        },
        {
          label: "Edit Tenant",
          disableButton: numSelected > 1,
          tooltip:
            numSelected > 1
              ? "Cannot edit multiple tenants at the same time"
              : "",
          onClick: () =>
            openEditTenantModal({
              tenantId: tenantIdToEdit,
              onEditTenantSuccess: () => {
                clearAll();
              },
            }),
        },
        {
          label: "Register Service To Tenant",
          // TODO: onClick ()=>{}
          disableButton: numSelected > 1,
        },
        {
          label: "Clear All",
          disableButton: !numSelected,
          onClick: () => {
            clearAll();
          },
        },
      ];

      openModal({
        modal: ({ isOpen, toggle }) => {
          return (
            <SimpleMenu
              anchorEl={target}
              items={menuItems}
              handleClose={toggle}
            />
          );
        },
      });
    },

    [
      numSelected,
      openArchiveTenantConfirmationModal,
      openEditTenantModal,
      openModal,
      selectedTenantIdsArray,
      tenantIdToEdit,
    ]
  );

  return {
    openTenantTableBulkSelectMenu,
    setSelectedTenantIdsSet,
    selectedTenantIds,
    selectTenant,
  };
};
