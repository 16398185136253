import { useState } from "react";
import TransitionMenu from "components/TransitionMenu";
import { ButtonProps, Color } from "@material-ui/core";

/**
 * A custom utility hook to manage repetitive state for TransitionMenu.tsx
 * @returns
 */
const useTransitionMenu = ({
  defaultSelectedItem,
  accessorFn = undefined,
}: {
  defaultSelectedItem: any;
  accessorFn?: Function;
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

  const handleMenuClick = (e: any) => {
    setAnchorEl(e.target);
    setOpen(true);
  };

  const handleMenuClose = () => {
    setOpen(false);
  };

  const handleMenuItemClick = (item: any) => {
    setSelectedItem(item);
    handleMenuClose();
  };

  const renderTransitionMenu = ({
    isLoading,
    menuItems,
    handleCustomMenuItemClick,
    buttonVariant,
    buttonColor
  }: {
    isLoading: boolean;
    menuItems: Array<any>;
    buttonVariant?: ButtonProps | any;
    buttonColor?: Color | any;
    handleCustomMenuItemClick?: (item: any) => void;
  }) => (
    <TransitionMenu
      anchorEl={anchorEl}
      open={open}
      buttonColor={buttonColor}
      buttonVariant={buttonVariant}
      selectedItem={selectedItem}
      handleMenuClick={handleMenuClick}
      handleMenuClose={handleMenuClose}
      handleMenuItemClick={
        handleCustomMenuItemClick
          ? (item: any) => {
              handleCustomMenuItemClick(item);
              handleMenuItemClick(item);
            }
          : handleMenuItemClick
      }
      menuItems={menuItems}
      isLoading={isLoading}
      accessorFn={accessorFn}
    />
  );

  return {
    anchorEl,
    open,
    selectedItem,
    handleMenuClick,
    handleMenuClose,
    handleMenuItemClick,
    renderTransitionMenu,
  };
};

export default useTransitionMenu;
