import { AppStateType } from "reducers";
import { createSelector } from "reselect";
import { keyBy, orderBy } from "lodash";
import * as constants from "@constants";
import * as thunks from "ProfileModule/thunks";

import { initialState } from "ProfileModule/reducers";
import { DefaultBoard, TeamMembershipType } from "types";

const sortByLastActive = ({
  data,
  orderDirection,
}: {
  data: TeamMembershipType[];
  orderDirection: string;
}) => {
  let sortedData = [];
  if (orderDirection === constants.ORDER_DIRECTION.DESC) {
    sortedData = [...data].sort((a, b) => {
      const dateA = a.last_active ? new Date(a.last_active) : null;
      const dateB = b.last_active ? new Date(b.last_active) : null;
      if (!dateA && !dateB) {
        return 0;
      } else if (!dateA) {
        return 1;
      } else if (!dateB) {
        return -1;
      } else {
        return Number(dateB) - Number(dateA);
      }
    });
  } else {
    sortedData = orderBy(
      data,
      constants.TeamMemberColumns.LastActive,
      constants.ORDER_DIRECTION.ASC
    );
  }

  return sortedData;
};

const sortFnByColumns: Record<string, any> = {
  [constants.TeamMemberColumns.LastActive]: sortByLastActive,
};

const selectProfileReducerRequestStatusesAndErrors = (state: AppStateType) =>
  state.profileReducer.requestStatusesAndErrors || {};

const selectProfileState = (state: AppStateType) =>
  state.profileReducer || initialState;

/* ------------------------------ Team Members ------------------------------ */

export const selectTeamMemberTableFilter = (state: AppStateType) =>
  state.filterReducer[constants.filterIds.teamMemberTable];

const selectTeamMembersState = createSelector(
  selectProfileState,
  (state) => state.team_members
);

const selectTeamMembersData = createSelector(
  selectTeamMembersState,
  selectProfileReducerRequestStatusesAndErrors,
  (data, requestStatusesAndErrors) => ({
    data,
    isFetchingMember:
      requestStatusesAndErrors[thunks.fetchTeamMembers.typePrefix]
        ?.isRequesting,
    fetchingMemberError:
      requestStatusesAndErrors[thunks.fetchTeamMembers.typePrefix]?.error,

    isUpdatingMember:
      requestStatusesAndErrors[thunks.updateTeamMember.typePrefix]
        ?.isRequesting,
    updateMemberError:
      requestStatusesAndErrors[thunks.updateTeamMember.typePrefix]?.error,
  })
);

export const selectTeamMembers = createSelector(
  selectTeamMembersData,
  selectTeamMemberTableFilter,
  (
    {
      data: members,
      isUpdatingMember,
      updateMemberError,
      isFetchingMember,
      fetchingMemberError,
    },
    filter
  ) => {
    const filteredData = members.filter(
      (member) =>
        !member.email.includes("integrations.mosaicapp.com") &&
        !member.email.includes("maintenance.mosaicapp.com")
    );

    const sortFn = sortFnByColumns[filter.orderBy];
    const sortedData: TeamMembershipType[] =
      sortFn?.({ data: filteredData, orderDirection: filter.orderDirection }) ??
      orderBy(filteredData, filter.orderBy, filter.orderDirection);

    const activeMembers = sortedData.filter((member) => !member.is_archived);

    return {
      allMembers: sortedData,
      activeMembers,
      filter,
      isUpdatingMember,
      updateMemberError,
      isFetchingMember,
      fetchingMemberError,
    };
  }
);

export const selectTeamMembersHash = createSelector(
  [selectTeamMembersState],
  (members) => keyBy(members, "account_id")
);

/* ----------------------------- Team Selection ----------------------------- */

export const selectSelectedTargetService = createSelector(
  selectProfileState,
  (state) => state.selectedTargetService
);

/* -------------------------------- Provision ------------------------------- */

export const selectProvisionIntegrationResponse = createSelector(
  selectProfileState,
  (state) => state.provisionIntegrationResponse
);

export const selectProvisionIntegrationResponseData = createSelector(
  selectProvisionIntegrationResponse,
  selectProfileReducerRequestStatusesAndErrors,
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[thunks.provisionIntegration.typePrefix],
  })
);

// export const selectCheckConnection = createSelector(
//   selectProfileState,
//   (state) => state.checkConnection
// );
// export const selectprovisionQbdResponseData = createSelector(
//   selectprovisionQbdResponse,
//   selectProfileReducerRequestStatusesAndErrors,
//   (data, requestStatusesAndErrors) => ({
//     data,
//     ...requestStatusesAndErrors[thunks.provisionQbd.typePrefix],
//   })
// );

export const selectBoards = createSelector(
  selectProfileState,
  (state) => state.default_board
);
