import { useEffect, useRef, useMemo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiClientManager, { LocalStorageKeys } from "core/apiClient";
import { exchangeToken, fetchMe } from "thunk";
import { AppStateType } from "reducers";
import debounce from "lodash/debounce";
import { RedirectKeys } from "@constants";
import { fetchTenants } from "TenantModule/thunks";
import useSandboxes, {
  SandboxIsoStateIds,
} from "SandboxModule/hooks/useSandboxes";
import { useRequestStatus } from "RequestStatusModule/hooks/useRequestStatus";
import { useSearchParam } from "react-use";
import { Realm } from "types";
import { selectAuthUser } from "selectors";
import { Tenant } from "TenantModule/types";

const fetchMeRequestStatusId = "useAuthenticationLayer_fetchMeRequestStatusId";
const exchangeTokenRequestStatusId =
  "useAuthenticationLayer_exchangeTokenRequestStatusId";

type CustomApiBuilderParams = Nullable<{
  realmId: Realm["realm_id"] | undefined;
  tenantName: Tenant["tenantName"] | undefined;
}>;

export const useAuthenticationLayer = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectAuthUser);
  const { fetchSandboxes } = useSandboxes();
  const accessToken = useSearchParam(RedirectKeys.AccessToken);
  const realmIdParam = localStorage.getItem(
    LocalStorageKeys.LastSelectedRealmId
  );
  const tenantNameParam = localStorage.getItem(
    LocalStorageKeys.LastSelectedTenantName
  );
  const { requestStatus: fetchMeRequestStatus } = useRequestStatus({
    requestStatusId: fetchMeRequestStatusId,
  });
  const { requestStatus: exchangeTokenRequestStatus } = useRequestStatus({
    requestStatusId: exchangeTokenRequestStatusId,
  });

  const [
    shouldTriggerCompleteAuthFlow,
    setShouldTriggerCompleteAuthFlow,
  ] = useState(false);
  const [
    customApiBuilderParams,
    setCustomApiBuilderParams,
  ] = useState<CustomApiBuilderParams>(null);

  const authErrors = useSelector(
    (state: AppStateType) => state.authReducer.error
  );

  const debouncedHandleFetchMe = useRef(
    debounce((params: Parameters<typeof fetchMe>[0]) => {
      dispatch(
        fetchMe({
          ...params,
          meta: { requestStatusId: fetchMeRequestStatusId, ...params.meta },
        })
      );
    }, 500)
  ).current;

  const completeAuthFlow = useCallback(
    ({
      tenantToStore,
      realmToStore,
    }: {
      tenantToStore: Tenant;
      realmToStore: Realm;
    }) => {
      ApiClientManager.setTenant(tenantToStore);
      ApiClientManager.setRailsSandbox(realmToStore);

      debouncedHandleFetchMe({
        meta: {
          tenantName: tenantToStore.tenantName,
          realmId: realmToStore.realm_id,
          onSuccess: () => {
            localStorage.removeItem(LocalStorageKeys.LastSelectedTenantName);
            localStorage.removeItem(LocalStorageKeys.LastSelectedRealmId);
          },
        },
      });
    },
    [debouncedHandleFetchMe]
  );

  const completeAuthFlowWithDefault = useCallback(() => {
    completeAuthFlow({
      tenantToStore: ApiClientManager.getDefaultTenant(),
      realmToStore: ApiClientManager.getDefaultRealm(),
    });
  }, [completeAuthFlow]);

  const completeAuthFlowWithSelectedTenantRealm = useCallback(() => {
    if (customApiBuilderParams) {
      dispatch(
        // authorization is a requirement
        fetchTenants({
          meta: {
            ...customApiBuilderParams, // We know this exist
            onFailure: () => completeAuthFlowWithDefault(),
            onSuccess: ({ response }) => {
              // set tenantId and realmId

              const {
                tenantName: tenantNameParamToUse,
                realmId: realmIdParamToUse,
              } = customApiBuilderParams;

              /**
               * Find the selected tenant from the response by name
               * Make sure tenant exist
               */
              const tenantFromResponse =
                tenantNameParamToUse &&
                response.find(
                  (tenant) => tenant.tenantName === tenantNameParamToUse
                );

              const tenantToStore =
                tenantFromResponse || ApiClientManager.getDefaultTenant();

              const isRealmIdExistOnTenant = tenantToStore.realms.find(
                (realmId) => realmId === realmIdParamToUse
              );
              if (isRealmIdExistOnTenant) {
                fetchSandboxes(SandboxIsoStateIds.AppbarRealmSelector, {
                  meta: {
                    ...customApiBuilderParams,
                    onSuccess: ({ response }) => {
                      const realmToStore =
                        response.sandbox_info.find(
                          (realm) => realm.realm_id === realmIdParamToUse
                        ) || ApiClientManager.getDefaultRealm();

                      completeAuthFlow({
                        tenantToStore,
                        realmToStore,
                      });
                    },
                  },
                });
              } else {
                // Realm does not exist on Tenant, so use the default tenant and realm to fetch me
                completeAuthFlowWithDefault();
              }
            },
          },
        })
      );
    }
  }, [
    completeAuthFlow,
    completeAuthFlowWithDefault,
    customApiBuilderParams,
    dispatch,
    fetchSandboxes,
  ]);

  // When authToken is defined, meaning that we are in the middle of transition from login repo to glx repo
  const debouncedFetchAuthToken = useRef(
    debounce(() => {
      const tenantNameParamToUse = tenantNameParam ?? undefined;
      const realmIdParamToUse = realmIdParam ?? undefined;

      // In the auth flow, used for galaxy server header
      const paramsForBuildingCustomApiConfigs: CustomApiBuilderParams = {
        realmId: realmIdParamToUse,
        tenantName: tenantNameParamToUse,
      };

      dispatch(
        exchangeToken({
          tempToken: accessToken!,
          meta: {
            requestStatusId: exchangeTokenRequestStatusId,
            ...paramsForBuildingCustomApiConfigs,
            onSuccess: ({ response }) => {
              setShouldTriggerCompleteAuthFlow(true);
              setCustomApiBuilderParams(paramsForBuildingCustomApiConfigs);
            },
          },
        })
      );
    }, 500)
  ).current;

  const hasAuthToken =
    !!user?.auth_token ||
    (exchangeTokenRequestStatus && exchangeTokenRequestStatus.isSuccess);

  // Stage 1
  useEffect(() => {
    // fetchMeRequestStatus = undefined > Hasn't started to fetch ME yet.
    if (!fetchMeRequestStatus && !exchangeTokenRequestStatus) {
      if (user?.auth_token) {
        // Note that debounceHandlAuth also exchangeToken, so this will be called again...
        // Auth token exists meaning that the user already authenticated, just need to fetch me
        // Need to ensure only to fetchMe here if the 2nd flow (below) is already in progress
        debouncedHandleFetchMe({});
      } else if (!!accessToken && !user?.auth_token) {
        // Has access token in param BUT auth token Does Not exist
        debouncedFetchAuthToken();
      }
    }
  }, [
    accessToken,
    debouncedHandleFetchMe,
    user?.auth_token,
    fetchMeRequestStatus,
    exchangeTokenRequestStatus,
    debouncedFetchAuthToken,
  ]);

  /**
   * Stage 2 (Optional) This is only triggered if `debouncedFetchAuthToken` is triggered in Stage 1
   */
  useEffect(() => {
    if (shouldTriggerCompleteAuthFlow && hasAuthToken) {
      if (customApiBuilderParams) {
        /**
         * If tenant exist && tenant name !== defaultTenant => ApiClientManager.setTenant();
         * Take tenant and realm selected from local storage
         */
        completeAuthFlowWithSelectedTenantRealm();
      } else {
        completeAuthFlowWithDefault();
      }
    }
  }, [
    hasAuthToken,
    shouldTriggerCompleteAuthFlow,
    customApiBuilderParams,
    completeAuthFlowWithDefault,
    completeAuthFlowWithSelectedTenantRealm,
  ]);

  const isLoading = !fetchMeRequestStatus || fetchMeRequestStatus.isLoading;

  const isError =
    !isLoading &&
    !!authErrors.AUTHENTICATION_ERROR &&
    fetchMeRequestStatus &&
    !fetchMeRequestStatus.isSuccess;

  const canMoveToNextLayer =
    user &&
    user.account &&
    // Important to make sure Me gets called first
    fetchMeRequestStatus &&
    fetchMeRequestStatus.isSuccess;

  return useMemo(
    () => ({
      isLoading,
      isError,
      canMoveToNextLayer,
    }),
    [isLoading, isError, canMoveToNextLayer]
  );
};
