import { createAsyncThunk } from "@reduxjs/toolkit";
import * as CsmServices from "../services/csm";
import {
  GetCsmAssignmentsParams,
  GetCsmAssignmentsSuccessResponse,
  UpdateCsmAssignmentParams,
  UpdateCsmAssignmentSuccessResponse,
  CreateCsmAssignmentParams,
  CreateCsmAssignmentSuccessResponse,
} from "CsmModule/types/api";
import { AppStateType } from "reducers";

export const fetchCsmAssignments = createAsyncThunk<
  GetCsmAssignmentsSuccessResponse,
  GetCsmAssignmentsParams
>(
  "fetchCsmAssignments",
  async (params, { dispatch, getState, rejectWithValue }) => {
    try {
      const { onFailure, onSuccess } = params;
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token!;

      const response = await CsmServices.fetchCsmAssignments(
        params,
        {},
        auth_token
      );
      onSuccess?.({ response });
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createCsmAssignment = createAsyncThunk<
  CreateCsmAssignmentSuccessResponse,
  CreateCsmAssignmentParams
>(
  "createCsmAssignment",
  async (params, { dispatch, getState, rejectWithValue }) => {
    try {
      const { onFailure, onSuccess } = params;
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token!;

      const response = await CsmServices.createCsmAssignment(
        params,
        {},
        auth_token
      );
      onSuccess?.({ response });
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateCsmAssignment = createAsyncThunk<
  UpdateCsmAssignmentSuccessResponse,
  UpdateCsmAssignmentParams
>(
  "updateCsmAssignment",
  async (params, { dispatch, getState, rejectWithValue }) => {
    try {
      const { onFailure, onSuccess } = params;
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token!;

      const response = await CsmServices.updateCsmAssignment(
        params,
        {},
        auth_token
      );
      onSuccess?.({ response });
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
