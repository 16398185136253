import { useDispatch, useSelector } from "react-redux";
import { selectIntegrationHealthDataByIsoStateId } from "selectors";
import { AppStateType } from "reducers";
import isEqual from "lodash/isEqual";

type HookProps = {
  isoStateId: string;
};

const useIntegrationHealthData = ({ isoStateId }: HookProps) => {
  const { data, isRequesting, error } = useSelector(
    (state: AppStateType) =>
      selectIntegrationHealthDataByIsoStateId(state, { isoStateId }),
    isEqual
  );

  return {
    data,
    isRequesting,
    error,
  };
};

export default useIntegrationHealthData;
