import { Box, Tooltip } from "@material-ui/core";
import { DEFAULT_RAILS_REALM_ID } from "RealmModule/constants/realm";
import { MOSAIC_SANDBOX_TEAM_ID } from "SandboxModule/constants";

export const checkIsMosaicSandboxTeam = (teamId: number) => {
  return teamId === MOSAIC_SANDBOX_TEAM_ID;
};

export const checkIsDefaultRealm = ({ realmId }: { realmId: string }) => {
  return realmId === DEFAULT_RAILS_REALM_ID;
};

export const makeRealmLabel = ({
  realmId,
  teamName,
  showWarningLabel = false,
  tooltip = "",
}: {
  realmId: string;
  teamName: Nullable<string>;
  showWarningLabel?: boolean;
  tooltip?: string;
}) => {
  return (
    <Tooltip title={tooltip}>
      <Box>
        {/* prod has no teamName, this condition is intended */}
        {teamName
          ? `${teamName} (${realmId})`
          : `${realmId ?? "Loading..."}`}{" "}
        {checkIsDefaultRealm({ realmId }) && showWarningLabel ? (
          <Box fontSize={"12px"} color={"red"} display={"inline-block"}>
            | DO NOT SHARE
          </Box>
        ) : (
          ""
        )}
      </Box>
    </Tooltip>
  );
};
