import { IntegrationAgentNames, integrationAgents } from "@constants";
import { Box, TextField } from "@material-ui/core";
import { IntegrationAgentsNameSelector } from "TenantModule/components/Management/IntegrationAgentsNameSelector";
import { FC, ChangeEvent } from "react";
import { StyledDefaultPrimaryButton } from "styles";

export const IntegrationSecretsSection: FC<{
  isDisabled: boolean;
  sectionId: string;
  integrationAgentName: IntegrationAgentNames | string;
  integrationAgentsOverride?: typeof integrationAgents;
  secret: string;
  removeRow: (sectionId: string) => void;
  onChange: (e: ChangeEvent<any>) => void;
}> = ({
  isDisabled,
  sectionId,
  integrationAgentName,
  secret,
  removeRow,
  onChange,
  integrationAgentsOverride = integrationAgents,
}) => {
  const onRemove = () => {
    removeRow(sectionId);
  };

  return (
    <Box display={"flex"} gridGap={"20px"} key={sectionId} alignItems={"center"}>
      <Box width={"40%"} display={"flex"} flexDirection={"column"}>
        <IntegrationAgentsNameSelector
          value={integrationAgentName}
          onChange={onChange}
          disabled={isDisabled}
          items={integrationAgentsOverride}
        />
      </Box>
      <Box width={"40%"}>
        <TextField
          onChange={onChange}
          value={secret}
          label="Secret"
          name="secret"
          type="text"
          variant="outlined"
          fullWidth
          disabled={isDisabled}
        />
      </Box>
      <Box width={"20%"}>
        <StyledDefaultPrimaryButton onClick={onRemove}>
          Remove
        </StyledDefaultPrimaryButton>
      </Box>
    </Box>
  );
};
