import {
  createContext,
  useContext,
  FunctionComponent,
  useMemo,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
  MutableRefObject,
} from "react";
import useAppOnMount from "hooks/useAppOnMount";
import { useRoutes } from "src/routes/useRoutes";
import {
  SearchTeamStrategies,
  SearchTeamStrategyItem,
  searchTeamStategiesHash,
} from "@constants";

type AppContextProps = ReturnType<typeof useAppOnMount> &
  ReturnType<typeof useRoutes> & {
    searchTeamStrategy: SearchTeamStrategyItem;
    setSearchTeamStrategies: Dispatch<SetStateAction<SearchTeamStrategies>>;
    searchBarInputRef: MutableRefObject<Nullable<HTMLInputElement>>;
  };

const AppContext = createContext({} as AppContextProps);

export const AppProvider: FunctionComponent<{ children: JSX.Element }> = ({
  children,
}) => {
  const [searchIdType, setSearchTeamStrategies] = useState(
    SearchTeamStrategies.ByTeamId
  );
  const searchBarInputRef = useRef(null);
  const searchTeamStrategy = searchTeamStategiesHash[searchIdType];

  const appProps = useAppOnMount();
  const routesProps = useRoutes();

  const value = useMemo(
    () => ({
      ...appProps,
      ...routesProps,
      searchTeamStrategy,
      setSearchTeamStrategies,
      searchBarInputRef,
    }),
    [appProps, routesProps, searchTeamStrategy]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
