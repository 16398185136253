import { cloneDeep } from "lodash";

export const createError = (prevStateError: any, actionError: any) => {
  const ERRORS = cloneDeep(prevStateError);

  const error = actionError?.error ? actionError.error : actionError;

  /** Creating error for reducer */

  // if (actionError?.type) {
  //   if(actionError?.status > 399 || actionError?.message || actionError?.isAxiosError){
  //     ERRORS[actionError.type as keyof typeof ERRORS] = {
  //       ...actionError,
  //       message: actionError.message, // Shallow copy is enough because there are no nested keys
  //     };
  //   } else {
  //     ERRORS[actionError.type as keyof typeof ERRORS] = undefined;
  //   }
  // }

  if (error?.type) {
    ERRORS[error?.type as keyof typeof ERRORS] = {
      ...error,
      message: error?.message,
    };
  }
  return ERRORS;
};
