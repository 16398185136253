import { TableConfigV2 } from "hooks/useMuiTableBuilderV2";

// Data cells
import { AccountIdCell } from "components/Cells/AccountIdCell";
import { EmailCell } from "components/Cells/EmailCell";
import { LastActiveCell } from "components/Cells/LastActiveCell";
import { TeamIdCell } from "components/Cells/TeamIdCell";
import { TeamMemberNameCell } from "TeamModule/components/TeamMembersTable/DataCells/TeamMemberNameCell";

// Header cells
import { HeaderCell } from "components/Cells/HeaderCell";
import { columns, Columns } from "./columns";
import { RowTypes } from "./rows";

export const getTableConfig = (): TableConfigV2 => {
  const tableConfig = {
    /* --------------------------------- Shared --------------------------------- */
    hasStickyHeader: true,
    tableHeaderTitle: "Member",
    rowTypes: RowTypes,
    hideToolbar: true,
    columns,
    cellsByRowTypesAndColumns: {
      [RowTypes.Data]: {
        [Columns.AccountId]: AccountIdCell,
        [Columns.TeamId]: TeamIdCell,
        [Columns.Name]: TeamMemberNameCell,
        [Columns.Email]: EmailCell,
        [Columns.LastActive]: LastActiveCell,
      },
      [RowTypes.Header]: {
        [Columns.AccountId]: HeaderCell,
        [Columns.TeamId]: HeaderCell,
        [Columns.Name]: HeaderCell,
        [Columns.Email]: HeaderCell,
        [Columns.LastActive]: HeaderCell,
      },
    },
  };

  return tableConfig;
};
