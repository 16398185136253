import { createAsyncThunk } from "@reduxjs/toolkit";
import * as TeamServices from "TeamModule/services";
import * as Types from "TeamModule/types";
import { AppStateType } from "reducers";
import omit from "lodash/omit";
import ApiClientManager from "core/apiClient";
import { fetchCsmAssignments } from "CsmModule/thunks/csm";

export const fetchTeams = createAsyncThunk<
  Types.FetchTeamsSuccessResponse,
  Types.FetchTeamsParams
>(
  "fetchTeams",
  async (
    {
      requestStatusId,
      onSuccess,
      onFailure,
      teamIds,
      limit,
      initial,
      skipCsmFetch,
      isChainingRequests,
      isTakeLatest, // should be in meta
      ...params
    }: Types.FetchTeamsParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
        csmReducer: { csmAssignmentByTeamHash },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;

      const response = await TeamServices.getTeams(
        {
          params: {
            limit,
            ...(teamIds
              ? {
                  limit: teamIds.length,
                  ids: teamIds,
                }
              : null),
            ...params,
          },
          requestStatusId,
        },
        auth_token as string
      );

      if (teamIds && !skipCsmFetch) {
        // Fetch csm assignments
        const teamIdsToFetchCsmAssignments = teamIds.reduce<number[]>(
          (acc, teamId) => {
            if (!csmAssignmentByTeamHash[teamId]) acc.push(teamId);
            return acc;
          },
          []
        );
        if (teamIdsToFetchCsmAssignments.length) {
          dispatch(
            fetchCsmAssignments({
              mosaicTeamIds: teamIdsToFetchCsmAssignments,
            })
          );
        }
      }

      onSuccess && onSuccess({ response });
      return response.data;
    } catch (e) {
      onFailure && onFailure({ error: e });
      return rejectWithValue(e);
    }
  }
);

export const createTeam = createAsyncThunk(
  "createTeam",
  async (
    { meta, ...params }: Types.CreateTeamParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const { onSuccess, onFailure } = meta || {};
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      /**
       * Spec
       * On Prod:
       *  - If (subscription) realm id is selected > Show plus button + use default rails server url
       *  - If (subscription) realm id not selected > Use default rails server url
       * On Demo:
       *  - If realm id is selected > Hide + button
       *  - If realm id not selected > Use default rails server url
       */
      const response = await TeamServices.createTeam(
        params,
        {
          baseURL: ApiClientManager.getRailsServerUrl(),
        },
        auth_token as string
      );
      onSuccess && onSuccess({ response });
      return response.data;
    } catch (e) {
      onFailure && onFailure({ error: e });
      return rejectWithValue(e);
    }
  }
);

export const deleteBatchTimeEntries = createAsyncThunk(
  "deleteBatchTimeEntries",
  async (
    params: Types.DeleteBatchTimeEntriesParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const { onSuccess, onFailure } = params;
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const body = omit(params, ["onFailure", "onSuccess"]);

      const response = await TeamServices.deleteBatchTimeEntries(
        {},
        body,
        {},
        auth_token as string
      );

      onSuccess && onSuccess({ response });

      return response.data;
    } catch (e) {
      onFailure && onFailure({ error: e });
      return e;
    }
  }
);

export const exportDeletedWorkPlanCsv = createAsyncThunk(
  "exportDeletedWorkPlanCsv",
  async (
    params: Types.ExportDeletedWorkPlanCsvParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const { onSuccess, onFailure } = params;
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;

      const response = await TeamServices.exportDeletedWorkPlanCsv(
        params,
        {},
        auth_token as string
      );
      onSuccess && onSuccess({ response });
      return response;
    } catch (e) {
      onFailure && onFailure({ error: e });
      return e;
    }
  }
);
