import { createAsyncThunk } from "@reduxjs/toolkit";
import * as ReportService from "ReportsModule/services";
import * as ProfileService from "ProfileModule/services";
import { AppStateType } from "reducers";
import * as Types from "ReportsModule/types";
import ApiClientManager from "core/apiClient";

export const fetchSuperAdmins = createAsyncThunk<
  Types.FetchSuperAdminsSuccessResponse,
  Types.FetchSuperAdminsParams
>(
  "fetchSuperAdmins",
  async ({ meta = {}, ...params }, { dispatch, getState, rejectWithValue }) => {
    const { onFailure, onSuccess } = meta;
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const { realmId, tenantName } = params;

      const response = await ReportService.getSuperAdmins(
        {
          ...(realmId && {
            baseURL: ApiClientManager.getRailsServerUrl({
              realmId,
              tenantName,
            }),
            headers: ApiClientManager.buildUrlHeaders({
              realmId,
              tenantName,
            }),
            // don't log out if unautherized account
            shouldLogoutOnUnauthorized: false,
          }),
        },
        auth_token
      );
      onSuccess && onSuccess({ response: response.data });

      return response.data;
    } catch (error) {
      onFailure && onFailure({ error });
      return rejectWithValue(error);
    }
  }
);