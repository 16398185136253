import { CellProps, TableSortProps } from "hooks/useMuiTableBuilderV2";
import TableSort from "components/TableSort";

export interface HeaderCellProps
  extends CellProps<
    unknown,
    TableSortProps & {
      isLoading?: boolean;
    }
  > {}

export const HeaderCell = ({
  column,
  customRowProps: { orderDirection, orderBy, onSort, isLoading = false },
}: HeaderCellProps) => {
  // TODO: Column should really be an object, so need to go to other tables that use `useMuiTableBuilderV2` to make sure it uses the correct type
  const { tooltip, label, id, sortDisabled } =
    typeof column === "object"
      ? column
      : { id: column, tooltip: undefined, label: column, sortDisabled: true };

  return (
    <TableSort
      tooltip={tooltip}
      text={label ?? ""}
      column={id}
      orderDirection={orderDirection}
      orderBy={orderBy}
      onSort={onSort}
      disabled={isLoading || sortDisabled}
      children={undefined}
    />
  );
};
