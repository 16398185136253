import { useMutation } from "@tanstack/react-query";
import { createRealm } from "../services";
import { useSelector } from "react-redux";
import { selectAuthToken } from "selectors";
import { AxiosResponse } from "axios";
import {
  CreateRealmErrorResponse,
  CreateRealmParams,
  CreateRealmSuccessResponse,
} from "SandboxModule/types";
import useSandboxes, {
  SandboxIsoStateIds,
} from "SandboxModule/hooks/useSandboxes";
import { eventManager } from "core/event/eventManager";
import { AppEvents } from "core/event/constants";

export const useCreateRealm = ({
  onSuccess,
}: {
  onSuccess?: VoidFunction;
}) => {
  const token = useSelector(selectAuthToken);
  const { fetchSandboxes } = useSandboxes();

  const mutation = useMutation<
    AxiosResponse<CreateRealmSuccessResponse>,
    AxiosResponse<CreateRealmErrorResponse>,
    CreateRealmParams
  >({
    mutationFn: async (params) => {
      if (!token) {
        throw new Error("No auth token available");
      }
      return createRealm({
        params,
        token,
      });
    },
    onSuccess: ({ data }) => {
      onSuccess?.();
      eventManager.emit({
        eventName: AppEvents.CreateRealmSuccess,
        detail: { data },
      });

      if (!!data) alert(data);

      fetchSandboxes(SandboxIsoStateIds.SandboxCreationTimer);
      fetchSandboxes(SandboxIsoStateIds.AppbarRealmSelector);
    },
    onError: (error) => {
      alert(`${error.data.message || "Failed to create"}`);
    },
  });

  return {
    createRealm: mutation.mutate,
    isCreatingRealm: mutation.isLoading,
  };
};
