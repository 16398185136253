import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MEMBER_TABLE_VIEWS, DEFAULT_MEMBER_TABLE_VIEW } from "@constants";
import { setTargetService } from "actionCreators";
import {
  getTeamProfileUrl,
  getPendingEntitiesUrl,
  getNetworkLogUrl,
  getSandboxesPageUrl,
  getOverallIntegrationUrl,
  getSuperAdminPageUrl,
  getEntityErrorPageUrl,
  getPricingCalculatorUrl,
  standardizeUrl,
} from "utils/url";
import { getImpersonateToken } from "AuthModule/services";
import { selectAuthToken } from "selectors";
import * as Types from "types";
import {
  getIntegrationHealthDeepLinkUrl,
  getIntegrationDetailsDeepLinkUrl,
  getReportTeamsPageDeepLinkUrl,
} from "utils";
import { ValueOf } from "type-fest";
import ApiClientManager from "core/apiClient";
import qs from "qs";
import { EntityErrorPageUrlQueryParams } from "types";
import { fetchTempToken } from "AuthModule/thunks";

/**
 * A hook that returns navigation actions
 */
const useAppNavigation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token: string | undefined = useSelector(selectAuthToken);

  const navigateToTeamProfile = ({
    teamId,
    memberTableView = DEFAULT_MEMBER_TABLE_VIEW,
    shouldOpenInAnotherTab = false,
  }: {
    teamId: number | string;
    shouldOpenInAnotherTab?: boolean;
    memberTableView?: ValueOf<typeof MEMBER_TABLE_VIEWS>;
  }) => {
    const url = getTeamProfileUrl({ teamId: +teamId, memberTableView });
    if (shouldOpenInAnotherTab) {
      window.open(url);
    } else {
      history.push(url);
    }
  };

  const navigateToIntegrationDetailsPage = useCallback(
    (
      teamId: number | string,
      targetService: string,
      targetServiceId: number
    ) => () => {
      history.push(
        getIntegrationDetailsDeepLinkUrl(teamId, targetService, targetServiceId)
      );
    },
    [history]
  );

  const navigateToGeneralIntegrationPage = useCallback(
    (targetService?: string, interval?: string) => {
      if (targetService && interval) {
        const queryparam = qs.stringify({ targetService, interval });
        history.push(getOverallIntegrationUrl(queryparam));
      } else {
        history.push(getOverallIntegrationUrl());
      }
    },
    [history]
  );

  const navigateToPendingEntitiesPage = useCallback(
    (
      teamId: number,
      targetService: string,
      targetServiceId: number,
      dataTypes: string[],
      mappingStatuses: string[]
    ) => () => {
      dispatch(
        setTargetService({
          name: targetService,
          id: targetServiceId,
          pendingEntitiesDataTypes: dataTypes,
          pendingEntitiesMappingStatuses: mappingStatuses,
        })
      );
      history.push(
        getPendingEntitiesUrl({ targetServiceId: targetServiceId, teamId })
      );
    },
    [dispatch, history]
  );

  const navigateToNetworkLog = useCallback(
    (
      mosaic_team_id: number,
      requestId: string | null,
      sessionId: string
    ) => () => {
      window.open(
        getNetworkLogUrl({ mosaic_team_id, requestId, sessionId }),
        "_blank"
      );
    },
    []
  );

  const navigateToTeamsTable = (reportTableView?: string) => {
    history.push(getReportTeamsPageDeepLinkUrl({ reportTableView }));
  };

  const navigateToMosaicAppImpersonated = async ({
    accountId,
    urlDomain,
  }: {
    accountId: number;
    /**
     * If provided, use this url domain to impersonate this account
     */
    urlDomain?: string;
  }) => {
    try {
      const {
        data: { auth_token },
      } = await getImpersonateToken(
        accountId,
        {
          params: {
            skip_onboarding: true,
          },
        },
        token
      );
      dispatch(
        fetchTempToken({
          auth_token,
          onSuccess: ({ response }) => {
            const realmId = ApiClientManager.getCurrentRealmId();

            const queryParams = response.data
              ? qs.stringify({
                  access_token: response.data.temp_token,
                  realm_id: realmId,
                })
              : qs.stringify({
                  impersonate: auth_token,
                  realm_id: realmId,
                });

            const urlDomainToUse = urlDomain
              ? standardizeUrl({ url: urlDomain })
              : ApiClientManager.getWebappUrl();

            const redirectToMosaicUrl = `${urlDomainToUse}/?${queryParams}`;

            window.open(redirectToMosaicUrl);
          },
        })
      );
    } catch (e: any) {
      console.error(e);
    }
  };

  const navigateToIntegrationHealth = useCallback(
    ({
      targetServiceId,
      targetService,
      teamId,
    }: {
      targetServiceId: number | string;
      targetService: Types.TargetService;
      teamId: number;
    }) => {
      history.push(
        getIntegrationHealthDeepLinkUrl(teamId, targetService, targetServiceId)
      );
    },
    [history]
  );

  const navigateToSandboxesTable = useCallback(() => {
    history.push(getSandboxesPageUrl());
  }, [history]);

  const navigateToSuperAdminTable = useCallback(() => {
    history.push(getSuperAdminPageUrl());
  }, [history]);

  const navigateToEntityErrorsTable = useCallback(
    ({
      openInNewTab,
      targetServiceId,
      entityId,
    }: {
      openInNewTab: boolean;
    } & EntityErrorPageUrlQueryParams) => {
      const url = getEntityErrorPageUrl({
        targetServiceId,
        entityId,
      });

      if (openInNewTab) {
        window.open(url, "_blank");
      } else {
        history.push(url);
      }
    },
    [history]
  );

  const navigateToPricingCalculator = useCallback(
    (params: Parameters<typeof getPricingCalculatorUrl>[0]) => {
      history.push(getPricingCalculatorUrl(params));
    },
    [history]
  );

  return {
    navigateToTeamProfile,
    navigateToIntegrationDetailsPage,
    navigateToNetworkLog,
    navigateToPendingEntitiesPage,
    navigateToMosaicAppImpersonated,
    navigateToTeamsTable,
    navigateToIntegrationHealth,
    navigateToGeneralIntegrationPage,
    navigateToSandboxesTable,
    navigateToSuperAdminTable,
    navigateToEntityErrorsTable,
    navigateToPricingCalculator,
  };
};

export default useAppNavigation;
