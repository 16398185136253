import { Column } from "hooks/useMuiTableBuilderV2";

export enum Columns {
  AccountId = "account_id",
  TeamId = "team_id",
  Name = "name",
  Email = "email",
  LastActive = "last_active",
}

const accountIdColumn: Column = {
  id: Columns.AccountId,
  label: "Account ID",
};

const nameColumn: Column = {
  id: Columns.Name,
  label: "Name",
};

const emailColumn: Column = {
  id: Columns.Email,
  label: "Email",
};

const teamIdColumn: Column = {
  id: Columns.TeamId,
  label: "Team ID",
};

const lastActiveColumn: Column = {
  id: Columns.LastActive,
  label: "Last Active",
};

export const columns = [
  accountIdColumn,
  teamIdColumn,
  nameColumn,
  emailColumn,
  lastActiveColumn,
];
