import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  Paper,
  Button,
  Tooltip,
  TextField,
  FormControlLabel,
} from "@material-ui/core";
import styled from "styled-components";
import { ArrowDropDown } from "@material-ui/icons";
import { SimpleMenuItem } from "types";
import SearchBar from "components/SearchBar";
import { ItemFilter } from "FiltersModule/utils";
import { colors } from "@constants";

type SimpleMenuProps<
  ItemType extends SimpleMenuItem
> = {
  anchorEl: null | HTMLElement;
  items: ItemType[];
  handleClose: () => void;
  handleClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  handleClickV2?: (args: { item: ItemType }) => void;
  disabled?: boolean;
  renderAnchorEl?: () => JSX.Element;
  elevation?: number;
  menuMaxHeight?: number;
  showSearchBar?: boolean;
  itemFilter?: ItemFilter;
  className?: string;
  idKey?: string;
  transformMenu?: string;

  /**
   * If true, only the Menu is rendered
   */
  hideAnchor?: boolean;
};

const StyledPaper = styled(Paper)`
  height: 100%;

  &.transparent-background {
    background-color: transparent;
  }
`;

const noop = () => {};

const SimpleMenu = <ItemType extends SimpleMenuItem>({
  anchorEl,
  items,
  handleClose,
  /**
   * Do not use this
   *
   * Use handleClickV2
   */
  handleClick: deprecatedHandleClick,
  handleClickV2,
  renderAnchorEl,
  disabled,
  elevation,
  menuMaxHeight = 300,
  transformMenu = "translateX(10px) translateY(30px)",
  showSearchBar,
  itemFilter,
  className,
  idKey,
  hideAnchor,
}: SimpleMenuProps<ItemType>) => {
  const [inputValue, setInputValue] = useState("");

  const itemsToUse =
    showSearchBar && itemFilter && inputValue
      ? items.filter((item) =>
          itemFilter({ item, searchWords: [inputValue], filterKeysArray: [] })
        )
      : items;
  const MenuComponent = (
    <Menu
      MenuListProps={{
        style: {
          border: "1px solid rgba(0,0,0,.1)",
          borderRadius: "5px",
        },
      }}
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={{
        style: {
          transform: transformMenu,
          maxHeight: menuMaxHeight,
        },
      }}
    >
      <div>
        {showSearchBar ? (
          <SearchBar inputValue={inputValue} setInputValue={setInputValue} />
        ) : (
          <></>
        )}
        {itemsToUse.map((item, index) => {
          const key = idKey
            ? item[idKey as keyof typeof item]
            : item.value || String(item.label);
          const text = item.getLabel ? item.getLabel() : item.label;
          const shouldCloseMenuOnClick = item.shouldCloseMenuOnClick ?? true;

          return (
            <Tooltip title={item.tooltip || ""} key={`${key}-${index}-tooltip`}>
              <StyledMenuItemStyler className={item.className}>
                <MenuItem
                  disabled={item.disableButton}
                  className="menu-item"
                  style={{
                    borderBottom: item.hasUnderline
                      ? "1px solid rgba(0,0,0,.1)"
                      : "unset",
                  }}
                  key={`${key}-${index}`}
                  onClick={() => {
                    if (shouldCloseMenuOnClick) {
                      handleClose();
                    }
                    handleClickV2?.({ item });

                    item.onClick?.({ item });
                  }}
                >
                  {text}
                </MenuItem>
              </StyledMenuItemStyler>
            </Tooltip>
          );
        })}
      </div>
    </Menu>
  );

  if (hideAnchor) return MenuComponent;

  return (
    <StyledPaper
      elevation={elevation ?? 1}
      className={`menu-container ${className}`}
    >
      <div
        style={{ cursor: disabled ? "not-allowed" : "pointer" }}
        className="menu-button-container"
        onClick={disabled ? noop : deprecatedHandleClick}
      >
        {renderAnchorEl ? renderAnchorEl() : <ArrowDropDown />}
      </div>
      {MenuComponent}
    </StyledPaper>
  );
};

const StyledMenuItemStyler = styled.div`
  &.group-header {
    .menu-item {
      justify-content: center;
      background-color: ${colors.lightGrey};
    }
  }

  &.red-text {
    color: ${colors.red};
  }
`;

export default SimpleMenu;
