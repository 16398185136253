import { keyBy } from "lodash";

const ADMIN = "Admin";
const BASE_MEMBER = "Team Member";
const GUEST = "Guest";
const LOGIN = "Login";
const WORK_PLANNER_CAN_EDIT_ALL_PROJECT =
  "Work Planner - Can Edit - All Projects";
const TIMESHEET_MANAGER = "Timesheet Manager";
const TEAM_BUDGET_MANAGER = "Team Budget Manager";
const WORK_PLANNER_VIEW_ONLY_ALL_PROJECT =
  "Workload Planner View Only - All Projects";
const WORKLOAD_PLANNER_CAN_EDIT_PROJECT_MEMBER =
  "Workload Planner - Can Edit - Project Member";
const WORKLOAD_PLANNER_VIEW_ONLY_PROJECT_MEMBER =
  "Workload Planner - View Only - Project Member";
const WORKLOAD_PLANNER_CAN_EDIT_PROJECT_MANAGER =
  "Workload Planner - Can Edit - Project Manager";
const WORKLOAD_PLANNER_VIEW_ONLY_PROJECT_MANAGER =
  "Workload Planner - View Only - Project Manager";
const FINANCIAL_MANAGER_CAN_EDIT_ALL_PROJECT =
  "Financial Manager - Can Edit - All Projects";
const FINANCIAL_MANAGER_CAN_EDIT_PROJECT_MEMBER =
  "Financial Manager - Can Edit - Project Member";
const FINANCIAL_MANAGER_VIEW_ONLY_ALL_PROJECT =
  "Financial Manager - View Only - All Projects";
const FINANCIAL_MANAGER_VIEW_ONLY_PROJECT_MEMBER =
  "Financial Manager - View Only - Project Member";
const FINANCIAL_MANAGER_CAN_EDIT_PROJECT_MANAGER =
  "Financial Manager - Can Edit - Project Manager";
const FINANCIAL_MANAGER_VIEW_ONLY_PROJECT_MANAGER =
  "Financial Manager - View Only - Project Manager";
const BASE_MEMBER_NO_ACCESS = "Team Member - No Access";

const adminAccessRole = {
  label: ADMIN,
  roleId: 1,
  shortLabel: ADMIN,
};

const baseMemberAllProjectsAccessRole = {
  label: BASE_MEMBER,
  roleId: 2,
  shortLabel: "All Projects",
};

const baseMemberNoAccessRole = {
  label: BASE_MEMBER_NO_ACCESS,
  roleId: 26,
  shortLabel: "No Access",
};

const guestAccessRole = {
  label: GUEST,
  roleId: 4,
  shortLabel: GUEST,
};

const loginAccessRole = {
  label: LOGIN,
  roleId: 8,
  shortLabel: LOGIN,
};

const workPlannerCanEditAllProjectAccessRole = {
  label: WORK_PLANNER_CAN_EDIT_ALL_PROJECT,
  roleId: 9,
  shortLabel: "All Projects",
};

const timesheetManagerAccessRole = {
  label: TIMESHEET_MANAGER,
  roleId: 10,
  shortLabel: TIMESHEET_MANAGER,
};

const teamBudgetManagerAccessRole = {
  label: TEAM_BUDGET_MANAGER,
  roleId: 11,
  shortLabel: TEAM_BUDGET_MANAGER,
};

const workPlannerViewOnlyAllProjectAccessRole = {
  label: WORK_PLANNER_VIEW_ONLY_ALL_PROJECT,
  roleId: 12,
  shortLabel: "All Projects",
};

const budgetManagerCanEditAllProjectAccessRole = {
  label: FINANCIAL_MANAGER_CAN_EDIT_ALL_PROJECT,
  roleId: 13,
  shortLabel: "All Projects",
};

const budgetManagerViewOnlyAllProjectAccessRole = {
  label: FINANCIAL_MANAGER_VIEW_ONLY_ALL_PROJECT,
  roleId: 14,
  shortLabel: "All Projects",
};

const workPlannerCanEditProjectMemberAccessRole = {
  label: WORKLOAD_PLANNER_CAN_EDIT_PROJECT_MEMBER,
  roleId: 17,
  shortLabel: "Projects they are a member",
};

const workPlannerViewOnlyProjectMemberAccessRole = {
  label: WORKLOAD_PLANNER_VIEW_ONLY_PROJECT_MEMBER,
  roleId: 18,
  shortLabel: "Projects they are a member",
};

const budgetManagerCanEditProjectMemberAccessRole = {
  label: FINANCIAL_MANAGER_CAN_EDIT_PROJECT_MEMBER,
  roleId: 19,
  shortLabel: "Projects they are a member",
};

const budgetManagerViewOnlyProjectMemberAccessRole = {
  label: FINANCIAL_MANAGER_VIEW_ONLY_PROJECT_MEMBER,
  roleId: 20,
  shortLabel: "Projects they are a member",
};

const workPlannerCanEditProjectManagerAccessRole = {
  label: WORKLOAD_PLANNER_CAN_EDIT_PROJECT_MANAGER,
  roleId: 22,
  shortLabel: "Projects they are a project manager",
};

const workPlannerViewOnlyProjectManagerAccessRole = {
  label: WORKLOAD_PLANNER_VIEW_ONLY_PROJECT_MANAGER,
  roleId: 23,
  shortLabel: "Projects they are a project manager",
};

const budgetManagerCanEditProjectManagerAccessRole = {
  label: FINANCIAL_MANAGER_CAN_EDIT_PROJECT_MANAGER,
  roleId: 24,
  shortLabel: "Projects they are a project manager",
};

const budgetManagerViewOnlyProjectManagerAccessRole = {
  label: FINANCIAL_MANAGER_VIEW_ONLY_PROJECT_MANAGER,
  roleId: 25,
  shortLabel: "Projects they are a project manager",
};

export const ACCESS_ROLES = [
  adminAccessRole,
  baseMemberAllProjectsAccessRole,
  baseMemberNoAccessRole,
  guestAccessRole,
  loginAccessRole,
  workPlannerCanEditAllProjectAccessRole,
  timesheetManagerAccessRole,
  teamBudgetManagerAccessRole,
  workPlannerViewOnlyAllProjectAccessRole,
  budgetManagerCanEditAllProjectAccessRole,
  budgetManagerViewOnlyAllProjectAccessRole,
  workPlannerCanEditProjectMemberAccessRole,
  workPlannerViewOnlyProjectMemberAccessRole,
  budgetManagerCanEditProjectMemberAccessRole,
  budgetManagerViewOnlyProjectMemberAccessRole,
  workPlannerCanEditProjectManagerAccessRole,
  workPlannerViewOnlyProjectManagerAccessRole,
  budgetManagerCanEditProjectManagerAccessRole,
  budgetManagerViewOnlyProjectManagerAccessRole,
];

export const accessRolesHash = keyBy(ACCESS_ROLES, "roleId");

export interface RoleType {
  label: string;
  roleId: number;
  shortLabel: string;
}

export interface AccessRolesStructureType {
  label: string;
  subRoles?: AccessRolesStructureType[];
  roleId?: number;
  shortLabel?: string;
}

export const accessRolesStructure: AccessRolesStructureType[] = [
  {
    label: BASE_MEMBER,
    subRoles: [baseMemberAllProjectsAccessRole, baseMemberNoAccessRole],
  },
  {
    label: "Work Planner",
    subRoles: [
      {
        label: "View Only",
        subRoles: [
          workPlannerViewOnlyAllProjectAccessRole,
          workPlannerViewOnlyProjectMemberAccessRole,
          workPlannerViewOnlyProjectManagerAccessRole,
        ],
      },
      {
        label: "Can Edit",
        subRoles: [
          workPlannerCanEditAllProjectAccessRole,
          workPlannerCanEditProjectMemberAccessRole,
          workPlannerCanEditProjectManagerAccessRole,
        ],
      },
    ],
  },
  {
    label: "Budget Manager",
    subRoles: [
      {
        label: "View Only",
        subRoles: [
          budgetManagerViewOnlyAllProjectAccessRole,
          budgetManagerViewOnlyProjectMemberAccessRole,
          budgetManagerViewOnlyProjectManagerAccessRole,
        ],
      },
      {
        label: "Can Edit",
        subRoles: [
          budgetManagerCanEditAllProjectAccessRole,
          budgetManagerCanEditProjectMemberAccessRole,
          budgetManagerCanEditProjectManagerAccessRole,
        ],
      },
    ],
  },
  adminAccessRole,
];
