/**
 * Use case
 * - Showing percentage difference between current and previous period data on dashboard statistics
 */
export const calculatePercentageDifferenceBetweenPreviousAndAfterValues = (
  beforeNum: number,
  currentNum: number
) => {
  // Note that beforeNum is always a denominator, so if beforeNum is (not divisible), then default to 1
  const difference = beforeNum === 0 ? 1 : (currentNum - beforeNum) / beforeNum;

  return {
    difference,
  };
};
