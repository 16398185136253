import { Button, TextField } from "@material-ui/core";
import Modal from "shared/Modal";
import { ChangeEventHandler, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSuperAdminsHash } from "selectors";
import { useState } from "react";
import styled from "styled-components";
import { fetchSuperAdmins, updateTeamMember } from "thunk";
import useTransitionMenu from "hooks/useTransitionMenu";
import { ACCESS_ROLES, RoleType, accessRolesHash } from "@constants";
import { CustomFieldsContainer } from "./TeamMembersTable/style";
import { useRequestStatus } from "RequestStatusModule/hooks/useRequestStatus";

const DemoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1rem;
  .demote-button {
    margin-left: 1rem;
  }
`;

export const DemoteSuperAdminModal: FC<{
  onClose: VoidFunction;
  accountId: number;
}> = ({ onClose, accountId }) => {
  const superAdminsHash = useSelector(selectSuperAdminsHash);
  const superAdmin = superAdminsHash[accountId];
  const dispatch = useDispatch();
  const [demoteText, setDemoteText] = useState("");
  const canDemote = demoteText === "demote";
  const accessorFn = (role: RoleType) => role.label;
  const {
    renderTransitionMenu: renderRoleSelectionMenu,
    selectedItem: selectedRoleToDemote,
  } = useTransitionMenu({
    defaultSelectedItem: accessRolesHash[1], // Default role to demote to is admin
    accessorFn,
  });
  const requestStatusId = `demote-super-admin=${accountId}`;
  const { requestStatus } = useRequestStatus({ requestStatusId });
  const { isRequesting: isUpdatingMember = false } = requestStatus || {};

  const demoteSuperAdmin = () => {
    const team_role = selectedRoleToDemote.roleId;

    dispatch(
      updateTeamMember({
        teamMembershipId: superAdmin.id,
        team_role,
        onSuccess: () => {
          dispatch(fetchSuperAdmins({}));
        },
        requestStatusId,
      })
    );
  };

  const onDemoteTextChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDemoteText(e.target.value);
  };

  return (
    <Modal open onClose={onClose}>
      <CustomFieldsContainer>
        <h2>Demote</h2>
        <p>
          Select the access role to demote this super admin to. This is a
          one-time operation.{" "}
          <span className="warning-text">
            Once "Demote" is pressed, the super admin will lose access to
            Galaxy.
          </span>
        </p>
        <br />
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={demoteText}
          label={`To confirm demotion, type "demote" into the field *`}
          onChange={onDemoteTextChange}
        />
        <DemoteContainer>
          {" "}
          {renderRoleSelectionMenu({
            menuItems: ACCESS_ROLES,
            isLoading: isUpdatingMember,
            buttonVariant: "text",
            buttonColor: "primary",
          })}
          <Button
            color="primary"
            disabled={!canDemote || isUpdatingMember}
            className="demote-button"
            onClick={demoteSuperAdmin}
          >
            Demote
          </Button>
        </DemoteContainer>
      </CustomFieldsContainer>
    </Modal>
  );
};
