import { ORDER_DIRECTION } from "@constants/sorting";
import { ValueOf } from "type-fest";

export const getReverseSortDirection = (currentDirection: string) =>
  currentDirection === "asc" ? "desc" : "asc";

export const getSortAttributes = ({
  currentSortBy,
  newSortBy,
  currentSortDirection,
  defaultSortBy,
}: {
  currentSortBy: string;
  newSortBy: string;
  currentSortDirection: ValueOf<typeof ORDER_DIRECTION>;
  defaultSortBy?: string;
}) => {
  // If different sortBy selected, then sortBy = new, sortDir= default
  // If same sortBy selected,
  /**
   *  - Currently up, then sortBy = cur, sortDir = down
   *  - Currently down, then sortBy = null/default, sortDir = up
   */
  const isSame = currentSortBy === newSortBy;

  if (isSame) {
    const isAsc = currentSortDirection === ORDER_DIRECTION.ASC;

    return {
      nextSortBy: isAsc ? currentSortBy : defaultSortBy,
      nextSortDirection: isAsc ? ORDER_DIRECTION.DESC : ORDER_DIRECTION.ASC,
    };
  }

  return {
    nextSortBy: newSortBy,
    nextSortDirection: ORDER_DIRECTION.ASC,
  };
};
