import { Box, Button } from "@material-ui/core";
import { useToggle } from "react-use";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useTenantFilters } from "../hooks/useTenantFilters";

type TenantFiltersContainerProps = {
  fetchTenantAdditionalParams: {
    isArchived: boolean;
  };
  isoStateId: string;
};

export const TenantFiltersContainer = ({
  isoStateId,
  fetchTenantAdditionalParams,
}: TenantFiltersContainerProps) => {
  const [showTenantFilter, toggleTenantFilter] = useToggle(false);
  const { TenantFilter } = useTenantFilters({
    isoStateId,
    fetchTenantAdditionalParams,
  });

  return (
    <>
      <Box display={"flex"} justifyContent={"flex-end"} padding={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={toggleTenantFilter}
          startIcon={showTenantFilter ? <ExpandLessIcon /> : <FilterListIcon />}
        >
          {showTenantFilter ? "Collapse" : "Filter"}
        </Button>
      </Box>
      {showTenantFilter ? (
        <div>
          <Box display={"flex"} justifyContent={"space-between"} mx={3}>
            {TenantFilter}
          </Box>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
