import styled from "styled-components";
import { TableCell } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export const TruncatedTextTableCell = styled(TableCell)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  max-width: 30px;
`;

export const MemberInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}`;

export const MemberFormStyler = styled.div`
  .header-name,
  .header-id {
    word-break: break-all;
  }

  .MuiTextField-root {
    margin-bottom: 1rem;
  }
`;

export type CellWithBorderClassNames =
  | "show-border"
  | "show-border-top"
  | "show-border-bottom"
  | "show-border-left"
  | "show-border-right"
  | "text-centered"
  | "no-border-bottom"
  | "no-border-right"
  | "no-border-top"
  | "no-border-left"
  | "sticky"
  | "zindex3"
  | "zindex4";

export const CellWithBorder = styled(TableCell)`
  &.show-border {
    border: 1px solid rgba(224, 224, 224, 1);
  }

  &.show-border-top {
    border-top: 1px solid rgba(224, 224, 224, 1);
  }

  &.show-border-bottom {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  &.show-border-left {
    border-left: 1px solid rgba(224, 224, 224, 1);
  }

  &.show-border-right {
    border-right: 1px solid rgba(224, 224, 224, 1);
  }

  &.text-centered {
    text-align: center;
  }

  &.no-border-bottom {
    border-bottom: none;
  }
  &.no-border-right {
    border-right: none;
  }

  &.no-border-top {
    border-top: none;
  }

  &.no-border-left {
    border-left: none;
  }

  &.sticky {
    position: sticky;
    left: 0;
    background-color: white;
  }

  &.zindex3 {
    z-index: 3;
  }
  &.zindex4 {
    z-index: 4;
  }
`;

export const LinkText = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: blue;

  &.disabled {
    cursor: unset;
    text-decoration: unset;
    color: black;
  }
`;

export const StyledAlert = styled(Alert)`
  &.icon-only {
    &.MuiAlert-root {
      padding 0;
    }
    background-color: transparent;
  }
  &.inline-block {
    display: inline-block;
  }

  &.remove-icon-padding { 
    .MuiAlert-icon {
      padding: 0 !important;
    }
  }

  &.remove-message-padding { 
    .MuiAlert-message {
      padding: 0 !important;
    }
  }

  &.row-flex-center {
    display: flex;
    justify-content: center;
  }

  justify-content: flex-end;

  &.center-with-margin {
    width: fit-content;
    margin: auto;
  }

  &.no-margin-on-icon {

    .MuiAlert-icon {
      margin: 0;
    }
  }
`;
