import { EditTenantModal } from "TenantModule/components/EditTenantModal";
import { useDynamicModal } from "components/DynamicModalProvider/useDynamicModal";
import { useCallback } from "react";

export const useEditTenantModal = () => {
  const { openModal } = useDynamicModal();

  const openEditTenantModal = useCallback(
    (args: { tenantId: number; onEditTenantSuccess?: VoidFunction }) => {
      openModal({
        modal: ({ isOpen, toggle }) => {
          return <EditTenantModal onClose={toggle} {...args} />;
        },
      });
    },
    [openModal]
  );

  return {
    openEditTenantModal,
  };
};
