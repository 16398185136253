import {
  putApiRailsServer,
  postApiRailsServer,
  getApiIntegrationServer,
  postApiIntegrationServer,
  getApiRailsServer,
} from "services/api";
import {
  resetMemberPasswordParams,
  provisionQbdParams,
  SetSingleUserTemporaryPasswordParams,
  bulkActivateAccountsParams,
  ProvisionIntegrationPayload,
  fetchProvisionErrorsParams,
  provisionCustomerParams,
  DeactivateTeamParams,
} from "ProfileModule/types";

export const getTeamMember = (
  teamId: number,
  config: object = {},
  token: string = ""
) => getApiRailsServer(`/teams/${teamId}/team_memberships`, config, token);

export const updateTeamMember = (
  teamMembershipId: number,
  data: Record<any, any>,
  config: object = {},
  token: string
) =>
  putApiRailsServer(
    `/team_memberships/${teamMembershipId}`,
    data,
    config,
    token
  );
  
export const resetMemberPassword = (
  body: resetMemberPasswordParams,
  config: object = {},
  token: string
) => postApiRailsServer("/reset_passwords", body, config, token);

export const provisionQbd = (
  { targetService, teamId }: provisionQbdParams,
  config: Record<string, any>,
  token: string
) =>
  getApiIntegrationServer(
    `/forward/${targetService}/provision-company/${teamId}`,
    config,
    token
  );

export const provisionIntegration = (
  body: ProvisionIntegrationPayload,
  config: Record<string, any>,
  token: string
) => postApiIntegrationServer(`/provision`, body, config, token);

export const provisionCustomer = (
  { targetService, teamId }: provisionCustomerParams,
  config: Record<string, any>,
  token: string
) =>
  getApiIntegrationServer(
    `/forward/${targetService}/provision-company/${teamId}`,
    config,
    token
  );

export const fetchProvisionErrors = (
  { teamId }: fetchProvisionErrorsParams,
  config: Record<string, any>,
  token: string
) =>
  getApiIntegrationServer(`telemetry/provisionLogs/${teamId}`, config, token);

export const setSingleUserTemporaryPassword = (
  body: SetSingleUserTemporaryPasswordParams,
  config: Record<string, any>,
  token: string
) =>
  postApiRailsServer(`/auth_server/account_set_password`, body, config, token);

export const bulkActivateAccounts = (
  body: bulkActivateAccountsParams,
  config: Record<string, any>,
  token: string
) => postApiRailsServer(`/account/bulk-force-activate`, body, config, token);

export const deactivateTeam = (
  params: DeactivateTeamParams,
  body = {},
  config: Record<string, any>,
  token: string
) =>
  postApiRailsServer(
    `/teams/${params.team_id}/deactivate`,
    body,
    config,
    token
  );
