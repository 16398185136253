import { createReducer } from "@reduxjs/toolkit";
import * as thunkActions from "thunk";
import * as actions from "actionCreators";
import {
  IntegrationDataOverviewPerDataTypesType,
  DataHistoryPerDataTypesPerIntervalType,
  PendingEntitiesInstance,
  QueueStatusType,
  TelemetryLastUpdatesType,
  RecentErrorsType,
  Integration,
  IntegrationHealth,
  CsvUploadInfo,
} from "types";
import groupBy from "lodash/groupBy";
import { TargetServiceId, TeamId } from "IntegrationModule/types";

const makeLastTimeSyncData = (lastTimeSync: Array<TelemetryLastUpdatesType>) =>
  lastTimeSync?.reduce(
    (acc: Record<number, any>, curr: TelemetryLastUpdatesType) => {
      acc[curr.target_service_id] = {
        ...acc[curr.target_service_id],
        [curr.data_type]: {
          ...curr,
        },
      };
      return acc;
    },
    {}
  );

type InitialStateType = {
  integration_data_overview_per_data_type: IntegrationDataOverviewPerDataTypesType[];
  integration_data_history_per_data_type: DataHistoryPerDataTypesPerIntervalType;
  pending_entities: Record<number, Array<PendingEntitiesInstance>>;
  integrationsByTeamId: Record<TeamId, Record<TargetServiceId, Integration>>;
  queues_status: {
    [key: string]: QueueStatusType;
  };
  api_request_log: {
    currentApiRequestId?: string;
    currentMosaicTeamId?: number;
    [teamId: number]: {
      [apiRequestId: string]: Array<Record<any, any>>;
    };
  };
  network_log: {
    currentSessionId?: string;
    currentMosaicTeamId?: number;
    [teamId: number]: {
      [sessionId: string]: Array<Record<any, any>>;
    };
  };
  team_network_error_log: Record<any, any>[]; // TeamNetworkErrorLog
  team_agent_error_log: RecentErrorsType[];
  single_entity?: PendingEntitiesInstance;
  last_time_sync: Record<any, any>;
  integration_health_by_id: IntegrationHealth[];
  csv_upload_info: CsvUploadInfo[];
  requestStatusesAndErrors: {
    [x: string]: {
      isRequesting: boolean | Record<any, any>;
      error: Record<string, any>;
    };
  };
};

const initialState: InitialStateType = {
  integration_data_overview_per_data_type: [],
  integrationsByTeamId: {},
  integration_data_history_per_data_type: {
    day: {
      offset: 0,
      data: [],
      hasReachTheEnd: false,
    },
    week: {
      offset: 0,
      data: [],
      hasReachTheEnd: false,
    },
    month: {
      offset: 0,
      data: [],
      hasReachTheEnd: false,
    },
    year: {
      offset: 0,
      data: [],
      hasReachTheEnd: false,
    },
  },
  pending_entities: {},
  queues_status: {},
  api_request_log: {
    currentApiRequestId: undefined,
    currentMosaicTeamId: undefined,
  },
  network_log: {
    currentSessionId: undefined,
    currentMosaicTeamId: undefined,
  },
  team_network_error_log: [],
  team_agent_error_log: [],
  single_entity: undefined,
  last_time_sync: {},
  integration_health_by_id: [],
  csv_upload_info: [],
  requestStatusesAndErrors: {
    // Example with boolean
    [thunkActions.fetchApiRequestLog.typePrefix]: {
      isRequesting: {},
      error: {},
    },
    [thunkActions.fetchNetworkLog.typePrefix]: {
      isRequesting: {},
      error: {},
    },
  },
  //   telemetry_last_updates: {}, ????
};

export default createReducer(initialState, (builder) => {
  /* ---------------------------- Non Async Request --------------------------- */
  builder.addCase(actions.setIntegrationState, (state, action) => {
    state = {
      ...state,
      ...action.payload,
    };
  });
  /* -------------------------------------------------------------------------- */
  /* ------------------------ Data Types Mapping Status ----------------------- */

  /* ------------------------------- Data Overview ------------------------------ */
  builder.addCase(
    thunkActions.fetchDataOverviewPerDataTypes.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchDataOverviewPerDataTypes.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchDataOverviewPerDataTypes.fulfilled,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchDataOverviewPerDataTypes.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.integration_data_overview_per_data_type = action.payload;
    }
  );
  builder.addCase(
    thunkActions.fetchDataOverviewPerDataTypes.rejected,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchDataOverviewPerDataTypes.typePrefix
      ] = {
        isRequesting: false,
        error: action.error,
      };
      state.integration_data_overview_per_data_type = [];
    }
  );

  /* ------------------------------- Data History ------------------------------ */
  builder.addCase(
    thunkActions.fetchDataHistoryPerDataTypes.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchDataHistoryPerDataTypes.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchDataHistoryPerDataTypes.fulfilled,
    (state, { payload }) => {
      const interval = Object.keys(payload)[0];
      const { integration_data_history_per_data_type } = state;

      /** If newly retrieved data has length of 0, keep the same data */
      /** If data has 0 length, falsy check the current state for dataHistory for that datatype
       *
       */
      const newIntervalData =
        payload[interval].data.length > 0
          ? {
              ...integration_data_history_per_data_type,
              [interval]: {
                offset: payload[interval].offset,
                data: [...payload[interval].data],
              },
            }
          : {
              ...integration_data_history_per_data_type,
              [interval]: {
                ...(integration_data_history_per_data_type
                  ? (integration_data_history_per_data_type as DataHistoryPerDataTypesPerIntervalType)[
                      interval as keyof DataHistoryPerDataTypesPerIntervalType
                    ]
                  : []),
                hasReachTheEnd: true,
              },
            };

      const new_integration_data_history_per_data_type = payload.isInitialFetch
        ? {
            ...payload,
          }
        : newIntervalData;

      state.requestStatusesAndErrors[
        thunkActions.fetchDataHistoryPerDataTypes.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.integration_data_history_per_data_type = new_integration_data_history_per_data_type;
    }
  );
  builder.addCase(
    thunkActions.fetchDataHistoryPerDataTypes.rejected,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchDataHistoryPerDataTypes.typePrefix
      ] = {
        isRequesting: false,
        error: action.error,
      };
      state.integration_data_history_per_data_type = {
        day: {
          offset: 0,
          data: [],
          hasReachTheEnd: false,
        },
        week: {
          offset: 0,
          data: [],
          hasReachTheEnd: false,
        },
        month: {
          offset: 0,
          data: [],
          hasReachTheEnd: false,
        },
        year: {
          offset: 0,
          data: [],
          hasReachTheEnd: false,
        },
      };
    }
  );

  /* ------------------------------- Pending Entities ------------------------------ */
  builder.addCase(
    thunkActions.fetchPendingEntities.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchPendingEntities.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchPendingEntities.fulfilled,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchPendingEntities.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };

      const { arg } = meta;
      const { targetServiceId } = arg;

      state.pending_entities = {
        ...state.pending_entities,
        [targetServiceId]: payload,
      };
    }
  );
  builder.addCase(
    thunkActions.fetchPendingEntities.rejected,
    (state, { meta, error }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchPendingEntities.typePrefix
      ] = {
        isRequesting: false,
        error,
      };
      const { arg } = meta;
      const { targetServiceId } = arg;

      state.pending_entities = {
        ...state.pending_entities,
        [targetServiceId]: [],
      };
    }
  );

  /* ------------------------------- Queue Table ------------------------------ */
  builder.addCase(
    thunkActions.fetchIntegrationServerQueuesStatus.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchIntegrationServerQueuesStatus.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchIntegrationServerQueuesStatus.fulfilled,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchIntegrationServerQueuesStatus.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.queues_status = action.payload;
    }
  );
  builder.addCase(
    thunkActions.fetchIntegrationServerQueuesStatus.rejected,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchIntegrationServerQueuesStatus.typePrefix
      ] = {
        isRequesting: false,
        error: action.error,
      };
      state.queues_status = {};
    }
  );

  /* ------------------------------- Api Request Log / IS ------------------------------ */
  builder.addCase(
    thunkActions.fetchApiRequestLog.pending,
    (state, { meta }) => {
      const { teamId, apiRequestIds } = meta.arg;
      const actionName = thunkActions.fetchApiRequestLog.typePrefix;
      const requestState = state.requestStatusesAndErrors[actionName]
        .isRequesting as Record<number, any>;
      const errorState = state.requestStatusesAndErrors[actionName].error;

      state.requestStatusesAndErrors[actionName] = {
        isRequesting: {
          ...requestState,
          [teamId]: {
            ...requestState[teamId],
            [apiRequestIds[0]]: true,
          },
        },
        error: {
          ...errorState,
          [teamId]: {
            ...errorState[teamId],
            [apiRequestIds[0]]: {},
          },
        },
      };
    }
  );
  builder.addCase(
    thunkActions.fetchApiRequestLog.fulfilled,
    (state, { meta, payload }) => {
      const { teamId, apiRequestIds } = meta.arg;
      const currentApiRequestId = apiRequestIds[0];
      const actionName = thunkActions.fetchApiRequestLog.typePrefix;
      const requestState = state.requestStatusesAndErrors[actionName]
        .isRequesting as Record<number, any>;
      const errorState = state.requestStatusesAndErrors[actionName].error;

      // Probably useRef to store promise for aborting
      state.requestStatusesAndErrors[actionName] = {
        isRequesting: {
          ...requestState,
          [teamId]: {
            ...requestState[teamId],
            [currentApiRequestId]: false,
          },
        },
        error: {
          ...errorState,
          [teamId]: {
            ...errorState[teamId],
            [currentApiRequestId]: {},
          },
        },
      };
      state.api_request_log = {
        ...state.api_request_log,
        currentApiRequestId,
        currentMosaicTeamId: teamId,
        [teamId]: {
          [currentApiRequestId]: payload[currentApiRequestId],
        },
      };
    }
  );
  builder.addCase(
    thunkActions.fetchApiRequestLog.rejected,
    (state, { meta, error }) => {
      const { teamId, apiRequestIds } = meta.arg;
      const actionName = thunkActions.fetchApiRequestLog.typePrefix;
      const requestState = state.requestStatusesAndErrors[actionName]
        ?.isRequesting as Record<number, any>;
      const errorState = state.requestStatusesAndErrors[actionName]?.error;
      const currentApiRequestId = apiRequestIds[0];
      // Probably useRef to store promise for aborting
      state.requestStatusesAndErrors[actionName] = {
        isRequesting: {
          ...requestState,
          [teamId]: {
            ...requestState[teamId],
            [currentApiRequestId]: false,
          },
        },
        error: {
          ...errorState,
          [teamId]: {
            ...errorState[teamId],
            [currentApiRequestId]: error,
          },
        },
      };
      state.api_request_log = {
        ...state.api_request_log,
        currentApiRequestId: apiRequestIds[0],
        currentMosaicTeamId: teamId,
      };
    }
  );

  /* ------------------------------- Network log (Agent) ------------------------------ */
  builder.addCase(thunkActions.fetchNetworkLog.pending, (state, { meta }) => {
    const { mosaicTeamId, sessionId } = meta.arg;
    const actionName = thunkActions.fetchNetworkLog.typePrefix;
    const requestState = state.requestStatusesAndErrors[actionName]
      .isRequesting as Record<number, any>;
    const errorState = state.requestStatusesAndErrors[actionName].error;

    state.requestStatusesAndErrors[actionName] = {
      isRequesting: {
        ...requestState,
        [mosaicTeamId]: {
          ...requestState[mosaicTeamId],
          [sessionId]: true,
        },
      },
      error: {
        ...errorState,
        [mosaicTeamId]: {
          ...errorState[mosaicTeamId],
          [sessionId]: {},
        },
      },
    };
  });
  builder.addCase(
    thunkActions.fetchNetworkLog.fulfilled,
    (state, { meta, payload }) => {
      const { mosaicTeamId, sessionId } = meta.arg;
      const actionName = thunkActions.fetchNetworkLog.typePrefix;
      const requestState = state.requestStatusesAndErrors[actionName]
        .isRequesting as Record<number, any>;
      const errorState = state.requestStatusesAndErrors[actionName].error;

      // Probably useRef to store promise for aborting
      state.requestStatusesAndErrors[actionName] = {
        isRequesting: {
          ...requestState,
          [mosaicTeamId]: {
            ...requestState[mosaicTeamId],
            [sessionId]: false,
          },
        },
        error: {
          ...errorState,
          [mosaicTeamId]: {
            ...errorState[mosaicTeamId],
            [sessionId]: {},
          },
        },
      };
      state.network_log = {
        ...state.api_request_log,
        currentSessionId: sessionId,
        currentMosaicTeamId: mosaicTeamId,
        [mosaicTeamId]: {
          [sessionId]: payload,
        },
      };
    }
  );
  builder.addCase(
    thunkActions.fetchNetworkLog.rejected,
    (state, { meta, error }) => {
      const { mosaicTeamId, sessionId } = meta.arg;
      const actionName = thunkActions.fetchNetworkLog.typePrefix;
      const requestState = state.requestStatusesAndErrors[actionName]
        .isRequesting as Record<number, any>;
      const errorState = state.requestStatusesAndErrors[actionName].error;

      // Probably useRef to store promise for aborting
      state.requestStatusesAndErrors[actionName] = {
        isRequesting: {
          ...requestState,
          [mosaicTeamId]: {
            ...requestState[mosaicTeamId],
            [sessionId]: false,
          },
        },
        error: {
          ...errorState,
          [mosaicTeamId]: {
            ...errorState[mosaicTeamId],
            [sessionId]: error,
          },
        },
      };
      state.network_log = {
        ...state.api_request_log,
        currentSessionId: sessionId,
        currentMosaicTeamId: mosaicTeamId,
      };
    }
  );

  /* ------------------------------- Network Error log (IS) ------------------------------ */
  builder.addCase(
    thunkActions.fetchTeamNetworkErrorLog.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamNetworkErrorLog.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchTeamNetworkErrorLog.fulfilled,
    (state, action) => {
      const {
        meta: { arg },
        payload,
      } = action;
      const { isInitialFetch } = arg;
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamNetworkErrorLog.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.team_network_error_log = isInitialFetch
        ? payload
        : [...state.team_network_error_log, ...payload];
    }
  );
  builder.addCase(
    thunkActions.fetchTeamNetworkErrorLog.rejected,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamNetworkErrorLog.typePrefix
      ] = {
        isRequesting: false,
        error: action.error,
      };
      state.team_network_error_log = [];
    }
  );

  /* ------------------------------- Agent Error Log ------------------------------ */
  builder.addCase(
    thunkActions.fetchTeamAgentErrorLog.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamAgentErrorLog.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchTeamAgentErrorLog.fulfilled,
    (state, action) => {
      const {
        meta: { arg },
        payload,
      } = action;
      const { isInitialFetch } = arg;
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamAgentErrorLog.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.team_agent_error_log = isInitialFetch
        ? payload
        : [...state.team_agent_error_log, ...payload];
    }
  );
  builder.addCase(
    thunkActions.fetchTeamAgentErrorLog.rejected,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamAgentErrorLog.typePrefix
      ] = {
        isRequesting: false,
        error: action.error,
      };
      state.team_agent_error_log = [];
    }
  );
  /* ------------------------------- Single Entity ------------------------------ */
  builder.addCase(thunkActions.fetchSingleEntity.pending, (state, action) => {
    state.requestStatusesAndErrors[
      thunkActions.fetchSingleEntity.typePrefix
    ] = {
      isRequesting: true,
      error: {},
    };
  });
  builder.addCase(thunkActions.fetchSingleEntity.fulfilled, (state, action) => {
    state.requestStatusesAndErrors[
      thunkActions.fetchSingleEntity.typePrefix
    ] = {
      isRequesting: false,
      error: {},
    };
    state.single_entity = action.payload;
  });
  builder.addCase(thunkActions.fetchSingleEntity.rejected, (state, action) => {
    state.requestStatusesAndErrors[
      thunkActions.fetchSingleEntity.typePrefix
    ] = {
      isRequesting: false,
      error: action.error,
    };
  });
  /* ------------------------------- Last Time Sync ------------------------------ */
  builder.addCase(thunkActions.fetchLastTimeSync.pending, (state, action) => {
    state.requestStatusesAndErrors[
      thunkActions.fetchLastTimeSync.typePrefix
    ] = {
      isRequesting: true,
      error: {},
    };
  });
  builder.addCase(thunkActions.fetchLastTimeSync.fulfilled, (state, action) => {
    state.requestStatusesAndErrors[
      thunkActions.fetchLastTimeSync.typePrefix
    ] = {
      isRequesting: false,
      error: {},
    };
    state.last_time_sync = makeLastTimeSyncData(action.payload);
  });
  builder.addCase(thunkActions.fetchLastTimeSync.rejected, (state, action) => {
    state.requestStatusesAndErrors[
      thunkActions.fetchLastTimeSync.typePrefix
    ] = {
      isRequesting: false,
      error: action.error,
    };
    state.last_time_sync = {};
  });
  /* -------------------------- Integration Settings -------------------------- */
  builder.addCase(thunkActions.fetchIntegrations.pending, (state, action) => {
    state.requestStatusesAndErrors[
      thunkActions.fetchIntegrations.typePrefix
    ] = {
      isRequesting: true,
      error: {},
    };
  });
  builder.addCase(thunkActions.fetchIntegrations.fulfilled, (state, action) => {
    state.requestStatusesAndErrors[
      thunkActions.fetchIntegrations.typePrefix
    ] = {
      isRequesting: false,
      error: {},
    };

    action.payload.forEach((integration: Integration) => {
      const teamId = integration.mosaicTeamId;
      const targetServiceId = integration.id;

      if (!state.integrationsByTeamId[teamId]) {
        state.integrationsByTeamId[teamId] = {};
      }

      state.integrationsByTeamId[teamId][targetServiceId] = integration;
    });
  });
  builder.addCase(thunkActions.fetchIntegrations.rejected, (state, action) => {
    state.requestStatusesAndErrors[
      thunkActions.fetchIntegrations.typePrefix
    ] = {
      isRequesting: false,
      error: action.error,
    };
  });
  /* -------------------------- Update Integration Notes -------------------------- */
  builder.addCase(
    thunkActions.updateIntegrationNotes.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.updateIntegrationNotes.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.updateIntegrationNotes.fulfilled,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.updateIntegrationNotes.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      const { notes } = payload;
      const { arg } = meta;
      const { targetServiceId, skipAlertOnSuccess, teamId } = arg;

      if (state.integrationsByTeamId[teamId]?.[targetServiceId]) {
        state.integrationsByTeamId[teamId][targetServiceId].notes = notes;
      }
      if (!skipAlertOnSuccess) {
        alert("Notes updated successfully.");
      }
    }
  );
  builder.addCase(
    thunkActions.updateIntegrationNotes.rejected,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.updateIntegrationNotes.typePrefix
      ] = {
        isRequesting: false,
        error: action.error,
      };
      alert("Failed to update notes. Please try again.");
    }
  );
});
