import useSandboxes, {
  SandboxIsoStateIdsValues,
} from "SandboxModule/hooks/useSandboxes";
import { useEffect, useMemo, useCallback } from "react";
import { useDynamicModal } from "components/DynamicModalProvider/useDynamicModal";
import SimpleMenu from "components/SimpleMenu";
import { ItemFilter, filterItemWithKeywords } from "FiltersModule/utils";
import Box from "@material-ui/core/Box";
import { checkIsSandboxAccessible } from "utils/sandbox";
import orderBy from "lodash/orderBy";
import { RealmTypes } from "RealmModule/constants/realm";
import { Realm, SimpleMenuItem } from "types";
import { getRealmLabel } from "RealmModule/utils/realm";
import { makeRealmLabel } from "SandboxModule/utils";
import { useStateEffect } from "hooks/useStateEffect";

type SandboxMenuItem = SimpleMenuItem & {
  value: string;
  name: Nullable<string>;
  isHeader?: boolean;
  isDefault?: boolean;
};

type SandboxHeaderMenuItem = SandboxMenuItem & { isHeader: true };

const makeHeaderMenuItem = (label: string): SandboxHeaderMenuItem => ({
  isHeader: true,
  label,
  disableButton: true,
  shouldAlwaysShow: true,
  className: "group-header",
  value: "sb-header",
  name: "sb-header",
});

const itemFilter: ItemFilter = ({ searchWords, item }) =>
  filterItemWithKeywords({
    searchWords,
    item,
    filterKeysArray: ["value", "name"],
  });

export const useSandboxDropdown = ({
  isoStateId,
  realmId,
}: {
  isoStateId: SandboxIsoStateIdsValues;
  /**
   * Initial selected realm id.
   * If not provided, the default realm will be selected.
   */
  realmId?: Realm["realm_id"];
}) => {
  const { openModal } = useDynamicModal();
  const {
    fetchSandboxes,
    getSandboxesByIsoState,
    getSandbox,
    getSelectedRailsSandbox,
    getDefaultRealm,
    handleSelectGlobalRailsSandbox,
  } = useSandboxes();

  const selectedGlobalRealm = getSelectedRailsSandbox() ?? null;

  const defaultRealm = getDefaultRealm();

  const initialRealm = realmId
    ? getSandbox(realmId) ?? selectedGlobalRealm
    : selectedGlobalRealm;

  const [selectedLocalSandbox, setSelectedLocalSandbox] = useStateEffect<
    Nullable<Realm>
  >(initialRealm);

  useEffect(() => {
    fetchSandboxes(isoStateId);
  }, [fetchSandboxes, isoStateId]);

  const {
    isRequesting: isFetchingSandboxes,
    ordersByGroup,
  } = getSandboxesByIsoState(isoStateId);

  const menuItems = useMemo(() => {
    const items: SandboxMenuItem[] = [];

    // Add Default realm to the item list
    if (defaultRealm) {
      const defaultRailsSandboxMenuItem: SandboxMenuItem = {
        label: (
          <span>
            {defaultRealm.realm_id}{" "}
            <Box fontSize={"12px"} color={"red"} display={"inline-block"}>
              | DO NOT SHARE
            </Box>
          </span>
        ),
        disableButton: isFetchingSandboxes,
        value: defaultRealm.realm_id,
        name: defaultRealm.team_name,
        isDefault: true, //to separate default realm from 'prod' realm in stanely
      };

      const defaultSandboxHeaderMenuItem = makeHeaderMenuItem(
        getRealmLabel(RealmTypes.sandbox)
      );

      items.push(defaultRailsSandboxMenuItem);
      items.push(defaultSandboxHeaderMenuItem);
    }

    //Other relams
    Object.values(RealmTypes).forEach((realmType) => {
      const order = ordersByGroup[realmType];
      if (order?.length) {
        // Add header
        if (realmType !== RealmTypes.sandbox) {
          const headerMenuItem: SandboxHeaderMenuItem = makeHeaderMenuItem(
            getRealmLabel(realmType)
          );
          items.push(headerMenuItem);
        }

        // Add realm items
        const realmMenuItems =
          order.reduce<SandboxMenuItem[]>((acc, id) => {
            const sb = getSandbox(id);

            if (sb && checkIsSandboxAccessible(sb.status)) {
              acc.push({
                label: makeRealmLabel({
                  realmId: sb.realm_id,
                  teamName: sb.team_name,
                }),
                disableButton: isFetchingSandboxes,
                value: sb.realm_id,
                name: sb.team_name?.toLocaleLowerCase() || "",
              });
            }
            return acc;
          }, []) || [];

        items.push(...orderBy(realmMenuItems, ["value"], ["asc"]));
      }
    });
    return items;
  }, [defaultRealm, getSandbox, isFetchingSandboxes, ordersByGroup]);

  const openRealmDropdown = useCallback(
    ({
      target,
      isSelectingGlobalRealm = false,
      onSelect,
      formatItems,
    }: {
      target: HTMLElement;
      isSelectingGlobalRealm?: boolean;
      onSelect?: (args: { realm: Realm }) => void;
      formatItems?: (args: SandboxMenuItem[]) => SandboxMenuItem[];
    }) => {
      const onClick = ({ item }: { item: SandboxMenuItem }) => {
        const realm = getSandbox(item.value);
        if (realm) {
          if (isSelectingGlobalRealm) {
            handleSelectGlobalRailsSandbox({ sandbox: realm });
            return;
          }

          // Local select
          onSelect?.({ realm });
          setSelectedLocalSandbox(realm);
        }
      };

      openModal({
        modal: ({ isOpen, toggle }) => {
          return (
            <SimpleMenu<SandboxMenuItem>
              anchorEl={target}
              items={formatItems ? formatItems(menuItems) : menuItems}
              handleClickV2={onClick}
              handleClose={toggle}
              showSearchBar
              itemFilter={itemFilter}
              menuMaxHeight={316}
            />
          );
        },
      });
    },
    [
      getSandbox,
      handleSelectGlobalRailsSandbox,
      menuItems,
      openModal,
      setSelectedLocalSandbox,
    ]
  );

  return {
    openRealmDropdown,
    selectedGlobalRealm,
    selectedLocalSandbox,
    handleSelectGlobalRailsSandbox,
  };
};
