import { createReducer } from "@reduxjs/toolkit";
import { Tenant } from "TenantModule/types";
import keyBy from "lodash/keyBy";
import * as thunkActions from "thunk";
import {
  defaultInitialIsoState,
  DefaultIsoState,
} from "IsoStateModule/constants";
import { TenantIsoStateIds } from "TenantModule/constants";

export type InitialStateType = {
  defaultTenant: Tenant | undefined;
  order: Tenant["id"][];
  tenantHash: Record<Tenant["id"], Tenant>;
  isoStates: Record<string, DefaultIsoState<Tenant["id"]>>;
};

export const initialState: InitialStateType = {
  defaultTenant: undefined,
  order: [],
  tenantHash: {},
  isoStates: {},
};

export default createReducer(initialState, (builder) => {
  builder.addCase(
    thunkActions.fetchTenants.fulfilled,
    (state, { payload, meta: { arg } }) => {
      const { isoStateId } = arg.meta || {};
      const { tenantIds, defaultTenant } = payload.reduce<{
        tenantIds: number[];
        defaultTenant: InitialStateType["defaultTenant"];
      }>(
        (acc, tenant) => {
          if (tenant.isDefault) {
            acc.defaultTenant = tenant;
          }

          acc.tenantIds.push(tenant.id);

          return acc;
        },
        {
          tenantIds: [],
          defaultTenant: undefined,
        }
      );

      if (isoStateId) {
        // Check if isoState of the isoStateId exists
        const isoState = state.isoStates[isoStateId];
        if (isoState) {
          // update the order
          isoState.topLevelOrder = tenantIds;
        } else {
          const newIsoState = {
            ...defaultInitialIsoState,
            topLevelOrder: tenantIds,
          };

          state.isoStates[isoStateId] = newIsoState;
        }
      } else {
        // Update Global Order
        state.order = tenantIds;
      }

      state.tenantHash = {
        ...state.tenantHash,
        ...keyBy(payload, "id"),
      };

      if (!state.defaultTenant && defaultTenant)
        state.defaultTenant = defaultTenant;
    }
  );
  builder.addCase(thunkActions.createTenant.fulfilled, (state, { payload }) => {
    state.tenantHash[payload.id] = payload;
    Object.keys(state.isoStates).forEach((isoStateId) => {
      state.isoStates[isoStateId].topLevelOrder.push(payload.id);
    });
    state.order.push(payload.id);
  });
  builder.addCase(thunkActions.updateTenant.fulfilled, (state, { payload }) => {
    state.tenantHash[payload.id] = payload;
  });
  builder.addCase(
    thunkActions.archiveTenant.fulfilled,
    (state, { meta, payload }) => {
      state.tenantHash[payload.id] = payload;

      [TenantIsoStateIds.ActiveTenantTable].forEach((isoStateId) => {
        const isoState = state.isoStates[isoStateId];

        if (isoState) {
          isoState.topLevelOrder = isoState.topLevelOrder.filter(
            (tenantId) => tenantId !== payload.id
          );
        }
      });
    }
  );
});
