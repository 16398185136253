import { FC } from "react";
import { DataCellProps } from "../types";
import { Edit } from "@material-ui/icons";
import styled from "styled-components";
import EllipsisText, { TextContainer } from "components/EllipsisText";

const StyledEditIcon = styled(Edit)`
  font-size: 1rem;
  color: #376fd0;
`;

const MemberNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  ${StyledEditIcon} {
    visibility: hidden;
  }

  cursor: pointer;
  &:hover {
    ${StyledEditIcon} {
      visibility: visible;
    }
  }

  ${TextContainer} {
    flex: 1;
    width: 0;
  }
`;

export const TeamMemberNameCell: FC<DataCellProps> = ({
  customRowProps: { onRowEditClick },
  member,
}) => {
  return (
    <MemberNameContainer onClick={onRowEditClick(member.account_id)}>
      <EllipsisText text={member.name}>
        {member.name || "Not given"}
      </EllipsisText>
      <StyledEditIcon />
    </MemberNameContainer>
  );
};
