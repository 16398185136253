import { da } from "date-fns/locale";
import keyBy from "lodash/keyBy";
import { getRandomColor } from "utils";

/* ----------------------- Integration data type keys ----------------------- */

export enum DataType {
  Activity = "activity",
  ActivityPhase = "activity_phase",
  Client = "client",
  Employee = "employee",
  Phase = "phase",
  Project = "project",
  TimeEntry = "time_entry",
  WorkPlan = "work_plan",
  BudgetEstimate = "budget_estimate",
  Task = "task",
  Events = "events",
  Scope = "scope",
  Calendar = "calendar",
  CalendarEvent = "calendar_event",
  CostRate = "cost_rate",
  BillRate = "bill_rate",
  Rate = "rate",
  MemberProjectRate = "member_project_rate",
  Role = "role",
  Invoice = "invoice",
  Department = "department",
  MemberProjectRole = "member_project_role",
  MemberRole = "member_role",
  PTO = "pto",
  Portfolio = "portfolio",
  Office = "office",
  Holiday = "holiday",
  EntityRate = "entity_rate",
  RateGroup = "rate_group",
}

export const dataTypes = [
  {
    label: "Activity",
    value: DataType.Activity,
    color: getRandomColor(),
  },
  {
    label: "Work Category",
    value: DataType.ActivityPhase,
    color: getRandomColor(),
  },
  {
    label: "Client",
    value: DataType.Client,
    color: getRandomColor(),
  },
  {
    label: "Employee",
    value: DataType.Employee,
    color: getRandomColor(),
  },
  {
    label: "Phase",
    value: DataType.Phase,
    color: getRandomColor(),
  },
  {
    label: "Project",
    value: DataType.Project,
    color: getRandomColor(),
  },
  {
    label: "Time Entry",
    value: DataType.TimeEntry,
    color: getRandomColor(),
  },
  {
    label: "Work Plan",
    value: DataType.WorkPlan,
    color: getRandomColor(),
  },
  {
    label: "Budget Estimate",
    value: DataType.BudgetEstimate,
    color: getRandomColor(),
  },
  {
    label: "Task",
    value: DataType.Task,
    color: getRandomColor(),
  },
  {
    label: "Events",
    value: DataType.Events,
    color: getRandomColor(),
  },
  {
    label: "Scope",
    value: DataType.Scope,
    color: getRandomColor(),
  },
  {
    label: "Calendar",
    value: DataType.Calendar,
    color: getRandomColor(),
  },
  {
    label: "Calendar Events",
    value: DataType.CalendarEvent,
    color: getRandomColor(),
  },
  {
    label: "Rate",
    value: DataType.Rate,
    color: getRandomColor(),
  },
  {
    label: "Bill Rate",
    value: DataType.BillRate,
    color: getRandomColor(),
  },
  {
    label: "Member Project Rate",
    value: DataType.MemberProjectRate,
    color: getRandomColor(),
  },
  {
    label: "Cost Rate",
    value: DataType.CostRate,
    color: getRandomColor(),
  },
  {
    label: "Role",
    value: DataType.Role,
    color: getRandomColor(),
  },
  {
    label: "Invoice",
    value: DataType.Invoice,
    color: getRandomColor(),
  },
  {
    label: "Department",
    value: DataType.Department,
    color: getRandomColor(),
  },
  {
    label: "Member Project Role",
    value: DataType.MemberProjectRole,
    color: getRandomColor(),
  },
  {
    label: "Member Role",
    value: DataType.MemberRole,
    color: getRandomColor(),
  },
  {
    label: "PTO",
    value: DataType.PTO,
    color: getRandomColor(),
  },
  {
    label: "Portfolio",
    value: DataType.Portfolio,
    color: getRandomColor(),
  },
  {
    label: "Office",
    value: DataType.Office,
    color: getRandomColor(),
  },
  {
    label: "Holiday",
    value: DataType.Holiday,
    color: getRandomColor(),
  },
  {
    label: "Entity Rate",
    value: DataType.EntityRate,
    color: getRandomColor(),
  },
  {
    label: "Rate Group",
    value: DataType.RateGroup,
    color: getRandomColor(),
  },
];

export const DATA_TYPES = dataTypes.map((dataType) => dataType.value);

/** This is integration data type, not Backend data type
 * Do not export, please use getDataType
 */
const dataTypesHash = keyBy(dataTypes, "value");

export const getDataType = (dataTypeVal: DataType) =>
  dataTypesHash[dataTypeVal] || {};

/* ------------------------- Backend Data type keys ------------------------- */
export const BACKEND_DATA_TYPES: Array<string> = ["mosaic-time_entry"];

export const backendDataTypesItems = [
  {
    label: "Time Entry",
    value: "mosaic-time_entry",
  },
];

export const backendDataTypesItemsArray = backendDataTypesItems.map(
  (i) => i.value
);

export const backendDataTypesHash = keyBy(backendDataTypesItems, "value");
