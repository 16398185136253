import { Box } from "@material-ui/core";
import { useDynamicModal } from "components/DynamicModalProvider/useDynamicModal";
import SimpleModal from "components/SimpleModal";
import { useCallback } from "react";
import { useTenants } from "./useTenants";

export const useArchiveTenantConfirmationModal = () => {
  const { openModal } = useDynamicModal();
  const { getTenant, bulkArchiveTenant } = useTenants();

  const openArchiveTenantConfirmationModal = useCallback(
    (args: { tenantIds: number[]; onArchiveTenantSuccess?: VoidFunction }) => {
      openModal({
        modal: ({ isOpen, toggle }) => {
          const onConfirmArchive = () => {
            bulkArchiveTenant({ ...args });
          };
          const tenants = args.tenantIds.flatMap(
            (tenantId) => getTenant({ tenantId }) || []
          );

          return (
            <SimpleModal
              onClose={toggle}
              open
              onConfirm={onConfirmArchive}
              closeOnConfirm
              Body={
                <div>
                  <div>
                    You are about to archive <b>{tenants.length}</b>{" "}
                    {tenants.length > 1 ? "Tenants" : "Tenant"}.
                  </div>
                  <Box component="ul" maxHeight={350} overflow="auto">
                    {tenants.map((tenant) => {
                      return (
                        <li key={tenant.id}>
                          Id: {tenant.id}, Name: {tenant.tenantName}
                        </li>
                      );
                    })}
                  </Box>
                  <Box margin="1rem 0">
                    <b>Tenant are non-recoverable after archived!</b>
                  </Box>
                </div>
              }
            />
          );
        },
      });
    },
    [bulkArchiveTenant, getTenant, openModal]
  );
  return {
    openArchiveTenantConfirmationModal,
  };
};
