import React, { useState } from "react";
import { Select } from "@material-ui/core";
import keyBy from "lodash/keyBy";
import styled from "styled-components/macro";

const StyledSelect = styled(Select)`
  border-style: solid solid none solid;
  border-width: 1px;
  border-radius: 5px;
`;

type Props = {
  onChange: (values: string[]) => void;
  optionList: {
    label: string;
    value: string;
  }[];
  values: string[];
  name: string;
  required?: boolean;
  isMultiSelect?: boolean;
  className?: string;
};

const ListSelect = ({
  onChange,
  optionList,
  name,
  className = "",
  isMultiSelect = false,
  values = [],
  required = false,
}: Props) => {
  const [_values, setValues] = useState<string[]>(values);
  const optionHash = keyBy(optionList, "value");

  const onSingleSelect = (event: React.ChangeEvent<any>) => {
    const selectedValue = event.target.value;
    if (!values.includes(selectedValue)) {
      setValues([selectedValue]);
      onChange([selectedValue]);
    } else {
      setValues([]);
    }
  };

  const onMultiSelect = (event: React.ChangeEvent<any>) => {
    const selectedValue = event.target.value;
    if (!_values.includes(selectedValue)) {
      const newValue = [..._values];
      newValue.push(selectedValue);
      setValues(newValue);
      onChange(newValue);
    } else {
      const newValue = _values.filter((option) => option !== selectedValue);
      setValues(newValue);
      onChange(newValue);
    }
  };

  return (
    <StyledSelect
      fullWidth
      required={required}
      className={className}
      name={name}
      multiple
      native
      value={_values}
    >
      {optionList.map((option) => (
        <option
          key={option.value}
          value={option.value}
          onClick={isMultiSelect ? onMultiSelect : onSingleSelect}
        >
          {option.label}
        </option>
      ))}
    </StyledSelect>
  );
};

export default ListSelect;
