import moment from "moment";
import { Integration } from "types";

// Assumption is there is only at most 1 active integration per target service id group (checked)
export const findMostRecentlyArchivedIntegrationAndOnlyActiveIntegrationInTargetServiceIdGroup = (
  integrationsInTargetServiceIdGroup?: Integration[]
) => {
  let activeIntegration: Integration | undefined;
  let mostRecentlyArchivedIntegration: Integration | undefined;

  if (!integrationsInTargetServiceIdGroup)
    return {
      activeIntegration,
      mostRecentlyArchivedIntegration,
    };

  integrationsInTargetServiceIdGroup.forEach((integration) => {
    if (!integration.isArchived) {
      // found active integration
      activeIntegration = integration;
    } else {
      // unarchived
      if (!mostRecentlyArchivedIntegration) {
        // Defined if not exist yet
        mostRecentlyArchivedIntegration = integration;
      } else {
        // already exist, check which one is most recently archived

        mostRecentlyArchivedIntegration = moment(
          integration.archivedAt
        ).isAfter(mostRecentlyArchivedIntegration.archivedAt)
          ? integration
          : mostRecentlyArchivedIntegration;
      }
    }
  });

  return {
    activeIntegration,
    mostRecentlyArchivedIntegration,
  };
};
