import styled from "styled-components";
import { darken } from "polished";
import {
  Box,
  Button,
  Card as MuiCard,
  Chip as MuiChip,
  TableRow,
  Typography as MuiTypography,
  InputBase,
} from "@material-ui/core";
import { spacing, SpacingProps } from "@material-ui/system";

export const SearchWrapper = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

export const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

export const StyledSearchBar = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(2.5)}px;
    width: 400px;
  }
`;

export const Card = styled(MuiCard)(spacing);

export const Chip = styled(MuiChip)<{ rgbcolor: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) => props.rgbcolor};
  color: ${(props) => props.theme.palette.common.white};
`;

export const ChartWrapper = styled.div`
  height: 280px;
  position: relative;
`;

export const Spacer = styled.div(spacing);

export interface TypographyPropsType extends SpacingProps {
  component?: string;
}

export const Typography = styled(MuiTypography)<TypographyPropsType>(spacing);

export const ClickableRow = styled(TableRow)`
  cursor: pointer;
  white-space: pre;
`;

/** Should provide a max-width for ellipsi */
export const EllipsisDiv = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const WordBreakDiv = styled.div<{
  width?: string;
}>`
  word-break: break-all;
  width: ${({ width }) => width || "20rem"};
`;

export const WhiteBox = styled(Box)`
  text-align: center;
  border-radius: 10px;
  white-space: nowrap;
  color: black;
  border: 1px solid gray;
  background-color: white;
  padding: 5px 8px;
`;

export const StyledSimpleCard = styled(MuiCard)`
  padding: 1rem;
`;

export const StyledDefaultPrimaryButton = styled(Button).attrs({
  color: "primary",
})``;
