export enum QueryKeyType {
  List = "lists",
  Detail = "details",
}

export interface RequiredQueryKeyFilter {
  /**
   * Required to avoid query key collision
   * The idea is to remove the need for introducing newer query key
   * The more query key generator we have, the more complex the invalidation process will be
   *
   * This value is usually the name of the query hook that generates this key
   */
  identifier: string;
}

export const createDefaultQueryKeysGenerator = <
  QueryListKeyFilter,
  QueryDetailKeyFilter
>({
  keyDataType,
}: {
  keyDataType: string;
}) => {
  const queryKeys = {
    all: [keyDataType] as const,

    lists: () => [queryKeys.all[0], { keyType: QueryKeyType.List }] as const,

    list: (filter: QueryListKeyFilter) => [
      queryKeys.all[0],
      { ...queryKeys.lists()[1], ...filter },
    ],

    listInvalidation: (filter: Partial<QueryListKeyFilter>) => [
      queryKeys.all[0],
      { ...queryKeys.lists()[1], ...filter },
    ],

    details: () =>
      [queryKeys.all[0], { keyType: QueryKeyType.Detail }] as const,

    detail: (filter: QueryDetailKeyFilter) => [
      queryKeys.all[0],
      { ...queryKeys.details()[1], ...filter },
    ],

    detailInvalidation: (filter: Partial<QueryDetailKeyFilter>) => [
      queryKeys.all[0],
      { ...queryKeys.details()[1], ...filter },
    ],
  };

  return queryKeys;
};
