type RowType = {
  [key: string]: string | number;
  id: number;
  name: string;
  created_at: string;
  last_user_activity_at: string;
  archived_members_count: number;
  actives_count: number;
  admins_count: number;
  financial_managers_count: number;
  workload_planners_count: number;
  guests_count: number;
  boards_count: number;
  projects_count: number;
  project_tasks_count: number;
  time_entries_count: number;
};

export const getReportsTeamSearchValue = (search_text: string) =>
  !isNaN(+search_text)
    ? { ids: [parseInt(search_text)], search_text: '' }
    : { ids: [], search_text };
