import { ValueOf } from "type-fest";

export enum PromiseAllSettledStatus {
  //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled
  Fulfilled = "fulfilled",
  Rejected = "rejected",
}

export type PromiseAllSettledStatusType = ValueOf<
  typeof PromiseAllSettledStatus
>;
