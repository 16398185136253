import { createActionTypes } from "actionCreators";

export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILED = "SIGN_IN_FAILED";

export const SIGN_IN = createActionTypes("SIGN_IN");
export const RESET_PASSWORD = createActionTypes("RESET_PASSWORD");
export const CONFIRM_PASSWORD_RESET = createActionTypes(
  "CONFIRM_PASSWORD_RESET"
);
export const FETCH_ME = createActionTypes("FETCH_ME");

export const SIGN_OUT = "SIGN_OUT";
export const LOGIN_TO_TENANT = "LOGIN_TO_TENANT";

export const RESET_AUTH = "RESET_AUTH";

export enum RedirectKeys {
  AccessToken = "access_token"
}
