import { useMutation } from "@tanstack/react-query";
import { generateRealmId } from "../services";
import { useSelector } from "react-redux";
import { selectAuthToken } from "selectors";
import { AxiosResponse } from "axios";
import {
  GenerateRealmIdErrorResponse,
  GenerateRealmIdParams,
  GenerateRealmIdSuccessResponse,
} from "SandboxModule/types";

export const useRealmIdGeneration = ({
  onSuccess,
}: {
  onSuccess?: ({
    response,
    initialParams,
  }: {
    response: AxiosResponse<GenerateRealmIdSuccessResponse>;
    initialParams: GenerateRealmIdParams;
  }) => void;
}) => {
  const token = useSelector(selectAuthToken);

  const mutation = useMutation<
    AxiosResponse<GenerateRealmIdSuccessResponse>,
    AxiosResponse<GenerateRealmIdErrorResponse>,
    GenerateRealmIdParams
  >({
    mutationFn: async (params) => {
      if (!token) {
        throw new Error("No auth token available");
      }
      return generateRealmId({ params, token });
    },
    onSuccess: (data, variable) =>
      onSuccess?.({ response: data, initialParams: variable }),
    onError: (error) => {
      alert(
        `Failed to generate realm. Please try again. ${error.data.message}`
      );
    },
  });
  return {
    generateRealmId: mutation.mutate,
    isGeneratingRealmId: mutation.isLoading,
  };
};
