import { ActionInterface, FilterType } from "types";
import * as constants from "@constants";
import { REPORTS_VIEW_TYPES } from "@constants";

export type FiltersStateType = Record<number, FilterType>;

const INITIAL_STATE: FiltersStateType = {
  [constants.filterIds.pendingEntities]: {
    id: constants.filterIds.pendingEntities,
    offset: 0,
    limit: 100,
    orderDirection: "asc",
    orderBy: "id",
    page: 0,
  },
  [constants.filterIds.agentErrorLog]: {
    id: constants.filterIds.agentErrorLog,
    offset: 0,
    limit: 100,
    orderDirection: "asc",
    orderBy: "mosaic_team_id",
    page: 0,
  },
  [constants.filterIds.teamAgentErrorLog]: {
    id: constants.filterIds.teamAgentErrorLog,
    offset: 0,
    limit: 100,
    orderDirection: "asc",
    orderBy: "mosaic_team_id",
    page: 0,
  },
  [constants.filterIds.generalNetworkErrorLog]: {
    id: constants.filterIds.generalNetworkErrorLog,
    /** BE Pagination */
    offset: 0,
    limit: 100,

    /** FE Sorting */
    orderDirection: "asc",
    orderBy: "id",
    page: 0,
  },
  [constants.filterIds.teamNetworkErrorLog]: {
    id: constants.filterIds.teamNetworkErrorLog,
    /** BE Pagination */
    offset: 0, // limit * current Page
    limit: 100,

    // offset start index, end index, offset + limit

    /** FE Sorting */
    orderDirection: "asc",
    orderBy: "id",
    page: 0,
  },
  [constants.filterIds.networkLogByTeam]: {
    id: constants.filterIds.networkLogByTeam,
    offset: 0,
    limit: 100,
    orderDirection: "asc",
    orderBy: "id",
    page: 0,
  },
  [constants.filterIds.apiNetworkLog]: {
    id: constants.filterIds.apiNetworkLog,
    offset: 0,
    limit: 50,
    orderDirection: "asc",
    orderBy: "id", // ?
    page: 0,
  },
  [constants.filterIds.teamMemberTable]: {
    id: constants.filterIds.teamMemberTable,
    offset: 0,
    limit: 50,
    orderDirection: "asc",
    orderBy: "account_id",
    page: 0,
  },
  [constants.filterIds.superAdminTable]: {
    id: constants.filterIds.superAdminTable,
    offset: 0,
    limit: 50,
    orderDirection: "asc",
    orderBy: "account_id",
    page: 0,
  },
  [constants.filterIds.superAdminTable]: {
    id: constants.filterIds.superAdminTable,
    offset: 0,
    limit: 50,
    orderDirection: "asc",
    orderBy: "account_id",
    page: 0,
  },
  [constants.filterIds.teamsReports]: {
    id: constants.filterIds.teamsReports, // This is filter id
    offset: 0,
    limit: constants.REPORT_TEAMS_FETCH_LIMIT,
    orderDirection: "desc",
    orderBy: "account_id",
    page: 0,
    total: 0,
    isSearchMode: false,
    isSearchByName: false,
    search_text: "",
    tags: [],
    ids: [], // This is BE search
  },
  [constants.filterIds.IntegrationHealthTable]: {
    id: constants.filterIds.IntegrationHealthTable,
    offset: 0,
    limit: 5,
    orderDirection: "desc",
    orderBy: "id",
    page: 0,
    total: 90,
  },
  [constants.filterIds.teamsUtilizationReports]: {
    id: constants.filterIds.teamsUtilizationReports, // This is filter id
    offset: 0,
    limit: constants.REPORT_TEAMS_UTILIZATION_LIMIT,
    orderDirection: "desc",
    orderBy: "account_id", // not used
    page: 0,
    total: 0,
    threshold: constants.REPORT_TEAMS_UTILIZATION_THRESHOLD,
    compliant_start_period:
      constants.REPORT_TEAMS_UTILIZATION_COMPLIANT_START_DATE,
  },
  [constants.filterIds.csvImportStatusTable]: {
    id: constants.filterIds.csvImportStatusTable, // This is filter id
    offset: 0,
    limit: 10,
    orderDirection: "desc",
    orderBy: "createdAt",
    page: 0,
    total: 0,
  },
  [constants.filterIds.railsSandboxesTable]: {
    id: constants.filterIds.railsSandboxesTable, // This is filter id
    offset: 0,
    limit: 10,
    orderDirection: "desc",
    orderBy: "created_at",
    page: 0,
    total: 0,
  },
  [constants.filterIds.entityErrorTable]: {
    id: constants.filterIds.entityErrorTable, // This is filter id
    offset: 0,
    limit: 10,
    orderDirection: "desc",
    orderBy: "name",
    page: 0,
    total: 0,
  },
};

export default function reducer(
  state: FiltersStateType = INITIAL_STATE,
  action: ActionInterface
) {
  const { type } = action;

  switch (type) {
    case constants.SET_FILTERS: {
      return {
        ...state,
        [action.payload.filterId]: {
          ...state[action.payload.filterId],
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
}
