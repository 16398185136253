import { useState } from "react";
import { useCsmDropdown } from "./useCsmDropdown";
import { csmHashById } from "CsmModule/constants";

export const useCsmSelector = ({
  accountId,
}: {
  accountId: Nullable<number>;
}) => {
  const [csm, setCsm] = useState(
    accountId ? csmHashById[accountId] : undefined
  );
  const { openCsmDropdown } = useCsmDropdown();

  const onCsmSelectorClick = ({
    target,
  }: Pick<Parameters<typeof openCsmDropdown>[0], "target">) => {
    openCsmDropdown({
      target,
      onSelect: ({ csm }) => {
        setCsm(csm);
      },
    });
  };

  const clearCsm = () => {
    setCsm(undefined);
  };

  return {
    selectedCsm: csm,
    onCsmSelectorClick,
    clearCsm,
  };
};
