import React from "react";
import { InputBase, Box } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

type SearchBarProps = {
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
};

export default function SearchBar({ inputValue, setInputValue }: SearchBarProps) {
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };
  
  return (
    <Box display={"flex"}>
      <Box
        alignItems={"center"}
        justifyContent={"center"}
        display={"flex"}
        padding={2}
      >
        <SearchIcon />
      </Box>
      <InputBase
        placeholder="Search…"
        inputProps={{ "aria-label": "search" }}
        value={inputValue}
        onKeyDown={onKeyDown}
        onChange={(e) => setInputValue(e.target.value)}
      />
    </Box>
  );
}
