import {
  ArchiveTenantPayload,
  CreateTenantPayload,
  CreateTenantSecretMappingPayload,
  FetchTenantPayload,
  RegisterServiceToTenantPayload,
  UpdateTenantPayload,
} from "TenantModule/types";
import { putApiGalaxyServer, postApiGalaxyServer } from "services";

export const fetchTenants = (
  body: FetchTenantPayload,
  config = {},
  token: string
) => {
  return postApiGalaxyServer("/tenant/getAll", body, config, token);
};

export const createTenant = (
  body: CreateTenantPayload,
  config = {},
  token: string
) => postApiGalaxyServer("/tenant", body, config, token);

export const updateTenant = (
  { tenantName, ...body }: UpdateTenantPayload,
  config = {},
  token: string
) => putApiGalaxyServer(`/tenant/${tenantName}`, body, config, token);

export const archiveTenant = (
  {
    ...body
  }: {
    tenant: ArchiveTenantPayload["tenant"];
    meta: {
      name: ArchiveTenantPayload["archiver"];
    };
  },
  config = {},
  token: string
) => {
  return postApiGalaxyServer(`/tenant/archive`, body, config, token);
};

export const createTenantSecretMapping = ({
  params,
  token,
  config,
}: BaseApiParams<CreateTenantSecretMappingPayload>) => {
  return postApiGalaxyServer(
    "/tenant/createServiceDefaultDatabaseCredential",
    params,
    config,
    token
  );
};

export const registerTenantService = ({
  params: body,
  token,
  config = {},
}: BaseApiParams<RegisterServiceToTenantPayload>) => {
  return postApiGalaxyServer(
    "/tenant/registerServiceToTenant",
    body,
    config,
    token
  );
};