import { QueryClient, QueryCache } from "@tanstack/react-query";

const DEFAULT_STALE_TIME = 180000; // 3 minutes

const cache = new QueryCache();

export const queryClient = new QueryClient({
  queryCache: cache,
  defaultOptions: { queries: { staleTime: DEFAULT_STALE_TIME } },
});
