import { createAction } from "@reduxjs/toolkit";

export const setIntegrationState = createAction(
  "setIntegrationState",
  (params) => ({ payload: params })
);

export const setGeneralIntegrationState = createAction(
  "setGeneralIntegrationState",
  (params) => ({ payload: params })
);
