export const filterIds = {
  pendingEntities: 1,
  agentErrorLog: 2,
  teamAgentErrorLog: 3,
  generalNetworkErrorLog: 4,
  teamNetworkErrorLog: 5,
  networkLogByTeam: 6,
  apiNetworkLog: 7,
  teamMemberTable: 8,
  superAdminTable: 9,
  teamsReports: 10,
  IntegrationHealthTable: 11,
  teamsUtilizationReports: 12,
  csvImportStatusTable: 13,
  railsSandboxesTable: 14,
  entityErrorTable: 15,
};

export const SET_FILTERS = "SET_FILTERS";
