import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { AppStateType } from "../redux/reducers";
import { signOut, resetAuth } from "../redux/actionCreators";
import { useHistory } from "react-router-dom";

interface AuthGuardType {
  children: React.ReactNode;
}

function AuthGuard({ children }: AuthGuardType) {

  return children;
}

export default AuthGuard;
