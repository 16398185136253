import { AppStateType } from "reducers";
import { createSelector } from "reselect";
import keyBy from "lodash/keyBy";
import { RecurlyPlan } from "GlobalModule/types";

export const selectGlobalReducerState = (state: AppStateType) =>
  state.globalReducer;

export const selectIsDevMode = createSelector(
  selectGlobalReducerState,
  (state) => state.isDevMode
);

export const selectOpenRoutes = createSelector(
  selectGlobalReducerState,
  (state) => state.openRoutes
);

export const selectRecurlyPlans = createSelector(
  selectGlobalReducerState,
  (state) => {
    const formattedPlans = state.recurlyPlans.map((plan: RecurlyPlan) => ({
      value: plan.code,
      label: plan.name,
      ...plan,
    }));

    const formattedPlansHash = keyBy(formattedPlans, "code");

    return {
      plans: state.recurlyPlans,
      formattedPlans,
      plansHash: formattedPlansHash,
    };
  }
);