import { CreateTenantRequestModal } from "TenantModule/components/CreateTenantRequestModal";
import { useDynamicModal } from "components/DynamicModalProvider/useDynamicModal";
import { useCallback } from "react";

export const useCreateTenantRequestModal = () => {
  const { openModal } = useDynamicModal();

  const openCreateTenantRequestModal = useCallback(() => {
    openModal({
      modal: ({ isOpen, toggle }) => {
        return <CreateTenantRequestModal toggle={toggle} />;
      },
    });
  }, [openModal]);
  return {
    openCreateTenantRequestModal,
  };
};
