import { useCallback } from "react";
import { useMount } from "react-use";
import { useDispatch } from "react-redux";
import { fetchAvailableDumpFiles } from "SandboxModule/thunks";
import { getShouldFetchAvailableDumpFiles } from "utils";
import { useDynamicModal } from "components/DynamicModalProvider/useDynamicModal";
import { CreateSandboxModal } from "SandboxModule/components/CreateSandboxModal";
import { CreateSandboxDumpFileModal } from "SandboxModule/components/CreateSandboxDumpFileModal";

const shouldFetchAvailableDumpFiles = getShouldFetchAvailableDumpFiles();

const useCreateSandboxModal = () => {
  const dispatch = useDispatch();
  const { openModal } = useDynamicModal();

  useMount(() => {
    shouldFetchAvailableDumpFiles && dispatch(fetchAvailableDumpFiles({}));
  });

  const openCreateSandboxDumpFileModal = useCallback(() => {
    openModal({
      modal: ({ isOpen, toggle }) => {
        return <CreateSandboxDumpFileModal toggle={toggle} />;
      },
    });
  }, [openModal]);

  const openCreateSandboxModal = useCallback(() => {
    openModal({
      modal: ({ isOpen, toggle }) => {
        return <CreateSandboxModal toggle={toggle} />;
      },
    });
  }, [openModal]);

  return {
    openCreateSandboxModal,
    openCreateSandboxDumpFileModal,
  };
};

export default useCreateSandboxModal;
