import { AppStateType } from "reducers";
import { keyBy, orderBy } from "lodash";
import { createSelector } from "reselect";

import { getTeamsWithIntegration } from "utils";
import * as thunks from "thunk";
import * as constants from "@constants";

const selectReportsReducerRequestStatusesAndErrors = (state: AppStateType) =>
  state.reportsReducer.requestStatusesAndErrors;

export const selectAllReportsTeams = (state: AppStateType) =>
  state.reportsReducer.teams;

export const selectTeamsInfoMapping = (state: AppStateType) =>
  state.reportsReducer.teamsInfoMapping;

const selectReportsState = (state: AppStateType) => state.reportsReducer;

const selectTeams = createSelector(selectReportsState, (state) => state.teams);

export const selectTeamOverviewFilter = (state: AppStateType) =>
  state.filterReducer[constants.filterIds.teamsReports];

const selectTeamsUtilization = createSelector(selectReportsState, (state) => ({
  teams_historical_utilization: state.teams_historical_utilization,
  teams_historical_utilization_order: state.teams_historical_utilization_order,
}));

const selectTeamsUtilizationFilter = (state: AppStateType) =>
  state.filterReducer[constants.filterIds.teamsUtilizationReports];

export const selectReportsTeamsData = createSelector(
  selectTeams,
  selectTeamOverviewFilter,
  selectReportsReducerRequestStatusesAndErrors,
  (allTeams, filter, requestStatusesAndErrors) => {
    const { limit, offset } = filter;
    const slicedTeams = allTeams.slice(offset, limit + offset);

    return {
      data: slicedTeams,
      totalSoFar: allTeams.length,
      allTeams,
      filter,
      ...requestStatusesAndErrors[thunks.fetchReportsTeams.typePrefix],
    };
  }
);

export const selectTeamsWithIntegration = createSelector(
  [selectTeamsInfoMapping],
  (teamsInfoMapping) => {
    return teamsInfoMapping
      ? getTeamsWithIntegration(Object.values(teamsInfoMapping))
      : [];
  }
);

export const selectTeamsIdsWithTargetService = (targetService: string) =>
  createSelector(selectTeamsWithIntegration, (teamsWithIntegrations) =>
    teamsWithIntegrations
      .filter((team) => {
        const targetServicesSet = new Set(team.targetServices);
        return targetServicesSet.has(targetService);
      })
      .map((team) => team.id)
  );

export const selectTagsList = createSelector(
  selectTeamOverviewFilter,
  (teamFilter) => {
    return teamFilter.tags.map((tag: string) => ({ label: tag, value: tag }));
  }
);
