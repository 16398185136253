import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import isNil from "lodash/isNil";

import {
  Typography as MuiTypography,
  Theme,
  Tooltip,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { Alert, AlertProps } from "@material-ui/lab";
import { spacing } from "@material-ui/system";

import { ClickableRow } from "styles";

import { DATA_TYPES, integrationHealthIsoStateIds } from "@constants";

import { selectTeamHash, selectProcessedServiceMetricsData } from "selectors";

import {
  isDataTypePending,
  convertISODateToRegularDateString,
  calculateLatestDate,
  isIntegrationHealthy,
  renderIntegrationHealthStatus,
  getIntegrationHealthAlertDisplay,
  renderIntegrationHealthByRequestAndSuccessCountPercent,
} from "utils";
import { getSyncRatioAlertDisplay } from "utils/display";
import useAppNavgation from "hooks/useAppNavigation";
import useIntegrationHealthData from "hooks/useIntegrationHealthData";

import { TelemetryLastUpdatesType, TargetService } from "types";
import * as IntegrationTypes from "IntegrationModule/types";

import { StyledAlert } from "shared/styles";

const Typography = styled(MuiTypography)(spacing);

const DataTipContainer = styled.div``;

type useServiceMetricsTableProps = {
  currentService: TargetService;
  selectedInterval: string; // "day", "week", "month"
};

const calculateLatestLastUpdateDate = (
  data: Record<string, TelemetryLastUpdatesType>
): string => {
  if (!data) return "";
  const datesArray = Object.values(data).map((d) => d.updated_at);
  return convertISODateToRegularDateString(calculateLatestDate(datesArray));
};

const useServiceMetricsTable = ({
  currentService,
  selectedInterval,
}: useServiceMetricsTableProps) => {
  const dispatch = useDispatch();
  const {
    navigateToIntegrationDetailsPage,
    navigateToIntegrationHealth,
  } = useAppNavgation();
  const {
    data: processedServiceMetricsData,
    error,
    isRequesting: isFetchingServiceMetricsData,
  } = useSelector(selectProcessedServiceMetricsData);
  const teamHash = useSelector(selectTeamHash);
  const {
    data: syncRatioTableIntegrationsHealthData,
    isRequesting: isRequestingSyncRatioTableIntegrationsHealthData,
  } = useIntegrationHealthData({
    isoStateId: integrationHealthIsoStateIds.SyncRatioTable,
  });

  const isRequesting =
    isRequestingSyncRatioTableIntegrationsHealthData ||
    isFetchingServiceMetricsData;

  /** Pagination (unused) */
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(-1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const hasReachTheEnd =
  //   overallIntegrationServiceMetricsData &&
  //   overallIntegrationServiceMetricsData[currentService].hasReachTheEnd;

  // const isAtBeginning =
  //   overallIntegrationServiceMetricsData &&
  //   overallIntegrationServiceMetricsData[currentService].offset === 0;

  const onRowChangePerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const onPageChange = (
  //   event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
  //   newPage: number
  // ) => {
  //   if (!overallIntegrationServiceMetricsData) return;
  //   const {
  //     data,
  //   }: IntegrationServiceMetricsDataInterface = overallIntegrationServiceMetricsData;
  //   if (
  //     data
  //     // integrationData
  //     // integrationData.total_rows > integrationData.limit &&
  //     // newPage >= integrationData.offsetSofar
  //     // (rowsPerPage * newPage) + 1 >= integrationData.offset
  //   ) {
  //     /** Currently fetching all targetService ids */
  //     dispatch(
  //       fetchIntegrationServiceMetrics({
  //         isInitialFetch: false,
  //         offset: INTEGRATION_SERVICE_METRICS_API_DEFAULT_VALUES.OFFSET,
  //         limit: INTEGRATION_SERVICE_METRICS_API_DEFAULT_VALUES.LIMIT,
  //         interval: selectedInterval,
  //         targetService: currentService as string,
  //       })
  //     );
  //   }
  //   setPage(newPage);
  // };

  /****** Logic ******/

  const renderRows = useCallback(() => {
    return Object.values(processedServiceMetricsData).reduce(
      (acc: JSX.Element[], processedData: Record<string, any>) => {
        const { targetServiceId, mosaicTeamId, ...data } = processedData;
        const integrationHealth =
          syncRatioTableIntegrationsHealthData[currentService]?.[
            targetServiceId
          ] || {};
        // const isHealthy = isIntegrationHealthy(integrationHealth);

        const row = (
          <ClickableRow
            hover
            key={targetServiceId}
            onClick={navigateToIntegrationDetailsPage(
              mosaicTeamId,
              currentService,
              targetServiceId
            )}
          >
            <TableCell>
              {teamHash?.[mosaicTeamId]?.name || "Loading Team..."}
            </TableCell>
            <TableCell align="center">{mosaicTeamId}</TableCell>
            <TableCell align="center">{targetServiceId}</TableCell>
            <TableCell
              align="center"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                navigateToIntegrationHealth({
                  targetService: currentService,
                  targetServiceId,
                  teamId: mosaicTeamId,
                });
              }}
            >
              {renderIntegrationHealthByRequestAndSuccessCountPercent({
                integrationHealth,
                className: "icon-only",
              })}
              {/* {renderIntegrationHealthStatus({
                isHealthy,
                integrationHealth,
                className: "icon-only",
              })} */}
            </TableCell>
            {DATA_TYPES.map((dataType) => {
              const countsByDataType: IntegrationTypes.CountPerDataTypeValues =
                data[dataType] || {};

              const totalCreate = countsByDataType.totalCreate ?? 0;
              const totalUpdate = countsByDataType.totalUpdate ?? 0;
              const updateOnMosaic = countsByDataType.updateOnMosaic ?? 0;
              const updateOnTarget = countsByDataType.updateOnTarget ?? 0;
              const createOnMosaic = countsByDataType.createOnMosaic ?? 0;
              const createOnTarget = countsByDataType.createOnTarget ?? 0;

              const totalCreateAndUpdate = totalCreate + totalUpdate;

              const ratioPercentage = !totalCreateAndUpdate
                ? 0
                : 100 - Math.floor((totalCreate / totalCreateAndUpdate) * 100);

              // Spec: If all data points are 0, show empty cell
              const severity = !totalCreateAndUpdate
                ? ""
                : getSyncRatioAlertDisplay(countsByDataType, ratioPercentage);

              const displayValue = !totalCreateAndUpdate
                ? ""
                : `${totalCreate} / ${totalCreateAndUpdate}`;

              return (
                <Tooltip
                  title={
                    <React.Fragment>
                      <Typography>
                        Update on Mosaic: {updateOnMosaic}
                      </Typography>
                      <Typography>
                        Update on Target: {updateOnTarget}
                      </Typography>
                      <Typography>Total Update: {totalUpdate}</Typography>

                      <hr />

                      <Typography>
                        Create on Target: {createOnTarget}
                      </Typography>
                      <Typography>
                        Create on Mosaic: {createOnMosaic}
                      </Typography>
                      <Typography>Total Create: {totalCreate}</Typography>
                    </React.Fragment>
                  }
                >
                  <TableCell key={targetServiceId} align="left">
                    <Alert severity={severity as any}>{displayValue}</Alert>
                  </TableCell>
                </Tooltip>
              );
            })}
          </ClickableRow>
        );
        acc.push(row);
        return acc;
      },
      []
    );
  }, [
    processedServiceMetricsData,
    syncRatioTableIntegrationsHealthData,
    currentService,
    navigateToIntegrationDetailsPage,
    teamHash,
    navigateToIntegrationHealth,
  ]);

  return {
    page,
    count,
    rowsPerPage,
    // onPageChange,
    onRowChangePerPage,
    renderRows,
    isIntegrationServiceMetricsTableLoading: isRequesting,
    integrationServiceMetricsTableError: error?.message,
  };
};

export default useServiceMetricsTable;
