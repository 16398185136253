import {
  SIGN_OUT,
  SIGN_IN,
  RESET_AUTH,
  RESET_PASSWORD,
  CONFIRM_PASSWORD_RESET,
  LOGIN_TO_TENANT,
} from "@constants";
import { InitialActionInterface } from "types";
import { createEntityActions } from "actionCreators";
import { createAction } from "@reduxjs/toolkit";

export const signOut = (): InitialActionInterface => {
  return {
    type: SIGN_OUT,
  };
};

export const resetAuth = () => {
  return {
    type: RESET_AUTH,
  };
};

export const signIn = () => createEntityActions(SIGN_IN);

export const resetPassword = () => createEntityActions(RESET_PASSWORD);

export const confirmPasswordReset = () =>
  createEntityActions(CONFIRM_PASSWORD_RESET);

interface LoginToTenantPayload {
  tenantName: string;
  realmId: string;
}

export const loginToTenant = (params: any) => ({
  type: LOGIN_TO_TENANT,
  payload: params,
});
