import { createReducer } from "@reduxjs/toolkit";
import keyBy from "lodash/keyBy";
import * as CsmModuleThunks from "CsmModule/thunks/csm";
import { CsmAssignment } from "CsmModule/types/models";

export type CsmState = {
  csmAssignmentByTeamHash: Record<number, CsmAssignment | undefined>;
  isLoaded: boolean;
  isLoading: boolean;
};

const INITIAL_STATE: CsmState = {
  csmAssignmentByTeamHash: {},
  isLoaded: false,
  isLoading: false,
};

export default createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(
    CsmModuleThunks.fetchCsmAssignments.pending,
    (state, { payload }) => {
      state.isLoaded = false;
      state.isLoading = true;
    }
  );
  builder.addCase(
    CsmModuleThunks.fetchCsmAssignments.fulfilled,
    (state, { payload }) => {
      state.csmAssignmentByTeamHash = {
        ...state.csmAssignmentByTeamHash,
        ...keyBy(payload.csm_info, "team_id"),
      };
      state.isLoaded = true;
      state.isLoading = false;
    }
  );
  builder.addCase(
    CsmModuleThunks.fetchCsmAssignments.rejected,
    (state, { payload }) => {
      state.isLoaded = false;
      state.isLoading = false;
    }
  );
  /* -------------------------------------------------------------------------- */
  builder.addCase(
    CsmModuleThunks.createCsmAssignment.fulfilled,
    (state, { payload }) => {
      state.csmAssignmentByTeamHash = {
        ...state.csmAssignmentByTeamHash,
        ...keyBy(payload.csm_info, "team_id"),
      };
    }
  );
  builder.addCase(
    CsmModuleThunks.updateCsmAssignment.fulfilled,
    (state, { payload }) => {
      state.csmAssignmentByTeamHash[payload.team_id] = payload;
    }
  );
});
