import {
  postApiRailsServer,
  postApiGalaxyServer,
  getApiGalaxyServer,
  putApiGalaxyServer,
} from "services";
import {
  DeleteRealmPayload,
  CreateRealmParams,
  GenerateRealmIdParams,
} from "SandboxModule/types";

/* --------------------------- Samples --------------------------- */
export const fetchSandboxes = (
  params: {}, // Omit<FetchSandboxParams, "isoStateId">,
  config = {},
  token: string
) => {
  return getApiGalaxyServer(`/realm/all`, config, token);
};

export const createRealm = ({
  params,
  token,
  config,
}: BaseApiParams<CreateRealmParams>) => {
  return postApiGalaxyServer(
    "/realm/createRealm",
    { ...params, meta: params.metaPayload },
    config,
    token
  );
};

export const deleteRealm = ({
  config = {},
  params,
  token,
}: BaseApiParams<DeleteRealmPayload>) =>
  postApiGalaxyServer("/realm/destroyRealm", params, config, token);

export const getAvailableDumpFiles = (
  params = {},
  data = {},
  config = {},
  token?: string
) => postApiGalaxyServer(`/realm/getAvailableDumpFiles`, data, config, token);

export const createDumpFile = (
  params = {},
  data = {},
  config = {},
  token?: string
) => postApiGalaxyServer(`/database/createDumpFile`, data, config, token);

export const generateRealmId = ({
  params,
  token,
  config,
}: BaseApiParams<GenerateRealmIdParams>) => {
  return getApiGalaxyServer(
    `/realm/generateRealmId/${params.teamName}`,
    config,
    token
  );
};

export const initializeSandbox = (
  params = {},
  data = {},
  config = {},
  token?: string
) => postApiGalaxyServer(`/realm/initializeSandbox`, data, config, token);

export const getSandboxMembersCsvUrl = (
  params = {},
  body = {},
  config = {},
  token?: string
) =>
  postApiRailsServer(
    `/sandbox/csv_export_sandbox_members`,
    body,
    config,
    token
  );

export const updateSandbox = (
  params = {},
  data = {},
  config = {},
  token?: string
) => putApiGalaxyServer(`/realm/updatesandbox`, data, config, token);

export const getSandboxCreators = (params = {}, config = {}, token?: string) =>
  getApiGalaxyServer(`/realm/listCreators`, config, token);
