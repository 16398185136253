type CallApiArgumentsType = {
  api: (() => any) | null;
  actionEntity: {
    request: any;
    success: any;
    failure: any;
    dispatch: any;
    requestPayload: Record<any, any>;
  };
  errorEntity: {
    enhanceErrorFn: null | ((error: Record<any, any>) => Record<any, any>);
    errorType: string;
  };
  /** 99% won't need this */
  customTryCallApiFn?: (() => void) | null; // Example: async () => try { await callApi(), dispatch(success(payload)) } catch(e){ throw e}
};

type ResponseReturnType = {
  requestPayload: Record<any, any>;
  responsePayload: Record<any, any>;
};

type ErrorReturnType = Record<any, any>;

export type CallAPIReturnType =
  | {
      response?: ResponseReturnType;
      error?: ErrorReturnType;
    }
  | any;

export const callApi = async (
  api: CallApiArgumentsType["api"], // Should be provided if customTryCallApiFn is null
  {
    request,
    success,
    failure,
    dispatch,
    requestPayload,
  }: CallApiArgumentsType["actionEntity"],
  { enhanceErrorFn, errorType }: CallApiArgumentsType["errorEntity"],
  customTryCallApiFn?: CallApiArgumentsType["customTryCallApiFn"]
) => {
  dispatch(request(requestPayload));
  try {
    if (customTryCallApiFn) {
      try {
        await new Promise((resolve) => resolve(customTryCallApiFn()));
      } catch (customTryCallError) {
        throw customTryCallError;
      }
    } else {
      const response = await new Promise((resolve) => resolve(api && api()));
      const successPayload = {
        requestPayload: { ...requestPayload, errorType },
        responsePayload: { ...(response as any) },
      };
      dispatch(success(successPayload));
      return { response: successPayload };
    }
  } catch (e: any) {
    e.type = errorType;
    e.requestPayload = requestPayload;
    const enhancedError = enhanceErrorFn
      ? enhanceErrorFn(e as Record<any, any>)
      : e;
    console.error(
      "Failed with error: ",
      enhancedError
        ? enhancedError?.devMessage || { ...enhancedError }
        : "Error"
    );
    const failurePayload = {
      ...enhancedError,
      requestPayload,
    };
    dispatch(failure(failurePayload));
    return { error: enhancedError };
  }
};
