import {
  useQuery,
  UseQueryOptions,
  QueryKey,
  UseQueryResult,
  QueryFunctionContext,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { selectAuthToken } from "AuthModule/selectors";
import { SetRequired } from "type-fest";

type UseAppQueryParams<
  SuccessResponseType,
  ErrorResponseType,
  QueryKeyType extends QueryKey
> = Omit<
  SetRequired<
    UseQueryOptions<
      SuccessResponseType,
      ErrorResponseType,
      SuccessResponseType,
      QueryKeyType
    >,
    "queryKey"
  >,
  "queryFn"
> & {
  queryFn: (
    args: QueryFunctionContext & { token: string }
  ) => Promise<SuccessResponseType>;
};
/**
 * Wrap useQuery, which provides the base needed for calling query
 */
export const useAppQuery = <
  SuccessResponseType,
  ErrorResponseType = unknown,
  QueryKeyType extends QueryKey = QueryKey
>(
  options: UseAppQueryParams<
    SuccessResponseType,
    ErrorResponseType,
    QueryKeyType
  >
): UseQueryResult<SuccessResponseType, ErrorResponseType> => {
  const token = useSelector(selectAuthToken);
  const { queryFn, ...rest } = options;

  return useQuery(({
    ...rest,
    queryFn: (args: QueryFunctionContext) => {
      if (!token) return null;

      return queryFn({ ...args, token });
    },
  } as unknown) as UseQueryOptions<SuccessResponseType, ErrorResponseType, SuccessResponseType, QueryKeyType>);
};
