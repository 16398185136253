import { Alert, AlertTitle } from "@material-ui/lab";
import { Box } from "@material-ui/core";

export const CreateSandboxWarning = () => {
  return (
    <Box>
      <Alert severity="error">
        <AlertTitle>Sandbox Creation Notice</AlertTitle>
        Creating a Sandbox will{" "}
        <strong>
          restart the api-server and rails-api services and execute the
          rails-api release task.
        </strong>{" "}
        Please avoid creating multiple Sandboxes in rapid succession.
      </Alert>
    </Box>
  );
};
