import { AppStateType } from "reducers";
import { createSelector } from "reselect";
import * as thunks from "ReportsModule/thunks";

const selectReportsReducerRequestStatusesAndErrors = (state: AppStateType) =>
  state.reportsReducer.requestStatusesAndErrors;

/* ------------------------------------ Fetch Data to IS ----------------------------------- */

const selectFetchDataToISResponseState = (state: AppStateType) =>
  state.reportsReducer.fetchToIntegrationServerResponse;

const selectFetchDataToISResponseStateData = createSelector(
  selectFetchDataToISResponseState,
  selectReportsReducerRequestStatusesAndErrors,
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[thunks.fetchDataToIntegrationServer.typePrefix],
  })
);

export const selectFetchDataToISResponse = createSelector(
  selectFetchDataToISResponseStateData,
  ({ data, isRequesting: requestStatuses, error }) => {
    const { agent, backend } = data;

    return {
      agent: {
        statusCode: agent.statusCode,
        response: agent.data,
        isRequesting: requestStatuses.agent,
      },
      backend: {
        statusCode: backend.statusCode,
        response: backend.data,
        isRequesting: requestStatuses.backend,
      },
    };
  }
);

/* ----------------------------------- Force Update ----------------------------------- */

const selectForceUpdateResponseState = (state: AppStateType) =>
  state.reportsReducer.forceUpdateResponse;

const selectForceUpdateResponseData = createSelector(
  selectForceUpdateResponseState,
  selectReportsReducerRequestStatusesAndErrors,
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[thunks.integrationForceUpdate.typePrefix],
  })
);

export const selectForceUpdateResponse = createSelector(
  selectForceUpdateResponseData,
  ({ data: response, isRequesting, error }) => {
    const { statusCode, data } = response;
    return { statusCode, response: data, isRequesting, error };
  }
);
