import { LOWER_CASE_STAND_BY } from "@constants";
import {
  INPUT_ERROR_MESSAGE,
  STAND_BY_TAG_ERROR_MESSAGE,
} from "SandboxModule/constants";
import { checkUppercaseAndSpecialCharacters } from "./regex";

export const checkTagsInputError = (input: string) => {
  // check if tag input contain uppercase or special characters
  if (checkUppercaseAndSpecialCharacters(input)) {
    // show error if they do
    return INPUT_ERROR_MESSAGE;
    // check if the tag is stand by
  } else if (input === LOWER_CASE_STAND_BY) {
    // show error if it is
    return STAND_BY_TAG_ERROR_MESSAGE;
  }

  return "";
};
