import {
  INTEGRATION_SERVICE_NAMES,
  StandardTag,
  TargetService,
  integrationsHash,
} from "@constants";
import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { bulkFetchIntegrationSettingSchema, fetchIntegrations } from "thunk";
import { checkIsodbcSupportIntegrations } from "utils";
import useTeams from "hooks/useTeams";
import { useRequestStatus } from "RequestStatusModule/hooks/useRequestStatus";
import { useIntegrationsListQuery } from "IntegrationModule/api/queries/useIntegrationsListQuery";

export const useTeamRetoolIntegrations = ({
  teamId,
}: {
  // teamId only exist when using renewal button
  // if navigate to the page using side bar, then teamId is null
  teamId: Nullable<number>;
}) => {
  const {
    integrationsList,
  } = useIntegrationsListQuery({ mosaicTeamId: teamId ?? undefined, includeArchived: false });
  const fetchIntegrationSchemaRequestStatusId = `fetchIntegrationSchema_${teamId}`;
  const fetchIntegrationRequestStatusId = `fetchIntegration_${teamId}`;
  const fetchTeamRequestStatusId = `fetchTeam_${teamId}`;
  const { teamHash, debouncedFetchTeams } = useTeams();
  const dispatch = useDispatch();
  const {
    requestStatus: fetchIntegrationSchemaRequestStatus,
  } = useRequestStatus({
    requestStatusId: fetchIntegrationSchemaRequestStatusId,
  });
  const { requestStatus: fetchIntegrationRequestStatus } = useRequestStatus({
    requestStatusId: fetchIntegrationRequestStatusId,
  });
  const { requestStatus: fetchTeamRequestStatus } = useRequestStatus({
    requestStatusId: fetchTeamRequestStatusId,
  });
  const [
    { retoolIntegrationSet, integrationToFetchSchemaFor },
    setIntegrationInfo,
  ] = useState<{
    retoolIntegrationSet: Set<TargetService>;
    integrationToFetchSchemaFor: {
      targetService: TargetService;
      targetServiceId: number;
    }[];
  }>({
    retoolIntegrationSet: new Set(),
    integrationToFetchSchemaFor: [],
  });

  const isFetchingIntegrationSchema =
    fetchIntegrationSchemaRequestStatus &&
    fetchIntegrationSchemaRequestStatus.isRequesting;
  const isFetchingIntegration =
    fetchIntegrationRequestStatus && fetchIntegrationRequestStatus.isRequesting;
  const isFetchingTeam =
    fetchTeamRequestStatus && fetchTeamRequestStatus.isRequesting;
  const isFetchingIntegrationForRetool = teamId
    ? isFetchingIntegrationSchema || isFetchingIntegration || isFetchingTeam
    : false;

  const isApiKeysEnabled = teamId
    ? Boolean(teamHash[teamId]?.tags?.includes(StandardTag.ApiEnabled))
    : false;
  const team = teamId ? teamHash[teamId] : null;

  useEffect(() => {
    if (teamId) {
      debouncedFetchTeams({
        requestStatusId: fetchTeamRequestStatusId,
        teamIds: [teamId],
        skipCsmFetch: true,
      });
    }
  }, [debouncedFetchTeams, dispatch, fetchTeamRequestStatusId, teamId]);

  useEffect(() => {
    if (teamId) {
      dispatch(
        fetchIntegrations({
          requestStatusId: fetchIntegrationRequestStatusId,
          mosaicTeamId: teamId,
          includeArchived: false,
        })
      );
    }
  }, [dispatch, fetchIntegrationRequestStatusId, teamId]);

  useEffect(() => {
    setIntegrationInfo((prev) => {
      if (!integrationsList) return prev;

      const nextRetoolIntegrationSet = new Set(prev.retoolIntegrationSet);
      const nextIntegrationToFetchSchemaFor = [
        ...prev.integrationToFetchSchemaFor,
      ];

      integrationsList.forEach((integration) => {
        // Initial guard only care about the integrations they are using right now
        // csv is not on the pricing calculator
        if (
          integration.isArchived ||
          integration.targetService === INTEGRATION_SERVICE_NAMES.CSV_AGENT
        )
          return;

        if (checkIsodbcSupportIntegrations(integration.targetService)) {
          nextIntegrationToFetchSchemaFor.push({
            targetService: integration.targetService,
            targetServiceId: integration.targetServiceId,
          });
        } else {
          // Not odbc supported integration
          nextRetoolIntegrationSet.add(integration.targetService);
        }
      });

      return {
        ...prev,
        retoolIntegrationSet: nextRetoolIntegrationSet,
        integrationToFetchSchemaFor: nextIntegrationToFetchSchemaFor,
      };
    });
  }, [integrationsList]);

  useEffect(() => {
    if (integrationToFetchSchemaFor.length) {
      integrationToFetchSchemaFor.forEach((integration) => {
        dispatch(
          bulkFetchIntegrationSettingSchema({
            meta: {
              requestStatusId: fetchIntegrationSchemaRequestStatusId,
              onSuccess: ({ response }) => {
                response.forEach((response) => {
                  const odbcData =
                    response.data.agentSchema.properties.credentials.properties
                      .odbc;
                  const odbcDataSource = odbcData.properties.dataSource;
                  const { odbcTargetService } = integrationsHash[
                    integration.targetService
                  ];
                  //check if has ODBC, update the set with odbc target service name
                  const targetServiceToAdd =
                    odbcDataSource?.default && odbcTargetService
                      ? odbcTargetService
                      : integration.targetService;

                  setIntegrationInfo((prev) => {
                    const nextRetoolIntegrationSet = new Set(
                      prev.retoolIntegrationSet
                    );

                    nextRetoolIntegrationSet.add(targetServiceToAdd);

                    return {
                      ...prev,
                      retoolIntegrationSet: nextRetoolIntegrationSet,
                    };
                  });
                });
              },
            },
            targetServiceIds: integrationToFetchSchemaFor.map(
              (integration) => integration.targetServiceId
            ),
          })
        );
      });
    }
  }, [
    dispatch,
    fetchIntegrationSchemaRequestStatusId,
    integrationToFetchSchemaFor,
  ]);

  useEffect(() => {
    //check if team has api enabled, update the set with api-keys
    if (isApiKeysEnabled) {
      setIntegrationInfo((prev) => {
        const nextRetoolIntegrationSet = new Set(prev.retoolIntegrationSet);
        nextRetoolIntegrationSet.add("api-keys");

        return {
          ...prev,
          retoolIntegrationSet: nextRetoolIntegrationSet,
        };
      });
    }
  }, [isApiKeysEnabled]);

  const getRetoolIntegrationList = useCallback(() => {
    if (isFetchingIntegrationForRetool) return [];

    return Array.from(retoolIntegrationSet);
  }, [isFetchingIntegrationForRetool, retoolIntegrationSet]);

  const companyName = team?.name;
  const userCount = team?.actives_count;

  return {
    getRetoolIntegrationList,
    userCount,
    companyName,
    isFetchingIntegrationForRetool,
  };
};
