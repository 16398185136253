import { useMemo } from "react";
import { getTableConfig } from "./tableConfigs";
import useMuiTableBuilderV2 from "hooks/useMuiTableBuilderV2";
import { Box } from "@material-ui/core";
import { useTenantListItemsBuilder } from "./helpers/useTenantListItemsBuilder";
import { TenantViewType } from "TenantModule/constants";

export const TenantTable = ({
  isLoading,
  variant,
  selectTenant,
  selectedTenantIds,
  tenantIds,
}: {
  isLoading: boolean;
  variant: TenantViewType;
  selectTenant: ({ tenantId }: { tenantId: number }) => void;
  selectedTenantIds: Set<number>;
  tenantIds: number[];
}) => {
  const listItemsBuilder = useTenantListItemsBuilder({
    tenantIds,
  });
  const tableConfig = useMemo(() => {
    return getTableConfig({ variant });
  }, [variant]);
  const mainList = useMemo(() => {
    return {
      listItems: listItemsBuilder(),
    };
  }, [listItemsBuilder]);

  const customRowProps = useMemo(
    () => ({
      selectTenant,
      selectedTenantIds,
    }),
    [selectTenant, selectedTenantIds]
  );

  const { TableComponent } = useMuiTableBuilderV2({
    ...tableConfig,
    mainList,
    isLoading,
    customRowProps,
    tableMaxHeight: "800px",
  });

  return <Box>{TableComponent}</Box>;
};
