import { Box, Chip, TextField } from "@material-ui/core";
import { BaseTextFieldProps } from "@material-ui/core/TextField";
import { FC } from "react";
import EllipsisText from "./EllipsisText";
import { REMOVE_TAG_TEXT, chipWidth } from "@constants";
import styled from "styled-components";
import cn from "classnames";

const ChipsContainer = styled(Box)`
  .red-chip {
    background-color: #f44336;
    color: white;
  }
`;

interface TagsListSectionWithInputProps {
  tagList: string[];
  onDelete: (id: string) => void;
  inputRef?: BaseTextFieldProps["inputRef"];
  onKeyDown?: BaseTextFieldProps["onKeyDown"];
  helperText?: string;
  disabled?: BaseTextFieldProps["disabled"];
  error?: BaseTextFieldProps["error"];
  placeholder?: BaseTextFieldProps["placeholder"];
  label?: BaseTextFieldProps["label"];
  type?: BaseTextFieldProps["type"];
}

export const TagsListSectionWithInput: FC<TagsListSectionWithInputProps> = ({
  tagList,
  onDelete,
  inputRef,
  onKeyDown,
  helperText,
  placeholder,
  disabled,
  label,
  type = "text",
  error,
}) => {
  return (
    <>
      <TextField
        type={type}
        placeholder={placeholder}
        inputRef={inputRef}
        onKeyDown={onKeyDown}
        helperText={helperText}
        disabled={disabled}
        error={error}
        label={label}
      />
      <ChipsContainer display={"flex"} gridGap={4} flexWrap={"wrap"} my={3}>
        {tagList.map((tag) => (
          <EllipsisText text={tag} width={chipWidth}>
            <Chip
              color="primary"
              className={cn({ "red-chip": tag === REMOVE_TAG_TEXT })}
              label={tag}
              onDelete={() => onDelete(tag)}
              style={{ maxWidth: `calc(${chipWidth} - 10%)` }}
            />
          </EllipsisText>
        ))}
      </ChipsContainer>
    </>
  );
};
