import { createContext, ReactNode, useCallback, useMemo } from "react";
import { useList } from "react-use";
import { ModalComponent } from "./types";

interface DynamicModalContextValues {
  /**
   * Open modal dynamically with unique id
   */
  openModal: (values: ModalState) => void;
  closeModal: (ids: string | string[]) => void;
  openModalIds: string[];
}

interface ModalState {
  id: string;
  /**
   * Pass modal renderer function to be rendered
   * propsToOverride should be passed to the actual Modal component
   */
  modal: ModalComponent;
}

export const DynamicModalContext = createContext<DynamicModalContextValues>({
  openModal: () => undefined,
  closeModal: () => undefined,
  openModalIds: [],
});

/**
 * It is originally designed to trigger to open/close modal dynamically
 * that isn't bound to any URL routes e.g. SimpleConfirmModal
 * It can open modal by passing existing modal component as a renderer function
 *
 * NOTE: It won't rerender when the props of modal are changed
 *
 */
export const DynamicModalProvider = ({ children }: { children: ReactNode }) => {
  const [modals, { upsert, filter }] = useList<ModalState>([]);

  const handleAddModal = useCallback(
    ({ id, modal }) => {
      upsert((modalState) => modalState.id === id, { id, modal });
    },
    [upsert]
  );

  // NOTE: modal is unmounted directly when the toggle is called
  // it is not gracefully handle the animation
  // since most of our existing modals do not take onClosed props
  const handleRemoveModal = useCallback(
    (ids: string | string[]) => {
      const idsToUse = !Array.isArray(ids) ? [ids] : ids;
      filter((modalState) => !idsToUse.includes(modalState.id));
    },
    [filter]
  );

  const openModalIds = useMemo(() => modals.map((modal) => modal.id), [modals]);

  return (
    <DynamicModalContext.Provider
      value={{
        openModal: handleAddModal,
        closeModal: handleRemoveModal,
        openModalIds,
      }}
    >
      {children}
      {modals.map(({ id, modal }) => (
        <ModalHandler
          key={id}
          id={id}
          modal={modal}
          onClose={handleRemoveModal}
        />
      ))}
    </DynamicModalContext.Provider>
  );
};

interface ModalHandlerProps {
  id: string;
  modal: ModalComponent;
  onClose: (id: string) => void;
}

const ModalHandler = ({ id, modal: Modal, onClose }: ModalHandlerProps) => {
  const handleClose = useCallback(() => onClose(id), [id, onClose]);

  return <Modal isOpen toggle={handleClose} />;
};
