import { DemoteSuperAdminModal } from "TeamModule/components/DemoteSuperAdminModal";
import { useDynamicModal } from "components/DynamicModalProvider/useDynamicModal";
import { ComponentProps, useCallback } from "react";

export const useDemoteSuperAdminModal = () => {
  const { openModal } = useDynamicModal();

  const openDemoteSuperAdminModal = useCallback(
    (args: Omit<ComponentProps<typeof DemoteSuperAdminModal>, "onClose">) => {
      openModal({
        modal: ({ isOpen, toggle }) => {
          return <DemoteSuperAdminModal onClose={toggle} {...args} />;
        },
      });
    },
    [openModal]
  );

  return {
    openDemoteSuperAdminModal,
  };
};
