import { CreateSubscriptionRealmModal } from "RealmModule/components/SubscriptionRealm/CreateSubscriptionRealmModal";
import { useDynamicModal } from "components/DynamicModalProvider/useDynamicModal";
import { useCallback } from "react";

export const useCreateSubscriptionRealmModal = () => {
    const { openModal } = useDynamicModal();

  const openCreateSubscriptionRealmModal = useCallback(() => {
    openModal({
      modal: ({ isOpen, toggle }) => {
        return <CreateSubscriptionRealmModal toggle={toggle} />;
      },
    });
  }, [openModal]);
  return {
    openCreateSubscriptionRealmModal,
  };
};
