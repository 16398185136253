import { useCallback, useState, useRef } from "react";
import { useMount } from "react-use";
import useUrl from "./useUrl";
import { fetchTeams as fetchTeamsActionCreator } from "thunk";
import { useDispatch, useSelector } from "react-redux";
import { selectTeamHash } from "selectors";
import debounce from "lodash/debounce";

const useTeams = ({
  shouldAutoLoad = true,
}: {
  shouldAutoLoad?: boolean; // Will autoload the team from url
} = {}) => {
  const { teamId: teamIdFromUrl } = useUrl();
  const dispatch = useDispatch();
  const [fetchedLocalTeamIdsSet, setFetchedLocalTeamIdsSet] = useState<
    Set<number>
  >(new Set());
  const teamHash = useSelector(selectTeamHash);
  const [isFetchingTeams, setIsFetchingTeams] = useState(false);

  /** If teamId is not provided in args, then fetch the current */
  const fetchTeams = useCallback(
    ({
      teamIds,
      onSuccess,
      ...rest
    }: {
      teamIds?: number[];
    } & Parameters<typeof fetchTeamsActionCreator>[0]) => {
      const teamIdsToFetch =
        teamIds ?? (teamIdFromUrl ? [teamIdFromUrl] : undefined);

      setFetchedLocalTeamIdsSet((prev) => {
        if (!teamIdsToFetch) return prev;

        const next = new Set(prev);

        teamIdsToFetch.forEach((id) => next.add(id));

        return next;
      });

      const params: Parameters<typeof fetchTeamsActionCreator>[0] = {
        ...(teamIdsToFetch ? { teamIds: teamIdsToFetch } : null),
        ...rest,
        onSuccess: ({ response }) => {
          setIsFetchingTeams(false);
          onSuccess?.({ response });
        },
        onFailure: () => {
          setIsFetchingTeams(false);
        },
      };

      setIsFetchingTeams(true);
      dispatch(fetchTeamsActionCreator(params));
    },
    [dispatch, teamIdFromUrl]
  );

  const debouncedFetchTeams = useRef(debounce(fetchTeams, 50)).current;

  const fetchAllTeams = useCallback(
    (
      params: Omit<
        Parameters<typeof fetchTeamsActionCreator>[0],
        "limit" | "isChainingRequests"
      >
    ) => {
      const { requestStatusId, isTakeLatest, ...rest } = params;
      fetchTeams({
        ...rest,
        requestStatusId,
        isTakeLatest,
        isChainingRequests: true,
        limit: 0, // Only care about `total` in the response
        onSuccess: ({ response }) => {
          const total = response.data.total;

          fetchTeams({
            ...rest,
            requestStatusId,
            limit: total,
          });
        },
      });
    },
    [fetchTeams]
  );

  useMount(() => {
    if (shouldAutoLoad && teamIdFromUrl) {
      fetchTeams({ teamIds: [teamIdFromUrl] });
    }
  });

  return {
    fetchedLocalTeamIdsSet,
    fetchTeams,
    debouncedFetchTeams,
    fetchAllTeams,
    teamHash,
    isFetchingTeams,
    currentTeam: teamHash?.[teamIdFromUrl] || {},
    currentTeamId: teamIdFromUrl || 0,
  };
};

export default useTeams;
