import * as Types from "types";
import { Moment } from "moment";
import { ValueOf } from "type-fest";
import {
  INTEGRATION_SERVICE_NAMES,
  twoWaySyncIntegrations,
  replicationIntegrations,
  odbcSupportIntegrations,
} from "@constants";

export const isIntegrationHealthy = (
  integrationHealth: Types.IntegrationHealth
) =>
  integrationHealth
    ? integrationHealth.requestCount === integrationHealth.successCount
    : null;

export const formatIntegrationHealthDateParams = (momentDate: Moment) =>
  momentDate.format("YYYY-MM-DD");

export const sumRequestAndSuccessIntegrationHealthCountsByTargetService = (
  integrationHealthDataByTargetServiceHash: Record<
    string,
    Types.IntegrationHealth[]
  >,
  targetService: string
) => {
  let requestTotal = 0;
  let successTotal = 0;

  const integrationHealthDataByTargetService =
    integrationHealthDataByTargetServiceHash[targetService] || [];

  integrationHealthDataByTargetService.forEach(
    (data: Types.IntegrationHealth) => {
      const { requestCount, successCount } = data;
      requestTotal += requestCount;
      successTotal += successCount;
    }
  );

  return {
    requestTotal,
    successTotal,
    successToRequestRatioLabel: `${successTotal} / ${requestTotal}`,
    successToRequestRatioPercent: requestTotal
      ? +((successTotal / requestTotal) * 100).toFixed(1)
      : null,
  };
};

export const checkIsCsvIntegration = (targetService: ValueOf<typeof INTEGRATION_SERVICE_NAMES>)=>{
  return targetService === INTEGRATION_SERVICE_NAMES.CSV_AGENT
}

export const checkIsTwoWaySyncIntegration = (
  targetService: ValueOf<typeof INTEGRATION_SERVICE_NAMES>
) => {
  return twoWaySyncIntegrations.has(targetService);
};

export const checkIsodbcSupportIntegrations = (
  targetService: ValueOf<typeof INTEGRATION_SERVICE_NAMES>
) => {
  return odbcSupportIntegrations.has(targetService);
};

export const checkIsReplicationIntegration = (
  targetService: ValueOf<typeof INTEGRATION_SERVICE_NAMES>
) => replicationIntegrations.has(targetService);


export const checkIsTimeEntrySendToAgent = (
  settings?: Types.Integration['settings']
) => {
  // if sentToAgent:{time_entry} is not set or false, return false
  return settings?.sendToAgent?.time_entry;
};


export const checkIsTimeEntrySendToMosaic = (
  settings?: Types.Integration['settings']
) => {
  const timeEntrySendToMosaicSetting =
  settings?.sendToMosaic?.time_entry;
    // if sentToMosaic:{time_entry} is not set or true, return true
  if (timeEntrySendToMosaicSetting === undefined) {
    return true;
  }

  return timeEntrySendToMosaicSetting;
};
