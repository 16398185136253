import { Box, TextField } from "@material-ui/core";
import SimpleModal from "components/SimpleModal";
import { useState } from "react";
import { createDumpFile } from "SandboxModule/thunks";
import { useDispatch } from "react-redux";

const newDumpFileInitialValues = {
  fileName: "",
};

export const CreateSandboxDumpFileModal = ({
  toggle,
}: {
  toggle: () => void;
}) => {
  const dispatch = useDispatch();
  const [newDumpFileValues, setNewDumpFileValues] = useState(
    newDumpFileInitialValues
  );
  const handleNewDumpFileInfoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, name } = event.target;
    setNewDumpFileValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreateFile = () => {
    alert(
      `Initiating creating new dump file ${newDumpFileValues.fileName}. It will take a while.`
    );
    dispatch(
      createDumpFile({
        fileName: newDumpFileValues.fileName,
        onFailure: ({ error }: any) => {
          alert(`Failed to create dump file. ${error.message}`);
        },
      })
    );
    setNewDumpFileValues(newDumpFileInitialValues);
  };

  return (
    <SimpleModal
      open
      onClose={toggle}
      headerText="Create New Sandbox File"
      Body={
        <form>
          <Box mb={5}>
            <TextField
              onChange={handleNewDumpFileInfoChange}
              value={newDumpFileValues.fileName}
              label="File Name"
              name="fileName"
              type="text"
              fullWidth
              variant="outlined"
            />
          </Box>
        </form>
      }
      onConfirm={handleCreateFile}
      confirmButtonText="Create File"
      closeOnConfirm
    />
  );
};
