import { AppStateType } from "reducers";
import { createSelector } from "reselect";
import * as constants from "@constants";
import { initialState } from "SandboxModule/reducers";
import * as SandboxModuleThunks from "SandboxModule/thunks";

const emptyObj = {};

export const selectSandboxReducerState = (state: AppStateType) =>
  state.sandboxReducer || initialState;

export const selectSandboxReducerRequestStatusesAndErrors = createSelector(
  selectSandboxReducerState,
  (state) => state.requestStatusesAndErrors
);

/* -------------------------------------------------------------------------- */

export const selectRailsSandboxIsoState = createSelector(
  selectSandboxReducerState,
  (state) => state.railsSandboxes
);

export const selectRailsSandboxesTableFilter = (state: AppStateType) =>
  state.filterReducer[constants.filterIds.railsSandboxesTable];

export const selectRailsSandboxesHash = createSelector(
  selectSandboxReducerState,
  (state) => state.railsSandboxesHash
);

/* -------------------------------------------------------------------------- */
const selectAvailableDumpFiles = createSelector(
  selectSandboxReducerState,
  (state) => state.dumpFiles
);

export const selectAvailableDumpFilesData = createSelector(
  selectAvailableDumpFiles,
  selectSandboxReducerRequestStatusesAndErrors,
  (data, requestStatusesAndErrors) => ({
    data: data.map((file) => ({ value: file, label: file })),
    ...requestStatusesAndErrors[
      SandboxModuleThunks.fetchAvailableDumpFiles.typePrefix
    ],
  })
);
