import React, { MouseEventHandler, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { withTheme } from "styled-components/macro";
import { darken } from "polished";
import { Search as SearchIcon, AlertCircle } from "react-feather";
import Loader from "components/Loader";
import { debounce } from "lodash";
import { SandboxIsoStateIds } from "SandboxModule/hooks/useSandboxes";
import useAppNavigation from "hooks/useAppNavigation";
import { TenantSelector } from "src/modules/TenantModule/components/TenantSelector";
import RealmSelector from "SandboxModule/components/RealmSelector";
import * as constants from "@constants";
import { useAppContext } from "containers/AppProvider";
import { SearchTeamStrategies } from "@constants";
import { SearchTeamStrategiesDropdown } from "components/SearchTeamStrategiesDropdown";
import {
  Grid,
  Hidden,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";

import { Menu as MenuIcon } from "@material-ui/icons";

import NotificationsDropdown from "./NotificationsDropdown";
import MessagesDropdown from "./MessagesDropdown";
import LanguagesDropdown from "./LanguagesDropdown";
import UserDropdown from "./UserDropdown";
import { toggleDevMode as _toggleDevMode } from "actionCreators";

import { selectIsDevMode, selectReportsTeamsData } from "selectors";

import { fetchReportsTeams, fetchIntegrations } from "thunk";

import { getIsTenantEnabled, getReportsTeamSearchValue } from "utils";
import { REPORTS_VIEW_TYPES, KEYBOARD_KEYS } from "@constants";
import { useRouteMatch } from "react-router-dom";
import { FetchReportsTeamsParams } from "ReportsModule/types";
import { useTenantRealmDropdownsFlow } from "TenantModule/hooks/useTenantRealmDropdownsFlow";
import { useSwitchTenantModal } from "TenantModule/hooks/useSwitchTenantModal";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 300px;
  }
`;

type AppBarProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const sandboxIsoStateId = SandboxIsoStateIds.AppbarRealmSelector;

const AppBarComponent: React.FC<AppBarProps> = ({ onDrawerToggle }) => {
  const dispatch = useDispatch();
  const toggleDevMode = () => dispatch(_toggleDevMode());
  const isDevMode: boolean = useSelector(selectIsDevMode);
  const {
    params: { memberTableView, reportTableView },
  } = useRouteMatch<{ memberTableView?: string; reportTableView?: string }>();
  const { isRequesting: isFetchingTeams, filter } = useSelector(
    selectReportsTeamsData
  );
  const { navigateToTeamProfile, navigateToTeamsTable } = useAppNavigation();
  const { searchTeamStrategy, searchBarInputRef } = useAppContext();
  const isOnRetoolPage =
    window.location.pathname === constants.REPORTS_TABLE_TEAMS_V2_PATH;

  const realmDropdownRef = useRef<HTMLDivElement>(null);
  const searchTeams = ({
    valueToSearch,
  }: {
    valueToSearch: {
      ids: number[];
      search_text: string;
    };
  }) => {
    const fetchReportsTeamsOnSuccess: FetchReportsTeamsParams["onSuccess"] = ({
      response,
    }) => {
      const { teams } = response.data;
      if (
        teams.length === 1 &&
        reportTableView !== REPORTS_VIEW_TYPES.DEACTIVATED_TEAMS
      ) {
        navigateToTeamProfile({
          teamId: teams[0].id,
          memberTableView,
        });
        return;
      }
      navigateToTeamsTable(REPORTS_VIEW_TYPES.TEAMS);
    };
    const shouldShowDeactivateTeams =
      reportTableView === REPORTS_VIEW_TYPES.DEACTIVATED_TEAMS;

    dispatch(
      fetchReportsTeams({
        ...filter,
        ...valueToSearch,
        isInitialFetch: true,
        isSearchMode: true,
        show_only_deactivated: shouldShowDeactivateTeams,
        show_deactivation_info: shouldShowDeactivateTeams,
        onSuccess: fetchReportsTeamsOnSuccess,
      })
    );
  };

  /**
     * When on teams page, one team: navigate to team page
       When on teams page, 0 or 2+ team: show on the table
     */
  const handleSearchByDefault = ({
    valueToSearch,
  }: {
    valueToSearch: {
      ids: number[];
      search_text: string;
    };
  }) => {
    searchTeams({ valueToSearch });
  };

  /**
     * When on teams page, one team: navigate to team page
       When on teams page, 0 or 2+ team: show on the table
     */
  const handleSearchByTargetServiceId = (targetServiceId: number) => {
    dispatch(
      fetchIntegrations({
        targetServiceId,
        onSuccess: ({ response }) => {
          if (response.data.length > 0) {
            const { mosaicTeamId } = response.data[0];
            const valueToSearch = { ids: [mosaicTeamId], search_text: "" };
            searchTeams({ valueToSearch });
          } else {
            alert(`No team with integration ID ${targetServiceId} was found`);
          }
        },
      })
    );
  };

  const onEnterPress = debounce((key: string) => {
    const search_text = searchBarInputRef?.current?.value?.trim();
    if (key === KEYBOARD_KEYS.ENTER && search_text) {
      const valueToSearch = getReportsTeamSearchValue(search_text);
      const { ids } = valueToSearch;

      if (
        ids.length === 0 ||
        searchTeamStrategy.value === SearchTeamStrategies.ByTeamId
      ) {
        handleSearchByDefault({ valueToSearch });
      } else {
        const targetServiceId = ids[0];
        handleSearchByTargetServiceId(targetServiceId);
      }
    }
  }, 100);

  const { openSwitchTenantModal } = useSwitchTenantModal();

  const {
    onSelectTenantRealm,
    onSelectRealm,
    selectedGlobalRealm,
    selectedGlobalTenant,
    onSetGlobalTenantRealm,
  } = useTenantRealmDropdownsFlow();

  const onTenantSelectorClick: MouseEventHandler<HTMLElement> = (e) => {
    if (e.currentTarget && realmDropdownRef.current) {
      onSelectTenantRealm({
        tenantDropdownTarget: e.currentTarget,
        realmDropdownTarget: realmDropdownRef.current,
        onDone: ({ tenant, realm }) => {
          //Only log out user when new tenant is selected
          if (
            selectedGlobalTenant &&
            tenant.tenantName !== selectedGlobalTenant.tenantName
          ) {
            openSwitchTenantModal({
              tenantName: tenant.tenantName,
              realmId: realm.realm_id,
            });
          } else {
            onSetGlobalTenantRealm({
              tenantId: tenant.id,
              realmId: realm.realm_id,
            });
          }
        },
      });
    }
  };

  const onRealmSelectorClick: MouseEventHandler<HTMLElement> = (e) => {
    onSelectRealm({
      target: e.currentTarget,
      tenantId: selectedGlobalTenant?.id,
      isSelectingGlobalRealm: true,
    });
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={2}>
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            {isOnRetoolPage ? (
              <></>
            ) : (
              <>
                <Tooltip
                  title="If the team name contains mostly number, such as 123123123, put a ' in front of the input. (i.e: '123123123)"
                  style={{ margin: "0 10px 0 10px" }}
                >
                  <AlertCircle />
                </Tooltip>
                <SearchTeamStrategiesDropdown />
                <Grid item xs={6} md={3}>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <Input
                      disabled={isFetchingTeams}
                      onKeyDown={(e) => {
                        const { key } = e;
                        onEnterPress(key);
                      }}
                      inputRef={searchBarInputRef}
                    />
                  </Search>
                </Grid>
                <Grid item xs={1}>
                  {isFetchingTeams ? <Loader /> : <></>}
                </Grid>
              </>
            )}
            {getIsTenantEnabled() && selectedGlobalTenant ? (
              <TenantSelector
                onClick={onTenantSelectorClick}
                tenantId={selectedGlobalTenant.id}
              />
            ) : null}

            {selectedGlobalRealm ? (
              <RealmSelector
                onClick={onRealmSelectorClick}
                realmId={selectedGlobalRealm.realm_id}
                showWarningLabel
                ref={realmDropdownRef}
                tooltip={
                  "If your account does not exist on the realm, you will be logged out"
                }
              />
            ) : null}

            <Grid item xs />
            <Grid item>
              <Switch
                onChange={toggleDevMode}
                checked={isDevMode}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <span style={{ display: isDevMode ? "inline" : "none" }}>
                <MessagesDropdown />
                <NotificationsDropdown />
                <LanguagesDropdown />
              </span>
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(AppBarComponent);
