import SimpleModal from "components/SimpleModal";
import { useState } from "react";
import { CreateSandboxForm } from "./CreateSandboxForm";
import { isParty } from "core/apiClient";
import { CreateSandboxWarning } from "./CreateSandboxWarning";
import noop from "lodash/noop";

export const CreateSandboxModal = ({ toggle }: { toggle: () => void }) => {
  const [showCreateSandboxWarning, setShowCreateSandboxWarning] = useState(
    isParty
  ); // Show warning on party only

  const confirmWarning = () => {
    setShowCreateSandboxWarning(false);
  };

  const { Body, hideConfirmButton, onConfirm } = showCreateSandboxWarning
    ? {
        Body: <CreateSandboxWarning />,
        hideConfirmButton: false,
        onConfirm: confirmWarning,
      }
    : {
        Body: <CreateSandboxForm onRealmCreateSuccess={toggle} />,
        hideConfirmButton: true,
        onConfirm: noop,
      };

  return (
    <SimpleModal
      onClose={toggle}
      open
      onConfirm={onConfirm}
      headerText={"Create Sandbox"}
      hideConfirmButton={hideConfirmButton}
      Body={Body}
    />
  );
};
