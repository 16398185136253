import { createAsyncThunk } from "@reduxjs/toolkit";
import {} from "@constants";
import {} from "utils";
import { AppStateType } from "reducers";
import * as TenantServices from "TenantModule/services";
import {
  ArchiveTenantParams,
  ArchiveTenantSuccessResponse,
  CreateTenantParams,
  CreateTenantSuccessResponse,
  FetchTenantParams,
  FetchTenantSuccessResponse,
  UpdateTenantParams,
  UpdateTenantSuccessResponse,
} from "TenantModule/types";
import ApiClientManager from "core/apiClient";

export const fetchTenants = createAsyncThunk<
  FetchTenantSuccessResponse,
  FetchTenantParams
>(
  "fetchTenants",
  async (
    { meta = {}, ...params }: FetchTenantParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      authReducer: { user },
    } = getState() as AppStateType;
    const token = user?.auth_token!;
    const { onFailure, onSuccess, tenantName, realmId } = meta;

    try {
      const response = await TenantServices.fetchTenants(
        params,
        { headers: ApiClientManager.buildUrlHeaders({ tenantName, realmId }) },
        token
      );
      onSuccess && onSuccess({ response: response.data });
      return response.data;
    } catch (e) {
      onFailure && onFailure({ error: e });
      return rejectWithValue(e);
    }
  }
);

export const createTenant = createAsyncThunk<
  CreateTenantSuccessResponse,
  CreateTenantParams
>(
  "createTenant",
  async ({ meta = {}, ...params }, { getState, rejectWithValue }) => {
    const {
      authReducer: { user },
    } = getState() as AppStateType;
    const token = user?.auth_token!;
    const { onFailure, onSuccess } = meta;

    try {
      const response = await TenantServices.createTenant(params, {}, token);
      onSuccess && onSuccess({ response });
      return response.data;
    } catch (error) {
      onFailure && onFailure({ error });
      return rejectWithValue(error);
    }
  }
);

export const updateTenant = createAsyncThunk<
  UpdateTenantSuccessResponse,
  UpdateTenantParams
>(
  "updateTenant",
  async (
    { meta = {}, ...params }: UpdateTenantParams,
    { getState, rejectWithValue }
  ) => {
    const {
      authReducer: { user },
    } = getState() as AppStateType;
    const token = user?.auth_token!;
    const { onFailure, onSuccess } = meta;

    try {
      const response = await TenantServices.updateTenant(params, {}, token);
      onSuccess && onSuccess({ response });
      return response.data;
    } catch (error) {
      onFailure && onFailure({ error });
      return rejectWithValue(error);
    }
  }
);

export const archiveTenant = createAsyncThunk<
  ArchiveTenantSuccessResponse,
  ArchiveTenantParams
>(
  "archiveTenant",
  async (
    { meta = {}, ...params }: ArchiveTenantParams,
    { getState, rejectWithValue }
  ) => {
    const {
      authReducer: { user },
    } = getState() as AppStateType;
    const token = user?.auth_token!;
    const { onFailure, onSuccess } = meta;
    const { tenant, archiver } = params;

    try {
      const response = await TenantServices.archiveTenant(
        {
          tenant,
          meta: {
            name: archiver,
          },
        },
        {},
        token
      );

      onSuccess && onSuccess({ response });
      return response.data;
    } catch (e) {
      onFailure && onFailure({ error: e });
      return rejectWithValue(e);
    }
  }
);
