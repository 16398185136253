import { useState, useMemo, useCallback } from "react";

const useArrayFilters = <T extends string | number | never>({
  isMultiSelect = true,
  items: _items,
}: {
  isMultiSelect?: boolean;
  items: T[];
}) => {
  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const selectedItemsSet = useMemo(() => new Set(selectedItems), [
    selectedItems,
  ]);

  const onSelect = useCallback(
    (item: T | T[]) => {
      if (isMultiSelect) {
        setSelectedItems(item as T[]);
      } else {
        setSelectedItems([item as T]);
      }
    },
    [isMultiSelect]
  );

  const items = useMemo(() => {
    return [
      ...Array.from(selectedItems),
      ..._items.filter((i) => !selectedItemsSet.has(i)),
    ];
  }, [_items, selectedItems, selectedItemsSet]);

  const clearSelection = useCallback(() => {
    setSelectedItems([]);
  }, []);

  return {
    items,
    clearSelection,
    onSelect,
    selectedItems,
    isMultiSelect
  };
};

export default useArrayFilters;
