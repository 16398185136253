import { ComponentProps, Ref, forwardRef } from "react";
import { Box } from "@material-ui/core";
import useSandboxes from "SandboxModule/hooks/useSandboxes";
import { makeRealmLabel } from "SandboxModule/utils";
import { Realm } from "types";
import BaseSelectorWithArrow from "components/BaseSelectorWithArrow";

const RealmSelector = (
  {
    onClick,
    showWarningLabel,
    realmId,
    tooltip,
  }: {
    onClick: ComponentProps<typeof BaseSelectorWithArrow>["onClick"];
    showWarningLabel?: boolean;
    tooltip?: string;
    realmId: Realm["realm_id"];
  },
  ref?: Ref<HTMLDivElement>
) => {
  const { getSandbox } = useSandboxes();

  const sandbox = getSandbox(realmId);

  return (
    <Box display={"flex"} alignItems={"center"} ml={4} gridGap={10}>
      Realm:{" "}
      <BaseSelectorWithArrow
        onClick={onClick}
        label={
          <Box display={"flex"}>
            {sandbox
              ? makeRealmLabel({
                  realmId: sandbox.realm_id,
                  teamName: sandbox.team_name,
                  showWarningLabel,
                  tooltip,
                })
              : "Loading..."}
          </Box>
        }
        ref={ref}
      />
    </Box>
  );
};

export default forwardRef(RealmSelector);
