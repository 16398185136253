import styled from "styled-components";

export const CustomFieldsContainer = styled.div`
  .warning-text {
    font-weight: bold;
  }
  .horizontal-line {
    background-color: black;
    margin-top: 2rem;
  }
  .failed-text {
    color: red;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  .menu-container {
    margin-right: 10px;
  }
`;
