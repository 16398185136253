import { selectSuperAdminsHash } from "selectors";
import { SharedMemberInfoModalUI } from "./SharedMemberInfoModalUI";
import { useDispatch, useSelector } from "react-redux";
import { ComponentProps, FC, useCallback } from "react";
import { useDemoteSuperAdminModal } from "TeamModule/hooks/useDemoteSuperAdminModal";
import { SuperAdminInterface } from "types";
import { updateTeamMember } from "thunk";
import { useRequestStatus } from "RequestStatusModule/hooks/useRequestStatus";

export const SuperAdminInfoModal: FC<{
  accountId: SuperAdminInterface["account_id"];
  onClose: VoidFunction;
}> = ({ accountId, onClose }) => {
  const superAdminsHash = useSelector(selectSuperAdminsHash);
  const superAdmin = superAdminsHash[accountId];
  const dispatch = useDispatch();
  const superAdminTeamMembershipId = superAdmin.id;

  const requestStatusId = `update-superAdmin-${superAdminTeamMembershipId}`;
  const { openDemoteSuperAdminModal } = useDemoteSuperAdminModal();
  const { requestStatus: updateSuperAdminRequestStatus } = useRequestStatus({
    requestStatusId,
  });
  const isRequesting = Boolean(updateSuperAdminRequestStatus?.isRequesting);
  const error = updateSuperAdminRequestStatus?.error?.[0];

  const onFormSubmit = useCallback<
    ComponentProps<typeof SharedMemberInfoModalUI>["onFormSubmit"]
  >(
    (values) => {
      dispatch(
        updateTeamMember({
          ...values,
          requestStatusId,
          teamMembershipId: superAdminTeamMembershipId,
        })
      );
    },
    [dispatch, requestStatusId, superAdminTeamMembershipId]
  );

  const popoverItems = [
    {
      label: "Demote",
      onClick: () => {
        openDemoteSuperAdminModal({ accountId });
      },
    },
  ];

  return (
    <SharedMemberInfoModalUI
      onClose={onClose}
      firstName={superAdmin.first_name}
      lastName={superAdmin.last_name}
      email={superAdmin.email}
      accountId={superAdmin.account_id}
      popoverItems={popoverItems}
      onFormSubmit={onFormSubmit}
      isRequesting={isRequesting}
      error={error}
    />
  );
};
