import { useCallback, useContext, useRef } from "react";
import { useUnmount } from "react-use";
import { DynamicModalContext } from "./DynamicModalProvider";
import { ModalComponentWithHelpers, ModalHelpers } from "./types";
import { v4 } from "uuid"; 

/**
 * It is originally designed to trigger to open/close modal dynamically
 * that isn't bound to any URL routes e.g. SimpleConfirmModal
 * It can open modal by passing existing modal component as a renderer function
 *
 * NOTE: It won't rerender when the props of modal are changed
 *
 */
export const useDynamicModal = ({
  keepOpenOnUnmount,
}: {
  keepOpenOnUnmount?: boolean;
} = {}) => {
  const { openModal, closeModal } = useContext(DynamicModalContext);

  // Modal ids are generated and controlled by hook
  const modalIds = useRef<Set<string>>(new Set());

  const handleCloseModal = useCallback(
    (id: string) => {
      modalIds.current.delete(id);
      closeModal(id);
    },
    [closeModal]
  );

  const handleCloseAllModals = useCallback(() => {
    closeModal(Array.from(modalIds.current));
    modalIds.current.clear();
  }, [closeModal]);

  const handleOpenModal = useCallback(
    ({ modal: Modal }: { modal: ModalComponentWithHelpers }) => {
      const idToUse = v4();
      modalIds.current.add(idToUse);

      const modalHelpers: ModalHelpers = {
        id: idToUse,
        closeModal: () => handleCloseModal(idToUse),
      };

      openModal({
        id: idToUse,
        modal: (modalProps) => <Modal {...modalProps} />,
      });

      return modalHelpers;
    },
    [openModal, handleCloseModal]
  );

  useUnmount(() => {
    if (!keepOpenOnUnmount) {
      handleCloseAllModals();
    }
  });

  return {
    openModal: handleOpenModal,
    /**
     * Close the modal given by id.
     * Modal id is epxposed via ModalHelpers
     */
    closeModalWithId: handleCloseModal,
    /**
     * Only closes the modals opened by the component using the hook.
     */
    closeAllModals: handleCloseAllModals,
  };
};
