import {
  postApiRailsServer,
  getApiRailsServer,
  deleteApiRailsServer,
  getApiIntegrationServer,
} from "services";
import {
  CreateTeamPayload,
  DeleteBatchTimeEntriesPayload,
  ExportDeletedWorkPlanCsvParams,
  FetchTeamConfigsPayload,
  FetchTeamEmployeeStatisticsPayload,
} from "TeamModule/types";

export const createTeam = (
  params: CreateTeamPayload,
  config = {},
  token: string
) => postApiRailsServer("/signup", params, config, token);

export const getTeams = (config: {}, token: string = "") =>
  getApiRailsServer("/teams/all", config, token);

export const deleteBatchTimeEntries = (
  _ = {},
  body: DeleteBatchTimeEntriesPayload,
  config: {},
  token: string = ""
) => deleteApiRailsServer("/time_entries/batch_delete", body, config, token);

export const exportDeletedWorkPlanCsv = (
  params: ExportDeletedWorkPlanCsvParams,
  config = {},
  token: string
) => {
  return postApiRailsServer("/data_recovery", params, config, token);
};

export const fetchTeamConfigs = ({
  params,
  token,
  config,
}: BaseApiParams<FetchTeamConfigsPayload>) => {
  return getApiIntegrationServer(
    "/configs/teams",
    { ...config, params },
    token
  );
};

export const fetchTeamEmployeeStatistics = ({
  params,
  token,
  config,
}: BaseApiParams<FetchTeamEmployeeStatisticsPayload>) => {
  return getApiRailsServer(
    "/statistics/employee_statistics",
    { ...config, params },
    token
  );
};
