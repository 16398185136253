import { signIn as signInEntity } from "actionCreators";
import {
  signIn as authSignin,
  getAuthToken,
  getMe,
  getTempToken,
} from "services";

import {
  ExchangeTokenSuccessResponse,
  FetchTempTokenParams,
  FetchTempTokenSuccessResponse,
  ResetPasswordType,
  SignInType,
  SignUpType,
} from "types";

import { AUTH_ERRORS } from "@constants";

import { AppDispatchType } from "store";

import { callApi } from "thunk";
import { AppStateType } from "reducers";
import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiClientManager from "core/apiClient";

/**
 * Deprecated. We are using auth token to sign in from now
 */
export const signIn = (credentials: SignInType) => async (
  dispatch: AppDispatchType
) => {
  const api = () => authSignin(credentials);

  return callApi(
    api,
    {
      ...signInEntity(),
      requestPayload: { ...credentials },
      dispatch,
    },
    {
      enhanceErrorFn: (e: Record<any, any>) => {
        return {
          ...e,
          message:
            "Sign in failed. You either are not a super admin, or entered incorrect credentials",
        };
      },
      errorType: AUTH_ERRORS.AUTHENTICATION_ERROR,
    }
  );
};

export const fetchMe = createAsyncThunk(
  "fetchMe",
  async (
    {
      meta,
    }: {
      meta?: IMeta & {
        realmId?: string;
        tenantName?: string;
      };
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const { onSuccess, onFailure, realmId, tenantName } = meta || {};
    const {
      authReducer: { user },
    } = getState() as AppStateType;
    const token = user?.auth_token!;

    try {
      const response = await getMe(
        token,
        ApiClientManager.buildRailsServerApiConfigs({
          realmId,
          tenantName,
        })
      );
      onSuccess?.({ response: response.data });
      return response.data;
    } catch (e) {
      onFailure?.({ error: e });
      return e;
    }
  }
);

export const exchangeToken = createAsyncThunk(
  "exchangeToken",
  async (
    {
      tempToken,
      meta,
    }: {
      tempToken: string;
      // this is only used in auth layer
      meta?: IMeta<ExchangeTokenSuccessResponse> & {
        realmId?: string;
        tenantName?: string;
      };
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const { onSuccess, realmId, tenantName } = meta || {};
    try {
      const response = await getAuthToken(
        tempToken,
        ApiClientManager.buildRailsServerApiConfigs({
          tenantName,
          realmId,
        })
      );
      onSuccess?.({ response: response.data });
      return response.data;
    } catch (e) {
      return e;
    }
  }
);

export const fetchTempToken = createAsyncThunk<
  FetchTempTokenSuccessResponse,
  FetchTempTokenParams
>("fetchTempToken", async (params, { dispatch, getState, rejectWithValue }) => {
  try {
    const { onFailure, onSuccess, auth_token } = params;
    const response = await getTempToken(auth_token, {
      baseURL: ApiClientManager.getRailsServerUrl(),
    });
    onSuccess?.({ response });
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});
