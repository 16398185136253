import SimpleMenu from "components/SimpleMenu";
import { useState } from "react";
import { SimpleMenuItem } from "types";
import { ItemFilter } from "FiltersModule/utils";

type HookProps = {
  renderAnchorEl?: () => JSX.Element;
  containerElevation?: number;
  menuMaxHeight?: number;
  showSearchBar?: boolean;
  itemFilter?: ItemFilter;
  className?: string;
  idKey?: string;
};

/**
 * Deprecated, use useSimpleMenuV2
 */
const useSimpleMenu = ({
  renderAnchorEl,
  containerElevation,
  menuMaxHeight,
  showSearchBar,
  itemFilter,
  className,
  idKey
}: HookProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderSimpleMenu = ({
    disabled,
    items,
  }: {
    disabled: boolean;
    items: SimpleMenuItem[];
  }) => {
    return (
      <SimpleMenu
        disabled={disabled}
        items={items}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        renderAnchorEl={renderAnchorEl}
        elevation={containerElevation}
        menuMaxHeight={menuMaxHeight}
        showSearchBar={showSearchBar}
        itemFilter={itemFilter}
        className={className}
        idKey={idKey}
      />
    );
  };

  return {
    anchorEl,
    handleClose,
    handleClick,
    renderSimpleMenu,
  };
};

export default useSimpleMenu;
