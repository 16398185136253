import { getApiRailsServer } from "services/api";
import { FetchCountStatisticsParams } from "StatisticsModule/types";

export const getCountStatistics = (
  params: FetchCountStatisticsParams,
  config = {},
  token = ""
) => {
  const paramsToUse = {
    search_range_date: params.search_range_date,
    team_creation_date_lower_bound: params.team_creation_date_lower_bound,
    team_creation_date_upper_bound: params.team_creation_date_upper_bound,
    team_ids: params.team_ids,
    team_tags: params.team_tags,
    search_start_date: params.search_start_date,
    search_end_date: params.search_end_date,
  };

  return getApiRailsServer(
    `/statistics/overall_count`,
    {
      ...config,
      params: paramsToUse,
    },
    token
  );
};
