import styled from "styled-components";
import { MouseEventHandler, ReactNode, Ref, forwardRef } from "react";
import cn from "classnames";

const BaseSelectorUI = (
  {
    onClick,
    label,
    className,
    endAdorment,
  }: {
    onClick?: MouseEventHandler<HTMLElement>;
    label: JSX.Element | string;
    className?: string;
    endAdorment?: ReactNode;
  },
  ref: Ref<HTMLDivElement>
) => {
  return (
    <StyledBox
      onClick={onClick}
      className={cn(className, { disabled: !onClick })}
      ref={ref}
    >
      {label}
      {endAdorment}
    </StyledBox>
  );
};

export default forwardRef(BaseSelectorUI);

const StyledBox = styled.div`
  &.disabled {
    cursor: default;
    color: gray;
  }
  cursor: pointer;
  color: black;
  display: flex;
  border-bottom: 1px solid black;
  width: fit-content;
  &:focus {
    border-width: 2px;
  }
`;
