import { FormEvent, MouseEventHandler, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import debounce from "lodash/debounce";
import { useSandboxModalFields } from "SandboxModule/hooks/useSandboxModalFields";
import { checkRealmTeamNameInputError } from "utils/realm";
import { useSelector } from "react-redux";
import { selectAuthUser } from "selectors";
import { useRealmIdGeneration } from "SandboxModule/hooks/useRealmIdGeneration";
import { useCreateRealm } from "SandboxModule/hooks/useCreateRealm";
import { useTenantDropdown } from "TenantModule/hooks/useTenantDropdown";
import { RealmTypes } from "RealmModule/constants/realm";
import { integrationAgents, IntegrationAgentNames } from "@constants";
import { useIntegrationAgentsMultiSelector } from "IntegrationModule/components/IntegrationAgentsMultiSelector/useIntegrationAgentsMultiSelector";

export interface IntegrationAgentSecretMappingItem {
  integrationAgentName: IntegrationAgentNames | string;
  secret: string;
  id: string;
}

interface SubscriptionRealmFields {
  teamName: string;
  realmType: string;
  isFirstRealm?: boolean;
  integrationAgentName?: IntegrationAgentNames;
}

const createSubscriptionRealmFieldsInitialValues = {
  teamName: "",
  realmType: RealmTypes.subscription,
  isFirstRealm: false,
};

const defaultRegion = "us-east-1";
const defaultDomain = "mosaicapp.com";

export const useCreateSubscriptionRealmForm = ({
  showAdvancedFields = false,
  onSuccess,
}: {
  showAdvancedFields?: boolean;
  onSuccess?: VoidFunction;
}) => {
  const [
    createSubscriptionRealmFields,
    setCreateSubscriptionRealmFields,
  ] = useState<SubscriptionRealmFields>(
    createSubscriptionRealmFieldsInitialValues
  );
  const {
    handleTagCheckboxChange,
    inputRef,
    standardTags,
    addTag,
    removeTag,
    tagsList,
    tagError,
    teamNameRef,
    teamNameInputError,
    setTeamNameInputError,
  } = useSandboxModalFields();
  const user = useSelector(selectAuthUser);
  const integrationAgentsSelectorProps = useIntegrationAgentsMultiSelector();
  const { openTenantDropdown, selectedTenant } = useTenantDropdown();

  const { createRealm, isCreatingRealm } = useCreateRealm({
    onSuccess,
  });

  const generateRealmIdOnSuccess: Parameters<
    typeof useRealmIdGeneration
  >[0]["onSuccess"] = ({ response }) => {
    const realmId = response.data;
    const additionalServices =
      integrationAgentsSelectorProps.selectedIntegrationAgents;

    const adminCredentials = integrationAgentNamesAndSecrets.reduce<
      Record<string, string>
    >((acc, item) => {
      acc[item.integrationAgentName] = item.secret;
      return acc;
    }, {});

    if (realmId && user && selectedTenant) {
      const params = {
        realmId,
        teamName: createSubscriptionRealmFields.teamName,
        domain: defaultDomain,
        region: defaultRegion,
        realmType: RealmTypes.subscription,
        tenantName: selectedTenant.tenantName,
        tags: tagsList,
        metaPayload: { name: user.account.name },
        ...(additionalServices.length && {
          additionalServices,
        }),
        ...(showAdvancedFields && {
          adminCredentials,
          isFirstRealm: createSubscriptionRealmFields.isFirstRealm,
        }),
      };

      alert(
        `Initiating creating new subscription: ${createSubscriptionRealmFields.teamName}. It will take a while.`
      );
      createRealm(params);
    }
  };

  const { generateRealmId, isGeneratingRealmId } = useRealmIdGeneration({
    onSuccess: generateRealmIdOnSuccess,
  });

  const [
    integrationAgentNamesAndSecrets,
    setIntegrationAgentNamesAndSecrets,
  ] = useState<IntegrationAgentSecretMappingItem[]>([]);

  const selectedIntegrationAgentNamesSet = new Set(
    integrationAgentNamesAndSecrets.map((item) => item.integrationAgentName)
  );

  const handleIntegrationAgentsSecretsChange = ({
    name,
    value,
    id,
  }: {
    name: string;
    value: string;
    id: string;
  }) => {
    setIntegrationAgentNamesAndSecrets((prev) => {
      return prev.map((item) =>
        item.id === id
          ? {
              ...item,
              [name]: value,
            }
          : item
      );
    });
  };

  const onAddNewIntegrationSecret = () => {
    const uuid = uuidv4();
    const newIntegrationSecret = {
      integrationAgentName: "",
      secret: "",
      id: uuid,
    };

    setIntegrationAgentNamesAndSecrets((prev) => [
      ...prev,
      newIntegrationSecret,
    ]);
  };

  const removeIntegrationSecretsRow = (id: string) => {
    const newState = integrationAgentNamesAndSecrets.filter(
      (item) => item.id !== id
    );
    setIntegrationAgentNamesAndSecrets(newState);
  };

  const onCreateSubscriptionRealmFormChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value, type } = event.target;
    if (type === "checkbox") {
      const isChecked = event.target.checked;
      setCreateSubscriptionRealmFields((prev) => ({
        ...prev,
        [name]: isChecked,
      }));
    } else {
      setCreateSubscriptionRealmFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const debounceHandleTeamNameChange = debounce(() => {
    setTeamNameInputError(
      checkRealmTeamNameInputError(teamNameRef.current.value)
    );
  }, 50);

  const onTenantSelectorClick: MouseEventHandler<HTMLElement> = (e) => {
    openTenantDropdown({
      target: e.currentTarget,
    });
  };

  // only allow to add each integration once
  const integrationAgentsOverride = integrationAgents.map((item) => {
    if (selectedIntegrationAgentNamesSet.has(item.value)) {
      return { ...item, disabled: true };
    }
    return item;
  });

  const onSubmitCreateSubscriptionRealmForm = (
    event: FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    generateRealmId({ teamName: createSubscriptionRealmFields.teamName });
  };

  const onPressEnter = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return {
    createSubscriptionRealmFields,
    onCreateSubscriptionRealmFormChange,
    debounceHandleTeamNameChange,
    onSubmitCreateSubscriptionRealmForm,
    onPressEnter,
    isRequesting: isCreatingRealm || isGeneratingRealmId,
    showAdvancedFields,
    selectedTenant,
    onTenantSelectorClick,
    handleTagCheckboxChange,
    inputRef,
    standardTags,
    addTag,
    removeTag,
    tagsList,
    tagError,
    teamNameRef,
    teamNameInputError,
    integrationAgentNamesAndSecrets,
    onAddNewIntegrationSecret,
    removeIntegrationSecretsRow,
    handleIntegrationAgentsSecretsChange,
    integrationAgentsOverride,
    integrationAgentsSelectorProps,
  };
};
