import Modal from "shared/Modal";
import { CreateSubscriptionRealmForm } from "./CreateSubscriptionRealmForm";

export const CreateSubscriptionRealmModal = ({
  toggle,
}: {
  toggle: VoidFunction;
}) => {
  return (
    <Modal open onClose={toggle}>
      <CreateSubscriptionRealmForm
        disableTenantSelector
        onFormSubmitSuccess={toggle}
      />
    </Modal>
  );
};
