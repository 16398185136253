import { Tooltip } from "@material-ui/core";
import { csmHashById } from "CsmModule/constants";
import BaseSelectorWithArrow from "components/BaseSelectorWithArrow";
import { MouseEventHandler } from "react";

export const CsmSelector = ({
  accountId,
  onClick,
  tooltip = "",
}: {
  disabled?: boolean;
  accountId: Nullable<number>;
  onClick: MouseEventHandler<HTMLElement>;
  tooltip?: string;
}) => {
  const name = accountId
    ? csmHashById[accountId]?.name || "Set CSM"
    : "Set CSM";

  return (
    <Tooltip title={tooltip}>
      <BaseSelectorWithArrow onClick={onClick} label={name} />
    </Tooltip>
  );
};
