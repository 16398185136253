import { useDispatch } from "react-redux";
import { FilterType } from "types";
import { setFilters } from "actionCreators";
import { useCallback } from "react";

type HookProps = {
  filter: FilterType;
};

const useRequestSort = ({ filter }: HookProps) => {
  const dispatch = useDispatch();

  const toggleSort = useCallback(({ orderBy }: { orderBy: string }) => {
    if (!filter) return;

    const isAsc =
      filter.orderBy === orderBy && filter.orderDirection === "asc";

    dispatch(
      setFilters({
        filterId: filter.id,
        offset: 0,
        page: 0,
        limit: filter.limit as number,
        orderBy,
        orderDirection: isAsc ? "desc" : "asc",
      })
    );
  }, [dispatch, filter]);

  /**
   * @deprecated
   */
  const onRequestSort = (event: any, property: string) => {
    if (!filter) return;

    const isAsc =
      filter.orderBy === property && filter.orderDirection === "asc";
    dispatch(
      setFilters({
        filterId: filter.id,
        offset: 0,
        page: 0,
        limit: filter.limit as number,
        orderBy: property,
        orderDirection: isAsc ? "desc" : "asc",
      })
    );
  };

  return { onRequestSort, toggleSort };
};

export default useRequestSort;
