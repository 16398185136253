import { AppStateType } from "reducers";
import { keyBy, orderBy } from "lodash";
import { createSelector } from "reselect";
import { ValueOf } from "type-fest";

import {
  integrations,
  MAPPING_STATUS,
  DATA_TYPES,
  filterIds,
} from "@constants";
import {
  IntegrationHealth,
  IntegrationServiceMetricsTotalDataInstance,
  TelemetryLastUpdatesType,
} from "types";
import {
  isDataTypePending,
  serializeId,
  isCreateOnMosaic,
  isCreateOnTarget,
  isUpdateOnMosaic,
  isUpdateOnTarget,
  sumRequestAndSuccessIntegrationHealthCountsByTargetService,
} from "utils";
import * as thunks from "thunk";
import * as IntegrationTypes from "IntegrationModule/types";
import { selectFilterReducer } from "FiltersModule/selectors";
import * as Types from "types";
import groupBy from "lodash/groupBy";
const emptyObj = {};
const emptyArr: never[] = [];

/* --------------------------------- Helpers -------------------------------- */

const parseJson = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return {};
  }
};

const makeTotalCountsPerDataTypeObj = () =>
  DATA_TYPES.reduce(
    (
      acc: Record<string, IntegrationTypes.TotalCountPerDataTypeValues>,
      dataType: string
    ) => {
      acc[dataType] = {
        synced: 0,
        updateOnTarget: 0,
        updateOnMosaic: 0,
        createOnTarget: 0,
        createOnMosaic: 0,
        pendingOnTarget: 0,
        pendingOnMosaic: 0,
        totalPending: 0,
        activeOnly: 0,
      };
      return acc;
    },
    {}
  );

const makeTotalCountsPerDataTypeObjByTargetServiceIds = (
  targetServiceAndTeamIdsHash: IntegrationTypes.TargetServiceIdAndTeamIds[]
) => {
  return targetServiceAndTeamIdsHash?.reduce(
    (
      acc: Record<number, any>,
      curr: IntegrationTypes.TargetServiceIdAndTeamIds
    ) => {
      const { targetServiceId, mosaicTeamId } = curr;

      acc[targetServiceId] = {
        ...makeTotalCountsPerDataTypeObj(),
        mosaicTeamId,
        targetServiceId,
      };
      return acc;
    },
    {}
  );
};

const makeCountPerDataTypeObj = () =>
  DATA_TYPES.reduce(
    (
      acc: Record<string, IntegrationTypes.CountPerDataTypeValues>,
      dataType: string
    ) => {
      acc[dataType] = {
        total: 0,
        pending: 0,
        createOnTarget: 0,
        createOnMosaic: 0,
        updateOnMosaic: 0,
        updateOnTarget: 0,
        totalCreate: 0,
        totalUpdate: 0,
        dataType,
      };
      return acc;
    },
    {}
  );

const makeCountPerDataTypeObjByTargetServiceIds = (
  targetServiceAndTeamIdsHash: IntegrationTypes.TargetServiceIdAndTeamIds[]
) => {
  return (
    targetServiceAndTeamIdsHash?.reduce(
      (
        acc: Record<number, any>,
        curr: IntegrationTypes.TargetServiceIdAndTeamIds
      ) => {
        const { targetServiceId, mosaicTeamId } = curr;

        acc[targetServiceId] = {
          ...makeCountPerDataTypeObj(),
          mosaicTeamId,
          targetServiceId,
        };
        return acc;
      },
      {}
    ) || {}
  );
};

const selectOwnPropsIsoStateId = (
  _: AppStateType,
  ownProps: Record<string, any>
) => ownProps?.isoStateId;

const selectOwnPropsTargetService = (
  _: AppStateType,
  ownProps: Record<string, any>
) => ownProps?.targetService;

const selectOwnPropsTargetServiceId = (
  _: AppStateType,
  ownProps: Record<string, any>
) => ownProps?.targetServiceId;

/* ----------------------------Filters, Request Status and Error State---------------------------------------------- */
export const selectOverallIntegrationRequestStatusesAndErrors = (
  state: AppStateType
) => state.overallIntegrationReducer.requestStatusesAndErrors;

/* -------------------------------Service Metrics by Team------------------------------------------- */

const selectServiceMetricsState = (state: AppStateType) =>
  state.overallIntegrationReducer.service_metrics;

export const selectServiceMetricsData = createSelector(
  selectServiceMetricsState,
  selectOverallIntegrationRequestStatusesAndErrors,
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[
      thunks.fetchIntegrationServiceMetrics.typePrefix
    ],
  })
);

export const selectTargetServiceIdsAndTeamIdsByTargetServiceIdHash = createSelector(
  selectServiceMetricsData,
  ({ data }) => keyBy(data?.targetServiceAndTeamIds, "targetServiceId")
);

export const selectProcessedServiceMetricsData = createSelector(
  [selectServiceMetricsData],
  ({ data: rawServiceData, isRequesting, error }) => {
    if (!rawServiceData) return { data: [], isRequesting, error };

    const countPerDataTypeObjByTargetServiceIds = makeCountPerDataTypeObjByTargetServiceIds(
      (rawServiceData?.targetServiceAndTeamIds as unknown) as IntegrationTypes.TargetServiceIdAndTeamIds[]
    );

    const processedServiceMetricsData = rawServiceData?.data?.reduce(
      (
        acc: Record<number, any>,
        curr: IntegrationTypes.RawServiceMetricsDataInstance
      ) => {
        const { mappingStatus, dataType, count, targetServiceId } = curr;
        const parsedCount = +count ?? 0;
        if (acc[targetServiceId]) {
          const currDataTypeRecords: IntegrationTypes.CountPerDataTypeValues =
            acc[targetServiceId]?.[dataType] || {};

          const currTotalCount = currDataTypeRecords.total ?? 0;
          const currPending = currDataTypeRecords.pending ?? 0;

          /* -------------------------------------------------------------------------- */

          const currCreateOnTarget = currDataTypeRecords.createOnTarget ?? 0;
          const currCreateOnMosaic = currDataTypeRecords.createOnMosaic ?? 0;
          const currTotalCreate = currDataTypeRecords.totalCreate ?? 0;

          const currUpdateOnTarget = currDataTypeRecords.updateOnTarget ?? 0;
          const currUpdateOnMosaic = currDataTypeRecords.updateOnMosaic ?? 0;
          const currTotalUpdate = currDataTypeRecords.totalUpdate ?? 0;

          acc[targetServiceId] = {
            ...acc[targetServiceId],
            [dataType]: {
              ...acc[targetServiceId]?.[dataType],
              total: currTotalCount + parsedCount, // Everything regardless of mapping status
              pending:
                currPending +
                (isDataTypePending(mappingStatus) ? parsedCount : 0),

              /* -------------------------------------------------------------------------- */
              createOnTarget:
                currCreateOnTarget +
                (isCreateOnTarget(mappingStatus) ? parsedCount : 0),
              createOnMosaic:
                currCreateOnMosaic +
                (isCreateOnMosaic(mappingStatus) ? parsedCount : 0),
              totalCreate:
                currTotalCreate +
                (isCreateOnTarget(mappingStatus) ||
                isCreateOnMosaic(mappingStatus)
                  ? parsedCount
                  : 0),
              updateOnTarget:
                currUpdateOnTarget +
                (isUpdateOnTarget(mappingStatus) ? parsedCount : 0),
              updateOnMosaic:
                currUpdateOnMosaic +
                (isUpdateOnMosaic(mappingStatus) ? parsedCount : 0),
              totalUpdate:
                currTotalUpdate +
                (isUpdateOnTarget(mappingStatus) ||
                isUpdateOnMosaic(mappingStatus)
                  ? parsedCount
                  : 0),
            },
          };
        }

        return acc;
      },
      countPerDataTypeObjByTargetServiceIds
    );

    return {
      data: processedServiceMetricsData || emptyObj,
      isRequesting,
      error,
    };
  }
);
/* --------------------------- Service Metrics Sum -------------------------- */

const selectServiceMetricsTotalState = (state: AppStateType) =>
  state.overallIntegrationReducer.service_metrics_sum;

const selectServiceMetricsTotal = createSelector(
  selectServiceMetricsTotalState,
  selectOverallIntegrationRequestStatusesAndErrors,
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[
      thunks.fetchIntegrationServiceMetricsTotal.typePrefix
    ],
  })
);

type TotalCountsGroupedByTargetServiceIdThenDataTypes = Record<
  number,
  Record<string, IntegrationServiceMetricsTotalDataInstance[]>
>;

export const selectSyncOverviewCountsData = createSelector(
  selectServiceMetricsTotal,
  ({ data: rawServiceMetricsTotalData, isRequesting }) => {
    const groupByTeamIds = groupBy(rawServiceMetricsTotalData, "mosaicTeamId");

    const countsGroupedByTeamIdsThenTargetServiceIdThenDataTypes = Object.entries(
      groupByTeamIds
    ).reduce(
      (
        acc: Record<number, TotalCountsGroupedByTargetServiceIdThenDataTypes>,
        [teamId, values]
      ) => {
        const groupByTargetServiceIds = groupBy(values, "targetServiceId");

        const countsGroupedByTargetServiceIdThenDataTypes = Object.entries(
          groupByTargetServiceIds
        ).reduce(
          (
            acc: TotalCountsGroupedByTargetServiceIdThenDataTypes,
            [targetServiceId, countsValues]
          ) => {
            acc[+targetServiceId] = groupBy(countsValues, "dataType");

            return acc;
          },
          {}
        );

        acc[+teamId] = countsGroupedByTargetServiceIdThenDataTypes;

        return acc;
      },
      {}
    );

    const results = Object.entries(
      countsGroupedByTeamIdsThenTargetServiceIdThenDataTypes
    ).reduce(
      (
        acc: Record<
          string,
          | {
              synced: number;
              activeOnly: number;
              updateOnMosaic: number;
              updateOnTarget: number;
              createOnMosaic: number;
              createOnTarget: number;
              pendingOnMosaic: number;
              pendingOnTarget: number;
              totalPending: number;
              totalPendingUpdate: number;
            }
          | Record<string, never>
        >,
        [teamId, countsByTargetServiceThenDataType]
      ) => {
        Object.entries(countsByTargetServiceThenDataType).forEach(
          ([targetServiceId, countsByDataTypes]) => {
            Object.entries(countsByDataTypes).forEach(([dataType, counts]) => {
              const serializedId = serializeId([
                teamId,
                targetServiceId,
                dataType,
              ]);

              acc[serializedId] = {} as any;

              let synced = 0;
              let activeOnly = 0;

              let updateOnTarget = 0;
              let createOnTarget = 0;
              let pendingOnTarget = 0;

              let updateOnMosaic = 0;
              let createOnMosaic = 0;
              let pendingOnMosaic = 0;

              let totalPending = 0;
              let totalPendingUpdate = 0;

              counts.forEach((dataTypeCount) => {
                const { mappingStatus, count } = dataTypeCount;
                const numCount = +count; // count is str for some reason

                // Active Count + Update on Mosaic + Update on Target
                synced =
                  mappingStatus === MAPPING_STATUS.ACTIVE ||
                  mappingStatus === MAPPING_STATUS.UPDATE_ON_TARGET ||
                  mappingStatus === MAPPING_STATUS.UPDATE_ON_MOSAIC
                    ? numCount + synced
                    : synced;

                activeOnly =
                  mappingStatus === MAPPING_STATUS.ACTIVE
                    ? numCount + activeOnly
                    : activeOnly;

                /** Pending on target
                 *   = Create on Target ONLY. Show Update on Target as tooltip and a small number greyed out on cell.
                 */
                updateOnTarget =
                  MAPPING_STATUS.UPDATE_ON_TARGET === mappingStatus
                    ? numCount + updateOnTarget
                    : updateOnTarget;

                createOnTarget =
                  MAPPING_STATUS.CREATE_NEW_ON_TARGET === mappingStatus
                    ? numCount + createOnTarget
                    : createOnTarget;

                pendingOnTarget = createOnTarget;

                /** Pending on mosaic
                 * = Create on Mosaic ONLY. Show Update on Mosaic as tooltip and a small number greyed out on cell.
                 */
                updateOnMosaic =
                  MAPPING_STATUS.UPDATE_ON_MOSAIC === mappingStatus
                    ? numCount + updateOnMosaic
                    : updateOnMosaic;

                createOnMosaic =
                  MAPPING_STATUS.CREATE_NEW_ON_MOSAIC === mappingStatus
                    ? numCount + createOnMosaic
                    : createOnMosaic;

                pendingOnMosaic = createOnMosaic;

                /** Total pending
                 * DO NOT INCLUDE UPDATE. Main cell should only SHOW total CREATE, the small number grey out on cell show total UPDATE
                 */
                totalPending = pendingOnTarget + pendingOnMosaic; // Currently is just createOnMosaic + createOnTarget
                totalPendingUpdate = updateOnMosaic + updateOnTarget;

                // For each data type
              });

              acc[serializedId] = {
                synced,
                activeOnly,
                updateOnMosaic,
                updateOnTarget,
                createOnMosaic,
                createOnTarget,
                pendingOnMosaic,
                pendingOnTarget,
                totalPending,
                totalPendingUpdate,
              };
            });
          }
        );

        return acc;
      },
      {}
    );

    return { data: results, isRequesting };
  }
);

/**
 * @Deprecated
 */
export const selectServiceMetricsTotalData = createSelector(
  selectServiceMetricsTotal,
  ({ data: rawServiceMetricsTotalData, isRequesting, error }) => {
    if (!rawServiceMetricsTotalData || isRequesting)
      return { data: {}, isRequesting, error };
    /**
     *Make the target service id and mosaic team id array
     */
    const targetServiceAndTeamIds = Object.entries(
      rawServiceMetricsTotalData.reduce(
        (
          acc: Record<number, number>,
          rawData: IntegrationServiceMetricsTotalDataInstance
        ) => {
          const { mosaicTeamId, targetServiceId } = rawData;
          acc[targetServiceId] = mosaicTeamId;
          return acc;
        },
        {}
      )
    ).reduce(
      (acc: IntegrationTypes.TargetServiceIdAndTeamIds[], [key, value]) => {
        acc.push({
          mosaicTeamId: value as number,
          targetServiceId: parseInt(key as string),
        });
        return acc;
      },
      []
    );

    const totalCountsPerDataTypeObjByTargetServiceIds = makeTotalCountsPerDataTypeObjByTargetServiceIds(
      targetServiceAndTeamIds as IntegrationTypes.TargetServiceIdAndTeamIds[]
    );

    /**
     * Spec:
     * - Synced = Active Count + Update on Mosaic + Update on Target
     * - Pending on Mosaic = Create on Mosaic ONLY. Show Update on Mosaic as tooltip and a small number greyed out on cell.
     * - Pending on Target = Create on Target ONLY. Show Update on Target as tooltip and a small number greyed out on cell.
     * - Total Pending = DO NOT INCLUDE UPDATE. Main cell should only SHOW total CREATE, the small number grey out on cell show total UPDATE
     */
    const processedServiceMetricsTotalData = rawServiceMetricsTotalData.reduce(
      (
        acc: Record<number, any>,
        curr: IntegrationServiceMetricsTotalDataInstance
      ) => {
        const { mappingStatus, dataType, count, targetServiceId } = curr;
        const parsedCount = parseInt(count);
        const countsByDataType: IntegrationTypes.TotalCountPerDataTypeValues =
          acc[targetServiceId][dataType];

        if (!countsByDataType) return acc;

        // Active Count + Update on Mosaic + Update on Target
        const synced =
          mappingStatus === MAPPING_STATUS.ACTIVE ||
          mappingStatus === MAPPING_STATUS.UPDATE_ON_TARGET ||
          mappingStatus === MAPPING_STATUS.UPDATE_ON_MOSAIC
            ? parsedCount + countsByDataType.synced
            : countsByDataType.synced;

        const activeOnly =
          mappingStatus === MAPPING_STATUS.ACTIVE
            ? parsedCount + countsByDataType.activeOnly
            : countsByDataType.activeOnly;

        /** Pending on target
         *   = Create on Target ONLY. Show Update on Target as tooltip and a small number greyed out on cell.
         */
        const updateOnTarget =
          MAPPING_STATUS.UPDATE_ON_TARGET === mappingStatus
            ? parsedCount + countsByDataType.updateOnTarget
            : countsByDataType.updateOnTarget;
        const createOnTarget =
          MAPPING_STATUS.CREATE_NEW_ON_TARGET === mappingStatus
            ? parsedCount + countsByDataType.createOnTarget
            : countsByDataType.createOnTarget;

        const pendingOnTarget = createOnTarget;

        /** Pending on mosaic
         * = Create on Mosaic ONLY. Show Update on Mosaic as tooltip and a small number greyed out on cell.
         */
        const updateOnMosaic =
          MAPPING_STATUS.UPDATE_ON_MOSAIC === mappingStatus
            ? parsedCount + countsByDataType.updateOnMosaic
            : countsByDataType.updateOnMosaic;

        const createOnMosaic =
          MAPPING_STATUS.CREATE_NEW_ON_MOSAIC === mappingStatus
            ? parsedCount + countsByDataType.createOnMosaic
            : countsByDataType.createOnMosaic;

        const pendingOnMosaic = createOnMosaic;

        /** Total pending
         * DO NOT INCLUDE UPDATE. Main cell should only SHOW total CREATE, the small number grey out on cell show total UPDATE
         */
        const totalPending = pendingOnTarget + pendingOnMosaic; // Currently is just createOnMosaic + createOnTarget
        const totalPendingUpdate = updateOnMosaic + updateOnTarget;

        if (acc[targetServiceId]) {
          acc[targetServiceId] = {
            ...acc[targetServiceId],
            [dataType]: {
              updateOnTarget,
              updateOnMosaic,
              createOnTarget,
              createOnMosaic,
              synced,
              pendingOnTarget,
              pendingOnMosaic,
              totalPending,
              totalPendingUpdate,
              activeOnly,
            },
          };
        }
        return acc;
      },
      totalCountsPerDataTypeObjByTargetServiceIds
    );

    return { data: processedServiceMetricsTotalData, isRequesting, error };
  }
);

/* ------------------------------Agent Error Log -------------------------------------------- */
export const selectAgentErrorLogFilter = createSelector(
  selectFilterReducer,
  (filters) => filters[filterIds.agentErrorLog]
);

export const selectAgentErrorLogData = createSelector(
  (state: AppStateType) => state.overallIntegrationReducer.agent_error_log,
  selectOverallIntegrationRequestStatusesAndErrors,
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[thunks.fetchAgentErrorLog.typePrefix],
  })
);

export const selectAgentErrorsLog = createSelector(
  selectAgentErrorLogData,
  selectAgentErrorLogFilter,
  (agentErrorLog, filter) => {
    const { data, isRequesting, error } = agentErrorLog;

    if (!data || data.length === 0 || isRequesting || error?.message) {
      return {
        formattedData: [],
        filter,
        fetchedSoFar: 0,
        isRequesting,
        error,
        total: data && data.length > 0 ? data[0]?.total_rows : 0,
      };
    }
    /** Sorting */
    const sortedData = orderBy(data, filter.orderBy, filter.orderDirection);

    /** Slicing */
    const startIdx = filter.offset;
    const endIdx = filter.offset + filter.limit;
    const formattedData = sortedData.slice(startIdx, endIdx);

    return {
      filter,
      total: formattedData[0].total_rows,
      formattedData,
      fetchedSoFar: data.length,
      isRequesting,
      error,
    };
  }
);

/* -----------------------------Network Error Log --------------------------------------------- */

const selectNetworkErrorLogFilter = (state: AppStateType) =>
  state.filterReducer[filterIds.generalNetworkErrorLog];

const selectNetworkErrorLogState = (state: AppStateType) =>
  state.overallIntegrationReducer.network_error_log;

const selectNetworkErrorLogData = createSelector(
  selectNetworkErrorLogState,
  selectOverallIntegrationRequestStatusesAndErrors,
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[thunks.fetchNetworkErrorLog.typePrefix],
  })
);

export const selectNetworkErrorLog = createSelector(
  selectNetworkErrorLogData,
  selectNetworkErrorLogFilter,
  (networkErrorLog, filter) => {
    const { data, isRequesting, error } = networkErrorLog;

    if (!data || data.length === 0 || isRequesting || error?.message) {
      return {
        formattedData: [],
        filter,
        fetchedSoFar: 0,
        isRequesting,
        error,
        total: data && data.length > 0 ? data[0].total_rows : 0,
      };
    }

    /** Parse meta data */

    const parsedNetworkErrorLog = data.map((data) => {
      const parsedMetaData = data.metadata ? parseJson(data.metadata) : {};
      const parsedError = data.error ? parseJson(data.error) : {};

      return {
        ...data,
        ...parsedMetaData,
        "Meta Data": parsedMetaData,
        "All Errors": parsedError,
      };
    });

    /** Sorting */
    const sortedData = orderBy(
      parsedNetworkErrorLog,
      filter.orderBy,
      filter.orderDirection
    );

    // /** Slicing */
    const startIdx = filter.offset;
    const endIdx = filter.offset + filter.limit;
    const formattedData = sortedData.slice(startIdx, endIdx);

    return {
      filter,
      total: formattedData[0].total_rows,
      formattedData,
      fetchedSoFar: data.length,
      isRequesting,
      error,
    };
  }
);

/* --------------------------- Integration Health --------------------------- */
const selectIntegrationHealth = (state: AppStateType) =>
  state.overallIntegrationReducer.integration_health;

export const selectIntegrationHealthDataByIsoStateId = createSelector(
  selectIntegrationHealth,
  selectOwnPropsIsoStateId,
  selectOverallIntegrationRequestStatusesAndErrors,
  (state, isoStateId, requestStatusesAndErrors) => {
    return {
      data: state[isoStateId] || emptyObj,
      ...requestStatusesAndErrors[
        serializeId([thunks.fetchIntegrationHealth.typePrefix, isoStateId])
      ],
    };
  }
);

/* ------------------------ Integration Health Table ------------------------ */
export const selectIntegrationHealthTableFilter = createSelector(
  selectFilterReducer,
  (filters) => filters[filterIds.IntegrationHealthTable]
);

export const selectIntegrationHealthTableData = createSelector(
  selectIntegrationHealthDataByIsoStateId,
  selectOwnPropsTargetService,
  selectOwnPropsTargetServiceId,
  selectIntegrationHealthTableFilter,
  ({ data, isRequesting, error }, targetService, targetServiceId, filter) => {
    const { orderDirection, orderBy: filterOrderBy } = filter;
    const selectedData: Types.IntegrationHealth[] =
      data[targetService]?.[targetServiceId] || emptyArr;

    // Show all the rows as we fetch
    const formattedGroupedOrderedData = orderBy(
      selectedData.map((healthData) => ({
        ...healthData,
        ratio: healthData.successCount / healthData.requestCount,
        ratioLabel: `${healthData.successCount} / ${healthData.requestCount}`,
      })),
      filterOrderBy,
      orderDirection
    );

    return {
      data: formattedGroupedOrderedData,
      filter,
      isRequesting,
      error,
    };
  }
);

/* --------------------------- Active integration --------------------------- */

export const selectActiveIntegrations = (state: AppStateType) =>
  state.overallIntegrationReducer.active_integrations;

export const selectActiveIntegrationHash = createSelector(
  selectActiveIntegrations,
  (activeIntegrations) => keyBy(activeIntegrations, "targetService")
);

export const makeGetSelectActiveIntegrationCountsAndIntegrationHealthByIsoState = () =>
  createSelector(
    selectActiveIntegrationHash,
    selectIntegrationHealthDataByIsoStateId,
    (activeIntegrationHash, integrationHealthByIsoState) => {
      const integrationActiveCount = integrations.map((integration) => {
        const activeCount = activeIntegrationHash[integration.id]?.count
          ? `${activeIntegrationHash[integration.id].count}`
          : 0;

        const integrationHealth = integrationHealthByIsoState.data;

        return {
          integrationId: integration.id,
          activeCount,
          label: integration.label,
          // integrationHealthSum: sumRequestAndSuccessIntegrationHealthCountsByTargetService(
          //   integrationHealthByIsoState.data,
          //   integration.id
          // ),
          integrationHealth: integrationHealth[integration.id]?.reduce(
            (
              acc: {
                requestCount: number;
                successCount: number;
                serviceName: string;
              },
              healthData: IntegrationHealth
            ) => {
              const {
                requestCount: curRequestCount,
                successCount: curSuccessCount,
              } = healthData;

              acc.requestCount += curRequestCount;
              acc.successCount += curSuccessCount;
              return acc;
            },
            {
              requestCount: 0,
              successCount: 0,
              serviceName: integration.id,
            }
          ),
        };
      });
      const activeIntegrationHealthHash = keyBy(
        integrationActiveCount,
        "integrationId"
      );

      return activeIntegrationHealthHash;
    }
  );

export const selectIntegrationAgentDomains = (state: AppStateType) =>
  state.overallIntegrationReducer.integration_agent_domains;

export const selectIntegrationAgentDomainsData = createSelector(
  selectIntegrationAgentDomains,
  selectOverallIntegrationRequestStatusesAndErrors,
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[thunks.fetchIntegrationAgentDomains.typePrefix],
  })
);
